@use "../card"as card;
@use "../constants"as constants;
@use "../form"as form;
@use "../buttons"as buttons;

$padding-horizontal: 15px;

.pizza-settings-edit-widget {
  .form-input {
    .topping,
    .pizza-size {
      @include card.container;
      @include card.stack;
      margin-bottom: 20px;

      .info {
        @include card.upper;
        @include form.inline;
      }
      .actions {
        @include card.lower;
        @include card.round-buttons;
        .action {
          @include card.round-button;
        }
      }
    }

    .toppings,
    .pizza-sizes {
      button {
        @include buttons.primary-transparent;
      }
    }
  }

  .pizza-sizes-form-item > label,
  .toppings-list-form-item > label {
    @include form.top-align-label();
  }
}
