@use "../constants" as constants;
@use '../viewport'as viewport;
@use "../themes/default" as default-theme;
@use '../typography' as typography;

@include viewport.tablet {
  .platform-maintenance-banner {
    position: sticky;
    top: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #{default-theme.$red-light};
    height: constants.$store-close-banner-height;
    @include typography.body-regular;
    text-align: center;
    color: #b0204b;
  }

  .platform-maintenance-banner + .top-nav-bar-widget {
    top: constants.$store-close-banner-height;
  }
}
