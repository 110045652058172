@use '../viewport' as viewport;
@use '../constants' as constants;
@use '../themes/default' as default-theme;
@use "../typography" as typography;

@include viewport.mobile {
  .order-history-page {
    background: #{default-theme.$material-gray-50};
    height: calc(100vh - (56px + 68px));

    .list {
      margin: 0 constants.$horizontal-gap;

      .order-list-view {
        display: grid;
        grid-template-areas:
          'collection-type status'
          'time time'
          'address address'
          'customer customer'
          'grand-total grand-total';
        grid-template-columns: 1fr auto;
        background: white;
        border: 1px solid #{default-theme.$material-gray-300};
        box-sizing: border-box;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        margin: 20px 0px;
        padding: 16px;

        .status {
          grid-area: status;
          display: block;
          margin-bottom: 9px;
        }

        .collection-type {
          grid-area: collection-type;
          @include typography.body-bold;
          color: #{default-theme.$restoplus-dark};
          margin-bottom: 9px;
        }

        .time {
          grid-area: time;
          @include typography.body-regular;
          color: #{default-theme.$restoplus-dark};
        }

        .address {
          grid-area: address;
          @include typography.body-regular;
          color: #{default-theme.$restoplus-dark};
          margin-bottom: 8px;
        }

        .customer {
          grid-area: customer;
          @include typography.caption-regular;
          color: #{default-theme.$material-gray-600};
          margin-bottom: 8px;
        }

        .grand-total {
          grid-area: grand-total;
          @include typography.caption-regular;
          color: #{default-theme.$material-gray-600};
        }
      }
    }

    .body {
      .message {
        span {
          text-decoration: underline;
        }
      }
    }
  }
}
