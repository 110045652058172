@use "../viewport" as viewport;
@use "../constants" as constants;
@use "../themes/default" as default-theme;
@use "../typography" as typography;
@use "../colors";

@include viewport.mobile {
  .order-view-page {
    background-color: white;

    .order-view-header {
      display: none;
    }

    .order-tracking-label {
      margin: 5px constants.$horizontal-gap;
      color: #27283e;
      line-height: 24px;
      font-size: 18px;
      font-weight: 600;
    }

    .order-status-widget {
      margin-top: 20px;
    }

    .order-view-widget {
      margin-left: 20px;
      margin-right: 20px;
    }

    .order-placed-time {
      margin: 20px constants.$horizontal-gap 0;

      @include typography.caption-regular;
      text-align: center;
      color: colors.$light-gray-900;
    }

    .cancel-order-info {
      margin: 0 constants.$horizontal-gap;
      padding-top: 42px;
      text-align: center;
      color: colors.$material-gray-500;

      span {
        @include typography.body-bold;
      }

      .contact {
        @include typography.caption-regular;
        a {
          margin-left: 3px;
          color: #{default-theme.$restoplus-teal};
        }
      }
    }

    .buttons {
      margin: 0 constants.$horizontal-gap;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      > .customer-review-link-button {
        border: 1px solid colors.$material-gray-300;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
        background-color: colors.$white;
        padding: 20px;
        margin-top: 20px;

        > .label {
          display: flex;
          align-items: center;

          img {
            width: 44px;
            height: 44px;
          }

          span {
            color: colors.$blue-gray-900;
            padding-left: 16px;
          }
        }
      }

      .back-to-menu-button {
        background-color: transparent;
        border: none;
        box-shadow: none;
        color: colors.$material-gray-500;
        margin-top: 16px;
        padding: 14px 50px;
      }
    }
  }
}
