@use "../card"as card;
@use "../switch"as switch;
@use "../constants"as constants;
@use "../form"as form;
@use "../buttons"as buttons;

$padding-horizontal: 15px;

.deal-edit-widget {
  .deal-pizzas-form-item > label,
  .deal-items-form-item > label {
    @include form.top-align-label;
  }

  .deal-items,
  .deal-pizzas {
    .list {
      display: grid;
      grid-gap: 20px;
      margin-bottom: 20px;
    }

    .item {
      @include card.container;
      @include card.stack;

      .content {
        @include card.upper;
      }
      .actions {
        @include card.lower;
        @include card.round-buttons;
        .action {
          @include card.round-button;
        }
      }

      .info {
        display: grid;
        grid-template-columns: 1fr 100px;
        grid-gap: 20px;
        @include card.header;
        label,
        .meta {
          font-weight: normal;
        }
      }
    }

    .actions {
      button {
        @include buttons.primary-transparent;
      }
    }
  }

  .pizza-list {
    .head {
      @include card.header;
      background-color: #f9f9f9;
    }
    .body {
      @include card.content;
      display: grid;
      grid-gap: 20px;
    }
  }

  // pizzas table
  .pizza-list .head,
  .pizza {
    display: grid;
    grid-template-columns: min-content 1fr 100px;
    align-items: center;
    grid-gap: 15px;
  }

  /**
   * Deal Items
   */
  .deal-items {
    .form-item.modifiers > label {
      display: block;
      margin: 0;
      @include card.header;
      background-color: #f9f9f9;
    }

    .modifiers {
      .form-input,
      .meta {
        // margin: 15px;
      }
    }

    .modifier,
    .modifier-info {
      box-shadow: none !important;
      border-radius: 0 !important;
    }
    .modifier {
      margin-bottom: 0px !important;
    }

    .modifier-list-actions {
      margin: 15px;
      button {
        @include buttons.primary-transparent;
      }
    }

    // hide the error, at modifiers level, it's shown at the items anyways
    .form-item.modifiers > .meta {
      display: none !important;
    }
  }
}
