@use '../themes/default' as default-theme;
@use '../typography' as typography;
@use '../constants' as constants;

.allergen-info {
  margin: 18px #{constants.$horizontal-gap-desktop} 10px #{constants.$horizontal-gap-desktop};
  border-top: 1px solid var(--form-element-border);
  padding-top: 16px;
  display: grid;
  grid-column-gap: 10.5px;
  grid-row-gap: 4px;
  grid-template-areas:
    'icon title'
    'icon allergen-types';
  grid-template-columns: min-content 1fr;

  .icon {
    grid-area: icon;
  }
  .title {
    @include typography.body-bold;
    grid-area: title;
  }
  .types {
    @include typography.caption-regular;
    color: #{default-theme.$material-gray-600};
    grid-area: allergen-types;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px;
    word-spacing: 1px;
    list-style-type: none;
    display: grid;
    grid-row-gap: 4px;
  }
}
