@use '../themes/default' as default-theme;
@use '../constants' as constants;
@use '../viewport' as viewport;
@use '../typography' as typography;

@include viewport.tablet {
  .add-to-cart-widget {
    display: grid;
    background-color: linear-gradient(0deg, #ffffff, #ffffff);
    // grid-template-columns: 100%;
    grid-template-columns: 1fr auto;

    .quantity {
      @include typography.body-regular;
      align-self: center;

      label {
        display: none;
      }

      .quantity-actions {
        display: flex;
        gap: 18px;

        .inc-icon,
        .dec-icon {
          width: 24px;
          text-align: center;
        }

        .number {
          grid-area: number;
          align-self: center;
        }
      }
    }

    .total-price {
      display: none;
    }

    > .add-to-cart-button {
      background: #{default-theme.$restoplus-teal} !important;
      border-radius: 8px;
      @include typography.button1;
      text-align: center;
      padding: 12px 17px 12px 17px;

      color: #{default-theme.$bg};

      span {
        margin-left: 0.5em;
        font-weight: 700;
      }

      &.disabled {
        opacity: 0.5;
        color: #fff !important;
        pointer-events: none;
      }
    }
  }
}
