@use "../viewport" as viewport;
@use "../constants" as constants;
@use "../themes/default" as default-theme;
@use "../typography" as typography;
@use "../order_status_timeline/timeline_event" as timeline;

@include viewport.tablet {
  .order-status-widget {
    padding: 30px 31px;
    margin: 0 constants.$horizontal-gap;
    margin-bottom: 24px;
    background: white;
    border: 1px solid #{default-theme.$material-gray-300};
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    .website-order-delivery-timeline-widget,
    .website-order-pick-up-timeline-widget,
    .website-order-dine-in-timeline-widget {
      margin: 0 auto;
      max-width: 470px;
      .in-house-delivery-timeline,
      .pick-up-timeline,
      .dine-in-timeline {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 128px;
        @include timeline.timeline_event;
      }

      .no-delivery-channel-timeline {
        .enabled-uber-direct-and-unabled-in-house {
          display: flex;
          justify-content: center;
          align-items: center;
          @include timeline.timeline_event;
        }
        .enabled-uber-direct-and-in-house {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0px 128px;
          @include timeline.timeline_event;
        }
      }

      .uber-delivery-timeline {
        display: flex;
        justify-content: center;
        align-items: center;
        @include timeline.timeline_event;
      }

      .delivery-status-label,
      .pick-up-status-label,
      .dine-in-status-label {
        text-align: center;
        margin-top: 20px;
        .title {
          text-align: center;
          margin: 8px 0px;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          color: #{default-theme.$primary};
        }
        .rejected {
          color: #9f1239;
        }
        .delivery-failed {
          color: #fbbf24;
        }
        .sub-title {
          font-size: 14px;
          color: #9191a1;
          .store-phone-number {
            color: #{default-theme.$primary};
          }
          .request-proof-of-delivery {
            color: #9191a1;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
