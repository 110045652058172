@use '../typography';
@use '../themes/default';

.marketing-campaign-widget {
  padding: 24px;

  .header {
    @include typography.h3;
    color: default.$restoplus-dark;
  }

  .icon {
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .section {
    min-height: 52vh;

    .body {
      margin-top: 32px;
      padding: 16px 20px;
      border: 1px solid default.$border-gray;
      border-radius: 8px;

      .title {
        display: flex;
        align-items: center;
        img {
          margin-right: 10;
        }
      }

      .description {
        color: default.$material-gray-600;
        padding: 8px 0 0 30px;
      }
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;

    button {
      border: 2px solid default.$restoplus-teal;
      margin-left: 8px;

      &.skip {
        background-color: transparent;
        color: default.$restoplus-teal;
      }
    }
  }
}
