@use '../card'as card;
@use '../viewport'as viewport;
@use "../themes/default" as default-theme;
@use "../constants" as constants;
@use '../typography' as typography;
@import './common-styles-mobile';

@include viewport.mobile {
  .cart-page {
    padding-bottom: max(65px, var(--safe-area-inset-bottom)) !important;

    .cart-widget {
      background-color: white;

      .collection-info {
        display: grid;
        gap: 4px;

        .session-info {
          @include session-view-widget();
          justify-content: start;
          .collection-type {
            font-weight: 700;
          }
          .session-datetime {
            font-weight: 700;
            margin-left: 4px;
          }
        }

        .minimum-order-value-info {
          @include orange-strip();

          > .icon {
            display: none;
          }
        }
      }

      .empty-cart-header {
        display: none;
      }

      .cart-header-text {
        display: none;
      }

      .empty-cart {
        margin-top: 140px;
        text-align: center;

        > .cart-image {
          margin-bottom: 29px;
          width: 80px;
          height: 80px;
        }

        > .text {
          @include typography.body-regular;
          color: #{default-theme.$material-gray-600};
          margin: 0 70px;
        }
      }

      .item-count {
        @include typography.body-regular;
        color: #{default-theme.$material-gray-600};
        margin: 23px constants.$horizontal-gap;
        margin-bottom: 16px;
      }

      .items {
        margin: constants.$horizontal-gap;
        margin-top: 0;
        display: grid;
        gap: 18px;

        .item {
          padding: 16px;
          border: 1px solid #e2e2e2;
          box-shadow: 0px 2px 4px rgb(0 0 0 / 5%);
          box-sizing: border-box;
          border-radius: 8px;
          display: grid;
          grid-template-areas:
            'name     .     remove'
            'description  .     remove'
            'quantity deal  price';
          grid-template-columns: 1fr max-content;
        }

        .deal {
          cursor: pointer;
          display: flex;
          align-items: center;
          margin-right: 6px;
          grid-area: deal;
          @include typography.caption-regular;
          text-align: left;
          color: #{default-theme.$orange-dark};
          margin-top: 16px;
          text-transform: uppercase;

          img {
            margin-top: -3px;
            margin-right: 5px;
          }
        }

        .name {
          grid-area: name;
          @include typography.h4;
          color: #{default-theme.$restoplus-dark};
          letter-spacing: -0.2px;
          margin-bottom: 11px;
        }

        .remove-icon {
          grid-area: remove;
          justify-self: right;
          width: 12px;
          padding: 2px 4px;
          margin-bottom: 11px;
        }

        .item-description-view-widget {
          grid-area: description;
        }

        .quantity {
          grid-area: quantity;
          display: grid;
          grid-auto-flow: column;
          gap: 5px;
          width: fit-content;
          margin-top: 16px;

          .number {
            @include typography.body-regular;
            margin: 0 17px;
            line-height: 27px;
          }

          .icon {
            cursor: pointer;
            width: 24px;
          }
        }

        .price {
          grid-area: price;
          text-align: right;
          @include typography.body-regular;
          display: flex;
          align-items: center;
          color: #{default-theme.$restoplus-brand-green};
          margin-top: 16px;
        }
      }

      .totals {
        background: #{default-theme.$restoplus-light-green};
        color: #{default-theme.$restoplus-dark};
        padding: 22px constants.$horizontal-gap;
        @include typography.body-regular;
        letter-spacing: 0.3px;

        .sub-total,
        .delivery-charges,
        .public-holiday-surcharge,
        .grand-total,
        .gst-amount {
          padding: 3px 0;
          display: grid;
          grid-auto-flow: column;
          justify-content: space-between;
          font-weight: 400;
          border-bottom: 1px solid #{default-theme.$border-light};
        }

        .grand-total {
          @include typography.h4;
          border-bottom: none;
        }

        .gst-amount {
          border-bottom: none;
        }
      }

      .checkout {
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        padding: 5px constants.$horizontal-gap;
        padding-bottom: 50px;
        background: #{default-theme.$restoplus-light-green};

        .button {
          background: #0162ff;
          color: white;
          border-radius: 10px;
          text-align: center;
          @include typography.button1;
          padding: 8px 0;
          cursor: pointer;

          &.disabled {
            background: #f1f1f5;
            color: #bcbcc5;
            cursor: not-allowed;
          }
        }

        .quantity {
          @include typography.caption-bold;
          text-align: end;
        }
      }
    }
  }
}
