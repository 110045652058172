@use '../card' as card;
@use '../viewport'as viewport;
@use '../themes/default'as default-theme;
@use '../typography' as typography;

@include viewport.mobile {
  .collection-type-selector-widget {
    padding: 15px 20px;
    background-color: #{default-theme.$orange-light};

    .collection-types {
      .collection-type-single {
        @include typography.body-regular;
        text-align: left;
        color: #{default-theme.$orange-dark};

        display: flex;
        flex-direction: row;

        span {
          @include typography.body-bold;
          text-align: left;

          &:first-of-type {
            margin-right: 0.25em;
          }

          &:last-of-type {
            margin-left: auto;
          }
        }
      }

      .collection-type-selector {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 1fr;
        grid-auto-columns: 1fr;
        overflow: hidden;
        padding: 5px;
        gap: 5px;
        border-radius: 10px;
        background-color: rgba(214, 115, 0, 0.1);

        .start-order,
        .collection-type {
          position: relative;
          text-align: center;
          padding: 4px 0;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          background-color: transparent;
          border-radius: 10px;
          -webkit-tap-highlight-color: transparent;

          .collection-label {
            padding: 1px;
          }

          span {
            @include typography.body-regular;
            text-align: left;
            color: #{default-theme.$orange-dark};
          }

          &.selected {
            background-color: #{default-theme.$bg};

            .check {
              // position: absolute;
              margin-right: 10px;
              left: 33px;
              width: 14px;
            }
          }
        }

        .start-order {
          background-color: var(--button-success-bg);
          color: var(--button-success-fg);
        }
      }
    }

    .start-ordering-link {
      display: none;

      &.more-info {
        display: unset;
        @include typography.body-bold;
        color: #d67300;
      }
    }
  }
}
