@use "../viewport" as viewport;
@use "../constants" as constants;
@use "../themes/default" as default-theme;
@use "../typography" as typography;
@import './common-styles-desktop';

@include viewport.tablet {
  .table-booking-page {
    background: #{default-theme.$material-gray-50};

    &.page > .header {
      .title {
        line-height: 20px;
      }
    }

    form {
      .form-items > .form-buttons {
        margin-top: 21px;
      }

      .form-input input {
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0px;
      }

      .form-select-pax {
        .label {
          font-size: 14px;
          line-height: 20px;
        }

        .sub-text {
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 0.25px;
          margin-bottom: 24px;
        }
      }

      .form-single-select-pill {
        .label {
          font-size: 14px;
          line-height: 30px;
        }

        &.optional > .label::after {
          content: '(Optional)';
          @include typography.body-regular;
          color: #{default-theme.$material-gray-600};
          margin-left: 8px;
        }

        .option {
          @include typography.body-regular;
          padding: 8px 14px;
        }
      }

      .form-text-area {
        label {
          font-size: 14px;
          line-height: 50px;
        }

        .sub-text {
          font-size: 12px;
          line-height: 5px;
          letter-spacing: 0.25px;
        }

        textarea {
          font-size: 15px;
          letter-spacing: 0px;
        }
      }

      .form-select-icon input,
      .form-select-pax img {
        cursor: pointer;
      }

      .form-buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 37px;

        button {
          @include typography.button1;
          text-align: center;
          text-transform: uppercase;
          color: white;

          background: #{default-theme.$restoplus-teal};
          border-radius: 8px;
          padding: 14px 42px;

          &.disabled {
            cursor: unset;
            opacity: 0.5;
            color: white !important;
            background: #{default-theme.$restoplus-teal} !important;
          }
        }
      }
    }

    .body > .wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 64.25px;
      background: white;
      border: 1px solid #{default-theme.$material-gray-300};
      box-sizing: border-box;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      max-width: 799px;
      margin: 35px auto;
      padding: 37.5px 32px 32px 32px;
    }
  }

  .table-booking-summary-modal {
    > .header {
      @include modal-header();

      .close-icon {
        width: 16px;
      }
    }

    .buttons {
      display: flex;
      justify-content: flex-end;
      padding: 24px 32px;

      .confirm-button {
        order: 2;
        @include typography.button1;
        text-align: center;
        text-transform: uppercase;
        color: white;
        background: #{default-theme.$restoplus-teal};
        border-radius: 8px;
        padding: 12px 20px;
      }

      .back-button {
        order: 1;
        @include typography.caption-regular;
        text-align: center;
        text-transform: uppercase;
        color: #{default-theme.$material-gray-600};
        background: white;
        border-radius: 8px;
        box-shadow: none;
        padding: 12px 20px;
      }
    }
  }
}
