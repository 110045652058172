@use "../viewport" as viewport;
@use "../constants" as constants;
@use "../themes/default" as default-theme;
@use "../typography" as typography;
@import './common-styles-mobile';

@include viewport.mobile {
  .session-create-modal {
    > .header {
      @include item-bottom-sheet-modal-header();

      .close-icon {
        width: 16px;
      }

      .title {
        text-align: center;
        @include typography.h3;
      }
    }

    .main-display {
      padding-top: 30px;
      .collection-type-selector {
        margin-bottom: 72px;
        padding: 0 constants.$horizontal-gap;

        .title {
          @include typography.h4;
          color: #{default-theme.$restoplus-dark};
          margin-bottom: 17px;
        }

        .collection-types {
          display: grid;
          gap: 16px;

          .collection-type {
            display: grid;
            grid-auto-flow: column;
            grid-template-areas: 'collection-type-icon text . caret-right-icon';
            grid-auto-columns: min-content min-content 1fr min-content;
            gap: 15px;
            padding: 12px;
            border: 1px solid #{default-theme.$material-gray-300};
            border-radius: 8px;
            align-items: center;

            .collection-type-icon {
              grid-area: collection-type-icon;
              height: 38px;
              width: 38px;
            }

            .text {
              grid-area: text;
              align-items: center;
              @include typography.body-regular;
              color: #{default-theme.$restoplus-dark};
              display: flex;
              white-space: nowrap;
            }

            .caret-right.icon {
              grid-area: caret-right-icon;
            }
          }
        }
      }

      .table-selector {
        @include session-create-widget-title-and-image();
        padding: 0 constants.$horizontal-gap;
        margin-bottom: 72px;
        display: grid;
        grid-template-columns: 1fr max-content;
        grid-template-areas:
          'title image'
          'table-list table-list';

        .title {
          @include typography.h4;
          color: #{default-theme.$restoplus-dark};
        }

        .table-list {
          grid-area: table-list;
          display: grid;
          grid-gap: 15px;
          .table {
            display: grid;
            grid-template-areas: 'name caret-right-icon';
            @include typography.body-regular;
            padding: 14px 16px;
            border-radius: 8px;
            color: #{default-theme.$restoplus-dark};
            border: 1px solid #{default-theme.$material-gray-300};
            align-items: center;
            .name {
              grid-area: name;
              margin-right: 15px;
            }
            .caret-right.icon {
              grid-area: caret-right-icon;
              margin-left: auto;
            }
          }
        }
      }

      .collection-date-and-time-selector {
        @include session-create-widget-title-and-image();
        margin-bottom: 72px;
        padding: 0 constants.$horizontal-gap;
        display: grid;
        grid-template-areas:
          'title       image'
          'holiday-info holiday-info'
          'date-list   date-list';
        grid-template-columns: 1fr max-content;

        .title {
          @include typography.h4;
          color: #{default-theme.$restoplus-dark};
        }

        .third-party-delivery-banner {
          display: flex;
          flex-flow: row;
          grid-gap: 8px;
          width: 100%;
          background: #ffebcf;
          border-radius: 4px;
          padding: 16px;
          padding-right: 15px;
          box-sizing: border-box;
          margin: 13px 0 8px 0;

          .message {
            @include typography.body-regular;
            margin: 0;
            > div {
              display: inline;
              @include typography.body-bold;
              color: #d67300;
            }
          }
        }

        .collection-dates-and-times {
          grid-area: date-list;
        }

        .holiday-info {
          grid-area: holiday-info;
          @include typography.body-regular;
          color: #{default-theme.$restoplus-dark};

          border-radius: 4px;
          padding: 10px;
          margin: 12px 0 16px;
          background: #{default-theme.$orange-light};
        }
      }

      .action-buttons {
        display: grid;
        grid-gap: 17px;
        padding: constants.$horizontal-gap;
        box-sizing: border-box;
        width: 100%;
        background: #{default-theme.$material-gray-50};
        border-top: 1px solid #{default-theme.$material-gray-300};

        .start-order {
          background: #{default-theme.$restoplus-teal};
          color: #{default-theme.$bg};
          width: 100%;
          @include typography.button1;
          padding: 14px;

          &.disabled {
            opacity: 0.5;
            background: #{default-theme.$restoplus-teal} !important;
            color: #{default-theme.$bg} !important;
            border-color: unset !important;
          }
        }
      }
    }

    .section .get-order-message {
      padding-top: 30px;
    }
  }
}
