@use './viewport' as viewport;

@mixin h1 {
	font-size: 26px;
	font-weight: 700;
	line-height: 32px;	

	@include viewport.tablet {
		font-size: 32px;
		line-height: 48px;		
	}
}

@mixin h2 {
	font-size: 24px;
	font-weight: 700;
	line-height: 32px;		
}

@mixin h3 {
	font-size: 18px;
	font-weight: 700;
	line-height: 24px;

	@include viewport.tablet {
		font-size: 20px;
		line-height: 28px;
	}
}

@mixin h4 {
	font-size: 16px;
	font-weight: 700;
	line-height: 22px;
}

@mixin body-regular {
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
}

@mixin body-bold {
	font-size: 14px;
	font-weight: 700;
	line-height: 20px;
}

@mixin caption-regular {
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
}

@mixin caption-bold {
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
}

@mixin button1 {
	font-size: 14px;
	font-weight: 700;
	line-height: 20px;
	letter-spacing: 2px;
	text-transform: uppercase;
}

@mixin button2 {
	font-size: 14px;
	font-weight: 700;
	line-height: 20px;
}

@mixin button3 {
	font-size: 12px;
	font-weight: 700;
	line-height: 18px;
	letter-spacing: 1px;
	text-transform: uppercase;
}

@mixin overline {
	font-size: 10px;
	font-weight: 400;
	line-height: 14px;
	letter-spacing: 2px;
	text-transform: uppercase;
}

@mixin nav-link {
	font-family: "SF Pro Display";
	font-size: 10px;
	font-weight: 400;
	line-height: 14px;

	
	@include viewport.tablet {
		font-size: 14px;
		line-height: 20px;
		font-family: "Chivo";
	}
}

@mixin coupon-code {
	font-family: "IBM Plex";
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: 2px;
	text-transform: uppercase;
}

h1 {
  @include h1;
}

h2 {
  @include h2;
}

h3 {
  @include h3;
}

h4 {
  @include h4;
}

p {
  @include body-regular();
}

pre {
	font-family: Chivo;
  @include body-regular();
}
