@use "../viewport" as viewport;
@use "../themes/default" as default-theme;
@use "../typography" as typography;

@include viewport.mobile {
  .collection-time-selector-widget {
    display: grid;
    gap: 8px;

    .collection-date-and-time {
      display: grid;
      grid-template-areas: 'date-and-time . caret-right-icon';
      grid-auto-columns: max-content 1fr min-content;
      gap: 15px;
      padding: 14px 16px;
      border: 1px solid #{default-theme.$material-gray-300};
      border-radius: 8px;

      .text {
        grid-area: date-and-time;
        align-items: center;
        @include typography.body-regular;
        color: #{default-theme.$restoplus-dark};
        display: flex;
        align-self: center;
      }

      .caret-right.icon {
        grid-area: caret-right-icon;
        margin: auto 0;
      }

      &.disabled {
        background: #{default-theme.$material-gray-100};
        cursor: unset;
        .text {
          color: #{default-theme.$material-gray-600};
        }
      }
    }
  }
}
