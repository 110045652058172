@use '../viewport' as viewport;
@use '../constants' as constants;
@use '../themes/default' as default-theme;
@use '../typography' as typography;
@import './common-styles-mobile';

@include viewport.mobile {
  .update-delivery-address-widget {
    letter-spacing: 0.25px;
    color: #{default-theme.$restoplus-dark};
    height: 100%;

    .header-section {
      @include item-bottom-sheet-modal-header();
      padding: 0 #{constants.$horizontal-gap};

      .close-icon {
        width: 15px;
        margin-right: 5px;
      }
    }

    .body {
      padding-top: 30px;
      display: grid;

      .action-buttons {
        display: grid;
        grid-gap: 17px;
        padding: 0 constants.$horizontal-gap;
        box-sizing: border-box;
        width: 100%;
        padding: 15px constants.$horizontal-gap;

        .update-address-button {
          background: #{default-theme.$restoplus-teal};
          color: #fff;
          width: 100%;
          @include typography.button1;
          padding: 14px;
        }

        .cancel-order-button {
          background: #{default-theme.$bg};
          border: 1px solid #{default-theme.$restoplus-teal};
          color: #{default-theme.$restoplus-teal};
          width: 100%;
          @include typography.button1;
          padding: 13px;
        }
      }
    }
  }
}
