@use "../viewport" as viewport;
@use "../constants" as constants;
@use "../themes/default" as default-theme;
@use "../typography" as typography;

@include viewport.tablet {
  .table-booking-history-page {
    background: #{default-theme.$material-gray-50};

    &.page > .header {
      padding: 0px;
      box-shadow: none;
    }

    .header {
      padding: 0;

      .wrapper {
        position: relative;

        .nav {
          display: none;
        }

        .banner-image {
          position: relative;
          max-width: constants.$max-body-width;

          .mobile {
            display: none;
          }
        }

        .store-title {
          display: none;
        }

        #page-title-anchor {
          display: none;
        }
      }
    }

    .wrapper {
      .banner {
        padding: 0;

        .table-booking-title {
          display: none;

          &.hide {
            display: none;
          }
        }

        .not-available-banner {
          background: #{default-theme.$red-light};
          padding: 22.5px 20px;

          .message {
            @include typography.body-regular;
            color: #{default-theme.$red-dark};

            b {
              font-weight: 600;
            }
          }
        }

        .book-table-banner {
          display: flex;
          justify-content: space-between;
          align-content: center;
          align-items: center;
          background: #{default-theme.$orange-light};
          padding: 16px 20px;

          .message {
            @include typography.body-bold;
            color: #{default-theme.$orange-dark};
          }

          button {
            @include typography.button1;
            text-transform: uppercase;
            color: #{default-theme.$orange-dark};
            background: #{default-theme.$orange-light};
            padding: 7.5px 16px;
            border: 1.5px solid #{default-theme.$orange-dark};
            box-sizing: border-box;
            border-radius: 4px;
          }
        }

        &.hide {
          display: none;
        }
      }

      .table-bookings {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 81px;
        margin-top: 44px;

        .section {
          margin-bottom: 24px;

          .heading {
            @include typography.h3;
            color: #{default-theme.$restoplus-dark};
            margin-bottom: 42px;
          }

          .table-booking {
            display: grid;
            grid-template-areas:
              'booked-for status-icon'
              'customer customer'
              'details details'
              'hr hr'
              'status status';
            grid-template-columns: 1fr min-content;
            grid-template-rows: max-content;
            background: white;
            border: 1px solid #{default-theme.$material-gray-300};
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            padding: 15px 16px 20px 16px;
            margin-bottom: 24px;

            .bookedFor {
              grid-area: booked-for;
              @include typography.body-bold;
              color: #{default-theme.$restoplus-dark};
              margin-bottom: 7px;
            }

            .status-icon {
              grid-area: status-icon;
              margin-bottom: 7px;
            }

            .customer {
              grid-area: customer;
              @include typography.body-regular;
              color: #{default-theme.$restoplus-dark};
              margin-bottom: 16px;
            }

            .details {
              grid-area: details;
              @include typography.body-regular;
              color: #{default-theme.$restoplus-dark};
              margin-bottom: 16px;
            }

            hr {
              grid-area: hr;
              width: 100%;
              border: 1px solid #{default-theme.$material-gray-300};
              margin: 0px 0px 16px 0px;
            }

            .status {
              grid-area: status;
              @include typography.caption-regular;
              color: #{default-theme.$material-gray-500};

              b {
                font-weight: 600;
              }

              .success {
                color: #{default-theme.$green-dark};
              }

              .warning {
                color: #{default-theme.$orange-dark};
              }

              .danger {
                color: #{default-theme.$red-dark};
              }
            }
          }

          .table-booking:last-child {
            margin-bottom: 31px;
          }

          &.upcoming-table-bookings .empty-table-booking {
            .message::after {
              content: 'No upcoming bookings yet';
            }
          }

          &.past-table-bookings .empty-table-booking {
            .message::after {
              content: 'No previous bookings yet';
            }
          }

          .empty-table-booking {
            text-align: center;
            background: white;
            border: 1px solid #{default-theme.$material-gray-300};
            box-sizing: border-box;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            padding: 32px 0px;

            .icon {
              margin: 0px auto 21.96px auto;
            }

            .icon-mobile {
              display: none;
            }

            .message {
              @include typography.body-regular;
              text-align: center;
              color: #{default-theme.$material-gray-600};
            }
          }
        }

        .section:last-child {
          margin-bottom: 109px;
        }
      }
    }
  }
}
