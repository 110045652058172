@use '../viewport'as viewport;
@use "../themes/default" as default-theme;
@use "../typography" as typography;

@include viewport.tablet {
  .coupons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 864px;
    margin: 29px auto;
    gap: 20px 6px;
    margin-bottom: 52px;

    .coupon {
      position: relative;
      box-shadow: 0 0 11px 0 #bdbdbd78;
      clip-path: inset(-10px -10px -10px 0);
      width: 400px;
      min-height: 154px;

      .zigzag {
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(
            45deg,
            rgb(255 255 255 / 0%) 45%,
            #{default-theme.$material-gray-450}11 45%,
            #{default-theme.$material-gray-450}11 55%,
            rgb(255 255 255) 55%
          ),
          linear-gradient(
            315deg,
            rgb(255 255 255) 45%,
            #{default-theme.$material-gray-450}11 45%,
            #{default-theme.$material-gray-450}11 55%,
            #f4f4f45e 55%
          );
        background-repeat: repeat-y;
        background-size: 23px 20px;
        width: 12px;
        height: 100%;
      }

      .body {
        display: grid;
        grid-template-rows: min-content 1fr min-content;
        grid-template-columns: 1fr min-content;
        grid-template-areas:
          'code         icon'
          'description  icon'
          'discount     availability';
        padding: 16px 13px 11px 21px;
        min-height: 120px;
        gap: 3px 5px;
        color: #{default-theme.$restoplus-dark};

        &.used {
          filter: grayscale(1);
          color: #{default-theme.$restoplus-dark}69;
        }

        .code {
          grid-area: code;
          overflow: hidden;
          -o-text-overflow: ellipsis;
          text-overflow: ellipsis;
          @include typography.coupon-code;
          white-space: nowrap;
          width: 100%;
          overflow: hidden;
          -o-text-overflow: ellipsis;
          text-overflow: ellipsis;
        }

        .icon {
          grid-area: icon;
          width: 40px;
          height: 32px;
          padding-left: 30px;

          &.available {
            right: 0;
          }
        }

        .description {
          grid-area: description;
          @include typography.body-regular;
          display: -webkit-box;
          max-height: 63px;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .discount {
          grid-area: discount;
          @include typography.caption-regular;
          margin-top: auto;
          color: #{default-theme.$restoplus-dark};
        }

        .availability {
          grid-area: availability;
          text-transform: capitalize;
          @include typography.caption-regular;
          color: #{default-theme.$restoplus-teal};
          position: relative;
          margin-top: auto;
          justify-self: end;
        }
      }
    }
  }
}
