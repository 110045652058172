@use "../viewport" as viewport;
@use "../constants" as constants;
@use "../themes/default" as default-theme;
@use "../typography" as typography;
@import './common-styles-mobile';
@import '_top-nav-bar-mobile';

@include viewport.mobile {
  .online-ordering {
    header.table-booking-history-page {
      position: fixed !important;
    }

    header.on-top {
      transition: visibility 0s linear 0.2s, opacity 0.2s linear;
      display: none;
      opacity: 0;
    }

    #top-of-site-pixel-anchor {
      position: absolute;
      width: 1px;
      height: 1px;
      top: 100px;
      left: 0;
    }
  }

  .table-booking-history-page {
    &.page > .header {
      padding: 0px;
      box-shadow: none;
    }

    .header {
      margin-top: 0;
      padding: 0;
      &.on-top {
        transition: visibility 0s linear 0.2s, opacity 0.2s linear;
        display: none;
        opacity: 0;
      }

      .wrapper {
        position: relative;

        .nav {
          @include top-nav-bar();
          @include banner-nav-bar();
          @include banner-nav-bar-title();
        }

        .banner-image {
          position: relative;
          width: 100vw;

          .desktop {
            display: none;
          }
        }

        .store-title {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          padding: 20px;
          box-sizing: border-box;

          position: absolute;
          bottom: 0;
          color: var(--bg);
          width: 100%;

          background: linear-gradient(
            180deg,
            rgba(42, 42, 49, 0) 11.39%,
            rgba(42, 42, 49, 0.1) 24.94%,
            rgba(42, 42, 49, 0.8) 100%
          );
          background-blend-mode: multiply;

          .store-name {
            @include typography.h2;
            font-weight: 400;
            margin: 4px 0px;
          }

          .store-location {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;

            @include typography.caption-regular;
            text-align: left;

            .address {
              margin: 0px 9px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .icon {
              width: 17px;
            }
          }
        }

        #page-title-anchor {
          position: absolute;
          width: 1px;
          height: 1px;
          left: 0;

          &.open {
            top: 375px;
          }

          &.closed {
            top: 360px;
          }
        }
      }
    }

    .wrapper {
      .login {
        padding: 0px 20px;
        margin: 24px 24px 125px 24px;

        .text {
          text-align: center;
          @include typography.body-regular;
          color: #{default-theme.$material-gray-600};

          a {
            color: #{default-theme.$restoplus-brand-green};
            text-decoration: none;
          }
        }
      }

      .bottom-spacer {
        margin-bottom: 135px;
      }

      .desktop {
        display: none;
      }

      .banner {
        padding: 0;

        .table-booking-title {
          @include typography.h4;
          color: #{default-theme.$restoplus-dark};
          background: white;
          padding: 15px 20px;
          box-shadow: inset 0px -1px 0px #e8ecf1;
          display: block;

          &.hide {
            display: none;
          }
        }

        .not-available-banner {
          background: #{default-theme.$red-light};
          padding: 15.5px 20px;

          .message {
            @include typography.body-regular;
            color: #{default-theme.$red-dark};

            b {
              font-weight: 600;
            }
          }
        }

        .book-table-banner {
          display: flex;
          justify-content: space-between;
          align-content: center;
          align-items: center;
          background: #{default-theme.$orange-light};
          padding: 16px 20px;

          .message {
            @include typography.body-regular;
            color: #{default-theme.$orange-dark};
          }

          button {
            @include typography.button1;
            text-transform: uppercase;
            color: #{default-theme.$orange-dark};
            background: #{default-theme.$orange-light};
            padding: 7.5px 16px;
            border: 1.5px solid #{default-theme.$orange-dark};
            box-sizing: border-box;
            border-radius: 4px;
          }
        }

        &.hide {
          display: none;
        }
      }

      .empty-table-booking {
        text-align: center;
        padding: 42px 62.5px 40px 62.5px;

        .icon {
          margin: 0px auto 29px auto;
        }

        .icon-desktop {
          display: none;
        }

        .message {
          @include typography.body-regular;
          text-align: center;
          color: #{default-theme.$material-gray-600};
        }

        .message::after {
          content: 'You don’t have any table bookings yet';
        }
      }

      .table-bookings {
        margin: 30px constants.$horizontal-gap;

        .section {
          margin-bottom: 24px;

          &.hide {
            display: none;
          }

          .heading {
            @include typography.h3;
            color: #{default-theme.$restoplus-dark};
            margin-bottom: 24px;
          }

          .table-booking {
            display: grid;
            grid-template-areas:
              'booked-for status-icon'
              'customer customer'
              'details details'
              'hr hr'
              'status status';
            grid-template-columns: 1fr min-content;
            grid-template-rows: max-content;
            background: white;
            border: 1px solid #{default-theme.$material-gray-300};
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            padding: 15px 16px 20px 16px;
            margin-bottom: 24px;

            .bookedFor {
              grid-area: booked-for;
              @include typography.body-bold;
              color: #{default-theme.$restoplus-dark};
              margin-bottom: 7px;
            }

            .status-icon {
              grid-area: status-icon;
              margin-bottom: 7px;
            }

            .customer {
              grid-area: customer;
              @include typography.body-regular;
              color: #{default-theme.$restoplus-dark};
              margin-bottom: 16px;
            }

            .details {
              grid-area: details;
              @include typography.body-regular;
              color: #{default-theme.$restoplus-dark};
              margin-bottom: 16px;
            }

            hr {
              grid-area: hr;
              width: 100%;
              border: 1px solid #{default-theme.$material-gray-300};
              margin: 0px 0px 16px 0px;
            }

            .status {
              grid-area: status;
              @include typography.caption-regular;
              color: #{default-theme.$material-gray-500};

              b {
                font-weight: 700;
              }

              .success {
                color: #{default-theme.$green-dark};
              }

              .warning {
                color: #{default-theme.$orange-dark};
              }

              .danger {
                color: #{default-theme.$red-dark};
              }
            }
          }

          .table-booking:last-child {
            margin-bottom: 31px;
          }
        }
      }
    }
  }
}
