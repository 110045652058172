@use "../viewport" as viewport;
@use "../themes/default" as default-theme;
@use './common-styles-desktop' as common-styles;
@use "../typography" as typography;

@include viewport.tablet {
  .customer-login-flow-widget {
    position: relative;
    display: grid;
    grid-template-rows: min-content 1fr;
    box-sizing: border-box;
    padding-bottom: 32px;
    height: #{common-styles.$max-modal-height};

    .top-header {
      display: grid;
      align-items: center;
      justify-content: space-between;
      padding: 23px 26px;
      box-sizing: border-box;
      grid-template-areas: 'back-button step-indicator close-button';

      .back-button {
        grid-area: back-button;
        cursor: pointer;
        img {
          height: 17px;
          width: 17px;
        }
      }

      .close-button {
        grid-area: close-button;
        cursor: pointer;
        img {
          height: 17px;
          width: 17px;
        }
      }

      .step-indicator {
        grid-area: step-indicator;
        @include typography.caption-regular;
        text-align: left;
        color: #{default-theme.$material-gray-600};
      }
    }

    .body {
      margin: 0 32px;

      .intro {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 29px 0px 32px 0px;
        .title {
          margin-top: 7px;
          @include typography.h4;
          text-align: center;
          color: #{default-theme.$restoplus-dark};
        }
        .subtitle {
          margin-top: 6px;
          @include typography.body-regular;
          text-align: center;
          color: #{default-theme.$material-gray-600};
        }
      }
    }

    .form {
      padding-top: 10px;
      width: 90%;
      margin: 0 auto;
    }

    form {
      display: flex;
      flex-flow: column;
      padding-top: 0 !important;
      box-sizing: border-box;
      height: 100%;
      .form-items {
        padding-bottom: 10px;
        grid-gap: 20px;

        .form-input {
          input {
            height: 52px;
            @include typography.body-regular;
            &::placeholder {
              color: #{default-theme.$material-gray-500};
              @include typography.body-regular;
            }
          }

          ::-webkit-input-placeholder {
            color: #{default-theme.$material-gray-600};
          }
          ::-moz-placeholder {
            /* Firefox 19+ */
            color: #{default-theme.$material-gray-600};
          }
          :-ms-input-placeholder {
            /* IE 10+ */
            color: #{default-theme.$material-gray-600};
          }
          :-moz-placeholder {
            /* Firefox 18- */
            color: #{default-theme.$material-gray-600};
          }
        }
      }

      .form-buttons {
        margin-top: auto;
      }
    }

    button {
      width: 100%;
      margin: 16px 0;
      padding: 16px 20px;
      border-radius: 8px;
      @include typography.button1;
      text-align: center;
      text-transform: uppercase;
      span {
        color: #{default-theme.$bg};
      }
      &.disabled {
        background-color: #{default-theme.$restoplus-teal} !important;
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    .grecaptcha-badge {
      visibility: hidden;
    }

    #recaptcha-wrapper {
      position: fixed !important;
      bottom: 0;
      left: 50%;
      height: 75vh;
      overflow: auto;
      transform: translateX(-50%);
    }
  }
}
