@use "../viewport" as viewport;
@use "../constants" as constants;
@use "../themes/default" as default-theme;
@use "../typography" as typography;
@use "../colors";

@include viewport.tablet {
  .order-success-page {
    background-color: white;

    &.page > .header {
      padding: 0px;
      box-shadow: none;
    }

    .order-success-page-wrapper {
      max-width: 1100px;
      margin: 0 auto;

      .checkout-banner {
        text-align: center;
        margin: 36px constants.$horizontal-gap;

        .icon {
          width: 93px;
          margin-bottom: 24px;
        }

        .text {
          @include typography.h1;
          color: #{default-theme.$restoplus-dark};
        }
      }

      .order-tracking-label {
        margin: 5px constants.$horizontal-gap;
        color: #27283e;
        line-height: 24px;
        font-size: 18px;
        font-weight: 600;
      }

      .order-placed-time {
        @include typography.h3;
        padding-top: 71px;
        margin: 0 auto;    
        text-align: center;
        color: colors.$light-gray-900;
      }

      .buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 60px;

        > .customer-review-link-button {
          border: 1px solid colors.$material-gray-300;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
          background-color: colors.$white;
          padding: 20px;
          margin-top: 44px;
  
          > .label {
            display: flex;
            align-items: center;
  
            img {
              width: 44px;
              height: 44px;
            }
  
            span {
              color: colors.$blue-gray-900;
              padding-left: 16px;
            }
          }
        }

        .order-history-button {
          background-color: transparent;
          border: 2px solid colors.$teal-500;
          box-shadow: none;
          color: colors.$teal-500;
          margin-top: 44px;
          padding: 14px 50px;
        }

        .back-to-menu-button {
          background-color: transparent;
          border: none;
          box-shadow: none;
          color: colors.$material-gray-500;
          margin-top: 16px;
          padding: 14px 50px;
        }
      }
    }
  }

  .loyalty-card-modal {
    width: 422px !important;
    background-color: white;

    .loyalty-banner {
      margin: 21px;
      text-align: center;

      .icon {
        margin-bottom: 8px;
      }

      .text {
        @include typography.h4;
        text-align: center;
        color: #{default-theme.$restoplus-dark};
        margin-bottom: 4px;
      }

      .subtext {
        @include typography.body-regular;
        text-align: center;
        color: #{default-theme.$material-gray-600};
      }
    }

    .card {
      margin: 0px 54px;
      margin-top: 37px;

      .loyalty-card-widget {
        width: inherit !important;

        .details {
          .balance {
            font-size: 28px;
          }

          .customer-info {
            font-size: 16px;
          }
        }
      }
    }

    .caption {
      @include typography.body-regular;
      text-align: center;
      color: #{default-theme.$material-gray-600};
      margin: 30px 53px;
    }

    .button {
      background: #{default-theme.$restoplus-teal};
      border-radius: 8px;
      @include typography.button1;
      text-align: center;
      text-transform: uppercase;
      color: #{default-theme.$bg};
      padding: 8px 0;
      margin: 21px 30px;
      margin-top: 41px;
      align-content: flex-end;
      cursor: pointer;
    }
  }
}
