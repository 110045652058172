@use '../viewport'as viewport;
@use "../themes/default" as default-theme;
@use "../typography" as typography;

@include viewport.tablet {
  .no-coupons {
    margin-top: 60px;

    > .icon {
      margin: 0 auto;
      display: block;
    }

    > .text {
      @include typography.body-regular;
      color: #{default-theme.$material-gray-600};
      width: 240px;
      margin: 15px auto;
      text-align: center;
    }
  }
}
