@use "../constants" as constants;
@use "../coupon" as coupon;
@use './common-styles-desktop' as common-styles;
@use "../selection_list"as selection_list;
@use '../themes/default' as default-theme;
@use "../viewport" as viewport;
@use "../scrollbar" as scrollbar;
@use "../typography" as typography;

@mixin button {
  width: 100%;
  @include typography.button1;
  padding: 12px 20px;
  text-transform: uppercase;
}

@mixin note {
  @include typography.caption-regular;
  text-align: left;
  letter-spacing: unset;
  margin: unset;
  text-transform: unset;
  color: #{default-theme.$restoplus-dark};
  padding: 0 15px;
  margin: 0 20px;

  .learn-more {
    color: #{default-theme.$orange-dark};
    cursor: pointer;
    white-space: nowrap;
  }
}

@include viewport.tablet {
  .confirm-dialog {
    &.discounted-items-alert {
      .swal2-content {
        padding: 0;
      }

      .swal2-title {
        text-align: left;
        @include typography.body-regular;
      }

      .swal2-html-container {
        text-align: left;
        margin: 0 30px;
        font-size: 14px;
      }
    }
  }

  .checkout-widget {
    padding: 0 !important;
    display: flex;
    flex-flow: column;
    height: #{common-styles.$max-modal-height};
    box-sizing: border-box;

    .spinner-container {
      margin-top: 120px;
    }

    img {
      object-fit: cover;
    }

    input[type='text'] {
      padding: 13px 22px;
      border-radius: 8px;
      @include typography.body-regular;
    }

    .checkout-header {
      @include typography.h3;
      color: #{default-theme.$restoplus-dark};
      padding: 21px 32px;

      .close-icon {
        position: absolute;
        top: 22px;
        right: 28px;
        width: 15px;
        cursor: pointer;
      }
    }

    .firebaseui-phone-number {
      .firebaseui-country-selector {
        // margin: 45px 24px 10px 0;
        padding-top: 10px;
        height: auto !important;
      }
      button {
        box-shadow: none !important;
      }
    }

    .form {
      padding-top: 10px;
      width: 90%;
      margin: 0 auto;
    }

    .body {
      margin-top: 20px;
    }

    .sections {
      display: flex;
      flex-grow: 1;
      flex-flow: column;
      justify-content: space-between;
      margin-top: 30px;
      padding: 0 32px;
      height: 100%;

      div:only-child {
        display: grid;
        flex-flow: column;
        height: inherit;
      }

      .title {
        text-align: center;

        .header {
          margin-top: 11px;
          display: block;
          @include typography.h4;
          color: #{default-theme.$restoplus-dark};
        }

        .subtitle {
          display: block;
          width: 90%;
          margin: auto;
          margin-top: 6px;
          margin-bottom: 11px;
          box-sizing: border-box;
          padding: 0 32px;
          @include typography.body-regular;
          color: #{default-theme.$material-gray-600};
        }
      }

      .display-name {
        .subtitle {
          width: 70%;
          margin-bottom: 0;
        }
      }

      .icon {
        margin: 0 auto;
        height: 80px;
        width: 80px;
        background-repeat: no-repeat;
        background-position: center;

        &.customer-display-name {
          background-image: url('../images/customer-name-icon-desktop.svg');
        }
        &.coupon {
          background-image: url('../images/coupon-icon-desktop.svg');
        }
        &.comment {
          background-image: url('../images/comment-icon.svg');
        }
        &.payment {
          background-image: url('../images/payment-icon-desktop.svg');
        }
      }

      .update-display-name {
        margin-top: 35px;
      }

      .custom-coupon {
        display: grid;
        grid-template-areas:
          'input   button'
          'note    note';
        grid-template-columns: 1fr min-content;

        form .default {
          grid-area: input;
        }

        input {
          border-radius: 8px 0 0 8px;
        }

        .apply-button {
          grid-area: button;
          position: relative;
          border: 1px solid #{default-theme.$material-gray-300};
          border-left: none;
          cursor: pointer;
          border-radius: 0 8px 8px 0;
          display: flex;
          height: calc(100% - 2px);
          justify-content: center;
          align-items: center;
          padding: 0px 22px;
          @include typography.button1;
          background-color: #{default-theme.$material-gray-100};
        }

        .note {
          @include note();
          grid-area: note;
          margin: 10px 0;
        }
      }

      .suggested-coupon {
        @include coupon.coupon-body(#{default-theme.$border-gray}, #{default-theme.$bg});

        .content {
          display: grid;
          align-items: center;
          grid-template-rows: repeat(3, min-content);
          grid-template-columns: 1fr min-content;
          grid-template-areas:
            'name          actions'
            'description   actions'
            'discount      actions';
          gap: 0px 5px;
          color: #{default-theme.$restoplus-dark};
          margin-left: 10px;

          .name {
            grid-area: name;
            font-family: 'IBM Plex';
            @include typography.body-bold;
            text-align: left;
            color: #{default-theme.$restoplus-dark};
            margin-top: 15px;
            overflow: hidden;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
          }

          .description {
            grid-area: description;
            min-height: 34px;
            overflow: hidden;
            @include typography.caption-regular;
            text-align: left;
            color: #{default-theme.$material-gray-600};
            margin-top: 8px;
            margin-bottom: 8px;
          }

          .discount {
            grid-area: discount;
            @include typography.caption-regular;
            text-align: left;
            color: #{default-theme.$restoplus-dark};
            text-transform: lowercase;
            margin-bottom: 13px;
          }

          .actions {
            grid-area: actions;

            button {
              padding: 4px 14px;
              margin-right: 15px;
              margin-left: 15px;
              @include typography.button3;
              color: #{default-theme.$restoplus-teal};
              background-color: #{default-theme.$bg};
              border: 2px solid #{default-theme.$restoplus-teal};
              border-radius: 8px;
              cursor: pointer;
            }
          }
        }
      }

      .applied-coupon {
        @include coupon.coupon-body(#{default-theme.$restoplus-teal}, #{default-theme.$restoplus-light-green});

        .content {
          display: grid;
          align-items: center;
          margin-left: 10px;
          grid-template-rows: min-content 1fr min-content min-content;
          grid-template-columns: 1fr min-content;
          grid-template-areas:
            'name      icon'
            'desc      icon'
            'discount  icon'
            'actions   icon';

          .name {
            grid-area: name;
            font-family: 'IBM Plex';
            @include typography.body-bold;
            text-align: left;
            color: #{default-theme.$restoplus-dark};
            margin-top: 15px;
            overflow: hidden;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
          }

          .description {
            grid-area: desc;
            max-height: 34px;
            min-height: 48px;
            overflow: hidden;
            @include typography.caption-regular;
            margin-top: 5px;
            margin-bottom: 4px;
            color: #{default-theme.$material-gray-600};

            .learn-more {
              color: #{default-theme.$green-dark};
              cursor: pointer;
              letter-spacing: 0;
              white-space: nowrap;
            }
          }

          .discount {
            grid-area: discount;
            @include typography.caption-regular;
            text-align: left;
            color: #{default-theme.$restoplus-dark};
            text-transform: uppercase;
          }

          .icon {
            grid-area: icon;
            width: 16px;
            height: 16px;
            background-color: #{default-theme.$restoplus-teal};
            border-radius: 50%;
            padding: 8px;
            margin-right: 24px;
            margin-left: 24px;
          }

          .actions {
            grid-area: actions;

            button {
              @include typography.button3;
              margin-top: 16px;
              margin-bottom: 17px;
              padding: 0;
              color: #{default-theme.$restoplus-teal};
              background-color: rgba(239, 248, 249, 255);
              box-shadow: none;
              cursor: pointer;
            }
          }
        }
      }

      .ineligible-coupon {
        margin: 15px 0px;
        display: grid;
        grid-template-areas:
          'icon     description'
          '.        reset';
        grid-template-columns: min-content 1fr;
        gap: 15px 10px;
        padding: 19px 20px 20px 19px;
        background: #fef4f0;

        .error.icon {
          grid-area: icon;
          width: 22px !important;
          height: 20px !important;
        }

        .description {
          grid-area: description;
          @include typography.body-regular;
          text-align: left;
          color: #{default-theme.$restoplus-dark};
        }

        .reset {
          grid-area: reset;
          @include typography.button3;
          text-align: left;
          color: #{default-theme.$red-dark};
          text-transform: uppercase;
          cursor: pointer;
        }
      }

      .use-text {
        @include typography.button2;
        text-align: center;
        color: #{default-theme.$restoplus-teal};
        margin-top: 35px;
        cursor: pointer;
      }

      .redeem-coupon {
        .coupons {
          margin: 0;
          margin-top: 32px;
          padding-bottom: 20px;
          grid-gap: 10px;
          display: flex;
          flex-flow: column;

          .recommended-coupon {
            margin: 24px 25px 0 25px !important;
            text-transform: uppercase;
            @include typography.caption-regular;
            color: #d67300;
          }

          .note {
            @include note();
          }
        }
      }

      .comments {
        .add-comments {
          textarea {
            letter-spacing: 0.25px;
            vertical-align: middle;
            word-break: break-word;
            @include scrollbar.hide();
            @include typography.body-regular;
            line-height: 18px;
            padding: 16px;
          }

          .actions {
            margin-top: 16px;
            .cancel {
              display: none;
            }

            .clear {
              cursor: pointer;
              @include typography.button2;
              color: #{default-theme.$restoplus-teal};
            }
          }

          .form-input {
            margin: 0;
            padding: 0;
          }
        }

        .body {
          display: flex;
          flex-flow: column;
        }

        .saved-comments {
          .saved-comment {
            display: grid;
            grid-template-columns: 1fr min-content min-content;
            padding: 11px;
            padding-right: 5px;
            background: #{default-theme.$bg};
            border-radius: 8px;
            margin-bottom: 3px;
            overflow-wrap: anywhere;
            &.valid {
              border: 1px solid #{default-theme.$material-gray-300};
              > .comment {
                cursor: pointer;
              }
            }
            &.invalid {
              border: 1px solid #{default-theme.$form-element-error};
              > .comment {
                color: #{default-theme.$secondary-fg};
                cursor: not-allowed;
              }
            }
          }
          .error-message {
            font-size: 12px;
            color: #{default-theme.$form-element-error};
          }

          .comment {
            align-self: center;
            padding-left: 7px;
            letter-spacing: 0.1px;
            color: #{default-theme.$restoplus-dark};
            word-break: break-word;
            @include typography.body-regular;
          }

          .icon {
            height: 28px;
            width: 28px;
            cursor: pointer;

            &.remove {
              background-image: url('../images/trash-icon.svg');
            }

            &.edit {
              background-image: url('../images/pen-icon.svg');
            }
          }
        }

        .add-new-comment {
          cursor: pointer;
          margin-top: 5px;
          @include typography.body-bold;
          color: #{default-theme.$restoplus-teal};
        }
      }

      .payment {
        .payment-types {
          display: flex;
          flex-flow: column;
          grid-gap: 14px;

          .payment-type {
            border: 1px solid #{default-theme.$material-gray-300};
            border-radius: 8px;
            padding: 14.5px;

            label {
              white-space: nowrap;
              display: flex;
              width: 100%;
            }
          }

          .powered-by-stripe {
            display: flex;
            margin-top: 2px;

            .description {
              padding-right: 10px;
              @include typography.caption-regular;
              color: #{default-theme.$material-gray-600};
            }

            .logo {
              margin: auto 0;
            }
          }
        }
      }

      .order-view-widget {
        margin-top: -25px;
        display: flex;
        flex-flow: column;
        grid-gap: 30px;

        .order-summary,
        .cart-summary {
          margin: 0;
          .collection-type-and-time .value > * {
            display: inline-block;
          }
        }
      }

      form > .form-items {
        grid-gap: 16px;
      }
    }

    .alert {
      margin: 10px 0;
      box-shadow: none;
    }

    .step-buttons {
      display: grid;
      grid-gap: 6px;
      grid-template-areas: 'previous continue';
      grid-auto-flow: column;
      padding-top: 55px;
      padding-bottom: 32px;
      margin-top: auto;
      margin-left: auto;

      .button {
        @include button;
      }

      .spin.icon {
        margin: 0;
        margin-right: 7px;
        height: unset;
        width: unset;
      }

      button.previous {
        @include button;
        @include typography.button1;
        grid-area: previous;
        background-color: transparent;
        box-shadow: none;
        color: #{default-theme.$material-gray-600};
      }

      button.continue {
        @include button;
        grid-area: continue;
        @include typography.button1;
        background-color: #{default-theme.$restoplus-teal};
        color: var(--button-success-fg);
        width: max-content;
        border-radius: 8px;
      }

      button.place-order {
        @include button;
      }
    }
  }
}
