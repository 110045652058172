@use "../themes/default" as default-theme;
@use "../viewport" as viewport;

@include viewport.tablet {
  .table-page {
    .body {
      .table-ordering-welcome-screen,
      .table-ordering-qr-scanner {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 100;
        height: 100vh;
        background: #{default-theme.$bg};

        .has-banner {
          .image,
          .welcome {
            visibility: hidden;
          }
        }

        .table-ordering-banner {
          position: fixed;
          top: 0;
          right: 0;
          left: 0;
          z-index: -1;
        }

        .table-seat-info,
        .table-ordering-info {
          background: #{default-theme.$bg};
        }
      }
    }
  }
}
