@use '../viewport'as viewport;
@use "../themes/default" as default-theme;
@use "../typography" as typography;

@include viewport.mobile {
  .no-coupons {
    margin-top: 135px;

    > .icon {
      margin: 0 auto;
      display: block;
    }

    > .text {
      @include typography.body-regular;
      color: #{default-theme.$material-gray-600};
      width: 70%;
      margin: 32px auto;
      text-align: center;
    }
  }
}
