@use "../card"as card;
@use "../switch"as switch;
@use "../constants"as constants;
@use "../form"as form;
@use "../buttons"as buttons;

$padding-horizontal: 15px;

.form-item-price-spec {
  > label {
    @include form.top-align-label;
    margin-top: 34px;
  }
  > .form-input {
    @include card.container;
    @include form.inline;
  }
}

.price-spec {
  @include form.inline;
  padding: 0;

  .switch {
    @include switch.inline();
  }

  .base-price,
  .extra-price {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    input {
      max-width: 80px;
    }
  }
  .extra-price.form-item {
    margin-right: 15px;
  }

  .extra {
    display: grid;
    grid-template-columns: 4fr 1fr;
    align-items: center;
    margin-bottom: 15px;
  }
}
