@use "../constants" as constants;
@use "../viewport" as viewport;
@use "../themes/default" as default-theme;
@use '../typography' as typography;

@include viewport.tablet {
  .top-nav-bar-widget {
    position: sticky;
    background-color: #{default-theme.$restoplus-dark};
    top: 0; /* required */
    z-index: 100;
    width: 100%;

    .wrapper {
      display: grid;
      grid-template-areas: 'store-header-widget menu actions';
      grid-template-columns: minmax(20%, auto) 1fr minmax(20%, auto);
      box-sizing: border-box;
      max-width: 1320px;
      padding: 3.5px 20px;
      margin: 0 auto;

      .back-button {
        display: none;
      }

      .store-header-widget {
        grid-area: store-header-widget;
        white-space: nowrap;
        cursor: pointer;
      }

      .menu {
        grid-area: menu;
        display: flex;
        flex-direction: row;
        justify-content: center;

        .item,
        .item-with-counter {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
          cursor: pointer;
          height: constants.$header-height;
          margin: 0px 12.5px;

          .label {
            @include typography.nav-link;
            text-align: center;
            color: #{default-theme.$restoplus-cool-gray};
          }

          &.selected {
            .label {
              color: #ffffff;
            }
          }

          .count {
            position: absolute;
            right: -13px;
            top: 9px;
            background: red;
            padding: 1px 5px 0px 6px;
            border-radius: 5px;
            font-size: 10px;
            color: white;
            font-weight: 500;
          }
        }
      }

      .actions {
        grid-area: actions;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        margin: 0;

        .sidebar-menu-button {
          display: flex;
          cursor: pointer;
        }

        .login-icon {
          width: 28px;
          height: 28px;
          font-size: 30px;

          cursor: pointer;
        }

        .cart {
          position: relative;
          margin-right: 25px;
          margin-top: 2px;
          cursor: pointer;

          .cart-icon {
            width: 28px;
            height: 28px;
            opacity: 0.45;
          }

          .count {
            grid-area: count;
            @include typography.caption-bold;
            text-align: left;
            position: absolute;
            right: calc(50% - 23px);
            top: -4px;
            padding: 10px;
            background: red;
            color: #ffffff;
            border-radius: 50%;

            > span {
              position: absolute;
              top: 1px;
              width: 100%;
              left: 0;
              text-align: center;
            }
          }
        }

        .chevron-down-icon {
          grid-area: chevron-down-icon;
          align-self: center;
          padding-left: 6px;
          cursor: pointer;
        }
      }
    }

    &.store-closed,
    &.platform-maintenance {
      top: constants.$store-close-banner-height;
    }
  }
}
