@use "../viewport"as viewport;
@use "../themes/default" as default-theme;
@use "../constants" as constants;

@include viewport.mobile {
  .ReactModalPortal {
    .ReactModal__Overlay {
      background: rgba(82, 82, 82, 0.72) !important;
      z-index: 1000;
      height: 100vh !important;
      width: 100vw !important;

      .ReactModal__Content {
        border-radius: 0 !important;
        padding: 0 !important;
        border: unset !important;
        background: none !important;
        width: auto !important;

        .modal {
          position: fixed;
          left: 0;
          right: 0;
          background-color: #{default-theme.$bg};
          bottom: 0vh !important;

          &.full-screen {
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            overflow-x: hidden;
            overflow-y: scroll;
          }

          &.bottom-sheet {
            width: 100vw;
            overflow-x: hidden;
            overflow-y: scroll;
            border-radius: 16px 16px 0 0;

            &.fixed-height {
              height: 75vh;
            }

            &.min-content {
              max-height: 75vh;
            }
          }
        }
      }
    }
  }
}
