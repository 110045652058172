:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.alert {
  border-radius: 10px;
  grid-template-columns: min-content 1fr;
  grid-template-areas: "icon message";
  padding: 10px 15px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.alert.has-title {
  grid-template-areas: "icon title"
                       ". message";
}

.alert .icon {
  flex-direction: column;
  grid-area: icon;
  justify-self: center;
  font-size: 20px;
  display: flex;
}

.alert .icon i {
  margin-right: 10px;
  font-size: 25px;
}

.alert .title {
  grid-area: title;
  align-self: center;
}

.alert .message {
  grid-area: message;
  align-self: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.alert.success {
  background-color: var(--message-success-bg);
}

.alert.success .icon, .alert.success .title, .alert.success .message {
  color: var(--message-success-text);
}

.alert.info {
  background-color: var(--message-info-bg);
  color: var(--message-info-text);
}

.alert.info .icon, .alert.info .title, .alert.info .message {
  color: var(--message-info-text);
}

.alert.error {
  background-color: var(--message-error-bg);
  color: var(--message-error-text);
}

.alert.error .icon, .alert.error .title, .alert.error .message {
  color: var(--message-error-text);
}

.alert.warning {
  background-color: var(--message-warning-bg);
  color: var(--message-warning-text);
}

.alert.warning .icon, .alert.warning .title, .alert.warning .message {
  color: var(--message-warning-text);
}

.ar-4-2 {
  aspect-ratio: 4 / 2;
  object-fit: cover;
}

.ar-4-2-5 {
  aspect-ratio: 4 / 2.5;
  object-fit: cover;
}

.ar-8-3 {
  aspect-ratio: 8 / 3;
  object-fit: cover;
}

.ar-4-3 {
  aspect-ratio: 4 / 3;
  object-fit: cover;
}

.ar-1-1 {
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

.ar-4-5 {
  aspect-ratio: 4 / 5;
  object-fit: cover;
}

.ar-4-6 {
  aspect-ratio: 4 / 6;
  object-fit: cover;
}

button {
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
  border: 0;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  font-family: Chivo;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  display: flex;
}

button .icon {
  margin-right: 7px;
  font-size: 20px;
}

button.primary {
  background-color: var(--button-primary-bg);
  color: var(--button-primary-fg);
}

button.success {
  color: #fff;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  cursor: pointer;
  background-color: #4cbba1;
  padding: 14px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

button.disabled {
  cursor: not-allowed;
  color: #696974 !important;
  background-color: #f5f5f5 !important;
  border-color: #e2e2ea !important;
}

.checkbox {
  color: #555;
  grid-gap: 2px;
  grid-template-columns: min-content min-content;
  font-weight: 300;
  display: grid;
}

.checkbox input {
  vertical-align: middle;
}

.checkbox label {
  white-space: nowrap;
}

:root {
  --bg: #fff;
  --fg: #1c1d3e;
  --color-primary: #4cbba1;
  --color-secondary: #da6565;
  --link-fg: #1e75ff;
  --card-bg: white;
  --form-element-bg: white;
  --form-element-fg: #44444f;
  --form-element-border: #ddd;
  --form-element-error: #fe5b5b;
  --button-primary-bg: #4cbba1;
  --button-primary-fg: white;
  --button-secondary-bg: #eee;
  --button-secondary-fg: #454545;
  --button-success-bg: #454545;
  --button-success-fg: white;
  --button-danger-bg: red;
  --button-danger-fg: white;
  --message-success-text: #086e37;
  --message-success-bg: #def2d6;
  --message-success-fg: #086e37;
  --message-error-text: #b0204b;
  --message-error-bg: #ffe7df;
  --message-error-fg: #b0204b;
  --message-info-text: #0071b3;
  --message-info-bg: #e6edfa;
  --message-info-fg: #00a3ff;
  --message-warning-text: #d67300;
  --message-warning-bg: #ffebcf;
  --message-warning-fg: #d67300;
  --message-loading-text: #505f79;
  --message-loading-bg: white;
  --message-loading-fg: #ffab00;
}

[data-theme="dark"] {
  --color-bg: #000;
}

.form-switch {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
}

.form-switch i {
  width: 40px;
  height: 21px;
  vertical-align: text-bottom;
  background-color: #e6e6e6;
  border-radius: 23px;
  transition: all .3s linear;
  display: inline-block;
  position: relative;
}

.form-switch i:before {
  content: "";
  width: 36px;
  height: 20px;
  border-radius: 11px;
  transition: all .25s linear;
  position: absolute;
  left: 0;
  transform: translate3d(2px, 2px, 0)scale3d(1, 1, 1);
}

.form-switch i:after {
  content: "";
  width: 17px;
  height: 17px;
  background-color: #fff;
  border-radius: 11px;
  transition: all .2s ease-in-out;
  position: absolute;
  left: 0;
  transform: translate3d(2px, 2px, 0);
  box-shadow: 0 2px 2px #0000003d;
}

.form-switch:active i:after {
  width: 23px;
  transform: translate3d(2px, 2px, 0);
}

.form-switch:active input:checked + i:after {
  transform: translate3d(16px, 2px, 0);
}

.form-switch input {
  display: none;
}

.form-switch input:checked + i {
  background-color: #4bd763;
}

.form-switch input:checked + i:before {
  transform: translate3d(18px, 2px, 0)scale3d(0, 0, 0);
}

.form-switch input:checked + i:after {
  transform: translate3d(22px, 2px, 0);
}

.form-item.switch {
  grid-template-columns: auto max-content;
  grid-template-areas: "a b"
                       "c d";
  display: grid;
}

.form-item.switch label {
  grid-area: a;
  align-self: center;
  margin-left: 8px;
}

.form-item.switch .form-input {
  grid-area: b;
  justify-self: end;
}

.form-item.switch .meta {
  margin-top: -3px;
  grid-area: c !important;
}

form input[type="text"], form input[type="number"], form input[type="password"], form input[type="tel"], form input[type="email"], form textarea {
  color: #27283e;
  box-sizing: border-box;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin: 0;
  padding: 14.5px 16px;
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  transition: all .3s;
}

form input[type="file"]::-webkit-file-upload-button {
  border: 1px solid var(--form-element-border);
  background: var(--form-element-bg);
  border-radius: 5px;
}

form input[type="file"] {
  font-family: Poppins, sans-serif;
}

form .preview {
  margin-bottom: 15px;
}

form .preview img {
  max-width: 100px;
  border: 3px solid #f1f1f5;
  border-radius: 20px;
  margin: 0;
  padding: 0;
}

form .form-input.has-icon {
  color: #27283e;
  box-sizing: border-box;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin: 0;
  padding: 0;
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  transition: all .3s;
  display: flex;
}

form .form-input.has-icon .icon {
  width: 30px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-left: 12px;
  display: flex;
}

form .form-input.has-icon img {
  width: 100%;
  align-self: center;
}

form .form-input.has-icon input {
  border: 0;
  margin: 0;
}

form .form-input .prepend-text {
  color: #27283e;
  box-sizing: border-box;
  background: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-right: none;
  border-radius: 6px 0 0 6px;
  padding: 14.5px 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

form .form-input .has-prepend {
  display: flex;
}

form .form-input .has-prepend input {
  border-radius: 0 6px 6px 0;
}

form .form-text-area label {
  color: #27283e;
  margin-bottom: 4px;
  margin-left: 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

form .form-text-area .sub-text {
  letter-spacing: .4px;
  color: #757575;
  margin-bottom: 19px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

form .form-text-area textarea {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

form .form-select-icon input {
  background-image: url("form-select-icon.54fa1f6d.svg");
  background-position: right 13px center;
  background-repeat: no-repeat;
}

form .form-items {
  row-gap: 16px;
  display: grid;
}

form .form-buttons {
  margin-top: 20px;
}

form .select {
  color: #27283e;
  box-sizing: border-box;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin: 0;
  padding: 10px 14px;
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  transition: all .3s;
}

form .meta {
  margin-top: 5px;
  margin-left: 5px;
}

form .error {
  color: var(--form-element-error);
  font-size: 12px;
}

form .help {
  color: #6c6c6c;
  font-size: 12px;
}

form select {
  box-sizing: none;
  padding: none;
  width: 100%;
  background: none;
  border: none;
  margin: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
}

form .checkbox {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

form ::placeholder {
  color: #9e9e9e;
  opacity: 1;
}

form :-ms-input-placeholder {
  color: #9e9e9e;
}

form ::-moz-placeholder {
  color: #9e9e9e;
}

form input::-webkit-outer-spin-button, form input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

form input[type="number"] {
  -moz-appearance: textfield;
}

@media (min-width: 768px) {
  form.responsive {
    grid-template-columns: 180px 4fr 1fr;
    display: grid;
  }

  form.responsive .form-items > .form-item > label {
    text-align: right;
    align-self: center;
    margin-right: 15px;
  }

  form.responsive .form-items {
    grid-area: 1 / 1 / auto / -1;
    row-gap: 25px;
  }

  form.responsive .form-items > .form-item {
    grid-template-columns: 180px 4fr 1fr;
    display: grid;
  }

  form.responsive .form-items > .form-item label {
    grid-area: 1 / 1 / auto / 2;
  }

  form.responsive .form-items > .form-item .form-input {
    grid-area: 1 / 2 / auto / 3;
  }

  form.responsive .form-items > .form-item .meta {
    grid-area: 2 / 2 / auto / 3;
  }

  form.responsive .form-input {
    margin-top: 0;
  }

  form.responsive .form-buttons {
    grid-area: 2 / 2 / auto / 3;
    margin-top: 30px;
    padding-left: 4px;
  }

  form.horizontal .form-items {
    grid-column-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    display: grid;
  }
}

textarea.invalid, input.invalid, .input-box.invalid {
  border-color: var(--form-element-error) !important;
}

input:disabled, .form-input.disabled {
  background: #f9f9f9 !important;
}

.form-item {
  position: relative;
}

.form-item .required-sign {
  color: #ff8080;
}

.form-item label {
  margin-left: 4px;
  font-size: 14px;
}

.form-item .input-icon {
  cursor: pointer;
  pointer-events: none;
  position: absolute;
  top: 28px;
  right: 8px;
}

.form-item .input-icon > img {
  width: 27px;
}

.form-item.inline-title {
  background-color: #f9f9f9;
  font-weight: 400;
  padding: 8px 18px !important;
}

input {
  font-family: Chivo;
}

.DayPicker {
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  display: inline-block;
}

.DayPicker-wrapper {
  width: 100%;
  -webkit-user-select: none;
  user-select: none;
  flex-direction: row;
  margin: 0 auto;
  padding-bottom: 1em;
  position: relative;
}

.DayPicker-Months {
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.DayPicker-Month {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  -webkit-user-select: none;
  user-select: none;
  display: table;
}

.DayPicker-NavBar {
  width: 85%;
  justify-content: space-between;
  margin: 0 auto;
  display: flex;
  position: relative;
  top: 22px;
}

.DayPicker-NavButton {
  width: 1.25em;
  height: 1.25em;
  color: #8b9898;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  margin-top: 2px;
  display: inline-block;
  top: 1em;
  left: auto;
  right: 1.5em;
}

.DayPicker-NavButton:hover {
  opacity: .8;
}

.DayPicker-NavButton--prev {
  transform: rotate(180deg);
  background-image: url("caret-right-icon.5e06ea29.svg") !important;
  background-size: 11.9px !important;
}

.DayPicker-NavButton--next {
  background-image: url("caret-right-icon.5e06ea29.svg") !important;
  background-size: 11.9px !important;
}

.DayPicker-NavButton--interactionDisabled {
  display: none;
}

.DayPicker-Caption {
  text-align: center;
  margin-bottom: 34px;
  display: table-caption;
}

.DayPicker-Caption > div {
  font-size: 17px;
  font-weight: 700;
}

.DayPicker-Weekdays {
  display: table-header-group;
}

.DayPicker-WeekdaysRow {
  display: table-row;
}

.DayPicker-Weekday {
  color: #27283e;
  text-align: center;
  padding: 7px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  display: table-cell;
}

.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

.DayPicker-Body {
  display: table-row-group;
}

.DayPicker-Week {
  display: table-row;
}

.DayPicker-Week :before {
  content: "";
  height: 33px;
  display: table-row;
}

.DayPicker-Day {
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #27283e;
  z-index: 2;
  border-radius: 50%;
  padding: 7.5px 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  display: table-cell;
  position: relative;
}

.DayPicker-Day--highlighted {
  font-weight: bold;
  color: #fff !important;
}

.DayPicker-Day--highlighted:after {
  content: "";
  width: 40px;
  height: 40px;
  z-index: -1;
  background-color: #4cbba1;
  position: absolute;
  top: 30px;
  left: 5px;
  border-radius: 50% !important;
}

.DayPicker-Day--highlighted.DayPicker-Day--outside:after {
  display: none;
}

.DayPicker-Day--today {
  color: #1c1d3e !important;
  font-weight: 400 !important;
}

.DayPicker-Day--highlighted.DayPicker-Day--today {
  color: #fff !important;
  font-weight: 700 !important;
}

.DayPicker-Day.DayPicker-Day--disabled {
  pointer-events: none;
  text-decoration: line-through;
}

.DayPicker-WeekNumber {
  min-width: 1em;
  color: #8b9898;
  vertical-align: middle;
  text-align: right;
  cursor: pointer;
  border-right: 1px solid #eaecec;
  padding: .5em;
  font-size: .75em;
  display: table-cell;
}

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}

.DayPicker-Footer {
  padding-top: .5em;
}

.DayPicker-TodayButton {
  box-shadow: none;
  color: #4a90e2;
  cursor: pointer;
  background-color: #0000;
  background-image: none;
  border: none;
  font-size: .875em;
}

.DayPicker-Day--today {
  color: #d0021b;
  font-weight: 700;
}

.DayPicker-Day--outside {
  color: #8b9898;
  cursor: default;
}

.DayPicker-Day--disabled {
  color: #dce0e0 !important;
  cursor: default !important;
}

.DayPicker-Day--sunday {
  background-color: #f7f8f8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
  color: #dce0e0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  color: #f0f8ff;
  background-color: #4a90e2;
  position: relative;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #51a0fa;
}

.DayPickerInput {
  display: inline-block;
}

.DayPickerInput-OverlayWrapper {
  z-index: 998;
  background: #000000b3;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

@media (min-width: 1024px) {
  .DayPickerInput-OverlayWrapper {
    background: unset;
    position: relative;
  }
}

.DayPickerInput-Overlay {
  z-index: 1;
  background: #fff;
  border-radius: 10px;
  left: 0;
  box-shadow: 0 2px 5px #00000026;
}

.DayPickerInput {
  width: 100%;
}

.DayPickerInput input {
  color: #27283e;
  box-sizing: border-box;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin: 0;
  padding: 14.5px 16px;
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  transition: all .3s;
}

.DayPicker-Day--unavailable {
  color: #9c9c9c;
}

.DayPicker-Day--unavailable:after {
  content: "";
  width: 40px;
  height: 40px;
  z-index: -1;
  background-color: #f9f9f9;
  position: absolute;
  top: 29px;
  left: 5px;
  border-radius: 50% !important;
}

@media (max-width: 767px) {
  .DayPicker-Day--unavailable {
    width: 25px;
  }

  .DayPicker-Day--unavailable:after {
    left: 5%;
  }

  .DayPicker-Day--highlighted {
    width: 25px;
  }

  .DayPicker-Day--highlighted:after {
    left: 5%;
  }
}

.form-item.key-value-pair {
  grid-template-columns: auto 1fr;
  grid-template-areas: "a b"
                       "c c";
  display: grid;
}

.form-item.key-value-pair label {
  grid-area: a;
  align-self: flex-start;
}

.form-item.key-value-pair .form-input {
  flex-direction: row;
  grid-area: b;
  justify-content: center;
  justify-self: end;
  align-items: center;
  display: flex;
}

.form-item.key-value-pair .form-input span {
  text-align: right;
}

.form-item.key-value-pair .form-input .icon {
  padding-left: 5px;
  font-size: 16px;
}

.form-item.key-value-pair .form-input .icon.warning {
  color: #ff0;
}

.form-item.key-value-pair .form-input .icon.failure {
  color: red;
}

.form-item.key-value-pair .form-input .icon.success {
  color: green;
}

.form-item.key-value-pair .meta {
  margin-top: 5px;
  grid-area: c !important;
}

.form-address-search > label {
  margin-top: 12px;
  align-self: flex-start !important;
}

.form-address-search > .form-input {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.form-address-search .search {
  border-bottom: 1px solid #f1f1f5;
  padding: 12px 18px;
}

.form-address-search .info {
  grid-gap: 18px;
  padding: 20px 15px;
  display: grid;
}

.form-address-search .info .switch {
  grid-template-columns: auto max-content;
  grid-template-areas: "a b"
                       "c d";
  display: grid;
}

.form-address-search .info .switch label {
  grid-area: a;
  align-self: center;
  margin-left: 8px;
}

.form-address-search .info .switch .form-input {
  grid-area: b;
  justify-self: end;
}

.form-address-search .info .switch .meta {
  margin-top: -3px;
  grid-area: c !important;
}

.form-address-search .info .switch label {
  text-align: left !important;
}

.form-address-search .geopoint {
  grid-gap: 15px;
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

.dropdown .input-box {
  color: #27283e;
  box-sizing: border-box;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin: 0;
  padding: 10px 14px;
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  transition: all .3s;
}

.dropdown .options {
  background-color: var(--card-bg);
  grid-gap: 18px;
  cursor: pointer;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  padding: 20px 25px 20px 18px;
  font-weight: 400;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

@media (max-width: 767px) {
  .dropdown .overlay {
    z-index: 998;
    background: #000000b3;
    justify-content: center;
    align-items: center;
    display: flex;
    position: fixed;
    inset: 0;
  }
}

@media (min-width: 768px) {
  .dropdown .options-wrapper {
    position: relative;
  }

  .dropdown .options {
    z-index: 999;
    margin-top: 10px;
    position: absolute;
    top: 100%;
  }
}

.dropdown .selected .icon {
  color: var(--message-success-text);
}

.dropdown .option {
  white-space: nowrap;
  flex-direction: row;
  align-items: center;
  display: flex;
}

.form-select .input-box {
  color: #27283e;
  box-sizing: border-box;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin: 0;
  padding: 10px 14px;
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  transition: all .3s;
}

.form-select.disabled .input-box {
  color: #696974;
  cursor: auto;
  background-color: #f5f5f5;
  border-color: #e2e2ea;
}

.form-select .options {
  background-color: var(--card-bg);
  grid-gap: 18px;
  cursor: pointer;
  max-height: 400px;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  padding: 20px 25px 20px 18px;
  font-weight: 400;
  display: grid;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 0 0 3px #687a9b40;
}

@media (max-width: 767px) {
  .form-select .overlay {
    z-index: 998;
    background: #000000b3;
    justify-content: center;
    align-items: center;
    display: flex;
    position: fixed;
    inset: 0;
  }
}

@media (min-width: 768px) {
  .form-select .options-wrapper {
    position: relative;
  }

  .form-select .options {
    z-index: 999;
    margin-top: 10px;
    top: 100%;
  }
}

.form-select .icon {
  color: #777;
  margin-right: 12px;
  font-size: 30px;
}

.form-select .selected .icon {
  color: var(--message-success-text);
}

.form-select .option {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.optimized-image img {
  width: 100%;
  display: block;
}

.label {
  margin: 0;
}

.time-list {
  grid-template-columns: repeat(4, min-content);
  display: grid;
  grid-gap: 9px !important;
}

.time-list .option {
  text-align: center;
  white-space: nowrap;
  padding: 4px;
}

.time-list .selected {
  background-color: var(--message-success-bg);
  color: var(--message-success-text);
  border-radius: 4px;
}

.duration-picker {
  grid-gap: 15px;
  grid-template-columns: 2fr 1fr 1fr;
  align-items: center;
  display: grid;
}

.eta-options {
  margin-top: 1rem;
}

.eta-item {
  white-space: nowrap;
}

.status-indicator {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.status-indicator i {
  color: #fff;
  font-size: 14px;
}

.status-indicator.primary {
  background-color: #50b5ff;
}

.status-indicator.success {
  background-color: #3dd598;
}

.status-indicator.failure {
  background-color: #fc5a5a;
}

.status-indicator.warning {
  background-color: #ff974a;
}

@keyframes scale-up-center {
  0% {
    transform: scale(.5);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes fade-in-left {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.steps {
  height: -moz-fit-content;
  height: fit-content;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  grid-auto-flow: column;
  align-items: center;
  padding: 20px 0 10px;
  display: grid;
}

.steps .container {
  justify-content: center;
  display: grid;
  position: relative;
}

.steps .container .step {
  height: 40px;
  width: 40px;
  color: var(--button-success-fg);
  z-index: 1;
  background-color: #cacaca;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  display: grid;
}

@media (min-width: 768px) {
  .steps .container .step {
    height: 45px;
    width: 45px;
  }
}

.steps .container .step.passed {
  background-color: var(--message-info-fg);
}

.steps .container .step.active {
  height: 50px;
  width: 50px;
  background-color: var(--button-success-bg);
  animation: .2s cubic-bezier(.39, .575, .565, 1) both scale-up-center;
}

@media (min-width: 768px) {
  .steps .container .step.active {
    height: 55px;
    width: 55px;
  }
}

.steps .container .title {
  text-align: center;
  text-transform: capitalize;
  margin-top: 5px;
  font-size: 10px;
}

@media (min-width: 768px) {
  .steps .container .title {
    font-size: 12px;
  }
}

.steps .container .line {
  height: 3px;
  width: 60%;
  background: #bfbfbf;
  position: absolute;
  top: 33%;
  left: 74%;
}

.steps .container .line.active {
  top: 35%;
}

.steps .container .line.passed {
  background-color: var(--message-info-fg);
  animation: .2s cubic-bezier(.39, .575, .565, 1) both fade-in-left;
}

.steps .container:last-child .line {
  display: none;
}

progress {
  height: 8px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
}

progress::-webkit-progress-bar {
  background: #fff;
  border-radius: 10px;
}

progress::-webkit-progress-bar, progress::-webkit-progress-value, progress::-moz-progress-bar {
  border-radius: 10px;
}

.backoffice {
  width: 100vw;
  height: 100vh;
  grid-template: "header header" min-content
                 "nav main" 1fr
                 / min-content 1fr;
  display: grid;
}

.backoffice header {
  width: 100vw;
  height: 53px;
  z-index: 1;
  background-color: #fff;
  grid-area: header;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  display: grid;
  overflow: hidden;
  box-shadow: 0 0 3px #687a9b40;
}

@media (max-width: 767px) {
  .backoffice header {
    grid-template-columns: 40px 1fr 40px;
  }
}

@media (min-width: 768px) {
  .backoffice header {
    grid-template-columns: 110px 1fr 110px;
  }
}

.backoffice header .logo {
  margin-left: 20px;
}

.backoffice header .logo img {
  width: 100%;
  display: block;
}

@media (max-width: 767px) {
  .backoffice header .logo.full {
    display: none;
  }
}

.backoffice header .logo.icon {
  color: #92929d;
  cursor: pointer;
  place-self: center start;
  font-size: 20px;
}

@media (min-width: 768px) {
  .backoffice header .logo.icon {
    display: none;
  }
}

.backoffice header .title {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

.backoffice header .initials {
  color: #fff;
  background-color: #ffc542;
  border: 3px solid #f1f1f5;
  border-radius: 10px;
  justify-self: end;
  margin-right: 20px;
  padding: 3px 4px;
  font-weight: 500;
}

.backoffice header .userinfo {
  display: none;
}

.backoffice header .restaurant {
  flex-direction: horizontal;
  justify-content: center;
  align-items: center;
  display: flex;
}

.backoffice header .restaurant .image {
  width: 33px;
  border: 3px solid #f1f1f5;
  border-radius: 10px;
  margin-right: 10px;
  overflow: hidden;
}

.backoffice header .restaurant .name {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  .backoffice header .restaurant .name {
    font-size: 20px;
    line-height: 28px;
  }
}

.backoffice header .restaurant .description, .backoffice header .restaurant .timezone, .backoffice header .restaurant .country {
  display: none;
}

.backoffice nav {
  z-index: 999;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  background-color: #fff;
  flex-direction: column;
  grid-area: nav;
  display: flex;
  overflow-y: scroll;
  box-shadow: 0 0 3px #687a9b40;
}

.backoffice nav::-webkit-scrollbar {
  width: 1px;
}

.backoffice nav::-webkit-scrollbar-track {
  background: none;
}

.backoffice nav::-webkit-scrollbar-thumb {
  background-color: #0000;
}

@media (max-width: 767px) {
  .backoffice nav {
    height: calc(100vh - 53px);
    transition: transform .3s;
    position: fixed;
    top: 53px;
    left: 0;
    transform: translateX(-105%);
  }

  .backoffice nav.show {
    transform: none;
  }
}

.backoffice nav .title {
  text-transform: uppercase;
  color: #92929d;
  letter-spacing: 1px;
  margin-top: 20px;
  margin-bottom: 5px;
  margin-left: 20px;
  font-size: 11.5px;
  font-weight: 400;
}

.backoffice nav .link {
  border-left: 4px solid var(--card-bg);
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  margin: 5px 0;
  padding: 8px 40px 8px 16px;
  display: flex;
}

.backoffice nav .link .icon {
  color: #92929d;
  margin-right: 16px;
  font-size: 21px;
}

.backoffice nav .link .label {
  white-space: nowrap;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  display: inline-block;
}

.backoffice nav .link.selected {
  border-left: 4px solid var(--color-primary);
}

.backoffice nav .link.selected .icon, .backoffice nav .link.selected .label {
  color: var(--color-primary);
}

.backoffice main {
  grid-area: main;
  padding: 20px;
  overflow-y: scroll;
}

.website {
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  grid-template: "header header" min-content
                 "nav main" 1fr
                 "footer footer" min-content
                 / min-content 1fr;
  display: grid;
  overflow-y: hidden;
}

.website header {
  width: 100vw;
  height: 53px;
  z-index: 1;
  background-color: #fff;
  grid-area: header;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  display: grid;
  overflow: hidden;
  box-shadow: 0 0 3px #687a9b40;
}

@media (max-width: 767px) {
  .website header {
    grid-template-columns: 40px 1fr 40px;
  }
}

@media (min-width: 768px) {
  .website header {
    grid-template-columns: 110px 1fr 110px;
  }
}

.website header .logo {
  margin-left: 20px;
}

.website header .logo img {
  width: 100%;
  display: block;
}

@media (max-width: 767px) {
  .website header .logo.full {
    display: none;
  }
}

.website header .logo.icon {
  color: #92929d;
  cursor: pointer;
  place-self: center start;
  font-size: 20px;
}

@media (min-width: 768px) {
  .website header .logo.icon {
    display: none;
  }
}

.website header .title {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

.website header .initials {
  color: #fff;
  background-color: #ffc542;
  border: 3px solid #f1f1f5;
  border-radius: 10px;
  justify-self: end;
  margin-right: 20px;
  padding: 3px 4px;
  font-weight: 500;
}

.website header .userinfo {
  display: none;
}

.website header .restaurant {
  flex-direction: horizontal;
  justify-content: center;
  align-items: center;
  display: flex;
}

.website header .restaurant .image {
  width: 33px;
  border: 3px solid #f1f1f5;
  border-radius: 10px;
  margin-right: 10px;
  overflow: hidden;
}

.website header .restaurant .name {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  .website header .restaurant .name {
    font-size: 20px;
    line-height: 28px;
  }
}

.website header .restaurant .description, .website header .restaurant .timezone, .website header .restaurant .country {
  display: none;
}

.website nav {
  z-index: 999;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  background-color: #fff;
  flex-direction: column;
  grid-area: nav;
  display: flex;
  overflow-y: scroll;
  box-shadow: 0 0 3px #687a9b40;
}

.website nav::-webkit-scrollbar {
  width: 1px;
}

.website nav::-webkit-scrollbar-track {
  background: none;
}

.website nav::-webkit-scrollbar-thumb {
  background-color: #0000;
}

@media (max-width: 767px) {
  .website nav {
    height: calc(100vh - 53px);
    transition: transform .3s;
    position: fixed;
    top: 53px;
    left: 0;
    transform: translateX(-105%);
  }

  .website nav.show {
    transform: none;
  }
}

.website nav .title {
  text-transform: uppercase;
  color: #92929d;
  letter-spacing: 1px;
  margin-top: 20px;
  margin-bottom: 5px;
  margin-left: 20px;
  font-size: 11.5px;
  font-weight: 400;
}

.website nav .link {
  border-left: 4px solid var(--card-bg);
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  margin: 5px 0;
  padding: 8px 40px 8px 16px;
  display: flex;
}

.website nav .link .icon {
  color: #92929d;
  margin-right: 16px;
  font-size: 21px;
}

.website nav .link .label {
  white-space: nowrap;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  display: inline-block;
}

.website nav .link.selected {
  border-left: 4px solid var(--color-primary);
}

.website nav .link.selected .icon, .website nav .link.selected .label {
  color: var(--color-primary);
}

.website main {
  grid-area: main;
  padding: 20px;
  overflow-y: scroll;
}

.website .website-bottom-nav-bar {
  grid-area: footer;
}

@media (max-width: 767px) {
  #intro-widget {
    padding: 10px 25px;
  }

  #intro-widget #logo {
    display: none;
  }
}

@media (min-width: 768px) {
  #intro-widget {
    background-color: #fff;
    border-right: 1px solid #eee;
    padding: 25px;
    display: grid;
  }

  #intro-widget #logo img {
    height: 25px;
    margin-bottom: 20px;
  }
}

#intro-widget .graphic {
  text-align: center;
}

#intro-widget .graphic img {
  width: 70%;
  margin-bottom: 10px;
}

#intro-widget .marketing-message {
  text-align: center;
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  #intro-widget .marketing-message {
    font-size: 32px;
    line-height: 48px;
  }
}

.document-edit-widget {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.document-edit-widget .title {
  border-bottom: 1px solid #f1f1f5;
  padding: 12px 18px;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  .document-edit-widget .title {
    font-size: 20px;
    line-height: 28px;
  }
}

.document-edit-widget form {
  margin-top: 20px;
  padding: 15px 18px;
}

.document-edit-widget form .form-sub-header {
  border-bottom: 1px solid #f1f1f5;
  margin: 20px 0 0;
  padding-bottom: 5px;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  .document-edit-widget form .form-sub-header {
    font-size: 20px;
    line-height: 28px;
  }
}

.store-select-widget .list {
  grid-gap: 20px;
  display: grid;
}

.store-select-widget .item {
  background-color: var(--card-bg);
  cursor: pointer;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-columns: min-content 1fr;
  grid-template-areas: "a b"
                       "a c";
  align-items: center;
  padding: 15px 18px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.store-select-widget .item .icon {
  margin-top: 1px;
  margin-right: 7px;
}

.store-select-widget .item .icon i {
  background-color: var(--color-secondary);
  color: #fff;
  border-radius: 50%;
  grid-area: a;
  padding: 5px;
}

.store-select-widget .item .name {
  grid-area: b;
  margin-bottom: 2px;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  .store-select-widget .item .name {
    font-size: 20px;
    line-height: 28px;
  }
}

.store-select-widget .item .address {
  letter-spacing: .1px;
  color: #666;
  grid-area: c;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
}

.restaurant-website-info .form-input .photo, .restaurant-website-info .form-input .hero {
  background-color: var(--card-bg);
  background-color: #fafafb;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-rows: 1fr min-content;
  margin-bottom: 20px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.restaurant-website-info .form-input .photo .item, .restaurant-website-info .form-input .hero .item {
  grid-gap: 18px;
  background-color: #fff;
  border-bottom: 3px solid #f1f1f5;
  border-radius: 10px;
  padding: 20px 15px;
  display: grid;
}

.restaurant-website-info .form-input .photo .item .switch, .restaurant-website-info .form-input .hero .item .switch {
  grid-template-columns: auto max-content;
  grid-template-areas: "a b"
                       "c d";
  display: grid;
}

.restaurant-website-info .form-input .photo .item .switch label, .restaurant-website-info .form-input .hero .item .switch label {
  grid-area: a;
  align-self: center;
  margin-left: 8px;
}

.restaurant-website-info .form-input .photo .item .switch .form-input, .restaurant-website-info .form-input .hero .item .switch .form-input {
  grid-area: b;
  justify-self: end;
}

.restaurant-website-info .form-input .photo .item .switch .meta, .restaurant-website-info .form-input .hero .item .switch .meta {
  margin-top: -3px;
  grid-area: c !important;
}

.restaurant-website-info .form-input .photo .item .switch label, .restaurant-website-info .form-input .hero .item .switch label {
  text-align: left !important;
}

.restaurant-website-info .form-input .photo .actions, .restaurant-website-info .form-input .hero .actions {
  grid-template-columns: repeat(5, auto);
  justify-content: left;
  padding: 5px 12px;
  display: grid;
}

.restaurant-website-info .form-input .photo .actions .action, .restaurant-website-info .form-input .hero .actions .action {
  color: #999;
  cursor: pointer;
  font-size: 30px;
}

.restaurant-website-info .form-input .photo .actions .action img, .restaurant-website-info .form-input .hero .actions .action img {
  width: 26px;
  margin-right: 5px;
}

.restaurant-website-info .form-input .heros button, .restaurant-website-info .form-input .photos button {
  color: var(--button-primary-bg);
  border: 1px solid var(--button-primary-bg);
  background-color: #0000;
}

.restaurant-website-info .photos-form-item > label, .restaurant-website-info .heros-form-item > label, .pizza-edit-widget .pizza-sizes-form-item > label {
  margin-top: 12px;
  align-self: flex-start !important;
}

.pizza-edit-widget .sizes {
  grid-row-gap: 20px;
  display: grid;
}

.pizza-edit-widget .size {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.pizza-edit-widget .size .switch {
  grid-template-columns: auto max-content;
  grid-template-areas: "a b"
                       "c d";
  display: grid;
}

.pizza-edit-widget .size .switch label {
  grid-area: a;
  align-self: center;
  margin-left: 8px;
}

.pizza-edit-widget .size .switch .form-input {
  grid-area: b;
  justify-self: end;
}

.pizza-edit-widget .size .switch .meta {
  margin-top: -3px;
  grid-area: c !important;
}

.pizza-edit-widget .size .name {
  border-bottom: 1px solid #f1f1f5;
  padding: 12px 18px;
}

.pizza-edit-widget .size .spec {
  grid-gap: 18px;
  padding: 15px 18px;
  display: grid;
}

.pizza-edit-widget .size .spec .switch {
  grid-template-columns: auto max-content;
  grid-template-areas: "a b"
                       "c d";
  display: grid;
}

.pizza-edit-widget .size .spec .switch label {
  grid-area: a;
  align-self: center;
  margin-left: 8px;
}

.pizza-edit-widget .size .spec .switch .form-input {
  grid-area: b;
  justify-self: end;
}

.pizza-edit-widget .size .spec .switch .meta {
  margin-top: -3px;
  grid-area: c !important;
}

.pizza-edit-widget .size .spec .switch label {
  text-align: left !important;
}

.pizza-edit-widget .remove-toppings-form-item > label {
  margin-top: 12px;
  align-self: flex-start !important;
}

.pizza-edit-widget .remove-toppings-form-item .list {
  background-color: var(--card-bg);
  grid-gap: 20px;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  margin-bottom: 25px;
  padding: 15px 18px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.pizza-edit-widget .remove-toppings-form-item .list .item {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.pizza-edit-widget .remove-toppings-form-item .list .item input {
  margin-right: 20px;
}

.pizza-edit-widget .remove-toppings-form-item .list .item .action {
  color: #999;
  cursor: pointer;
  font-size: 30px;
}

.pizza-edit-widget .remove-toppings-form-item .list .item .action img {
  width: 26px;
  margin-right: 5px;
}

.pizza-edit-widget .remove-toppings-form-item .actions {
  margin-top: 4px;
}

.pizza-edit-widget .remove-toppings-form-item .actions button {
  color: var(--button-primary-bg);
  border: 1px solid var(--button-primary-bg);
  background-color: #0000;
}

.deal-edit-widget .deal-pizzas-form-item > label, .deal-edit-widget .deal-items-form-item > label {
  margin-top: 12px;
  align-self: flex-start !important;
}

.deal-edit-widget .deal-items .list, .deal-edit-widget .deal-pizzas .list {
  grid-gap: 20px;
  margin-bottom: 20px;
  display: grid;
}

.deal-edit-widget .deal-items .item, .deal-edit-widget .deal-pizzas .item {
  background-color: var(--card-bg);
  background-color: #fafafb;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-rows: 1fr min-content;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.deal-edit-widget .deal-items .item .content, .deal-edit-widget .deal-pizzas .item .content {
  background-color: #fff;
  border-bottom: 3px solid #f1f1f5;
  border-radius: 10px;
}

.deal-edit-widget .deal-items .item .actions, .deal-edit-widget .deal-pizzas .item .actions {
  grid-template-columns: repeat(5, auto);
  justify-content: left;
  padding: 5px 12px;
  display: grid;
}

.deal-edit-widget .deal-items .item .actions .action, .deal-edit-widget .deal-pizzas .item .actions .action {
  color: #999;
  cursor: pointer;
  font-size: 30px;
}

.deal-edit-widget .deal-items .item .actions .action img, .deal-edit-widget .deal-pizzas .item .actions .action img {
  width: 26px;
  margin-right: 5px;
}

.deal-edit-widget .deal-items .item .info, .deal-edit-widget .deal-pizzas .item .info {
  grid-gap: 20px;
  border-bottom: 1px solid #f1f1f5;
  grid-template-columns: 1fr 100px;
  padding: 12px 18px;
  display: grid;
}

.deal-edit-widget .deal-items .item .info label, .deal-edit-widget .deal-items .item .info .meta, .deal-edit-widget .deal-pizzas .item .info label, .deal-edit-widget .deal-pizzas .item .info .meta {
  font-weight: normal;
}

.deal-edit-widget .deal-items .actions button, .deal-edit-widget .deal-pizzas .actions button {
  color: var(--button-primary-bg);
  border: 1px solid var(--button-primary-bg);
  background-color: #0000;
}

.deal-edit-widget .pizza-list .head {
  background-color: #f9f9f9;
  border-bottom: 1px solid #f1f1f5;
  padding: 12px 18px;
}

.deal-edit-widget .pizza-list .body {
  grid-gap: 20px;
  padding: 15px 18px;
  display: grid;
}

.deal-edit-widget .pizza-list .head, .deal-edit-widget .pizza {
  grid-gap: 15px;
  grid-template-columns: min-content 1fr 100px;
  align-items: center;
  display: grid;
}

.deal-edit-widget .deal-items .form-item.modifiers > label {
  background-color: #f9f9f9;
  border-bottom: 1px solid #f1f1f5;
  margin: 0;
  padding: 12px 18px;
  display: block;
}

.deal-edit-widget .deal-items .modifier, .deal-edit-widget .deal-items .modifier-info {
  box-shadow: none !important;
  border-radius: 0 !important;
}

.deal-edit-widget .deal-items .modifier {
  margin-bottom: 0 !important;
}

.deal-edit-widget .deal-items .modifier-list-actions {
  margin: 15px;
}

.deal-edit-widget .deal-items .modifier-list-actions button {
  color: var(--button-primary-bg);
  border: 1px solid var(--button-primary-bg);
  background-color: #0000;
}

.deal-edit-widget .deal-items .form-item.modifiers > .meta {
  display: none !important;
}

.form-item-price-spec > label {
  margin-top: 34px;
  align-self: flex-start !important;
}

.form-item-price-spec > .form-input {
  background-color: var(--card-bg);
  grid-gap: 18px;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  padding: 20px 15px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.form-item-price-spec > .form-input .switch {
  grid-template-columns: auto max-content;
  grid-template-areas: "a b"
                       "c d";
  display: grid;
}

.form-item-price-spec > .form-input .switch label {
  grid-area: a;
  align-self: center;
  margin-left: 8px;
}

.form-item-price-spec > .form-input .switch .form-input {
  grid-area: b;
  justify-self: end;
}

.form-item-price-spec > .form-input .switch .meta {
  margin-top: -3px;
  grid-area: c !important;
}

.form-item-price-spec > .form-input .switch label {
  text-align: left !important;
}

.price-spec {
  grid-gap: 18px;
  padding: 0;
  display: grid;
}

.price-spec .switch {
  grid-template-columns: auto max-content;
  grid-template-areas: "a b"
                       "c d";
  display: grid;
}

.price-spec .switch label {
  grid-area: a;
  align-self: center;
  margin-left: 8px;
}

.price-spec .switch .form-input {
  grid-area: b;
  justify-self: end;
}

.price-spec .switch .meta {
  margin-top: -3px;
  grid-area: c !important;
}

.price-spec .switch label {
  text-align: left !important;
}

.price-spec .switch {
  grid-template-columns: auto max-content;
  grid-template-areas: "a b"
                       "c d";
  display: grid;
}

.price-spec .switch label {
  grid-area: a;
  align-self: center;
  margin-left: 8px;
}

.price-spec .switch .form-input {
  grid-area: b;
  justify-self: end;
}

.price-spec .switch .meta {
  margin-top: -3px;
  grid-area: c !important;
}

.price-spec .base-price, .price-spec .extra-price {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.price-spec .base-price input, .price-spec .extra-price input {
  max-width: 80px;
}

.price-spec .extra-price.form-item {
  margin-right: 15px;
}

.price-spec .extra {
  grid-template-columns: 4fr 1fr;
  align-items: center;
  margin-bottom: 15px;
  display: grid;
}

.restaurant-browse-page .list, .restaurant-list {
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  display: grid;
}

.restaurant-browse-page .list .restaurant, .restaurant-list .restaurant {
  background-color: var(--card-bg);
  cursor: pointer;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  flex-direction: column;
  display: flex;
  overflow: hidden;
  box-shadow: 0 0 3px #687a9b40;
}

.restaurant-browse-page .list .restaurant .name, .restaurant-list .restaurant .name {
  padding: 10px 10px 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

.restaurant-browse-page .list .restaurant .timezone, .restaurant-list .restaurant .timezone {
  color: #92929d;
  padding: 0 10px 10px;
  font-size: 12px;
}

.restaurant-browse-page .list .restaurant .description, .restaurant-browse-page .list .restaurant .country, .restaurant-list .restaurant .description, .restaurant-list .restaurant .country {
  display: none;
}

.store-card-view {
  background-color: var(--card-bg);
  cursor: pointer;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  flex-direction: row;
  padding: 20px;
  display: flex;
  box-shadow: 0 0 3px #687a9b40;
}

.store-card-view .name {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.store-card-view .image {
  width: 60px;
  border-radius: 10px;
  margin-right: 22px;
  overflow: hidden;
}

.store-card-view .restaurant-name {
  text-transform: uppercase;
  margin-bottom: -1px;
  font-weight: 500;
}

.store-card-view .store-name {
  color: #4c4c77;
  margin-top: -1px;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

.store-list {
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  display: grid;
}

.form-input .modifier-list .modifier {
  background-color: var(--card-bg);
  background-color: #fafafb;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-rows: 1fr min-content;
  margin-bottom: 20px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.form-input .modifier-list .modifier .modifier-info {
  background-color: #fff;
  border-bottom: 3px solid #f1f1f5;
  border-radius: 10px;
}

.form-input .modifier-list .modifier .modifier-info .header {
  grid-column-gap: 10px;
  grid-template-columns: 1fr 60px 60px;
  padding: 15px;
  display: grid;
}

.form-input .modifier-list .modifier .modifier-info > .meta {
  border-bottom: 1px solid #f1f1f5;
  margin: 0;
  padding: 0 15px 15px;
}

.form-input .modifier-list .modifier .modifier-info > .badges {
  flex-direction: row;
  margin-bottom: 12px;
  margin-left: 15px;
  display: flex;
}

.form-input .modifier-list .modifier .modifier-info > .badges .badge {
  margin-right: 8px;
  display: inline;
}

.form-input .modifier-list .modifier .modifier-info .allow-multiple {
  border-bottom: 1px solid #f1f1f5;
  padding: 15px 0;
}

.form-input .modifier-list .modifier .modifier-info .allow-multiple .switch {
  grid-template-columns: auto max-content;
  grid-template-areas: "a b"
                       "c d";
  padding: 0 15px;
  display: grid;
}

.form-input .modifier-list .modifier .modifier-info .allow-multiple .switch label {
  grid-area: a;
  align-self: center;
  margin-left: 8px;
}

.form-input .modifier-list .modifier .modifier-info .allow-multiple .switch .form-input {
  grid-area: b;
  justify-self: end;
}

.form-input .modifier-list .modifier .modifier-info .allow-multiple .switch .meta {
  margin-top: -3px;
  grid-area: c !important;
}

.form-input .modifier-list .modifier .modifier-actions {
  grid-template-columns: repeat(5, auto);
  justify-content: left;
  padding: 5px 12px;
  display: grid;
}

.form-input .modifier-list .modifier .modifier-actions .action {
  color: #999;
  cursor: pointer;
  font-size: 30px;
}

.form-input .modifier-list .modifier .modifier-actions .action img {
  width: 26px;
  margin-right: 5px;
}

.form-input .modifier-options .head {
  padding: 15px 15px 4px;
  font-size: 13px;
}

.form-input .modifier-options > .meta {
  margin: 0;
  padding: 0 15px 15px;
}

.form-input .modifier-option {
  grid-column-gap: 10px;
  grid-template-columns: 1fr 60px min-content;
  align-items: center;
  margin-bottom: 15px;
  padding: 0 15px;
  display: grid;
}

.form-input .modifier-option .actions {
  grid-template-columns: repeat(3, min-content);
  display: grid;
}

.form-input .modifier-option .action {
  color: #999;
  cursor: pointer;
  font-size: 25px;
}

.form-input .modifier-option .action img {
  width: 26px;
  margin-right: 5px;
}

.form-input .modifier-list-actions button {
  color: var(--button-primary-bg);
  border: 1px solid var(--button-primary-bg);
  background-color: #0000;
}

.form-item.modifiers > label {
  margin-top: 12px;
  align-self: flex-start !important;
}

.pizza-settings-edit-widget .form-input .topping, .pizza-settings-edit-widget .form-input .pizza-size {
  background-color: var(--card-bg);
  background-color: #fafafb;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-rows: 1fr min-content;
  margin-bottom: 20px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.pizza-settings-edit-widget .form-input .topping .info, .pizza-settings-edit-widget .form-input .pizza-size .info {
  grid-gap: 18px;
  background-color: #fff;
  border-bottom: 3px solid #f1f1f5;
  border-radius: 10px;
  padding: 20px 15px;
  display: grid;
}

.pizza-settings-edit-widget .form-input .topping .info .switch, .pizza-settings-edit-widget .form-input .pizza-size .info .switch {
  grid-template-columns: auto max-content;
  grid-template-areas: "a b"
                       "c d";
  display: grid;
}

.pizza-settings-edit-widget .form-input .topping .info .switch label, .pizza-settings-edit-widget .form-input .pizza-size .info .switch label {
  grid-area: a;
  align-self: center;
  margin-left: 8px;
}

.pizza-settings-edit-widget .form-input .topping .info .switch .form-input, .pizza-settings-edit-widget .form-input .pizza-size .info .switch .form-input {
  grid-area: b;
  justify-self: end;
}

.pizza-settings-edit-widget .form-input .topping .info .switch .meta, .pizza-settings-edit-widget .form-input .pizza-size .info .switch .meta {
  margin-top: -3px;
  grid-area: c !important;
}

.pizza-settings-edit-widget .form-input .topping .info .switch label, .pizza-settings-edit-widget .form-input .pizza-size .info .switch label {
  text-align: left !important;
}

.pizza-settings-edit-widget .form-input .topping .actions, .pizza-settings-edit-widget .form-input .pizza-size .actions {
  grid-template-columns: repeat(5, auto);
  justify-content: left;
  padding: 5px 12px;
  display: grid;
}

.pizza-settings-edit-widget .form-input .topping .actions .action, .pizza-settings-edit-widget .form-input .pizza-size .actions .action {
  color: #999;
  cursor: pointer;
  font-size: 30px;
}

.pizza-settings-edit-widget .form-input .topping .actions .action img, .pizza-settings-edit-widget .form-input .pizza-size .actions .action img {
  width: 26px;
  margin-right: 5px;
}

.pizza-settings-edit-widget .form-input .toppings button, .pizza-settings-edit-widget .form-input .pizza-sizes button {
  color: var(--button-primary-bg);
  border: 1px solid var(--button-primary-bg);
  background-color: #0000;
}

.pizza-settings-edit-widget .pizza-sizes-form-item > label, .pizza-settings-edit-widget .toppings-list-form-item > label {
  margin-top: 12px;
  align-self: flex-start !important;
}

.item-spec-list {
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  display: grid;
}

.item-spec {
  background-color: var(--card-bg);
  cursor: pointer;
  background-color: #fafafb;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-rows: 1fr min-content;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.item-spec .body {
  background-color: #fff;
  border-bottom: 3px solid #f1f1f5;
  border-radius: 10px;
  grid-template: "a d" min-content
                 "b d" 1fr
                 "c d" min-content
                 / 1fr min-content;
  padding: 12px;
  display: grid;
}

.item-spec .image {
  width: 100px;
  grid-area: d;
  margin-left: 10px;
  overflow: hidden;
}

.item-spec .image img {
  border-radius: 10px;
}

.item-spec .name {
  grid-area: a;
  align-self: flex-start;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

.item-spec .description {
  grid-area: b;
  align-self: flex-start;
  font-size: 13px;
}

.item-spec .price {
  color: var(--color-secondary);
  grid-area: c;
  align-self: flex-end;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

.item-spec .actions {
  grid-template-columns: repeat(5, auto);
  justify-content: left;
  padding: 5px 12px;
  display: grid;
}

.item-spec .actions .action {
  color: #999;
  cursor: pointer;
  font-size: 30px;
}

.item-spec .actions .action img {
  width: 26px;
  margin-right: 5px;
}

.backoffice .category-list {
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  display: grid;
}

.backoffice .category {
  background-color: var(--card-bg);
  background-color: #fafafb;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-rows: 1fr min-content;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.backoffice .category .image {
  border-radius: 10px;
  margin-bottom: 15px;
  overflow: hidden;
}

.backoffice .category .name {
  padding-left: 6px;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

.backoffice .category .description {
  padding-left: 6px;
}

.backoffice .category .body {
  cursor: pointer;
  background-color: #fff;
  border-bottom: 3px solid #f1f1f5;
  border-radius: 10px;
  padding: 10px;
}

.backoffice .category .actions {
  grid-template-columns: repeat(5, auto);
  justify-content: left;
  padding: 5px 12px;
  display: grid;
}

.backoffice .category .actions .action {
  color: #999;
  cursor: pointer;
  font-size: 30px;
}

.backoffice .category .actions .action img {
  width: 26px;
  margin-right: 5px;
}

.printer-queue {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.printer-queue .title {
  border-bottom: 1px solid #f1f1f5;
  padding: 12px 18px;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  .printer-queue .title {
    font-size: 20px;
    line-height: 28px;
  }
}

.printer-queue .order-list-view {
  border-bottom: 1px solid #f1f1f5;
  grid-template-columns: min-content 1fr min-content;
  grid-template-areas: "restaurant-icon store-name"
                       "restaurant-icon collection-type"
                       ". grand-total"
                       ". actions";
  padding: 18px 0;
  display: grid;
}

.printer-queue .order-list-view:last-child {
  border-bottom: none;
}

.printer-queue .order-list-view .optimized-image {
  width: 45px;
  border: 3px solid #f1f1f5;
  border-radius: 10px;
  grid-area: restaurant-icon;
  align-self: flex-start;
  margin: 0 15px;
  overflow: hidden;
}

.printer-queue .order-list-view .store-name {
  color: #000521;
  grid-area: store-name;
  align-self: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.printer-queue .order-list-view .grand-total {
  color: #fa7268;
  grid-area: grand-total;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}

.printer-queue .order-list-view .collection-type {
  white-space: nowrap;
  color: #696974;
  grid-area: collection-type;
  align-self: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.printer-queue .order-list-view .message {
  grid-area: message;
}

.printer-queue .order-list-view .status {
  white-space: nowrap;
  grid-area: status;
  place-self: center start;
}

.printer-queue .order-list-view .actions {
  border-top: 1px dashed #e9ecf3;
  flex-direction: row;
  grid-area: actions;
  margin-top: 10px;
  padding-top: 15px;
  display: flex;
}

.printer-queue .order-list-view .actions button {
  color: var(--button-primary-bg);
  border: 1px solid var(--button-primary-bg);
  box-shadow: none;
  background-color: #0000;
  border-radius: 4px;
  margin-right: 7px;
  padding: 0 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.printer-queue .order-list-view .actions .accept-order {
  border: 1px solid var(--button-success-bg);
  color: #0e9347;
  background-color: #0000;
}

.printer-queue .order-list-view .actions .reject-order {
  color: #fc5a5a;
  background-color: #0000;
  border: 1px solid #fc5a5a;
}

.store-edit-widget .form-address > .form-input {
  background-color: var(--card-bg);
  grid-gap: 18px;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  padding: 20px 15px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.store-edit-widget .form-address > .form-input .switch {
  grid-template-columns: auto max-content;
  grid-template-areas: "a b"
                       "c d";
  display: grid;
}

.store-edit-widget .form-address > .form-input .switch label {
  grid-area: a;
  align-self: center;
  margin-left: 8px;
}

.store-edit-widget .form-address > .form-input .switch .form-input {
  grid-area: b;
  justify-self: end;
}

.store-edit-widget .form-address > .form-input .switch .meta {
  margin-top: -3px;
  grid-area: c !important;
}

.store-edit-widget .form-address > .form-input .switch label {
  text-align: left !important;
}

.store-edit-widget .form-address > label {
  margin-top: 12px;
  align-self: flex-start !important;
}

.coupon-card {
  background-color: var(--card-bg);
  cursor: pointer;
  background-color: #fafafb;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-rows: 1fr min-content;
  margin: 15px 0;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.coupon-card .body {
  background-color: #fff;
  border-bottom: 3px solid #f1f1f5;
  border-radius: 10px;
  grid-template: "a d" min-content
                 "b d" 1fr
                 "c d" min-content
                 / 1fr min-content;
  padding: 12px;
  display: grid;
}

.coupon-card .name {
  grid-area: a;
  align-self: flex-start;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

.coupon-card .description {
  grid-area: b;
  align-self: flex-start;
  font-size: 13px;
}

.coupon-card .actions {
  grid-template-columns: repeat(5, auto);
  justify-content: left;
  padding: 5px 12px;
  display: grid;
}

.coupon-card .actions .action {
  color: #999;
  cursor: pointer;
  font-size: 30px;
}

.coupon-card .actions .action img {
  width: 26px;
  margin-right: 5px;
}

.coupon-edit-widget .form-address > .form-input {
  background-color: var(--card-bg);
  grid-gap: 18px;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  padding: 20px 15px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.coupon-edit-widget .form-address > .form-input .switch {
  grid-template-columns: auto max-content;
  grid-template-areas: "a b"
                       "c d";
  display: grid;
}

.coupon-edit-widget .form-address > .form-input .switch label {
  grid-area: a;
  align-self: center;
  margin-left: 8px;
}

.coupon-edit-widget .form-address > .form-input .switch .form-input {
  grid-area: b;
  justify-self: end;
}

.coupon-edit-widget .form-address > .form-input .switch .meta {
  margin-top: -3px;
  grid-area: c !important;
}

.coupon-edit-widget .form-address > .form-input .switch label {
  text-align: left !important;
}

.coupon-edit-widget .form-address > label {
  margin-top: 12px;
  align-self: flex-start !important;
}

.modal.loyalty-card-modal {
  max-width: 450px;
}

.modal.loyalty-card-modal > .close {
  display: none;
}

.modal.loyalty-card-modal > .loyalty-card-modal-data > .loyalty-card-modal-body {
  text-align: center;
  margin-top: 30px;
  position: relative;
}

.modal.loyalty-card-modal > .loyalty-card-modal-data > .loyalty-card-modal-body .header-icon {
  width: 100%;
  position: absolute;
  top: -80px;
  left: 0;
  right: 0;
}

.modal.loyalty-card-modal > .loyalty-card-modal-data > .loyalty-card-modal-body .header-icon .bg {
  fill: var(--color-primary);
}

.modal.loyalty-card-modal > .loyalty-card-modal-data > .loyalty-card-modal-body .header-text {
  font-size: 20px;
  font-weight: 400;
}

.modal.loyalty-card-modal > .loyalty-card-modal-data > .loyalty-card-modal-body .loyalty-card {
  width: unset;
}

@media (min-width: 1024px) {
  .modal.loyalty-card-modal > .loyalty-card-modal-data > .loyalty-card-modal-body .loyalty-card {
    margin: 0 15px;
  }
}

.modal.loyalty-card-modal > .loyalty-card-modal-data > .loyalty-card-modal-body .subtitle {
  font-size: 16px;
}

.modal.loyalty-card-modal > .loyalty-card-modal-data > .loyalty-card-modal-body .close-link {
  cursor: pointer;
  margin-top: 30px;
  font-size: 16px;
}

.allergen-info {
  border-top: 1px solid var(--form-element-border);
  grid-column-gap: 10.5px;
  grid-row-gap: 4px;
  grid-template-columns: min-content 1fr;
  grid-template-areas: "icon title"
                       "icon allergen-types";
  margin: 18px 32px 10px;
  padding-top: 16px;
  display: grid;
}

.allergen-info .icon {
  grid-area: icon;
}

.allergen-info .title {
  grid-area: title;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

.allergen-info .types {
  color: #757575;
  word-spacing: 1px;
  grid-row-gap: 4px;
  grid-area: allergen-types;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  list-style-type: none;
  display: grid;
}

.uber-proof-of-delivery-widget {
  width: 100%;
  margin: 0 auto;
}

.uber-proof-of-delivery-widget .proof-of-delivery-image > img {
  width: 100%;
}

.item-summary-view-widget {
  color: #7d7e92;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: "quantity name price"
                       ". discount discount"
                       ". extra-charge extra-charge"
                       ". description ."
                       ". comments comments";
  column-gap: 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  display: grid;
}

.item-summary-view-widget > .quantity {
  color: #1c1d3e;
  grid-area: quantity;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.item-summary-view-widget > .name {
  color: #1c1d3e;
  grid-area: name;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.item-summary-view-widget > .price {
  color: #1c1d3e;
  grid-area: price;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.item-summary-view-widget > .discount {
  grid-area: discount;
  justify-content: space-between;
  margin-top: 8px;
  display: flex;
}

.item-summary-view-widget > .extra-charge {
  grid-area: extra-charge;
  justify-content: space-between;
  margin-top: 8px;
  display: flex;
}

.item-summary-view-widget > .comments {
  grid-area: comments;
  justify-content: space-between;
  margin-top: 8px;
  display: flex;
}

.item-summary-view-widget > .item-description-view-widget {
  grid-area: description;
  margin-top: 8px;
}

.item-description-view-widget {
  white-space: pre-wrap;
  flex-direction: column;
  display: flex;
}

.item-description-view-widget > * {
  text-align: left !important;
}

.item-description-view-widget > .title {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}

.item-description-view-widget > .title + * {
  margin-top: 8px;
}

.item-description-view-widget > .modifier, .item-description-view-widget > .simple {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.item-description-view-widget > .simple:first-child + .title {
  margin-top: 8px;
}

.item-description-view-widget > .break {
  height: 8px;
}

.item-description-view-widget > .break + * {
  margin-top: 0;
}

.marketing-campaign-widget {
  padding: 24px;
}

.marketing-campaign-widget .header {
  color: #27283e;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  .marketing-campaign-widget .header {
    font-size: 20px;
    line-height: 28px;
  }
}

.marketing-campaign-widget .icon {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 32px;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  display: flex;
}

.marketing-campaign-widget .section {
  min-height: 52vh;
}

.marketing-campaign-widget .section .body {
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  margin-top: 32px;
  padding: 16px 20px;
}

.marketing-campaign-widget .section .body .title {
  align-items: center;
  display: flex;
}

.marketing-campaign-widget .section .body .title img {
  margin-right: 10px;
}

.marketing-campaign-widget .section .body .description {
  color: #757575;
  padding: 8px 0 0 30px;
}

.marketing-campaign-widget .actions {
  justify-content: flex-end;
  display: flex;
}

.marketing-campaign-widget .actions button {
  border: 2px solid #4cbba1;
  margin-left: 8px;
}

.marketing-campaign-widget .actions button.skip {
  color: #4cbba1;
  background-color: #0000;
}

@media (min-width: 768px) {
  .backoffice .order-history-page form > .form-items > .form-item {
    max-width: 300px;
  }
}

.backoffice .order-history-page .date-range {
  margin-top: 12px;
  padding-left: 6px;
  font-size: 13px;
  font-style: italic;
}

.backoffice .order-history-page .summary {
  grid-row-gap: 20px;
  grid-column-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  margin: 20px 0;
  display: grid;
}

.backoffice .order-history-page .summary .statistic {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  padding: 10px 20px 15px;
  box-shadow: 0 0 3px #687a9b40;
}

.backoffice .order-history-page .summary .statistic .title {
  letter-spacing: .1px;
  color: #555;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.backoffice .order-history-page .summary .statistic .value {
  grid-template-columns: max-content max-content;
  justify-content: start;
  align-items: center;
  margin-top: 10px;
  display: grid;
}

.backoffice .order-history-page .summary .statistic .prefix {
  color: #171725;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
}

.backoffice .order-history-page .summary .statistic .suffix {
  letter-spacing: .1px;
  color: #777;
  margin-left: 8px;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.backoffice .order-history-page .summary .statistic.cash .suffix, .backoffice .order-history-page .summary .statistic.credit-card .suffix {
  color: #3dd598;
}

.backoffice .order-history-page .summary .statistic.pickup .suffix, .backoffice .order-history-page .summary .statistic.delivery .suffix {
  color: #ff974a;
}

.backoffice .order-history-page .summary .statistic.dine-in .suffix {
  color: #a461d8;
}

.backoffice .order-history-page .list {
  grid-gap: 25px;
  display: grid;
}

.backoffice .order-history-page .order-list-view {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-columns: min-content 1fr min-content;
  grid-template-areas: "status-indicator collection-type status"
                       ". time grand-total"
                       ". message message"
                       ". actions actions";
  padding: 18px 0;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.backoffice .order-history-page .order-list-view .status-indicator {
  grid-area: status-indicator;
  align-self: center;
  margin: 0 15px;
}

.backoffice .order-history-page .order-list-view .collection-type {
  color: #000521;
  grid-area: collection-type;
  align-self: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.backoffice .order-history-page .order-list-view .grand-total {
  color: #fa7268;
  text-align: right;
  grid-area: grand-total;
  align-self: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}

.backoffice .order-history-page .order-list-view .time {
  white-space: nowrap;
  color: #696974;
  grid-area: time;
  align-self: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.backoffice .order-history-page .order-list-view .message {
  grid-area: message;
}

.backoffice .order-history-page .order-list-view .status {
  white-space: nowrap;
  grid-area: status;
  place-self: center end;
}

.backoffice .order-history-page .order-list-view .actions {
  grid-area: actions;
}

.backoffice .order-history-page .order-list-view .status {
  margin-right: 20px;
}

.backoffice .order-history-page .order-list-view .grand-total, .backoffice .order-history-page .order-list-view .message {
  padding-right: 20px;
}

.backoffice .order-history-page .order-list-view .time, .backoffice .order-history-page .order-list-view .grand-total, .backoffice .order-history-page .order-list-view .message {
  border-bottom: 1px dashed #e9ecf3;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.backoffice .order-history-page .order-list-view .time, .backoffice .order-history-page .order-list-view .grand-total {
  margin-top: 8px;
}

.backoffice .order-history-page .order-list-view .actions {
  flex-direction: row;
  display: flex;
}

.backoffice .order-history-page .order-list-view .actions button {
  color: var(--button-primary-bg);
  border: 1px solid var(--button-primary-bg);
  box-shadow: none;
  background-color: #0000;
  border-radius: 4px;
  margin-right: 10px;
  padding: 0 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.backoffice .order-history-page .order-list-view .actions .accept-order {
  border: 1px solid var(--button-success-bg);
  color: #0e9347;
  background-color: #0000;
}

.backoffice .order-history-page .order-list-view .actions .reject-order {
  color: #fc5a5a;
  background-color: #0000;
  border: 1px solid #fc5a5a;
}

.store-timings-edit-widget .dow > label {
  margin-top: 12px;
  font-weight: 500;
  align-self: flex-start !important;
}

.store-timings-edit-widget .dow > .form-input {
  background-color: var(--card-bg);
  grid-gap: 18px;
  grid-gap: 0;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  padding: 0;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.store-timings-edit-widget .dow > .form-input .switch {
  grid-template-columns: auto max-content;
  grid-template-areas: "a b"
                       "c d";
  display: grid;
}

.store-timings-edit-widget .dow > .form-input .switch label {
  grid-area: a;
  align-self: center;
  margin-left: 8px;
}

.store-timings-edit-widget .dow > .form-input .switch .form-input {
  grid-area: b;
  justify-self: end;
}

.store-timings-edit-widget .dow > .form-input .switch .meta {
  margin-top: -3px;
  grid-area: c !important;
}

.store-timings-edit-widget .dow > .form-input .switch label {
  text-align: left !important;
}

.store-timings-edit-widget .hours {
  margin: 5px 0;
}

.store-timings-edit-widget .session:first-child > .form-item:first-child {
  background-color: #f9f9f9;
  border-bottom: 1px solid #f1f1f5;
  margin: 0;
  padding: 12px 18px;
  font-weight: 500;
}

.store-timings-edit-widget .session .form-item {
  padding: 10px 15px;
}

.delivery-settings-widget .form-input .delivery-suburb {
  background-color: var(--card-bg);
  background-color: #fafafb;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-rows: 1fr min-content;
  margin-bottom: 20px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.delivery-settings-widget .form-input .delivery-suburb .info {
  grid-gap: 18px;
  background-color: #fff;
  border-bottom: 3px solid #f1f1f5;
  border-radius: 10px;
  padding: 20px 15px;
  display: grid;
}

.delivery-settings-widget .form-input .delivery-suburb .info .switch {
  grid-template-columns: auto max-content;
  grid-template-areas: "a b"
                       "c d";
  display: grid;
}

.delivery-settings-widget .form-input .delivery-suburb .info .switch label {
  grid-area: a;
  align-self: center;
  margin-left: 8px;
}

.delivery-settings-widget .form-input .delivery-suburb .info .switch .form-input {
  grid-area: b;
  justify-self: end;
}

.delivery-settings-widget .form-input .delivery-suburb .info .switch .meta {
  margin-top: -3px;
  grid-area: c !important;
}

.delivery-settings-widget .form-input .delivery-suburb .info .switch label {
  text-align: left !important;
}

.delivery-settings-widget .form-input .delivery-suburb .actions {
  grid-template-columns: repeat(5, auto);
  justify-content: left;
  padding: 5px 12px;
  display: grid;
}

.delivery-settings-widget .form-input .delivery-suburb .actions .action {
  color: #999;
  cursor: pointer;
  font-size: 30px;
}

.delivery-settings-widget .form-input .delivery-suburb .actions .action img {
  width: 26px;
  margin-right: 5px;
}

.delivery-settings-widget .form-input .delivery-suburbs button {
  color: var(--button-primary-bg);
  border: 1px solid var(--button-primary-bg);
  background-color: #0000;
}

.delivery-settings-widget .delivery-suburbs-form-item > label {
  margin-top: 12px;
  align-self: flex-start !important;
}

.payments-page .body {
  grid-gap: 20px;
  display: grid;
}

@media (min-width: 768px) {
  .payments-page .body {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }
}

.payments-page .update-payout-schedule, .payments-page .payout-schedule, .payments-page .current-external-account, .payments-page .add-external-account, .payments-page .connected-account {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.payments-page .update-payout-schedule form, .payments-page .payout-schedule form, .payments-page .current-external-account form, .payments-page .add-external-account form, .payments-page .connected-account form {
  padding: 20px 0;
}

.payments-page .update-payout-schedule .title, .payments-page .payout-schedule .title, .payments-page .current-external-account .title, .payments-page .add-external-account .title, .payments-page .connected-account .title {
  border-bottom: 1px solid #f1f1f5;
  padding: 12px 18px;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  .payments-page .update-payout-schedule .title, .payments-page .payout-schedule .title, .payments-page .current-external-account .title, .payments-page .add-external-account .title, .payments-page .connected-account .title {
    font-size: 20px;
    line-height: 28px;
  }
}

.payments-page .update-payout-schedule .form-item, .payments-page .payout-schedule .form-item, .payments-page .current-external-account .form-item, .payments-page .add-external-account .form-item, .payments-page .connected-account .form-item {
  padding: 0 15px;
}

.payments-page .update-payout-schedule .actions, .payments-page .payout-schedule .actions, .payments-page .current-external-account .actions, .payments-page .add-external-account .actions, .payments-page .connected-account .actions {
  background-color: #fafafb;
  border-top: 1px solid #f1f1f5;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  flex-direction: row;
  align-items: center;
  padding: 15px 18px;
  display: flex;
}

.payments-page .update-payout-schedule .actions button, .payments-page .payout-schedule .actions button, .payments-page .current-external-account .actions button, .payments-page .add-external-account .actions button, .payments-page .connected-account .actions button {
  margin-right: 10px;
}

.payments-page .update-payout-schedule .actions a, .payments-page .payout-schedule .actions a, .payments-page .current-external-account .actions a, .payments-page .add-external-account .actions a, .payments-page .connected-account .actions a {
  margin-left: 6px;
  font-size: .75rem;
  font-weight: 400;
}

.payments-page .update-payout-schedule .actions a.cancel, .payments-page .payout-schedule .actions a.cancel, .payments-page .current-external-account .actions a.cancel, .payments-page .add-external-account .actions a.cancel, .payments-page .connected-account .actions a.cancel {
  color: var(--message-error-fg);
}

.payments-page .update-payout-schedule .actions button, .payments-page .payout-schedule .actions button, .payments-page .current-external-account .actions button, .payments-page .add-external-account .actions button, .payments-page .connected-account .actions button {
  margin-right: 10px;
}

.payments-page .add-external-account .StripeElement {
  padding: 15px 18px;
}

.payments-page .update-payout-schedule, .payments-page .payout-schedule {
  margin-top: 20px;
}

.backoffice-menu-page form {
  margin-bottom: 25px;
}

@media (min-width: 768px) {
  .backoffice-menu-page form {
    max-width: 300px;
  }
}

.order-settings-page .body {
  grid-gap: 20px;
  display: grid;
}

@media (min-width: 768px) {
  .order-settings-page .body {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }
}

.order-settings-page .checkout-settings, .order-settings-page .order-settings, .order-settings-page .printer-settings {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.order-settings-page .checkout-settings form, .order-settings-page .order-settings form, .order-settings-page .printer-settings form {
  padding: 20px 0;
}

.order-settings-page .checkout-settings .title, .order-settings-page .order-settings .title, .order-settings-page .printer-settings .title {
  border-bottom: 1px solid #f1f1f5;
  padding: 12px 18px;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  .order-settings-page .checkout-settings .title, .order-settings-page .order-settings .title, .order-settings-page .printer-settings .title {
    font-size: 20px;
    line-height: 28px;
  }
}

.order-settings-page .checkout-settings .form-item, .order-settings-page .order-settings .form-item, .order-settings-page .printer-settings .form-item {
  padding: 0 15px;
}

.order-settings-page .checkout-settings .actions, .order-settings-page .order-settings .actions, .order-settings-page .printer-settings .actions {
  background-color: #fafafb;
  border-top: 1px solid #f1f1f5;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  flex-direction: row;
  align-items: center;
  padding: 15px 18px;
  display: flex;
}

.order-settings-page .checkout-settings .actions button, .order-settings-page .order-settings .actions button, .order-settings-page .printer-settings .actions button {
  margin-right: 10px;
}

.order-settings-page .checkout-settings .actions a, .order-settings-page .order-settings .actions a, .order-settings-page .printer-settings .actions a {
  margin-left: 6px;
  font-size: .75rem;
  font-weight: 400;
}

.order-settings-page .checkout-settings .actions a.cancel, .order-settings-page .order-settings .actions a.cancel, .order-settings-page .printer-settings .actions a.cancel {
  color: var(--message-error-fg);
}

.order-settings-page .checkout-settings .actions button, .order-settings-page .order-settings .actions button, .order-settings-page .printer-settings .actions button {
  margin-right: 10px;
}

.printer-page .body {
  grid-gap: 20px;
  display: grid;
}

@media (min-width: 768px) {
  .printer-page .body {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }
}

.online-ordering main {
  margin-top: var(--safe-area-inset-top);
}

.online-ordering footer {
  display: none;
}

@media (max-width: 767px) {
  .online-ordering footer {
    width: 100%;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (min-width: 1024px) {
  .online-ordering main {
    margin: 0 auto;
  }
}

.online-ordering .banner {
  padding: 20px;
}

@media (min-width: 1024px) {
  .online-ordering .banner {
    max-width: 1280px;
    margin: 0 auto;
  }
}

.update-delivery-address {
  padding: 0 !important;
}

.update-delivery-address .section {
  border-bottom: 1px solid #e2e2ea;
  padding: 20px 30px 10px;
}

.update-delivery-address .section .header {
  font-size: 18px;
}

.update-delivery-address .section .sub-header {
  font-size: 16px;
}

.update-delivery-address .title {
  margin-bottom: 20px;
  font-weight: 500;
}

.update-delivery-address p {
  text-align: center;
  margin: 10px !important;
}

.update-delivery-address .delivery-address {
  padding-bottom: 40px;
}

.update-delivery-address .action-buttons {
  grid-gap: 18px;
  grid-auto-flow: row;
  justify-items: center;
  margin: 30px;
  display: grid;
}

.update-delivery-address .action-buttons button {
  width: 70%;
  font-size: 14px;
}

.update-delivery-address .action-buttons .save {
  background: var(--button-success-bg);
  color: var(--button-success-fg);
}

.update-delivery-address .action-buttons .discard {
  color: #fc6565;
  background: none;
  border: 1px solid #fc6565;
}

.update-delivery-address .action-buttons .cancel {
  color: var(--button-secondary-fg);
  background: none;
}

.third-party-delivery {
  text-align: center;
  padding: 30px !important;
}

.third-party-delivery .sub-title {
  color: #8d92a3;
  text-align: center;
}

@media (min-width: 768px) {
  .third-party-delivery {
    padding: 40px !important;
  }

  .third-party-delivery .sub-title {
    line-height: 20px;
  }

  .third-party-delivery .third-party-delivery-services-list > a {
    padding: 10px;
  }
}

.third-party-delivery .third-party-delivery-services-list {
  place-items: start center;
  display: grid;
}

.third-party-delivery .third-party-delivery-services-list a {
  cursor: pointer;
  width: 55%;
  color: var(--link-fg);
  border: 1px solid #0162ff;
  border-radius: 10px;
  margin: 5px;
  padding: 8px;
  font-weight: 600;
  text-decoration: none;
}

.third-party-delivery .third-party-delivery-services-list a:hover {
  color: #fff;
  background: #0162ff;
}

.restaurant-website header {
  width: 100%;
  position: fixed;
  top: 0;
}

.restaurant-website main {
  margin-top: var(--safe-area-inset-top);
  padding: 73px 20px 20px;
}

@media (min-width: 1024px) {
  .restaurant-website main {
    max-width: 1280px;
    margin: 0 auto;
  }
}

.restaurant-website header {
  padding: 0;
  padding-top: var(--safe-area-inset-top);
  height: 53px;
  z-index: 1;
  background-color: #fff;
  align-items: center;
  box-shadow: 0 0 3px #687a9b40;
}

.restaurant-website header .wrapper {
  width: 100vw;
  height: 53px;
  justify-content: center;
  align-items: center;
  display: grid;
  overflow: hidden;
}

@media (min-width: 1024px) {
  .restaurant-website header .wrapper {
    max-width: 1320px;
    margin: 0 auto;
  }
}

.restaurant-website-page {
  grid-row-gap: 30px;
  margin-bottom: 40px;
  display: grid;
}

@media (max-width: 767px) {
  .restaurant-website-page .heros {
    grid-gap: 16px;
    grid-template-columns: 83vw;
    grid-auto-columns: 83vw;
    grid-auto-flow: column;
    display: grid;
    overflow-x: auto;
  }

  .restaurant-website-page .heros::-webkit-scrollbar {
    display: none;
  }

  .restaurant-website-page .hero {
    width: 83vw;
  }
}

@media (min-width: 768px) {
  .restaurant-website-page .heros {
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    display: grid;
  }
}

.restaurant-website-page .hero {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 3px #687a9b40;
}

.restaurant-website-page .hero .content {
  padding: 15px 18px;
}

.restaurant-website-page .hero .title {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

.restaurant-website-page .hero .description {
  color: #666;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px;
}

.restaurant-website-page .hero .title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.restaurant-website-page .hero .description {
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.restaurant-website-page .ctas {
  grid-gap: 12px;
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

.restaurant-website-page .ctas .cta {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  padding: 18px 18px 30px;
  box-shadow: 0 0 3px #687a9b40;
}

.restaurant-website-page .ctas .cta .title {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
}

.restaurant-website-page .ctas .cta .icon img {
  height: 18px;
  width: auto;
}

.restaurant-website-page .ctas .cta.food-order {
  background: linear-gradient(148.83deg, #3a91ec .88%, #426ed9 100%);
}

.restaurant-website-page .ctas .cta.book-a-table {
  background: linear-gradient(150.26deg, #ff825f 11.6%, #ff4c51 86.35%);
}

.restaurant-website-page .quick-links {
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(80px, .5fr));
  display: grid;
}

.restaurant-website-page .quick-links .quick-link {
  background-color: var(--card-bg);
  cursor: pointer;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.restaurant-website-page .quick-links .quick-link .icon {
  width: 40px;
  color: #1b2749;
  margin: 5px auto;
}

.restaurant-website-page .quick-links .quick-link .label {
  text-align: center;
  color: #7f8592;
  padding-top: 3px;
  font-size: 11px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
}

.restaurant-website-page .about {
  background-color: var(--card-bg);
  cursor: pointer;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-columns: min-content 1fr;
  grid-template-areas: "a b"
                       "a c";
  align-items: center;
  padding: 15px 18px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.restaurant-website-page .about .icon {
  margin-top: 1px;
  margin-right: 15px;
}

.restaurant-website-page .about .icon i {
  background-color: var(--color-secondary);
  color: #fff;
  border-radius: 50%;
  grid-area: a;
  padding: 5px;
}

.restaurant-website-page .about .title {
  text-transform: uppercase;
  grid-area: b;
  margin: 8px 0;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  .restaurant-website-page .about .title {
    font-size: 20px;
    line-height: 28px;
  }
}

.restaurant-website-page .about .description {
  letter-spacing: .1px;
  color: #666;
  grid-area: c;
  font-size: 14px;
  font-style: normal;
  line-height: 22px;
}

.restaurant-website-page .photos {
  grid-row-gap: 20px;
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  padding: 15px 18px 25px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.restaurant-website-page .photos .header {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.restaurant-website-page .photos .header .icon {
  margin-top: 1px;
  margin-right: 15px;
}

.restaurant-website-page .photos .header .icon i {
  background-color: var(--color-secondary);
  color: #fff;
  border-radius: 50%;
  grid-area: a;
  padding: 5px;
}

.restaurant-website-page .photos .header .title {
  text-transform: uppercase;
  grid-area: b;
  margin: 8px 0;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  .restaurant-website-page .photos .header .title {
    font-size: 20px;
    line-height: 28px;
  }
}

.restaurant-website-page .photos .body {
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  display: grid;
}

.restaurant-website-page .photos .image img {
  border-radius: 10px;
}

.store-website header {
  width: 100%;
  position: fixed;
  top: 0;
}

.store-website main {
  margin-top: var(--safe-area-inset-top);
  padding: 73px 20px 20px;
}

@media (min-width: 1024px) {
  .store-website main {
    max-width: 1280px;
    margin: 0 auto;
  }
}

.store-website header {
  padding: 0;
  padding-top: var(--safe-area-inset-top);
  height: 53px;
  z-index: 1;
  background-color: #fff;
  align-items: center;
  box-shadow: 0 0 3px #687a9b40;
}

.store-website header .wrapper {
  width: 100vw;
  height: 53px;
  justify-content: center;
  align-items: center;
  display: grid;
  overflow: hidden;
}

@media (min-width: 1024px) {
  .store-website header .wrapper {
    max-width: 1320px;
    margin: 0 auto;
  }
}

.store-website-page {
  grid-row-gap: 30px;
  margin-bottom: 40px;
  display: grid;
}

@media (max-width: 767px) {
  .store-website-page .heros {
    grid-gap: 16px;
    grid-template-columns: 83vw;
    grid-auto-columns: 83vw;
    grid-auto-flow: column;
    display: grid;
    overflow-x: auto;
  }

  .store-website-page .heros::-webkit-scrollbar {
    display: none;
  }

  .store-website-page .hero {
    width: 83vw;
  }
}

@media (min-width: 768px) {
  .store-website-page .heros {
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    display: grid;
  }
}

.store-website-page .hero {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 3px #687a9b40;
}

.store-website-page .hero .content {
  padding: 15px 18px;
}

.store-website-page .hero .title {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

.store-website-page .hero .description {
  color: #666;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px;
}

.store-website-page .hero .title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.store-website-page .hero .description {
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.store-website-page .ctas {
  grid-gap: 12px;
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

.store-website-page .ctas a:link, .store-website-page .ctas a:visited, .store-website-page .ctas a:hover, .store-website-page .ctas a:active {
  text-decoration: none;
}

.store-website-page .ctas .cta {
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  padding: 18px 18px 30px;
  box-shadow: 0 0 3px #687a9b40;
}

.store-website-page .ctas .cta .title {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
}

.store-website-page .ctas .cta .icon {
  color: #fff;
  font-size: 30px;
}

.store-website-page .ctas .cta.food-order {
  background: linear-gradient(148.83deg, #3a91ec .88%, #426ed9 100%);
}

.store-website-page .ctas .cta.book-a-table {
  background: linear-gradient(150.26deg, #ff825f 11.6%, #ff4c51 86.35%);
}

.store-website-page .quick-links {
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(80px, .5fr));
  display: grid;
}

.store-website-page .quick-links .quick-link {
  background-color: var(--card-bg);
  cursor: pointer;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 0 3px #687a9b40;
}

.store-website-page .quick-links .quick-link .icon {
  width: 40px;
  color: #1b2749;
  margin: 5px auto;
}

.store-website-page .quick-links .quick-link .label {
  text-align: center;
  color: #7f8592;
  padding-top: 3px;
  font-size: 11px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
}

.store-website-page .about {
  background-color: var(--card-bg);
  cursor: pointer;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-columns: min-content 1fr;
  grid-template-areas: "a b"
                       "a c";
  align-items: center;
  padding: 15px 18px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.store-website-page .about .icon {
  margin-top: 1px;
  margin-right: 15px;
}

.store-website-page .about .icon i {
  background-color: var(--color-secondary);
  color: #fff;
  border-radius: 50%;
  grid-area: a;
  padding: 5px;
}

.store-website-page .about .title {
  text-transform: uppercase;
  grid-area: b;
  margin: 8px 0;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  .store-website-page .about .title {
    font-size: 20px;
    line-height: 28px;
  }
}

.store-website-page .about .description {
  letter-spacing: .1px;
  color: #666;
  grid-area: c;
  font-size: 14px;
  font-style: normal;
  line-height: 22px;
}

.store-website-page .photos {
  grid-row-gap: 20px;
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  padding: 15px 18px 25px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.store-website-page .photos .header {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.store-website-page .photos .header .icon {
  margin-top: 1px;
  margin-right: 15px;
}

.store-website-page .photos .header .icon i {
  background-color: var(--color-secondary);
  color: #fff;
  border-radius: 50%;
  grid-area: a;
  padding: 5px;
}

.store-website-page .photos .header .title {
  text-transform: uppercase;
  grid-area: b;
  margin: 8px 0;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  .store-website-page .photos .header .title {
    font-size: 20px;
    line-height: 28px;
  }
}

.store-website-page .photos .body {
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  display: grid;
}

.store-website-page .photos .image img {
  border-radius: 10px;
}

.otp-modal {
  width: 15rem;
}

.otp-modal .illustration {
  width: auto;
  margin: auto;
  display: block;
}

.otp-modal .instructions {
  text-align: center;
  margin-top: 1rem;
}

.otp-modal .instructions .title {
  color: #000521;
  font-size: 16px;
  font-weight: 500;
}

.otp-modal .instructions .sub-title {
  color: #92929d;
  font-size: 12px;
}

.otp-modal .otp-input {
  margin-top: 1rem;
}

.otp-modal .otp-input div {
  margin: 0 auto;
}

.otp-modal .help-text {
  text-align: center;
  color: #92929d;
  margin-top: 1rem;
  font-size: 12px;
}

.otp-modal .resend {
  text-align: center;
  box-shadow: unset;
  background-color: unset;
  color: #0062ff;
  margin: 1rem auto;
  padding: .4rem;
  font-size: 12px;
  font-weight: 500;
}

.otp-modal .actions {
  border-color: #e1e1e9;
  border-top-style: solid;
  border-top-width: 1px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  padding-top: 1rem;
  display: flex;
}

.otp-modal .actions button {
  width: 100%;
}

.mobile-number-widget #recaptcha-wrapper.hidden {
  display: none;
}

.mobile-number-widget .primary {
  width: 100%;
}

.website-item-page .item-actions {
  grid-gap: 10px;
  grid-template-columns: 120px 1fr;
  margin-top: 30px;
  margin-bottom: 10px;
  display: grid;
}

.website-item-page .item-actions .quantity {
  background-color: #fff;
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-columns: min-content min-content min-content;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.website-item-page .item-actions .quantity .icon {
  font-size: 16px;
}

.website-item-page .item-actions .add-to-cart-button {
  background-color: var(--card-bg);
  background-color: var(--button-success-bg);
  color: var(--button-success-fg);
  white-space: nowrap;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-columns: min-content min-content;
  justify-content: space-between;
  padding: 20px;
  font-weight: 400;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.item-actions {
  grid-gap: 10px;
  grid-template-columns: 120px 1fr;
  margin-top: 30px;
  margin-bottom: 10px;
  display: grid;
}

.item-actions .quantity {
  background-color: #fff;
  background-color: var(--card-bg);
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-columns: min-content min-content min-content;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.item-actions .quantity .icon {
  font-size: 16px;
}

.item-actions .add-to-cart-button {
  background-color: var(--card-bg);
  background-color: var(--button-success-bg);
  color: var(--button-success-fg);
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  grid-template-columns: min-content min-content;
  justify-content: space-between;
  padding: 20px;
  font-weight: 400;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.item-actions .add-to-cart-button .amount {
  margin-left: 5px;
}

.website-order-success-page #order-history-button {
  background-color: var(--button-primary-bg);
  color: var(--button-primary-fg);
  margin-left: 6px;
}

.basic-item-widget > .title {
  letter-spacing: .1px;
  color: var(--fg);
  margin-bottom: 20px;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.basic-item-widget .cancel-button {
  width: 100%;
}

.basic-item-widget .spinner-container {
  width: max-content;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

@media (max-width: 767px) {
  .trading-hours-page {
    background-color: #fafafa;
  }

  .trading-hours-page .store-logo-and-address .restaurant-logo {
    display: none;
  }

  .trading-hours-page .store-logo-and-address .info {
    padding: 20px 20px 0;
  }

  .trading-hours-page .store-logo-and-address .info.need-help {
    word-break: break-all;
    margin-top: 13px;
  }

  .trading-hours-page .store-logo-and-address .info .title {
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #27283e;
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
  }

  .trading-hours-page .store-logo-and-address .info .text {
    color: #757575;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .trading-hours-page .store-logo-and-address .info .text .link {
    color: #4cbba1;
    cursor: pointer;
  }

  .trading-hours-page .date-and-trading-hours-today {
    margin: 0 20px;
    padding-top: 36px;
    display: grid;
  }

  .trading-hours-page .date-and-trading-hours-today .date-today {
    grid-gap: 5px;
    margin: auto 0 30px;
    display: grid;
  }

  .trading-hours-page .date-and-trading-hours-today .date-today .trading-hours-label {
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: left;
    color: #27283e;
    margin-bottom: 14px;
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
  }

  .trading-hours-page .date-and-trading-hours-today .date-today .date {
    text-align: left;
    color: #27283e;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

@media (max-width: 767px) and (min-width: 768px) {
  .trading-hours-page .date-and-trading-hours-today .date-today .date {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (max-width: 767px) {
  .trading-hours-page .date-and-trading-hours-today .date-today .closed-banner {
    color: #b0204b;
    background: #ffe7df;
    flex-direction: row;
    margin-top: 10px;
    padding: 16px 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: inline-flex;
  }

  .trading-hours-page .date-and-trading-hours-today .date-today .closed-banner .closed-label {
    margin-right: .25em;
    font-weight: bold;
  }

  .trading-hours-page .date-and-trading-hours-today .collection-type-trading-hours-today {
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    grid-template-columns: max-content 1fr;
    grid-template-areas: "logo collection-type"
                         "logo sessions";
    column-gap: 45px;
    padding: 18px 10px 18px 27px;
    display: grid;
    box-shadow: 0 2px 4px #0000001a;
  }

  .trading-hours-page .date-and-trading-hours-today .collection-type-trading-hours-today .collection-type {
    text-align: center;
    color: #40415b;
    grid-area: collection-type;
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    display: flex;
  }

  .trading-hours-page .date-and-trading-hours-today .collection-type-trading-hours-today .logo {
    height: 62px;
    width: 62px;
    background-repeat: no-repeat;
    background-size: cover;
    grid-area: logo;
    margin: 0 auto;
    position: relative;
  }

  .trading-hours-page .date-and-trading-hours-today .collection-type-trading-hours-today .logo.pickup {
    background-image: url("pickup.21dd0bb4.svg");
  }

  .trading-hours-page .date-and-trading-hours-today .collection-type-trading-hours-today .logo.delivery {
    background-image: url("delivery.17d62165.svg");
  }

  .trading-hours-page .date-and-trading-hours-today .collection-type-trading-hours-today .logo.dineIn {
    background-image: url("dine-in.ea337d61.svg");
  }

  .trading-hours-page .date-and-trading-hours-today .collection-type-trading-hours-today .sessions {
    flex-direction: column;
    grid-area: sessions;
    align-items: flex-start;
    display: flex;
  }

  .trading-hours-page .date-and-trading-hours-today .collection-type-trading-hours-today .sessions .unavailable {
    text-align: center;
    color: #b0204b;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .trading-hours-page .date-and-trading-hours-today .collection-type-trading-hours-today .sessions .available {
    text-align: center;
    color: #27283e;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}

@media (max-width: 767px) and (min-width: 768px) {
  .trading-hours-page .date-and-trading-hours-today .date-today.closed {
    border-bottom: unset;
  }

  .trading-hours-page .date-and-trading-hours-today.closed {
    grid-auto-flow: column;
  }
}

@media (max-width: 767px) {
  .trading-hours-page .trading-hours-today {
    grid-gap: 15px;
    margin-bottom: 15px;
    display: grid;
  }

  .trading-hours-page .trading-hours-today.closed .unavailable {
    margin-bottom: 0 !important;
  }

  .trading-hours-page .label {
    width: -moz-fit-content;
    width: -moz-fit-content;
    width: -moz-fit-content;
    width: -moz-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    grid-gap: 7px;
    border-radius: 8px;
    grid-auto-flow: column;
    align-items: center;
    margin-bottom: 10px;
    padding: 3px 10px 3px 3px;
    font-size: 13px;
    font-weight: 400;
    display: grid;
  }

  .trading-hours-page .collection-type-selector {
    margin: 5px 0 20px;
    padding: 0 17px;
    box-shadow: inset 0 -1px #e3ebec;
  }

  .trading-hours-page .collection-type-selector .collection-types-tab-selector {
    display: flex;
  }

  .trading-hours-page .collection-type-selector .collection-types-tab-selector .collection-type {
    color: #757575;
    padding: 10px 11px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .trading-hours-page .collection-type-selector .collection-types-tab-selector .collection-type.selected {
    color: #27283e;
    border-bottom: 1px solid #27283e;
    font-weight: 700;
  }

  .trading-hours-page .trading-hours {
    background-color: #fff;
    border-radius: 10px;
    margin: 33px 20px;
    box-shadow: 0 0 8px #31303e1a;
  }

  .trading-hours-page .time-item {
    grid-gap: 10px;
    grid-row-gap: 5px;
    border-bottom: 1px solid #e0e0e0;
    grid-template-rows: 1fr;
    grid-template-columns: max-content 1fr;
    padding: 8px 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: grid;
  }

  .trading-hours-page .time-item:last-child {
    border-bottom: none;
  }

  .trading-hours-page .time-item .dow {
    color: #27283e;
    padding: 10px 0;
    font-weight: 700;
  }

  .trading-hours-page .time-item .session {
    text-align: right;
    color: #27283e;
    justify-items: end;
    padding: 10px 0;
    font-weight: 400;
  }

  .trading-hours-page .time-item .session.closed {
    color: #b0204b;
  }

  .bottom-nav-bar {
    z-index: 1;
    background-color: #27283e;
    box-shadow: 0 -1px 3px #687a9b40;
  }

  @supports (padding: max(0px)) {
    .bottom-nav-bar {
      padding-bottom: max(10px, var(--safe-area-inset-bottom));
    }
  }

  .bottom-nav-bar .item {
    text-align: center;
    color: #fff;
    flex-grow: 1;
    flex-basis: 0;
    grid-template-rows: 1fr max-content;
    padding-top: 15px;
    display: grid;
    position: relative;
  }

  .bottom-nav-bar .item .icon-count-container .icon {
    opacity: .45;
  }

  .bottom-nav-bar .item .icon-count-container .icon.selected {
    opacity: 1;
  }

  .bottom-nav-bar .item .icon-count-container .count {
    color: #fff;
    background: red;
    border-radius: 50%;
    padding: 8px;
    position: absolute;
    top: 5px;
    right: calc(50% - 23px);
  }

  .bottom-nav-bar .item .icon-count-container .count > span {
    width: 100%;
    text-align: center;
    font-size: 9px;
    position: absolute;
    top: -2px;
    left: 0;
  }

  .bottom-nav-bar .item .label {
    color: #fff;
    text-align: center;
    opacity: .45;
    font-family: SF Pro Display;
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    position: relative;
  }
}

@media (max-width: 767px) and (min-width: 768px) {
  .bottom-nav-bar .item .label {
    font-family: Chivo;
    font-size: 14px;
    line-height: 20px;
  }
}

@media (max-width: 767px) {
  .bottom-nav-bar .item .label.selected {
    opacity: 1;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@media (max-width: 767px) {
  .top-nav-bar-widget {
    z-index: 100;
    width: 100%;
    background: linear-gradient(178.96deg, #ececed .92%, #fff 112.41%);
    position: sticky;
    top: 0;
    box-shadow: inset 0 -1px #e8ecf1;
  }

  .top-nav-bar-widget .wrapper {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    display: flex;
  }

  .top-nav-bar-widget .wrapper .actions {
    align-items: center;
    gap: 16px;
    display: flex;
  }

  .top-nav-bar-widget .wrapper .actions .search-icon, .top-nav-bar-widget .wrapper .actions .icon, .top-nav-bar-widget .wrapper .actions svg {
    display: block;
  }

  .top-nav-bar-widget .wrapper .back-button, .top-nav-bar-widget .wrapper .store-header-widget .image, .top-nav-bar-widget .wrapper .menu, .top-nav-bar-widget .wrapper .actions .cart {
    display: none;
  }

  .top-nav-bar-widget .wrapper .actions .sidebar-menu-button svg path {
    stroke: #27283e;
  }

  .top-nav-bar-widget.store-closed, .top-nav-bar-widget.platform-maintenance {
    top: 60px;
  }

  .online-ordering .menu-page .header {
    margin-top: 0;
    padding: 0;
  }

  .online-ordering .menu-page .header.on-top {
    opacity: 0;
    transition: visibility 0s linear .2s, opacity .2s linear;
    display: none;
  }

  .online-ordering .menu-page .header .wrapper {
    position: relative;
  }

  .online-ordering .menu-page .header .wrapper .nav {
    width: 100%;
    z-index: 1;
    box-sizing: border-box;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    display: flex;
    position: absolute;
  }

  .online-ordering .menu-page .header .wrapper .nav .actions {
    align-items: center;
    gap: 16px;
    display: flex;
  }

  .online-ordering .menu-page .header .wrapper .nav .actions .search-icon, .online-ordering .menu-page .header .wrapper .nav .actions .icon, .online-ordering .menu-page .header .wrapper .nav .actions svg {
    display: block;
  }

  .online-ordering .menu-page .header .wrapper .nav .page-title {
    color: var(--bg);
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .online-ordering .menu-page .header .wrapper .banner-image {
    width: 100vw;
    position: relative;
  }

  .online-ordering .menu-page .header .wrapper .banner-image .desktop {
    display: none;
  }

  .online-ordering .menu-page .header .wrapper .banner-image .restaurant-info {
    z-index: 1;
    flex-direction: column;
    display: flex;
  }

  .online-ordering .menu-page .header .wrapper .banner-image .restaurant-info.mobile-banner {
    display: none;
  }

  .online-ordering .menu-page .header .wrapper .banner-image .restaurant-info .restaurant-logo {
    width: 120px;
    background-color: #fff;
    border-radius: 23px;
    position: absolute;
    bottom: 127px;
    left: 24px;
  }
}

@media (max-width: 767px) and (max-width: 330px) {
  .online-ordering .menu-page .header .wrapper .banner-image .restaurant-info .restaurant-logo {
    bottom: 90px;
  }
}

@media (max-width: 767px) {
  .online-ordering .menu-page .header .wrapper .banner-image .restaurant-info .restaurant-logo > .image {
    border-radius: 23px;
    overflow: hidden;
  }

  .online-ordering .menu-page .header .wrapper .banner-image .restaurant-info .store-title {
    box-sizing: border-box;
    color: var(--bg);
    width: 100%;
    background-blend-mode: multiply;
    background: linear-gradient(#2a2a3100 11.39%, #2a2a311a 24.94%, #2a2a31cc 100%);
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px;
    display: flex;
    position: absolute;
    bottom: 0;
  }

  .online-ordering .menu-page .header .wrapper .banner-image .restaurant-info .store-title .store-name {
    margin: 4px 0;
    font-size: 26px;
    font-weight: 700;
    line-height: 32px;
  }
}

@media (max-width: 767px) and (min-width: 768px) {
  .online-ordering .menu-page .header .wrapper .banner-image .restaurant-info .store-title .store-name {
    font-size: 32px;
    line-height: 48px;
  }
}

@media (max-width: 767px) {
  .online-ordering .menu-page .header .wrapper .banner-image .restaurant-info .store-title .store-location {
    width: 100%;
    text-align: left;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    display: flex;
  }

  .online-ordering .menu-page .header .wrapper .banner-image .restaurant-info .store-title .store-location .address {
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0 9px;
    overflow: hidden;
  }

  .online-ordering .menu-page .header .wrapper .banner-image .restaurant-info .store-title .store-location .icon {
    width: 17px;
  }

  .online-ordering .menu-page .body > .menu {
    background-color: #fafafa;
    grid-template-areas: "collection-type-selector"
                         "store-closed-info"
                         "categories"
                         "items";
    display: grid;
  }

  .online-ordering .menu-page .body > .menu .collection-type-selector-widget {
    grid-area: collection-type-selector;
  }

  .online-ordering .menu-page .body > .menu .store-closed-info-widget {
    grid-area: store-closed-info;
  }

  .online-ordering .menu-page .body > .menu .category-list-widget {
    grid-area: categories;
  }

  .online-ordering .menu-page .body > .menu .item-list-widget {
    grid-area: items;
  }

  .online-ordering .menu-page .body > .menu #top-of-site-pixel-anchor {
    width: 1px;
    height: 1px;
    position: absolute;
    top: 100px;
    left: 0;
  }

  .top-nav-bar-widget {
    z-index: 100;
    width: 100%;
    background: linear-gradient(178.96deg, #ececed .92%, #fff 112.41%);
    position: sticky;
    top: 0;
    box-shadow: inset 0 -1px #e8ecf1;
  }

  .top-nav-bar-widget .wrapper {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    display: flex;
  }

  .top-nav-bar-widget .wrapper .actions {
    align-items: center;
    gap: 16px;
    display: flex;
  }

  .top-nav-bar-widget .wrapper .actions .search-icon, .top-nav-bar-widget .wrapper .actions .icon, .top-nav-bar-widget .wrapper .actions svg {
    display: block;
  }

  .top-nav-bar-widget .wrapper .back-button, .top-nav-bar-widget .wrapper .store-header-widget .image, .top-nav-bar-widget .wrapper .menu, .top-nav-bar-widget .wrapper .actions .cart {
    display: none;
  }

  .top-nav-bar-widget .wrapper .actions .sidebar-menu-button svg path {
    stroke: #27283e;
  }

  .top-nav-bar-widget.store-closed, .top-nav-bar-widget.platform-maintenance {
    top: 60px;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@media (max-width: 767px) {
  .cart-page {
    padding-bottom: max(65px, var(--safe-area-inset-bottom)) !important;
  }

  .cart-page .cart-widget {
    background-color: #fff;
  }

  .cart-page .cart-widget .collection-info {
    gap: 4px;
    display: grid;
  }

  .cart-page .cart-widget .collection-info .session-info {
    height: 30px;
    min-width: 0;
    color: #086e37;
    background: #def2d6;
    justify-content: start;
    align-items: center;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
  }

  .cart-page .cart-widget .collection-info .session-info .session {
    width: 80%;
    display: flex;
  }

  .cart-page .cart-widget .collection-info .session-info .session .collection-info {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .cart-page .cart-widget .collection-info .session-info .datetime, .cart-page .cart-widget .collection-info .session-info .to, .cart-page .cart-widget .collection-info .session-info .address, .cart-page .cart-widget .collection-info .session-info .table-name {
    margin-left: 4px;
    font-weight: 700;
    display: inline;
  }

  .cart-page .cart-widget .collection-info .session-info .to {
    font-weight: 400;
  }

  .cart-page .cart-widget .collection-info .session-info .action-label {
    justify-self: end;
    font-weight: 700;
  }

  .cart-page .cart-widget .collection-info .session-info .collection-type {
    font-weight: 700;
  }

  .cart-page .cart-widget .collection-info .session-info .session-datetime {
    margin-left: 4px;
    font-weight: 700;
  }

  .cart-page .cart-widget .collection-info .minimum-order-value-info {
    color: #d67300;
    text-align: left;
    background: #ffebcf;
    padding: 15px 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .cart-page .cart-widget .collection-info .minimum-order-value-info > .icon, .cart-page .cart-widget .empty-cart-header, .cart-page .cart-widget .cart-header-text {
    display: none;
  }

  .cart-page .cart-widget .empty-cart {
    text-align: center;
    margin-top: 140px;
  }

  .cart-page .cart-widget .empty-cart > .cart-image {
    width: 80px;
    height: 80px;
    margin-bottom: 29px;
  }

  .cart-page .cart-widget .empty-cart > .text {
    color: #757575;
    margin: 0 70px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .cart-page .cart-widget .item-count {
    color: #757575;
    margin: 23px 20px 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .cart-page .cart-widget .items {
    gap: 18px;
    margin: 0 20px 20px;
    display: grid;
  }

  .cart-page .cart-widget .items .item {
    box-sizing: border-box;
    border: 1px solid #e2e2e2;
    border-radius: 8px;
    grid-template-columns: 1fr max-content;
    grid-template-areas: "name . remove"
                         "description . remove"
                         "quantity deal price";
    padding: 16px;
    display: grid;
    box-shadow: 0 2px 4px #0000000d;
  }

  .cart-page .cart-widget .items .deal {
    cursor: pointer;
    text-align: left;
    color: #d67300;
    text-transform: uppercase;
    grid-area: deal;
    align-items: center;
    margin-top: 16px;
    margin-right: 6px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    display: flex;
  }

  .cart-page .cart-widget .items .deal img {
    margin-top: -3px;
    margin-right: 5px;
  }

  .cart-page .cart-widget .items .name {
    color: #27283e;
    letter-spacing: -.2px;
    grid-area: name;
    margin-bottom: 11px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .cart-page .cart-widget .items .remove-icon {
    width: 12px;
    grid-area: remove;
    justify-self: right;
    margin-bottom: 11px;
    padding: 2px 4px;
  }

  .cart-page .cart-widget .items .item-description-view-widget {
    grid-area: description;
  }

  .cart-page .cart-widget .items .quantity {
    width: -moz-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    grid-area: quantity;
    grid-auto-flow: column;
    gap: 5px;
    margin-top: 16px;
    display: grid;
  }

  .cart-page .cart-widget .items .quantity .number {
    margin: 0 17px;
    font-size: 14px;
    font-weight: 400;
    line-height: 27px;
  }

  .cart-page .cart-widget .items .quantity .icon {
    cursor: pointer;
    width: 24px;
  }

  .cart-page .cart-widget .items .price {
    text-align: right;
    color: #1faa89;
    grid-area: price;
    align-items: center;
    margin-top: 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
  }

  .cart-page .cart-widget .totals {
    color: #27283e;
    letter-spacing: .3px;
    background: #eff8f9;
    padding: 22px 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .cart-page .cart-widget .totals .sub-total, .cart-page .cart-widget .totals .delivery-charges, .cart-page .cart-widget .totals .public-holiday-surcharge, .cart-page .cart-widget .totals .grand-total, .cart-page .cart-widget .totals .gst-amount {
    border-bottom: 1px solid #e3ebec;
    grid-auto-flow: column;
    justify-content: space-between;
    padding: 3px 0;
    font-weight: 400;
    display: grid;
  }

  .cart-page .cart-widget .totals .grand-total {
    border-bottom: none;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .cart-page .cart-widget .totals .gst-amount {
    border-bottom: none;
  }

  .cart-page .cart-widget .checkout {
    background: #eff8f9;
    grid-auto-flow: column;
    align-items: center;
    padding: 5px 20px 50px;
    display: grid;
  }

  .cart-page .cart-widget .checkout .button {
    color: #fff;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    cursor: pointer;
    background: #0162ff;
    border-radius: 10px;
    padding: 8px 0;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .cart-page .cart-widget .checkout .button.disabled {
    color: #bcbcc5;
    cursor: not-allowed;
    background: #f1f1f5;
  }

  .cart-page .cart-widget .checkout .quantity {
    text-align: end;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .loyalty-card-page {
    margin-bottom: 100px;
  }

  .loyalty-card-page > .spinner {
    text-align: center;
    margin: 50px;
  }

  .loyalty-card-page > .alert {
    margin: 20px;
  }

  .loyalty-card-page .loyalty {
    max-width: 400px;
    margin: 40px auto;
    padding: 0 20px;
  }

  .loyalty-card-page .loyalty .claim-info {
    margin: 50px 2px 0;
  }

  .loyalty-card-page .loyalty .claim-info .title {
    color: #27283e;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .loyalty-card-page .loyalty .claim-info .subtitle {
    white-space: pre-wrap;
    color: #757575;
    margin-top: 6px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@media (max-width: 767px) {
  .session-view-widget {
    height: 30px;
    min-width: 0;
    color: #086e37;
    background: #def2d6;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
  }

  .session-view-widget .session {
    width: 80%;
    display: flex;
  }

  .session-view-widget .session .collection-info {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .session-view-widget .datetime, .session-view-widget .to, .session-view-widget .address, .session-view-widget .table-name {
    margin-left: 4px;
    font-weight: 700;
    display: inline;
  }

  .session-view-widget .to {
    font-weight: 400;
  }

  .session-view-widget .action-label {
    justify-self: end;
    font-weight: 700;
  }

  .no-coupons {
    margin-top: 135px;
  }

  .no-coupons > .icon {
    margin: 0 auto;
    display: block;
  }

  .no-coupons > .text {
    color: #757575;
    width: 70%;
    text-align: center;
    margin: 32px auto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .coupons {
    gap: 23px;
    margin: 20px 23px 10px;
    display: grid;
  }

  .coupons .coupon {
    border: .01px solid #ffffff63;
    border-left: unset;
    min-width: 321px;
    -webkit-clip-path: inset(-10px -10px -10px 0);
    clip-path: inset(-10px -10px -10px 0);
    position: relative;
    box-shadow: 0 0 11px #bdbdbd78;
  }

  .coupons .coupon .zigzag {
    width: 12px;
    height: 100%;
    background-color: #0000;
    background-image: linear-gradient(45deg, #fff0 45%, #c4c4c411 45% 55%, #fff 55%), linear-gradient(315deg, #fff 45%, #c4c4c411 45% 55%, #f4f4f45e 55%);
    background-position: 0 0, 0 0;
    background-repeat: repeat-y;
    background-size: 23px 20px;
    background-attachment: scroll, scroll;
    background-origin: padding-box, padding-box;
    background-clip: border-box, border-box;
    position: absolute;
    top: 0;
    left: 0;
  }

  .coupons .coupon .body {
    min-height: 112px;
    color: #27283e;
    grid-template: "code icon" min-content
                   "description icon" 1fr
                   "discount availability" min-content
                   / 1fr min-content;
    padding: 16px 13px 11px 21px;
    display: grid;
  }

  .coupons .coupon .body.used {
    filter: grayscale();
    color: #27283e69;
  }

  .coupons .coupon .body .code {
    text-overflow: ellipsis;
    letter-spacing: 2px;
    text-transform: uppercase;
    white-space: nowrap;
    width: 100%;
    grid-area: code;
    font-family: IBM Plex;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    overflow: hidden;
  }

  .coupons .coupon .body .icon {
    width: 45px;
    grid-area: icon;
    justify-self: end;
    margin-bottom: 1px;
  }

  .coupons .coupon .body .icon.available {
    right: 0;
  }

  .coupons .coupon .body .description {
    color: #757575;
    grid-area: description;
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    display: block;
  }

  .coupons .coupon .body .discount {
    color: #27283e;
    grid-area: discount;
    margin-top: auto;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .coupons .coupon .body .availability {
    text-transform: capitalize;
    color: #4cbba1;
    grid-area: availability;
    margin-top: auto;
    margin-left: 5px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .collection-type-selector-widget {
    background-color: #ffebcf;
    padding: 15px 20px;
  }

  .collection-type-selector-widget .collection-types .collection-type-single {
    text-align: left;
    color: #d67300;
    flex-direction: row;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
  }

  .collection-type-selector-widget .collection-types .collection-type-single span {
    text-align: left;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .collection-type-selector-widget .collection-types .collection-type-single span:first-of-type {
    margin-right: .25em;
  }

  .collection-type-selector-widget .collection-types .collection-type-single span:last-of-type {
    margin-left: auto;
  }

  .collection-type-selector-widget .collection-types .collection-type-selector {
    background-color: #d673001a;
    border-radius: 10px;
    grid-template-columns: 1fr;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    gap: 5px;
    padding: 5px;
    display: grid;
    overflow: hidden;
  }

  .collection-type-selector-widget .collection-types .collection-type-selector .start-order, .collection-type-selector-widget .collection-types .collection-type-selector .collection-type {
    text-align: center;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    background-color: #0000;
    border-radius: 10px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 0;
    display: flex;
    position: relative;
  }

  .collection-type-selector-widget .collection-types .collection-type-selector .start-order .collection-label, .collection-type-selector-widget .collection-types .collection-type-selector .collection-type .collection-label {
    padding: 1px;
  }

  .collection-type-selector-widget .collection-types .collection-type-selector .start-order span, .collection-type-selector-widget .collection-types .collection-type-selector .collection-type span {
    text-align: left;
    color: #d67300;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .collection-type-selector-widget .collection-types .collection-type-selector .start-order.selected, .collection-type-selector-widget .collection-types .collection-type-selector .collection-type.selected {
    background-color: #fff;
  }

  .collection-type-selector-widget .collection-types .collection-type-selector .start-order.selected .check, .collection-type-selector-widget .collection-types .collection-type-selector .collection-type.selected .check {
    width: 14px;
    margin-right: 10px;
    left: 33px;
  }

  .collection-type-selector-widget .collection-types .collection-type-selector .start-order {
    background-color: var(--button-success-bg);
    color: var(--button-success-fg);
  }

  .collection-type-selector-widget .start-ordering-link {
    display: none;
  }

  .collection-type-selector-widget .start-ordering-link.more-info {
    display: unset;
    color: #d67300;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .order-success-page {
    background-color: #fff;
  }

  .order-success-page.page > .header {
    box-shadow: none;
    padding: 0;
  }

  .order-success-page .checkout-banner {
    text-align: center;
    margin: 40px 20px;
  }

  .order-success-page .checkout-banner .icon {
    margin-bottom: 14px;
  }

  .order-success-page .checkout-banner .text {
    color: #27283e;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

@media (max-width: 767px) and (min-width: 768px) {
  .order-success-page .checkout-banner .text {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (max-width: 767px) {
  .order-success-page .order-placed-time {
    text-align: center;
    color: #9191a1;
    margin: 20px 20px 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .order-success-page .buttons {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 20px 100px;
    display: flex;
  }

  .order-success-page .buttons > .customer-review-link-button {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    margin-top: 20px;
    padding: 20px;
    box-shadow: 0 2px 4px #0000000d;
  }

  .order-success-page .buttons > .customer-review-link-button > .label {
    align-items: center;
    display: flex;
  }

  .order-success-page .buttons > .customer-review-link-button > .label img {
    width: 44px;
    height: 44px;
  }

  .order-success-page .buttons > .customer-review-link-button > .label span {
    color: #1c1d3e;
    padding-left: 16px;
  }

  .order-success-page .buttons .order-history-button {
    width: 100%;
    box-shadow: none;
    color: #4cbba1;
    background-color: #0000;
    border: 2px solid #4cbba1;
    margin-top: 20px;
    padding: 14px 50px;
  }

  .order-success-page .buttons .back-to-menu-button {
    box-shadow: none;
    color: #9e9e9e;
    background-color: #0000;
    border: none;
    margin-top: 16px;
    padding: 14px 50px;
  }

  .order-success-page .order-success-page-wrapper .order-view-widget {
    margin-left: 20px;
    margin-right: 20px;
  }

  .order-success-page .order-success-page-wrapper .order-tracking-label {
    color: #27283e;
    margin: 5px 20px;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }

  .loyalty-card-modal {
    background-color: #fff;
  }

  .loyalty-card-modal .loyalty-banner {
    text-align: center;
    margin: 32px 20px 40px;
  }

  .loyalty-card-modal .loyalty-banner .icon {
    margin-bottom: 16px;
  }

  .loyalty-card-modal .loyalty-banner .text {
    text-align: center;
    color: #27283e;
    margin-bottom: 8px;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

@media (max-width: 767px) and (min-width: 768px) {
  .loyalty-card-modal .loyalty-banner .text {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (max-width: 767px) {
  .loyalty-card-modal .loyalty-banner .subtext {
    text-align: center;
    color: #757575;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .loyalty-card-modal .card {
    margin: 0 20px;
  }

  .loyalty-card-modal .caption {
    text-align: center;
    color: #757575;
    margin: 30px 20px 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .loyalty-card-modal .button {
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    background: #4cbba1;
    border-radius: 8px;
    align-content: flex-end;
    margin: 0 20px 16px;
    padding: 14px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .order-status-widget {
    box-sizing: border-box;
    z-index: 2;
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin: 0 20px 24px;
    padding: 20px 7px;
    position: relative;
    box-shadow: 0 2px 4px #0000001a;
  }

  .order-status-widget .website-order-delivery-timeline-widget, .order-status-widget .website-order-pick-up-timeline-widget, .order-status-widget .website-order-dine-in-timeline-widget {
    max-width: 340px;
    margin: 0 auto;
  }

  .order-status-widget .website-order-delivery-timeline-widget .in-house-delivery-timeline, .order-status-widget .website-order-delivery-timeline-widget .pick-up-timeline, .order-status-widget .website-order-delivery-timeline-widget .dine-in-timeline, .order-status-widget .website-order-pick-up-timeline-widget .in-house-delivery-timeline, .order-status-widget .website-order-pick-up-timeline-widget .pick-up-timeline, .order-status-widget .website-order-pick-up-timeline-widget .dine-in-timeline, .order-status-widget .website-order-dine-in-timeline-widget .in-house-delivery-timeline, .order-status-widget .website-order-dine-in-timeline-widget .pick-up-timeline, .order-status-widget .website-order-dine-in-timeline-widget .dine-in-timeline {
    justify-content: center;
    align-items: center;
    padding: 0 70px;
    display: flex;
  }

  .order-status-widget .website-order-delivery-timeline-widget .in-house-delivery-timeline .timeline-event-container, .order-status-widget .website-order-delivery-timeline-widget .pick-up-timeline .timeline-event-container, .order-status-widget .website-order-delivery-timeline-widget .dine-in-timeline .timeline-event-container, .order-status-widget .website-order-pick-up-timeline-widget .in-house-delivery-timeline .timeline-event-container, .order-status-widget .website-order-pick-up-timeline-widget .pick-up-timeline .timeline-event-container, .order-status-widget .website-order-pick-up-timeline-widget .dine-in-timeline .timeline-event-container, .order-status-widget .website-order-dine-in-timeline-widget .in-house-delivery-timeline .timeline-event-container, .order-status-widget .website-order-dine-in-timeline-widget .pick-up-timeline .timeline-event-container, .order-status-widget .website-order-dine-in-timeline-widget .dine-in-timeline .timeline-event-container {
    width: 100%;
  }

  .order-status-widget .website-order-delivery-timeline-widget .in-house-delivery-timeline .timeline-event-container:first-child, .order-status-widget .website-order-delivery-timeline-widget .pick-up-timeline .timeline-event-container:first-child, .order-status-widget .website-order-delivery-timeline-widget .dine-in-timeline .timeline-event-container:first-child, .order-status-widget .website-order-pick-up-timeline-widget .in-house-delivery-timeline .timeline-event-container:first-child, .order-status-widget .website-order-pick-up-timeline-widget .pick-up-timeline .timeline-event-container:first-child, .order-status-widget .website-order-pick-up-timeline-widget .dine-in-timeline .timeline-event-container:first-child, .order-status-widget .website-order-dine-in-timeline-widget .in-house-delivery-timeline .timeline-event-container:first-child, .order-status-widget .website-order-dine-in-timeline-widget .pick-up-timeline .timeline-event-container:first-child, .order-status-widget .website-order-dine-in-timeline-widget .dine-in-timeline .timeline-event-container:first-child {
    width: 60px;
  }

  .order-status-widget .website-order-delivery-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper, .order-status-widget .website-order-delivery-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper, .order-status-widget .website-order-delivery-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper, .order-status-widget .website-order-pick-up-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper, .order-status-widget .website-order-pick-up-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper, .order-status-widget .website-order-pick-up-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper, .order-status-widget .website-order-dine-in-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper, .order-status-widget .website-order-dine-in-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper, .order-status-widget .website-order-dine-in-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .order-status-widget .website-order-delivery-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-line, .order-status-widget .website-order-delivery-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-line, .order-status-widget .website-order-delivery-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-line, .order-status-widget .website-order-pick-up-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-line, .order-status-widget .website-order-pick-up-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-line, .order-status-widget .website-order-pick-up-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-line, .order-status-widget .website-order-dine-in-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-line, .order-status-widget .website-order-dine-in-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-line, .order-status-widget .website-order-dine-in-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-line {
    width: 100%;
    height: 2px;
  }

  .order-status-widget .website-order-delivery-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color, .order-status-widget .website-order-delivery-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color, .order-status-widget .website-order-delivery-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color, .order-status-widget .website-order-pick-up-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color, .order-status-widget .website-order-pick-up-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color, .order-status-widget .website-order-pick-up-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color, .order-status-widget .website-order-dine-in-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color, .order-status-widget .website-order-dine-in-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color, .order-status-widget .website-order-dine-in-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color {
    border-radius: 50%;
    margin: 0 5px;
    padding: 12px 13px;
  }

  .order-status-widget .website-order-delivery-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color img, .order-status-widget .website-order-delivery-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color img, .order-status-widget .website-order-delivery-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color img, .order-status-widget .website-order-pick-up-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color img, .order-status-widget .website-order-pick-up-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color img, .order-status-widget .website-order-pick-up-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color img, .order-status-widget .website-order-dine-in-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color img, .order-status-widget .website-order-dine-in-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color img, .order-status-widget .website-order-dine-in-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color img {
    width: 20px;
    display: block;
  }

  .order-status-widget .website-order-delivery-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper .default-status-color, .order-status-widget .website-order-delivery-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper .default-status-color, .order-status-widget .website-order-delivery-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper .default-status-color, .order-status-widget .website-order-pick-up-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper .default-status-color, .order-status-widget .website-order-pick-up-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper .default-status-color, .order-status-widget .website-order-pick-up-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper .default-status-color, .order-status-widget .website-order-dine-in-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper .default-status-color, .order-status-widget .website-order-dine-in-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper .default-status-color, .order-status-widget .website-order-dine-in-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper .default-status-color {
    background-color: #c8c8d0;
  }

  .order-status-widget .website-order-delivery-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper .success, .order-status-widget .website-order-delivery-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper .success, .order-status-widget .website-order-delivery-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper .success, .order-status-widget .website-order-pick-up-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper .success, .order-status-widget .website-order-pick-up-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper .success, .order-status-widget .website-order-pick-up-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper .success, .order-status-widget .website-order-dine-in-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper .success, .order-status-widget .website-order-dine-in-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper .success, .order-status-widget .website-order-dine-in-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper .success {
    background-color: #4cbba1;
  }

  .order-status-widget .website-order-delivery-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper .delivery-failed, .order-status-widget .website-order-delivery-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper .delivery-failed, .order-status-widget .website-order-delivery-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper .delivery-failed, .order-status-widget .website-order-pick-up-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper .delivery-failed, .order-status-widget .website-order-pick-up-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper .delivery-failed, .order-status-widget .website-order-pick-up-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper .delivery-failed, .order-status-widget .website-order-dine-in-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper .delivery-failed, .order-status-widget .website-order-dine-in-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper .delivery-failed, .order-status-widget .website-order-dine-in-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper .delivery-failed {
    background-color: #fbbf24;
  }

  .order-status-widget .website-order-delivery-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper .rejected, .order-status-widget .website-order-delivery-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper .rejected, .order-status-widget .website-order-delivery-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper .rejected, .order-status-widget .website-order-pick-up-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper .rejected, .order-status-widget .website-order-pick-up-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper .rejected, .order-status-widget .website-order-pick-up-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper .rejected, .order-status-widget .website-order-dine-in-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper .rejected, .order-status-widget .website-order-dine-in-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper .rejected, .order-status-widget .website-order-dine-in-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper .rejected {
    background-color: #9f1239;
  }

  .order-status-widget .website-order-delivery-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house, .order-status-widget .website-order-pick-up-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house, .order-status-widget .website-order-dine-in-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .order-status-widget .website-order-delivery-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container, .order-status-widget .website-order-pick-up-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container, .order-status-widget .website-order-dine-in-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container {
    width: 100%;
  }

  .order-status-widget .website-order-delivery-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container:first-child, .order-status-widget .website-order-pick-up-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container:first-child, .order-status-widget .website-order-dine-in-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container:first-child {
    width: 60px;
  }

  .order-status-widget .website-order-delivery-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper, .order-status-widget .website-order-pick-up-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper, .order-status-widget .website-order-dine-in-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .order-status-widget .website-order-delivery-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper .timeline-event-line, .order-status-widget .website-order-pick-up-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper .timeline-event-line, .order-status-widget .website-order-dine-in-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper .timeline-event-line {
    width: 100%;
    height: 2px;
  }

  .order-status-widget .website-order-delivery-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper .timeline-event-color, .order-status-widget .website-order-pick-up-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper .timeline-event-color, .order-status-widget .website-order-dine-in-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper .timeline-event-color {
    border-radius: 50%;
    margin: 0 5px;
    padding: 12px 13px;
  }

  .order-status-widget .website-order-delivery-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper .timeline-event-color img, .order-status-widget .website-order-pick-up-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper .timeline-event-color img, .order-status-widget .website-order-dine-in-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper .timeline-event-color img {
    width: 20px;
    display: block;
  }

  .order-status-widget .website-order-delivery-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper .default-status-color, .order-status-widget .website-order-pick-up-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper .default-status-color, .order-status-widget .website-order-dine-in-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper .default-status-color {
    background-color: #c8c8d0;
  }

  .order-status-widget .website-order-delivery-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper .success, .order-status-widget .website-order-pick-up-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper .success, .order-status-widget .website-order-dine-in-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper .success {
    background-color: #4cbba1;
  }

  .order-status-widget .website-order-delivery-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper .delivery-failed, .order-status-widget .website-order-pick-up-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper .delivery-failed, .order-status-widget .website-order-dine-in-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper .delivery-failed {
    background-color: #fbbf24;
  }

  .order-status-widget .website-order-delivery-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper .rejected, .order-status-widget .website-order-pick-up-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper .rejected, .order-status-widget .website-order-dine-in-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper .rejected {
    background-color: #9f1239;
  }

  .order-status-widget .website-order-delivery-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house, .order-status-widget .website-order-pick-up-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house, .order-status-widget .website-order-dine-in-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house {
    justify-content: center;
    align-items: center;
    padding: 0 70px;
    display: flex;
  }

  .order-status-widget .website-order-delivery-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container, .order-status-widget .website-order-pick-up-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container, .order-status-widget .website-order-dine-in-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container {
    width: 100%;
  }

  .order-status-widget .website-order-delivery-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container:first-child, .order-status-widget .website-order-pick-up-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container:first-child, .order-status-widget .website-order-dine-in-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container:first-child {
    width: 60px;
  }

  .order-status-widget .website-order-delivery-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper, .order-status-widget .website-order-pick-up-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper, .order-status-widget .website-order-dine-in-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .order-status-widget .website-order-delivery-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper .timeline-event-line, .order-status-widget .website-order-pick-up-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper .timeline-event-line, .order-status-widget .website-order-dine-in-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper .timeline-event-line {
    width: 100%;
    height: 2px;
  }

  .order-status-widget .website-order-delivery-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper .timeline-event-color, .order-status-widget .website-order-pick-up-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper .timeline-event-color, .order-status-widget .website-order-dine-in-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper .timeline-event-color {
    border-radius: 50%;
    margin: 0 5px;
    padding: 12px 13px;
  }

  .order-status-widget .website-order-delivery-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper .timeline-event-color img, .order-status-widget .website-order-pick-up-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper .timeline-event-color img, .order-status-widget .website-order-dine-in-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper .timeline-event-color img {
    width: 20px;
    display: block;
  }

  .order-status-widget .website-order-delivery-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper .default-status-color, .order-status-widget .website-order-pick-up-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper .default-status-color, .order-status-widget .website-order-dine-in-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper .default-status-color {
    background-color: #c8c8d0;
  }

  .order-status-widget .website-order-delivery-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper .success, .order-status-widget .website-order-pick-up-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper .success, .order-status-widget .website-order-dine-in-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper .success {
    background-color: #4cbba1;
  }

  .order-status-widget .website-order-delivery-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper .delivery-failed, .order-status-widget .website-order-pick-up-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper .delivery-failed, .order-status-widget .website-order-dine-in-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper .delivery-failed {
    background-color: #fbbf24;
  }

  .order-status-widget .website-order-delivery-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper .rejected, .order-status-widget .website-order-pick-up-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper .rejected, .order-status-widget .website-order-dine-in-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper .rejected {
    background-color: #9f1239;
  }

  .order-status-widget .website-order-delivery-timeline-widget .uber-delivery-timeline, .order-status-widget .website-order-pick-up-timeline-widget .uber-delivery-timeline, .order-status-widget .website-order-dine-in-timeline-widget .uber-delivery-timeline {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .order-status-widget .website-order-delivery-timeline-widget .uber-delivery-timeline .timeline-event-container, .order-status-widget .website-order-pick-up-timeline-widget .uber-delivery-timeline .timeline-event-container, .order-status-widget .website-order-dine-in-timeline-widget .uber-delivery-timeline .timeline-event-container {
    width: 100%;
  }

  .order-status-widget .website-order-delivery-timeline-widget .uber-delivery-timeline .timeline-event-container:first-child, .order-status-widget .website-order-pick-up-timeline-widget .uber-delivery-timeline .timeline-event-container:first-child, .order-status-widget .website-order-dine-in-timeline-widget .uber-delivery-timeline .timeline-event-container:first-child {
    width: 60px;
  }

  .order-status-widget .website-order-delivery-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper, .order-status-widget .website-order-pick-up-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper, .order-status-widget .website-order-dine-in-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .order-status-widget .website-order-delivery-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-line, .order-status-widget .website-order-pick-up-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-line, .order-status-widget .website-order-dine-in-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-line {
    width: 100%;
    height: 2px;
  }

  .order-status-widget .website-order-delivery-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color, .order-status-widget .website-order-pick-up-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color, .order-status-widget .website-order-dine-in-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color {
    border-radius: 50%;
    margin: 0 5px;
    padding: 12px 13px;
  }

  .order-status-widget .website-order-delivery-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color img, .order-status-widget .website-order-pick-up-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color img, .order-status-widget .website-order-dine-in-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color img {
    width: 20px;
    display: block;
  }

  .order-status-widget .website-order-delivery-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper .default-status-color, .order-status-widget .website-order-pick-up-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper .default-status-color, .order-status-widget .website-order-dine-in-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper .default-status-color {
    background-color: #c8c8d0;
  }

  .order-status-widget .website-order-delivery-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper .success, .order-status-widget .website-order-pick-up-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper .success, .order-status-widget .website-order-dine-in-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper .success {
    background-color: #4cbba1;
  }

  .order-status-widget .website-order-delivery-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper .delivery-failed, .order-status-widget .website-order-pick-up-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper .delivery-failed, .order-status-widget .website-order-dine-in-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper .delivery-failed {
    background-color: #fbbf24;
  }

  .order-status-widget .website-order-delivery-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper .rejected, .order-status-widget .website-order-pick-up-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper .rejected, .order-status-widget .website-order-dine-in-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper .rejected {
    background-color: #9f1239;
  }

  .order-status-widget .website-order-delivery-timeline-widget .delivery-status-label, .order-status-widget .website-order-delivery-timeline-widget .pick-up-status-label, .order-status-widget .website-order-delivery-timeline-widget .dine-in-status-label, .order-status-widget .website-order-pick-up-timeline-widget .delivery-status-label, .order-status-widget .website-order-pick-up-timeline-widget .pick-up-status-label, .order-status-widget .website-order-pick-up-timeline-widget .dine-in-status-label, .order-status-widget .website-order-dine-in-timeline-widget .delivery-status-label, .order-status-widget .website-order-dine-in-timeline-widget .pick-up-status-label, .order-status-widget .website-order-dine-in-timeline-widget .dine-in-status-label {
    text-align: center;
    margin-top: 20px;
  }

  .order-status-widget .website-order-delivery-timeline-widget .delivery-status-label .title, .order-status-widget .website-order-delivery-timeline-widget .pick-up-status-label .title, .order-status-widget .website-order-delivery-timeline-widget .dine-in-status-label .title, .order-status-widget .website-order-pick-up-timeline-widget .delivery-status-label .title, .order-status-widget .website-order-pick-up-timeline-widget .pick-up-status-label .title, .order-status-widget .website-order-pick-up-timeline-widget .dine-in-status-label .title, .order-status-widget .website-order-dine-in-timeline-widget .delivery-status-label .title, .order-status-widget .website-order-dine-in-timeline-widget .pick-up-status-label .title, .order-status-widget .website-order-dine-in-timeline-widget .dine-in-status-label .title {
    color: #4cbba1;
    margin: 8px 0;
    font-size: 16px;
    font-weight: 400;
  }

  .order-status-widget .website-order-delivery-timeline-widget .delivery-status-label .rejected, .order-status-widget .website-order-delivery-timeline-widget .pick-up-status-label .rejected, .order-status-widget .website-order-delivery-timeline-widget .dine-in-status-label .rejected, .order-status-widget .website-order-pick-up-timeline-widget .delivery-status-label .rejected, .order-status-widget .website-order-pick-up-timeline-widget .pick-up-status-label .rejected, .order-status-widget .website-order-pick-up-timeline-widget .dine-in-status-label .rejected, .order-status-widget .website-order-dine-in-timeline-widget .delivery-status-label .rejected, .order-status-widget .website-order-dine-in-timeline-widget .pick-up-status-label .rejected, .order-status-widget .website-order-dine-in-timeline-widget .dine-in-status-label .rejected {
    color: #9f1239;
  }

  .order-status-widget .website-order-delivery-timeline-widget .delivery-status-label .delivery-failed, .order-status-widget .website-order-delivery-timeline-widget .pick-up-status-label .delivery-failed, .order-status-widget .website-order-delivery-timeline-widget .dine-in-status-label .delivery-failed, .order-status-widget .website-order-pick-up-timeline-widget .delivery-status-label .delivery-failed, .order-status-widget .website-order-pick-up-timeline-widget .pick-up-status-label .delivery-failed, .order-status-widget .website-order-pick-up-timeline-widget .dine-in-status-label .delivery-failed, .order-status-widget .website-order-dine-in-timeline-widget .delivery-status-label .delivery-failed, .order-status-widget .website-order-dine-in-timeline-widget .pick-up-status-label .delivery-failed, .order-status-widget .website-order-dine-in-timeline-widget .dine-in-status-label .delivery-failed {
    color: #fbbf24;
  }

  .order-status-widget .website-order-delivery-timeline-widget .delivery-status-label .sub-title, .order-status-widget .website-order-delivery-timeline-widget .pick-up-status-label .sub-title, .order-status-widget .website-order-delivery-timeline-widget .dine-in-status-label .sub-title, .order-status-widget .website-order-pick-up-timeline-widget .delivery-status-label .sub-title, .order-status-widget .website-order-pick-up-timeline-widget .pick-up-status-label .sub-title, .order-status-widget .website-order-pick-up-timeline-widget .dine-in-status-label .sub-title, .order-status-widget .website-order-dine-in-timeline-widget .delivery-status-label .sub-title, .order-status-widget .website-order-dine-in-timeline-widget .pick-up-status-label .sub-title, .order-status-widget .website-order-dine-in-timeline-widget .dine-in-status-label .sub-title {
    color: #9191a1;
    font-size: 14px;
  }

  .order-status-widget .website-order-delivery-timeline-widget .delivery-status-label .sub-title .store-phone-number, .order-status-widget .website-order-delivery-timeline-widget .pick-up-status-label .sub-title .store-phone-number, .order-status-widget .website-order-delivery-timeline-widget .dine-in-status-label .sub-title .store-phone-number, .order-status-widget .website-order-pick-up-timeline-widget .delivery-status-label .sub-title .store-phone-number, .order-status-widget .website-order-pick-up-timeline-widget .pick-up-status-label .sub-title .store-phone-number, .order-status-widget .website-order-pick-up-timeline-widget .dine-in-status-label .sub-title .store-phone-number, .order-status-widget .website-order-dine-in-timeline-widget .delivery-status-label .sub-title .store-phone-number, .order-status-widget .website-order-dine-in-timeline-widget .pick-up-status-label .sub-title .store-phone-number, .order-status-widget .website-order-dine-in-timeline-widget .dine-in-status-label .sub-title .store-phone-number {
    color: #4cbba1;
  }

  .order-status-widget .website-order-delivery-timeline-widget .delivery-status-label .sub-title .request-proof-of-delivery, .order-status-widget .website-order-delivery-timeline-widget .pick-up-status-label .sub-title .request-proof-of-delivery, .order-status-widget .website-order-delivery-timeline-widget .dine-in-status-label .sub-title .request-proof-of-delivery, .order-status-widget .website-order-pick-up-timeline-widget .delivery-status-label .sub-title .request-proof-of-delivery, .order-status-widget .website-order-pick-up-timeline-widget .pick-up-status-label .sub-title .request-proof-of-delivery, .order-status-widget .website-order-pick-up-timeline-widget .dine-in-status-label .sub-title .request-proof-of-delivery, .order-status-widget .website-order-dine-in-timeline-widget .delivery-status-label .sub-title .request-proof-of-delivery, .order-status-widget .website-order-dine-in-timeline-widget .pick-up-status-label .sub-title .request-proof-of-delivery, .order-status-widget .website-order-dine-in-timeline-widget .dine-in-status-label .sub-title .request-proof-of-delivery {
    color: #9191a1;
    text-decoration: underline;
  }

  .order-view-widget .order-summary {
    box-sizing: border-box;
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    row-gap: 16px;
    margin-bottom: 24px;
    padding: 24px;
    display: grid;
    box-shadow: 0 2px 4px #0000001a;
  }

  .order-view-widget .order-summary .title {
    justify-content: space-between;
    display: flex;
  }

  .order-view-widget .order-summary .title .summary {
    color: #27283e;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .order-view-widget .order-summary .title .order-id {
    color: #9191a1;
    font-size: 13px;
  }

  .order-view-widget .order-summary .label {
    color: #27283e;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .order-view-widget .order-summary .value {
    color: #27283e;
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .order-view-widget .order-summary .payment-method .value {
    display: flex;
  }

  .order-view-widget .order-summary .payment-method .wallet, .order-view-widget .order-summary .payment-method .brand {
    height: 25px;
    margin-right: 5px;
  }

  .order-view-widget .order-summary .payment-method .status, .order-view-widget .order-summary .transaction-fee .status {
    color: #757575;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .order-view-widget .cart-summary {
    box-sizing: border-box;
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 24px;
    box-shadow: 0 2px 4px #0000001a;
  }

  .order-view-widget .cart-summary .cart-items {
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 16px;
    padding-bottom: 16px;
  }

  .order-view-widget .cart-summary .cart-items > .item-summary-view-widget {
    margin-top: 16px;
  }

  .order-view-widget .cart-summary .cart-items > .item-summary-view-widget:first-child {
    margin-top: 0;
  }

  .order-view-widget .cart-summary .amounts {
    row-gap: 10px;
    margin-bottom: 20px;
    display: grid;
  }

  .order-view-widget .cart-summary .amounts > div {
    grid-template-rows: max-content;
    grid-template-columns: 1fr min-content;
    display: grid;
  }

  .order-view-widget .cart-summary .amounts > div .label, .order-view-widget .cart-summary .amounts > div .value {
    color: #757575;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .order-view-widget .cart-summary .amounts .discount .label {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .order-view-widget .cart-summary .amounts > div:last-child {
    margin-top: 8px;
  }

  .order-view-widget .cart-summary .amounts > div:last-child .label, .order-view-widget .cart-summary .amounts > div:last-child .value {
    color: #27283e;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .page > .header {
    background-color: #fff;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    display: flex;
    position: relative;
    box-shadow: inset 0 -1px #e8ecf1;
  }

  .page > .header .wrapper .title {
    text-align: left;
    color: #27283e;
    font-size: 26px;
    font-weight: 700;
    line-height: 32px;
  }
}

@media (max-width: 767px) and (min-width: 768px) {
  .page > .header .wrapper .title {
    font-size: 32px;
    line-height: 48px;
  }
}

@media (max-width: 767px) {
  .page > .header .wrapper .search .menu-search-widget .text {
    display: none;
  }

  .page > .header .wrapper .search .icon {
    filter: invert();
    width: 21px;
  }

  .page > .header .actions button {
    background-color: #fff;
    padding: 7px 14px;
  }

  .page > .header .actions button .icon {
    color: #92929d;
    font-size: 20px;
  }

  .page > .header .actions button .label {
    color: var(--color-primary);
  }

  .page > .header .actions a:link {
    text-decoration: none;
  }

  .page > .body {
    margin-top: var(--safe-area-inset-top);
    padding-top: 0;
  }

  .page > .body > div {
    padding-bottom: max(85px, var(--safe-area-inset-bottom));
  }

  .page > .body > .spinner {
    text-align: center;
    margin: 50px;
    padding: 0;
  }

  .page > .footer {
    display: none;
  }

  .ReactModalPortal .ReactModal__Overlay {
    z-index: 1000;
    height: 100vh !important;
    width: 100vw !important;
    background: #525252b8 !important;
  }

  .ReactModalPortal .ReactModal__Overlay .ReactModal__Content {
    border: unset !important;
    width: auto !important;
    background: none !important;
    border-radius: 0 !important;
    padding: 0 !important;
  }

  .ReactModalPortal .ReactModal__Overlay .ReactModal__Content .modal {
    background-color: #fff;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0 !important;
  }

  .ReactModalPortal .ReactModal__Overlay .ReactModal__Content .modal.full-screen {
    inset: 0;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .ReactModalPortal .ReactModal__Overlay .ReactModal__Content .modal.bottom-sheet {
    width: 100vw;
    border-radius: 16px 16px 0 0;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .ReactModalPortal .ReactModal__Overlay .ReactModal__Content .modal.bottom-sheet.fixed-height {
    height: 75vh;
  }

  .ReactModalPortal .ReactModal__Overlay .ReactModal__Content .modal.bottom-sheet.min-content {
    max-height: 75vh;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

@media (max-width: 767px) {
  .deal-widget {
    height: 100vh;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
  }

  .deal-widget .item-body .close-icon {
    width: 20px;
    z-index: 1;
    background: #fff;
    border-radius: 100%;
    padding: 10px;
    position: fixed;
    top: 19px;
    right: 19px;
    box-shadow: 0 2px 2px #31303e14, 0 6px 10px #31303e1a;
  }

  .deal-widget .item-body .close-icon.no-image {
    background: #eee;
    top: 52px;
  }

  .deal-widget .item-footer {
    padding-top: 18px;
  }

  .deal-widget .item-name {
    color: #27283e;
    background: #fff;
    padding: 25px 65px 0 20px;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }

  .deal-widget .item-name.no-image {
    margin-top: 35px;
  }

  .deal-widget.no-image-and-modifier {
    height: unset;
  }

  .deal-widget.no-image-and-modifier .item-body .close-icon {
    position: absolute;
    top: 17px;
  }

  .deal-widget.no-image-and-modifier .item-body .item-name {
    margin-top: 0;
  }

  .deal-widget.no-image-and-modifier .add-to-cart-widget {
    padding: 32px 20px !important;
  }

  .deal-widget .item-sold-out, .deal-widget .item-unavailable {
    color: #27283e;
    width: -moz-fit-content;
    width: fit-content;
    background: #e0e0e0;
    border-radius: 4px;
    margin: 25px 65px 0 18px;
    padding: 2px 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .deal-widget .item-label {
    color: #27283e;
    background-color: #ffebcf;
    border-radius: 4px;
    margin: 20px 0 0 20px;
    padding: 3px 6px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    display: inline-block;
  }

  .deal-widget .item-name {
    color: #27283e;
    background: #fff;
    padding: 25px 65px 0 20px;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }

  .deal-widget .item-name.no-image {
    margin-top: 35px;
  }

  .deal-widget .item-details {
    background: #fff;
    padding: 10px 20px;
    font-style: normal;
  }

  .deal-widget .item-details * {
    margin: 0;
  }

  .deal-widget .item-details .item-description {
    color: #757575;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .deal-widget .item-details .item-price-and-dietary {
    flex-direction: row;
    align-items: baseline;
    padding-top: 15px;
    display: flex;
  }

  .deal-widget .item-details .item-price-and-dietary .item-price {
    color: #27283e;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .deal-widget .item-details .item-price-and-dietary .item-dietary {
    height: min-content;
    color: #757575;
    grid-column-start: 3;
    justify-self: flex-end;
    margin-left: auto;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .deal-widget .item-details .item-available-weekly-hours-widget {
    margin-top: 18px;
  }

  .deal-widget .deal-body {
    padding-top: 18px;
    padding-bottom: 14px;
  }

  .deal-widget .deal-body .deal-pizzas {
    padding-top: 0;
  }

  .deal-widget .deal-body .deal-pizzas .deal-pizza:first-child {
    margin-top: 0;
  }

  .deal-widget .deal-body .deal-pizzas .deal-pizza .header {
    background-color: #fafafa;
    flex-direction: column;
    grid-template-columns: 1fr max-content;
    grid-auto-flow: column;
    justify-content: space-between;
    padding: 20px;
    display: flex;
  }

  .deal-widget .deal-body .deal-pizzas .deal-pizza .header .title {
    color: #27283e;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .deal-widget .deal-body .deal-pizzas .deal-pizza .header .title .requiredness {
    color: #757575;
    justify-content: center;
    align-items: center;
    padding: 0 3px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    display: flex;
  }

  .deal-widget .deal-body .deal-pizzas .deal-pizza .header .title .selected:before {
    content: url("green-check.23f410e7.svg");
    padding-right: 5px;
  }

  .deal-widget .deal-body .deal-pizzas .deal-pizza .header .title .required {
    color: #086e37;
  }

  .deal-widget .deal-body .deal-pizzas .deal-pizza .header .title .error {
    color: #b0204b;
    background-color: #ffe7df;
  }

  .deal-widget .deal-body .deal-pizzas .deal-pizza .header > .form-item.switch label {
    color: #27283e;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .deal-widget .deal-body .deal-pizzas .deal-pizza .header .title {
    justify-content: space-between;
    margin-bottom: 4px;
    display: flex;
  }

  .deal-widget .deal-body .deal-pizzas .deal-pizza .header > .switch {
    justify-content: flex-start;
    display: flex;
  }

  .deal-widget .deal-body .deal-pizzas .deal-pizza .header > .switch > label {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #27283e !important;
  }

  .deal-widget .deal-body .deal-pizzas .deal-pizza .body {
    padding: 20px;
  }

  .deal-widget .deal-body .deal-pizzas .deal-pizza .body .deal-pizza-entry {
    margin-top: 8px;
  }

  .deal-widget .deal-body .deal-pizzas .deal-pizza .body .deal-pizza-entry:first-child {
    margin-top: 0;
  }

  .deal-widget .deal-body .deal-pizzas .deal-pizza .body .deal-pizza-entry .choose-container .choose-pizza-button {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    grid-auto-columns: 1fr min-content min-content;
    grid-auto-flow: column;
    align-items: center;
    gap: 15px;
    padding: 18px 16px;
    display: grid;
  }

  .deal-widget .deal-body .deal-pizzas .deal-pizza .body .deal-pizza-entry .choose-container .choose-pizza-button .text {
    color: #27283e;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .deal-widget .deal-body .deal-pizzas .deal-pizza .body .deal-pizza-entry .choose-container .choose-pizza-button .text.no-pizza {
    color: #9e9e9e;
  }

  .deal-widget .deal-body .deal-pizzas .deal-pizza .body .deal-pizza-entry .choose-container .choose-pizza-button .extra-charge {
    white-space: nowrap;
    color: #9e9e9e;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .deal-widget .deal-body .deal-pizzas .deal-pizza .body .deal-pizza-entry .choose-container .summary > pre {
    color: #757575;
    letter-spacing: .3px;
    white-space: pre-wrap;
    word-wrap: break-word;
    margin: 17px 2px 12px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    overflow-x: auto;
  }

  .deal-widget .deal-body .deal-pizzas .deal-pizza .body .deal-pizza-entry .choose-container .customize-pizza-button {
    color: #4cbba1;
    margin-bottom: 28px;
    margin-left: 2px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .deal-widget .deal-body .deal-pizzas .deal-pizza .body .deal-pizza-entry .choose-container .customize-pizza-button.customize {
    margin-top: 20px;
  }

  .deal-widget .deal-body .deal-items {
    grid-gap: 35px;
    padding-bottom: 12px;
    display: grid;
  }

  .deal-widget .deal-body .deal-items .deal-item > .header {
    background-color: #fafafa;
    grid-template-columns: 1fr max-content;
    grid-auto-flow: column;
    align-items: center;
    margin-bottom: 11px;
    padding: 16px 20px;
    display: grid;
  }

  .deal-widget .deal-body .deal-items .deal-item > .header .title {
    color: #27283e;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .deal-widget .deal-body .deal-items .deal-item > .modifiers .modifier-widget .header .title .modifier-name {
    font-weight: 700;
  }

  .deal-widget .deal-body .deal-modifiers {
    padding: 20px 0;
  }

  .deal-widget .actions .add-to-cart-widget {
    background: #eff8f9;
    padding: 32px 20px 122px;
  }

  .deal-widget .spinner-container {
    box-sizing: border-box;
    height: 51px;
    background: #ffebcf;
    border: 1px solid #f8bd79;
    border-radius: 8px;
    flex-direction: row;
    gap: 10px;
    padding: 14px;
    display: flex;
    position: absolute;
    top: 42px;
    left: calc(50% - 140px);
  }

  .deal-widget .spinner-container .message {
    text-align: center;
    color: #d67300;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .deal-widget .spinner-container .spinner {
    animation: 2s linear infinite spin;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@media (max-width: 767px) {
  .deal-pizza-list-widget {
    height: 100%;
    flex-flow: column;
    display: flex;
  }

  .deal-pizza-list-widget input[type="radio"] {
    display: none;
  }

  .deal-pizza-list-widget input[type="radio"] + label {
    color: #27283e;
    margin-left: 25px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    position: relative;
  }

  .deal-pizza-list-widget input[type="radio"] + label:before {
    content: "";
    width: 15px;
    height: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: -28px;
  }

  .deal-pizza-list-widget input[type="radio"]:checked + label:before {
    background: #4cbba1;
    border: 1px solid #4cbba1;
  }

  .deal-pizza-list-widget .item-header {
    z-index: 99;
    background: #fff;
    border-bottom: 1px solid #e0e0e0;
    justify-content: space-between;
    padding: 20px 20px 17px;
    display: flex;
    position: sticky;
    top: 0;
  }

  .deal-pizza-list-widget .item-header .title {
    color: #27283e;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

@media (max-width: 767px) and (min-width: 768px) {
  .deal-pizza-list-widget .item-header .title {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (max-width: 767px) {
  .deal-pizza-list-widget .item-header .close {
    color: #0000;
    width: 14px;
    height: 14px;
    background-image: url("x-icon.cd1cb403.svg");
    background-repeat: no-repeat;
    background-size: cover;
    margin: 5px;
    overflow: hidden;
  }

  .deal-pizza-list-widget .item-body {
    height: 100%;
    margin-bottom: 26px;
    overflow: scroll;
  }

  .deal-pizza-list-widget .pizzas {
    grid-gap: 20px;
    flex-direction: column;
    padding: 16px 20px;
    display: flex;
    overflow: auto;
  }

  .deal-pizza-list-widget .pizzas .pizza {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 14px 0;
    position: relative;
    overflow: hidden;
  }

  .deal-pizza-list-widget .pizzas .pizza .soldout-overlay {
    width: 100%;
    height: 100%;
    cursor: not-allowed;
    background: #ababab36;
    position: absolute;
    top: 0;
    left: 0;
  }

  .deal-pizza-list-widget .pizzas .pizza .header {
    color: #27283e;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px 0 15px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
  }

  .deal-pizza-list-widget .pizzas .pizza .price {
    color: #757575;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .deal-pizza-list-widget .pizzas .pizza .description {
    color: #757575;
    margin: 0;
    padding: 5px 15px 0 40px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

@media (max-width: 767px) {
  .half-and-half-widget {
    height: 100vh;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
  }

  .half-and-half-widget .item-body .close-icon {
    width: 20px;
    z-index: 1;
    background: #fff;
    border-radius: 100%;
    padding: 10px;
    position: fixed;
    top: 19px;
    right: 19px;
    box-shadow: 0 2px 2px #31303e14, 0 6px 10px #31303e1a;
  }

  .half-and-half-widget .item-body .close-icon.no-image {
    background: #eee;
    top: 52px;
  }

  .half-and-half-widget .item-footer {
    padding-top: 18px;
  }

  .half-and-half-widget .item-name {
    color: #27283e;
    background: #fff;
    padding: 25px 65px 0 20px;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }

  .half-and-half-widget .item-name.no-image {
    margin-top: 35px;
  }

  .half-and-half-widget.no-image-and-modifier {
    height: unset;
  }

  .half-and-half-widget.no-image-and-modifier .item-body .close-icon {
    position: absolute;
    top: 17px;
  }

  .half-and-half-widget.no-image-and-modifier .item-body .item-name {
    margin-top: 0;
  }

  .half-and-half-widget.no-image-and-modifier .add-to-cart-widget {
    padding: 32px 20px !important;
  }

  .half-and-half-widget .item-sold-out, .half-and-half-widget .item-unavailable {
    color: #27283e;
    width: -moz-fit-content;
    width: fit-content;
    background: #e0e0e0;
    border-radius: 4px;
    margin: 25px 65px 0 18px;
    padding: 2px 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .half-and-half-widget .item-label {
    color: #27283e;
    background-color: #ffebcf;
    border-radius: 4px;
    margin: 20px 0 0 20px;
    padding: 3px 6px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    display: inline-block;
  }

  .half-and-half-widget .item-name {
    color: #27283e;
    background: #fff;
    padding: 25px 65px 0 20px;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }

  .half-and-half-widget .item-name.no-image {
    margin-top: 35px;
  }

  .half-and-half-widget .item-details {
    background: #fff;
    padding: 10px 20px;
    font-style: normal;
  }

  .half-and-half-widget .item-details * {
    margin: 0;
  }

  .half-and-half-widget .item-details .item-description {
    color: #757575;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .half-and-half-widget .item-details .item-price-and-dietary {
    flex-direction: row;
    align-items: baseline;
    padding-top: 15px;
    display: flex;
  }

  .half-and-half-widget .item-details .item-price-and-dietary .item-price {
    color: #27283e;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .half-and-half-widget .item-details .item-price-and-dietary .item-dietary {
    height: min-content;
    color: #757575;
    grid-column-start: 3;
    justify-self: flex-end;
    margin-left: auto;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .half-and-half-widget .item-details .item-available-weekly-hours-widget {
    margin-top: 18px;
  }

  .half-and-half-widget .half-and-half-pizzas {
    grid-gap: 27px;
    margin-top: 10px;
    display: grid;
  }

  .half-and-half-widget .half-and-half-pizzas p {
    margin: 0 0 11px;
  }

  .half-and-half-widget .half-and-half-pizzas .header {
    background-color: #fafafa;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
    padding: 16px 20px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    display: flex;
  }

  .half-and-half-widget .half-and-half-pizzas .header .title {
    justify-content: space-between;
    margin-bottom: 3px;
    display: flex;
  }

  .half-and-half-widget .half-and-half-pizzas .header .title .pizza-half {
    margin-right: 8px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .half-and-half-widget .half-and-half-pizzas .header .title .requiredness {
    color: #757575;
    justify-content: center;
    align-items: center;
    padding: 3px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    display: flex;
  }

  .half-and-half-widget .half-and-half-pizzas .header .title .selected:before {
    content: url("green-check.23f410e7.svg");
    padding-right: 5px;
  }

  .half-and-half-widget .half-and-half-pizzas .header .title .required {
    color: #086e37;
  }

  .half-and-half-widget .half-and-half-pizzas .header .title .error {
    color: #b0204b;
    background-color: #ffe7df;
  }

  .half-and-half-widget .half-and-half-pizzas .half-and-half-pizza-entry {
    padding: 16px 20px;
  }

  .half-and-half-widget .half-and-half-pizzas .choose-pizza-button {
    width: 100%;
    box-sizing: border-box;
    color: #9e9e9e;
    background: #fff url("right-arrow-head-icon.ebb9d916.svg") right 14px bottom 19px no-repeat;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    justify-content: space-between;
    padding: 14px 30px 14px 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
  }

  .half-and-half-widget .half-and-half-pizzas .choose-pizza-button .label.with-input {
    color: #27283e;
  }

  .half-and-half-widget .half-and-half-pizzas .choose-pizza-button .extra-charge {
    grid-gap: 5px;
    display: flex;
  }

  .half-and-half-widget .half-and-half-pizzas .summary {
    white-space: pre-wrap;
    color: #757575;
    margin: 0;
    padding: 18px 0 0 2px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .half-and-half-widget .half-and-half-pizzas .customize-pizza-button {
    text-transform: none;
    color: #4cbba1;
    box-shadow: none;
    letter-spacing: unset;
    background: #fff;
    padding: 20px 3px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .half-and-half-widget .half-and-half-pizzas .edit-pizza-button {
    text-transform: none;
    color: #4cbba1;
    box-shadow: none;
    letter-spacing: unset;
    background: #fff;
    padding: 14px 0 0 2px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .half-and-half-widget .actions {
    margin-top: 33px;
  }

  .half-and-half-widget .actions .add-to-cart-widget {
    background: #eff8f9;
    padding: 32px 20px 122px;
  }

  .half-and-half-widget .spinner-container {
    box-sizing: border-box;
    height: 51px;
    background: #ffebcf;
    border: 1px solid #f8bd79;
    border-radius: 8px;
    flex-direction: row;
    gap: 10px;
    padding: 14px;
    display: flex;
    position: absolute;
    top: 42px;
    left: calc(50% - 140px);
  }

  .half-and-half-widget .spinner-container .message {
    text-align: center;
    color: #d67300;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .half-and-half-widget .spinner-container .spinner {
    animation: 2s linear infinite spin;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@media (max-width: 767px) {
  .half-and-half-pizza-list-widget {
    height: 100%;
    flex-flow: column;
    display: flex;
  }

  .half-and-half-pizza-list-widget input[type="radio"] {
    display: none;
  }

  .half-and-half-pizza-list-widget input[type="radio"] + label {
    color: #27283e;
    margin-left: 25px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    position: relative;
  }

  .half-and-half-pizza-list-widget input[type="radio"] + label:before {
    content: "";
    width: 15px;
    height: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: -28px;
  }

  .half-and-half-pizza-list-widget input[type="radio"]:checked + label:before {
    background: #4cbba1;
    border: 1px solid #4cbba1;
  }

  .half-and-half-pizza-list-widget .item-header {
    z-index: 99;
    background: #fff;
    border-bottom: 1px solid #e0e0e0;
    justify-content: space-between;
    padding: 20px 20px 17px;
    display: flex;
    position: sticky;
    top: 0;
  }

  .half-and-half-pizza-list-widget .item-header .title {
    color: #27283e;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

@media (max-width: 767px) and (min-width: 768px) {
  .half-and-half-pizza-list-widget .item-header .title {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (max-width: 767px) {
  .half-and-half-pizza-list-widget .item-header .close {
    color: #0000;
    width: 14px;
    height: 14px;
    background-image: url("x-icon.cd1cb403.svg");
    background-repeat: no-repeat;
    background-size: cover;
    margin: 5px;
    overflow: hidden;
  }

  .half-and-half-pizza-list-widget .item-body {
    height: 100%;
    margin-bottom: 26px;
    overflow: scroll;
  }

  .half-and-half-pizza-list-widget .pizzas {
    grid-gap: 20px;
    flex-direction: column;
    padding: 16px 20px;
    display: flex;
    overflow: auto;
  }

  .half-and-half-pizza-list-widget .pizzas .pizza {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 14px 0;
    position: relative;
    overflow: hidden;
  }

  .half-and-half-pizza-list-widget .pizzas .pizza .soldout-overlay {
    width: 100%;
    height: 100%;
    cursor: not-allowed;
    background: #ababab36;
    position: absolute;
    top: 0;
    left: 0;
  }

  .half-and-half-pizza-list-widget .pizzas .pizza .header {
    color: #27283e;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px 0 15px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
  }

  .half-and-half-pizza-list-widget .pizzas .pizza .price {
    color: #757575;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .half-and-half-pizza-list-widget .pizzas .pizza .description {
    color: #757575;
    margin: 0;
    padding: 5px 15px 0 40px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .order-history-page {
    height: calc(100vh - 124px);
    background: #fafafa;
  }

  .order-history-page .list {
    margin: 0 20px;
  }

  .order-history-page .list .order-list-view {
    box-sizing: border-box;
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    grid-template-columns: 1fr auto;
    grid-template-areas: "collection-type status"
                         "time time"
                         "address address"
                         "customer customer"
                         "grand-total grand-total";
    margin: 20px 0;
    padding: 16px;
    display: grid;
    box-shadow: 0 2px 4px #0000001a;
  }

  .order-history-page .list .order-list-view .status {
    grid-area: status;
    margin-bottom: 9px;
    display: block;
  }

  .order-history-page .list .order-list-view .collection-type {
    color: #27283e;
    grid-area: collection-type;
    margin-bottom: 9px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .order-history-page .list .order-list-view .time {
    color: #27283e;
    grid-area: time;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .order-history-page .list .order-list-view .address {
    color: #27283e;
    grid-area: address;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .order-history-page .list .order-list-view .customer {
    color: #757575;
    grid-area: customer;
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .order-history-page .list .order-list-view .grand-total {
    color: #757575;
    grid-area: grand-total;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .order-history-page .body .message span {
    text-decoration: underline;
  }

  .order-view-page {
    background-color: #fff;
  }

  .order-view-page .order-view-header {
    display: none;
  }

  .order-view-page .order-tracking-label {
    color: #27283e;
    margin: 5px 20px;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }

  .order-view-page .order-status-widget {
    margin-top: 20px;
  }

  .order-view-page .order-view-widget {
    margin-left: 20px;
    margin-right: 20px;
  }

  .order-view-page .order-placed-time {
    text-align: center;
    color: #9191a1;
    margin: 20px 20px 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .order-view-page .cancel-order-info {
    text-align: center;
    color: #9e9e9e;
    margin: 0 20px;
    padding-top: 42px;
  }

  .order-view-page .cancel-order-info span {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .order-view-page .cancel-order-info .contact {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .order-view-page .cancel-order-info .contact a {
    color: #4cbba1;
    margin-left: 3px;
  }

  .order-view-page .buttons {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 20px;
    display: flex;
  }

  .order-view-page .buttons > .customer-review-link-button {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    margin-top: 20px;
    padding: 20px;
    box-shadow: 0 2px 4px #0000000d;
  }

  .order-view-page .buttons > .customer-review-link-button > .label {
    align-items: center;
    display: flex;
  }

  .order-view-page .buttons > .customer-review-link-button > .label img {
    width: 44px;
    height: 44px;
  }

  .order-view-page .buttons > .customer-review-link-button > .label span {
    color: #1c1d3e;
    padding-left: 16px;
  }

  .order-view-page .buttons .back-to-menu-button {
    box-shadow: none;
    color: #9e9e9e;
    background-color: #0000;
    border: none;
    margin-top: 16px;
    padding: 14px 50px;
  }

  .badge {
    border-radius: 4px;
    padding: 3.5px 6px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    display: inline;
  }

  .badge.success {
    color: #086e37;
    background: #def2d6;
  }

  .badge.failure {
    color: #b0204b;
    background: #ffe7df;
  }

  .badge.primary, .badge.warning {
    color: #d67300;
    background: #ffebcf;
  }

  .delivery-address-edit-widget {
    flex-direction: column;
    padding: 0 20px 20px;
    display: flex;
  }

  .delivery-address-edit-widget .section {
    flex-direction: column;
    margin-bottom: 24px;
    display: flex;
  }

  .delivery-address-edit-widget .section .title {
    color: #27283e;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .delivery-address-edit-widget .section-header {
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    display: flex;
  }

  .delivery-address-edit-widget .section-header .image {
    width: 39px;
    display: flex;
  }

  .delivery-address-edit-widget .delivery-address {
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 24px;
  }

  .delivery-address-edit-widget .unavailable-address {
    background-color: #ffe7df;
    gap: 10px;
    margin-bottom: 12px;
    padding: 12px;
    display: flex;
  }

  .delivery-address-edit-widget .unavailable-address .image {
    width: 24px;
    grid-area: auto;
    top: 0;
    right: 0;
  }

  .delivery-address-edit-widget .unavailable-address .text {
    align-items: center;
    display: flex;
  }

  .delivery-address-edit-widget .delivery-charge {
    color: #9191a1;
    background-color: #fff;
    gap: 10px;
    margin-bottom: 17px;
    display: flex;
  }

  .delivery-address-edit-widget .invalid {
    border-color: #e0e0e0 !important;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@media (max-width: 767px) {
  .update-delivery-address-widget {
    letter-spacing: .25px;
    color: #27283e;
    height: 100%;
  }

  .update-delivery-address-widget .header-section {
    z-index: 99;
    background: #fff;
    border-bottom: 1px solid #e0e0e0;
    justify-content: space-between;
    padding: 0 20px;
    display: flex;
    position: sticky;
    top: 0;
  }

  .update-delivery-address-widget .header-section .title {
    color: #27283e;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

@media (max-width: 767px) and (min-width: 768px) {
  .update-delivery-address-widget .header-section .title {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (max-width: 767px) {
  .update-delivery-address-widget .header-section .close-icon {
    width: 15px;
    margin-right: 5px;
  }

  .update-delivery-address-widget .body {
    padding-top: 30px;
    display: grid;
  }

  .update-delivery-address-widget .body .action-buttons {
    grid-gap: 17px;
    box-sizing: border-box;
    width: 100%;
    padding: 15px 20px;
    display: grid;
  }

  .update-delivery-address-widget .body .action-buttons .update-address-button {
    color: #fff;
    width: 100%;
    letter-spacing: 2px;
    text-transform: uppercase;
    background: #4cbba1;
    padding: 14px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .update-delivery-address-widget .body .action-buttons .cancel-order-button {
    color: #4cbba1;
    width: 100%;
    letter-spacing: 2px;
    text-transform: uppercase;
    background: #fff;
    border: 1px solid #4cbba1;
    padding: 13px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .confirm-dialog.discounted-items-alert .swal2-content {
    padding: 0;
  }

  .confirm-dialog.discounted-items-alert .swal2-title {
    text-align: left;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .confirm-dialog.discounted-items-alert .swal2-html-container {
    text-align: left;
    margin: 0 30px;
    font-size: 14px;
  }

  .checkout-widget {
    width: 100%;
    display: grid;
    padding: 0 !important;
  }

  .checkout-widget.keyboard-visible {
    margin-bottom: 100px;
  }

  .checkout-widget .spinner-container {
    margin-top: 120px;
  }

  .checkout-widget .checkout-header {
    width: 100%;
    height: 60px;
    z-index: 200;
    background-color: #fff;
    border-radius: 16px 16px 0 0;
    justify-content: space-between;
    align-items: center;
    display: flex;
    position: fixed;
    box-shadow: inset 0 -1px #f0f0f0;
  }

  .checkout-widget .checkout-header .header-text {
    text-align: left;
    color: #27283e;
    margin-left: 20px;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

@media (max-width: 767px) and (min-width: 768px) {
  .checkout-widget .checkout-header .header-text {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (max-width: 767px) {
  .checkout-widget .checkout-header .close-icon {
    width: 15px;
    cursor: pointer;
    position: absolute;
    right: 25px;
  }

  .checkout-widget .alert {
    box-shadow: none;
    margin: 10px 0;
  }

  .checkout-widget form {
    padding-top: 0 !important;
  }

  .checkout-widget form .form-items {
    grid-gap: 10px;
  }

  .checkout-widget form .form-items .form-input input {
    height: 53px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .checkout-widget form .form-items .form-input textarea {
    word-break: break-word;
    scrollbar-width: none;
    scrollbar-color: transparent transparent;
    text-align: left;
    padding-top: 18px;
    padding-left: 15px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .checkout-widget form .form-items .form-input textarea::-webkit-scrollbar {
    width: 1px;
  }

  .checkout-widget form .form-items .form-input textarea::-webkit-scrollbar-track {
    background: none;
  }

  .checkout-widget form .form-items .form-input textarea::-webkit-scrollbar-thumb {
    background-color: #0000;
  }

  .checkout-widget form .form-items .form-input ::-webkit-input-placeholder {
    color: #757575;
  }

  .checkout-widget form .form-items .form-input :-ms-input-placeholder {
    color: #757575;
  }

  .checkout-widget form .form-items .form-input :-moz-placeholder {
    color: #757575;
  }

  .checkout-widget .step-buttons {
    grid-gap: 0;
    grid-auto-flow: row;
    display: grid;
  }

  .checkout-widget .step-buttons .button, .checkout-widget .step-buttons button.place-order, .checkout-widget .step-buttons button.continue, .checkout-widget .step-buttons button.previous {
    width: 100%;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 10px;
    padding: 20px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .checkout-widget .step-buttons button.previous {
    color: #757575;
    box-shadow: none;
    background-color: #0000;
  }

  .checkout-widget .step-buttons button.continue {
    background-color: #4cbba1;
  }

  .checkout-widget .step-buttons button.place-order {
    background-color: #4cbba1;
    margin-top: 10px;
  }

  .checkout-widget .sections {
    background-color: var(--card-bg);
    border: 1px solid #e2e2ea;
    border-radius: 10px;
    margin-top: 60px;
    margin-bottom: 30px;
    box-shadow: 0 0 3px #687a9b40;
  }

  .checkout-widget .section {
    height: auto;
    min-height: 47vh;
    padding-top: 8px;
  }

  .checkout-widget .section > .header {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 33px;
    display: flex;
  }

  .checkout-widget .section > .header .title {
    display: grid;
  }

  .checkout-widget .section > .header .title .icon {
    height: 61px;
    width: 61px;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 0 auto;
    padding-bottom: 15px;
  }

  .checkout-widget .section > .header .title .customer-display-name {
    background-image: url("customer-name-icon.4daddd9a.svg");
  }

  .checkout-widget .section > .header .title .coupon {
    background-image: url("coupon-icon.53b9d340.svg");
  }

  .checkout-widget .section > .header .title .comment {
    background-image: url("comment-icon.1e308300.svg");
  }

  .checkout-widget .section > .header .title .payment {
    background-image: url("payment-icon.56e9bb5d.svg");
  }

  .checkout-widget .section > .header .title .header {
    text-align: center;
    color: #27283e;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .checkout-widget .section > .header .title .subtitle {
    width: 80%;
    text-align: center;
    color: #757575;
    margin: 0 auto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .checkout-widget .section > .header .title-add-action {
    flex-direction: row;
    align-items: center;
    display: flex;
  }

  .checkout-widget .section > .header .title-add-action img {
    margin-right: 8px;
  }

  .checkout-widget .section > .header .title-add-action span, .checkout-widget .section > .header .title-value {
    text-align: right;
    color: #426ed9;
    font-family: Fira Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .checkout-widget .section > .header .title-value.close {
    color: var(--color-secondary);
  }

  .checkout-widget .items .cart-item {
    border-bottom: 1px dashed #e9ecf3;
    margin-bottom: 20px;
    padding: 0 20px 12px;
  }

  .checkout-widget .items .cart-item:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }

  .checkout-widget .items .cart-item > .header {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  .checkout-widget .items .cart-item > .header .name {
    color: #000521;
    word-break: break-all;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .checkout-widget .items .cart-item > .footer {
    grid-template-columns: 1fr min-content;
    justify-content: space-between;
    align-items: flex-end;
    margin: 8px 0;
    display: grid;
  }

  .checkout-widget .items .cart-item > .footer .price {
    color: #fa7268;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .checkout-widget .items .cart-item > .footer .quantity {
    flex-direction: row;
    align-items: center;
    display: flex;
  }

  .checkout-widget .items .cart-item > .footer .quantity .number {
    color: #000521;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .checkout-widget .items .cart-item > .footer .quantity .icon {
    cursor: pointer;
  }

  .checkout-widget .items .cart-item > .footer .quantity .dec {
    padding-right: 10px;
  }

  .checkout-widget .items .cart-item > .footer .quantity .inc {
    padding-left: 10px;
  }

  .checkout-widget .items .cart-item > .footer .quantity .icon {
    color: #8d92a3;
    font-size: 24px;
  }

  .checkout-widget .items .cart-item .summary {
    word-break: break-all;
    align-self: start;
  }

  .checkout-widget .items .cart-item .summary pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    color: #8d92a3;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    overflow-x: auto;
    margin: 0 !important;
  }

  .checkout-widget .amounts {
    padding: 10px 20px;
  }

  .checkout-widget .amounts .amount {
    color: #565c65;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
    font-family: Fira Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
  }

  .checkout-widget .amounts .grand-total {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .checkout-widget .add-delivery-address {
    padding: 5px 20px 10px;
  }

  .checkout-widget .add-delivery-address .delivery-suburbs {
    margin-top: 10px;
    margin-bottom: 5px;
    display: grid;
  }

  .checkout-widget .add-delivery-address .delivery-suburbs .delivery-suburb {
    grid-gap: 5px;
    border-bottom: 1px dashed #e9ecf3;
    grid-template-columns: min-content 1fr min-content;
    padding: 10px 0;
    display: grid;
  }

  .checkout-widget .add-delivery-address .delivery-suburbs .delivery-suburb.selected {
    color: var(--fg);
    font-weight: 700;
  }

  .checkout-widget .add-delivery-address .delivery-suburbs .delivery-suburb:last-child {
    border-bottom: 0;
  }

  .checkout-widget .add-delivery-address .delivery-suburbs .delivery-suburb .charge {
    white-space: nowrap;
  }

  .checkout-widget .saved-addresses {
    padding: 5px 20px 10px;
  }

  .checkout-widget .saved-addresses .saved-address {
    grid-gap: 5px;
    border-bottom: 1px dashed #e9ecf3;
    grid-template-columns: min-content 1fr;
    padding: 10px 0;
    display: grid;
    grid-template-columns: min-content 1fr min-content min-content !important;
  }

  .checkout-widget .saved-addresses .saved-address.selected {
    color: var(--fg);
    font-weight: 700;
  }

  .checkout-widget .saved-addresses .saved-address:last-child {
    border-bottom: 0;
  }

  .checkout-widget .saved-addresses .radio-button {
    position: relative;
    top: 10px;
  }

  .checkout-widget .saved-addresses .address {
    padding-top: 10px;
  }

  .checkout-widget .saved-addresses .icon {
    color: #8d92a3;
    cursor: pointer;
    font-size: 24px;
  }

  .checkout-widget .no-comments {
    text-align: center;
    padding: 50px 0;
  }

  .checkout-widget .add-new-comment {
    text-align: left;
    color: #4cbba1;
    padding: 10px 0;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .checkout-widget .saved-comments {
    height: auto;
    padding-bottom: 10px;
  }

  .checkout-widget .saved-comments .saved-comment {
    overflow-wrap: anywhere;
    background: #fff;
    border-radius: 8px;
    grid-template-columns: 1fr min-content min-content;
    margin-bottom: 3px;
    padding: 11px 5px 11px 11px;
    display: grid;
  }

  .checkout-widget .saved-comments .saved-comment.valid {
    border: 1px solid #e0e0e0;
  }

  .checkout-widget .saved-comments .saved-comment.invalid {
    border: 1px solid #fe5b5b;
  }

  .checkout-widget .saved-comments .error-message {
    color: #fe5b5b;
    font-size: 12px;
  }

  .checkout-widget .saved-comments .comment {
    text-align: left;
    color: #27283e;
    align-self: center;
    padding-left: 7px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .checkout-widget .saved-comments .icon {
    height: 28px;
    width: 28px;
    cursor: pointer;
  }

  .checkout-widget .saved-comments .remove {
    background-image: url("trash-icon.1451820b.svg");
    background-position: center;
    background-repeat: no-repeat;
  }

  .checkout-widget .saved-comments .edit {
    background-image: url("pen-icon.44caf3b1.svg");
    background-position: center;
    background-repeat: no-repeat;
  }

  .checkout-widget .payment {
    margin-bottom: -10px;
  }

  .checkout-widget .payment-types {
    grid-gap: 6px;
    display: grid;
  }

  .checkout-widget .payment-types .payment-type {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 14px;
  }

  .checkout-widget .redeem-coupon .header, .checkout-widget .payment .header {
    padding-bottom: 13px;
  }

  .checkout-widget .use-text {
    text-align: center;
    color: #4cbba1;
    margin: 34px 0;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .checkout-widget .coupons {
    margin: 0;
  }

  .checkout-widget .coupons .recommended-coupon {
    letter-spacing: 1.5px;
    text-align: left;
    text-transform: uppercase;
    color: #d67300;
    margin: 15px 0 5px 20px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .checkout-widget .coupons .note {
    text-align: left;
    letter-spacing: unset;
    margin: unset;
    text-transform: unset;
    color: #27283e;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 0 15px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .checkout-widget .coupons .note .learn-more {
    color: #d67300;
    cursor: pointer;
    white-space: nowrap;
  }

  .checkout-widget .suggested-coupon {
    background-color: #fff;
    border-top: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
    border-right: 1px solid #e9e9e9;
    grid-template-columns: 12px 1fr;
    grid-template-areas: "zigzag content";
    display: grid;
    box-shadow: 0 0 8px 1px #302f3a1a;
  }

  .checkout-widget .suggested-coupon .content {
    grid-area: content;
  }

  .checkout-widget .suggested-coupon .zigzag {
    width: 12px;
    background-color: #0000;
    background-image: linear-gradient(45deg, #fff0 47%, #e9e9e9 47% 52%, #fff 52%), linear-gradient(315deg, #fff 47%, #e9e9e9 47% 52%, #e1e1e14d 52%);
    background-position: 0 0, 0 0;
    background-repeat: repeat-y;
    background-size: 23px 23px;
    background-attachment: scroll, scroll;
    background-origin: padding-box, padding-box;
    background-clip: border-box, border-box;
    grid-area: zigzag;
  }

  .checkout-widget .suggested-coupon .content {
    color: #27283e;
    background-color: #fff;
    grid-template-rows: repeat(3, min-content);
    grid-template-columns: 1fr min-content;
    grid-template-areas: "name actions"
                         "description actions"
                         "discount actions";
    align-items: center;
    gap: 0 5px;
    margin-left: 10px;
    display: grid;
  }

  .checkout-widget .suggested-coupon .content .name {
    text-align: left;
    color: #27283e;
    text-overflow: ellipsis;
    grid-area: name;
    margin-top: 22px;
    font-family: IBM Plex;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    overflow: hidden;
  }

  .checkout-widget .suggested-coupon .content .icon {
    width: 43px;
    grid-area: icon;
    position: absolute;
    top: -10px;
  }

  .checkout-widget .suggested-coupon .content .icon.available {
    right: 0;
  }

  .checkout-widget .suggested-coupon .content .description {
    min-height: 50px;
    text-align: left;
    color: #757575;
    grid-area: description;
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    overflow: hidden;
  }

  .checkout-widget .suggested-coupon .content .discount {
    text-align: left;
    color: #27283e;
    text-transform: lowercase;
    grid-area: discount;
    margin-bottom: 18px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .checkout-widget .suggested-coupon .content .actions {
    grid-area: actions;
  }

  .checkout-widget .suggested-coupon .content .actions button {
    color: #4cbba1;
    background-color: #fff;
    border: 2px solid #4cbba1;
    border-radius: 8px;
    margin-right: 15px;
    padding: 4px 14px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .checkout-widget .suggested-coupon.used {
    filter: grayscale();
    color: #27283e;
  }

  .checkout-widget .suggested-coupon .code {
    grid-area: code;
    font-family: IBM Plex;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .checkout-widget .suggested-coupon .availability {
    text-transform: uppercase;
    color: #4cbba1;
    letter-spacing: 1px;
    grid-area: availability;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .checkout-widget .applied-coupon {
    background-color: #eff8f9;
    border-top: 1px solid #4cbba1;
    border-bottom: 1px solid #4cbba1;
    border-right: 1px solid #4cbba1;
    grid-template-columns: 12px 1fr;
    grid-template-areas: "zigzag content";
    display: grid;
    box-shadow: 0 0 8px 1px #302f3a1a;
  }

  .checkout-widget .applied-coupon .content {
    grid-area: content;
  }

  .checkout-widget .applied-coupon .zigzag {
    width: 12px;
    background-color: #0000;
    background-image: linear-gradient(45deg, #fff0 47%, #4cbba1 47% 52%, #eff8f9 52%), linear-gradient(315deg, #eff8f9 47%, #4cbba1 47% 52%, #e1e1e14d 52%);
    background-position: 0 0, 0 0;
    background-repeat: repeat-y;
    background-size: 23px 23px;
    background-attachment: scroll, scroll;
    background-origin: padding-box, padding-box;
    background-clip: border-box, border-box;
    grid-area: zigzag;
  }

  .checkout-widget .applied-coupon .content {
    grid-template: "name icon" min-content
                   "desc icon" 1fr
                   "discount icon" min-content
                   "actions icon" min-content
                   / 1fr min-content;
    align-items: center;
    margin-left: 10px;
    display: grid;
  }

  .checkout-widget .applied-coupon .content .name {
    text-align: left;
    color: #27283e;
    text-overflow: ellipsis;
    grid-area: name;
    margin-top: 13px;
    font-family: IBM Plex;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    overflow: hidden;
  }

  .checkout-widget .applied-coupon .content .description {
    min-height: 36px;
    color: #757575;
    grid-area: desc;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    overflow: hidden;
  }

  .checkout-widget .applied-coupon .content .description .learn-more {
    color: #086e37;
    cursor: pointer;
    letter-spacing: 0;
    white-space: nowrap;
  }

  .checkout-widget .applied-coupon .content .discount {
    text-align: left;
    color: #27283e;
    text-transform: uppercase;
    grid-area: discount;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .checkout-widget .applied-coupon .content .icon {
    width: 16px;
    height: 16px;
    background-color: #4cbba1;
    border-radius: 50%;
    grid-area: icon;
    margin-left: 22px;
    margin-right: 22px;
    padding: 8px;
  }

  .checkout-widget .applied-coupon .content .actions {
    grid-area: actions;
  }

  .checkout-widget .applied-coupon .content .actions button {
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: left;
    color: #4cbba1;
    box-shadow: none;
    background-color: #eff8f9;
    margin-top: 16px;
    margin-bottom: 13px;
    padding: 0;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
  }

  .checkout-widget .summary-title, .checkout-widget .collection-info .collection-type {
    color: #000521;
    margin: 0;
    padding: 0 20px 12px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .checkout-widget .summary-subTitle, .checkout-widget .mobile-number .number, .checkout-widget .display-name .name, .checkout-widget .payment .selected-payment-type, .checkout-widget .collection-info .delivery-address, .checkout-widget .collection-info .collection-date-time {
    color: #000521;
    margin: 0;
    padding: 0 20px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .checkout-widget .credential-already-in-use, .checkout-widget .mobile-update-widget, .checkout-widget .unlink-mobile-number, .checkout-widget .add-credit-card, .checkout-widget .add-comments, .checkout-widget .custom-coupon, .checkout-widget .add-delivery-address, .checkout-widget .update-display-name {
    padding: 5px 20px 10px;
  }

  .checkout-widget .credential-already-in-use .message, .checkout-widget .mobile-update-widget .message, .checkout-widget .unlink-mobile-number .message, .checkout-widget .add-credit-card .message, .checkout-widget .add-comments .message, .checkout-widget .custom-coupon .message, .checkout-widget .add-delivery-address .message, .checkout-widget .update-display-name .message {
    padding-left: 6px;
  }

  .checkout-widget .credential-already-in-use .actions, .checkout-widget .mobile-update-widget .actions, .checkout-widget .unlink-mobile-number .actions, .checkout-widget .add-credit-card .actions, .checkout-widget .add-comments .actions, .checkout-widget .custom-coupon .actions, .checkout-widget .add-delivery-address .actions, .checkout-widget .update-display-name .actions {
    grid-gap: 10px;
    grid-template-columns: min-content;
    grid-auto-columns: min-content;
    grid-auto-flow: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 15px;
    display: grid;
  }

  .checkout-widget .credential-already-in-use .actions button, .checkout-widget .mobile-update-widget .actions button, .checkout-widget .unlink-mobile-number .actions button, .checkout-widget .add-credit-card .actions button, .checkout-widget .add-comments .actions button, .checkout-widget .custom-coupon .actions button, .checkout-widget .add-delivery-address .actions button, .checkout-widget .update-display-name .actions button {
    background-color: #fff;
    padding: 7px 14px;
  }

  .checkout-widget .credential-already-in-use .actions button .icon, .checkout-widget .mobile-update-widget .actions button .icon, .checkout-widget .unlink-mobile-number .actions button .icon, .checkout-widget .add-credit-card .actions button .icon, .checkout-widget .add-comments .actions button .icon, .checkout-widget .custom-coupon .actions button .icon, .checkout-widget .add-delivery-address .actions button .icon, .checkout-widget .update-display-name .actions button .icon {
    color: #92929d;
    font-size: 20px;
  }

  .checkout-widget .credential-already-in-use .actions button .label, .checkout-widget .mobile-update-widget .actions button .label, .checkout-widget .unlink-mobile-number .actions button .label, .checkout-widget .add-credit-card .actions button .label, .checkout-widget .add-comments .actions button .label, .checkout-widget .custom-coupon .actions button .label, .checkout-widget .add-delivery-address .actions button .label, .checkout-widget .update-display-name .actions button .label {
    color: var(--color-primary);
  }

  .checkout-widget .credential-already-in-use .actions button img, .checkout-widget .mobile-update-widget .actions button img, .checkout-widget .unlink-mobile-number .actions button img, .checkout-widget .add-credit-card .actions button img, .checkout-widget .add-comments .actions button img, .checkout-widget .custom-coupon .actions button img, .checkout-widget .add-delivery-address .actions button img, .checkout-widget .update-display-name .actions button img {
    margin-right: 10px;
  }

  .checkout-widget .credential-already-in-use .actions button span, .checkout-widget .mobile-update-widget .actions button span, .checkout-widget .unlink-mobile-number .actions button span, .checkout-widget .add-credit-card .actions button span, .checkout-widget .add-comments .actions button span, .checkout-widget .custom-coupon .actions button span, .checkout-widget .add-delivery-address .actions button span, .checkout-widget .update-display-name .actions button span {
    white-space: nowrap;
    color: #426ed9;
  }

  .checkout-widget .credential-already-in-use .actions a, .checkout-widget .mobile-update-widget .actions a, .checkout-widget .unlink-mobile-number .actions a, .checkout-widget .add-credit-card .actions a, .checkout-widget .add-comments .actions a, .checkout-widget .custom-coupon .actions a, .checkout-widget .add-delivery-address .actions a, .checkout-widget .update-display-name .actions a {
    color: var(--color-secondary);
    white-space: nowrap;
    margin-left: 5px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-decoration: none;
  }

  .checkout-widget .credential-already-in-use b, .checkout-widget .mobile-update-widget b, .checkout-widget .unlink-mobile-number b, .checkout-widget .add-credit-card b, .checkout-widget .add-comments b, .checkout-widget .custom-coupon b, .checkout-widget .add-delivery-address b, .checkout-widget .update-display-name b {
    color: #426ed9;
    white-space: nowrap;
    font-weight: 400;
  }

  .checkout-widget .credential-already-in-use .warning-text, .checkout-widget .mobile-update-widget .warning-text, .checkout-widget .unlink-mobile-number .warning-text, .checkout-widget .add-credit-card .warning-text, .checkout-widget .add-comments .warning-text, .checkout-widget .custom-coupon .warning-text, .checkout-widget .add-delivery-address .warning-text, .checkout-widget .update-display-name .warning-text {
    color: var(--color-secondary);
    margin-left: 4px;
    font-size: 10.5px;
  }

  .checkout-widget .custom-coupon {
    padding: unset;
    grid-template-columns: 1fr min-content;
    grid-template-areas: "input button"
                         "note note";
    display: grid;
  }

  .checkout-widget .custom-coupon form .default {
    grid-area: input;
  }

  .checkout-widget .custom-coupon input {
    border-radius: 8px 0 0 8px;
  }

  .checkout-widget .custom-coupon .apply-button {
    cursor: pointer;
    height: calc(100% - 2px);
    letter-spacing: 2px;
    text-transform: uppercase;
    background-color: #f5f5f5;
    border: 1px solid #e0e0e0;
    border-left: none;
    border-radius: 0 8px 8px 0;
    justify-content: center;
    align-items: center;
    padding: 0 22px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    display: flex;
    position: relative;
  }

  .checkout-widget .custom-coupon .note {
    text-align: left;
    letter-spacing: unset;
    margin: unset;
    text-transform: unset;
    color: #27283e;
    grid-area: note;
    margin: 10px 0;
    padding: 0 15px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .checkout-widget .custom-coupon .note .learn-more {
    color: #d67300;
    cursor: pointer;
    white-space: nowrap;
  }

  .checkout-widget .ineligible-coupon {
    background: #fef4f0;
    grid-template-columns: min-content 1fr;
    grid-template-areas: "icon description"
                         ". reset";
    gap: 15px 10px;
    margin: 5px 0;
    padding: 19px 20px 20px 19px;
    display: grid;
  }

  .checkout-widget .ineligible-coupon .error.icon {
    grid-area: icon;
  }

  .checkout-widget .ineligible-coupon .description {
    text-align: left;
    color: #27283e;
    grid-area: description;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .checkout-widget .ineligible-coupon .reset {
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: left;
    color: #b0204b;
    text-transform: uppercase;
    grid-area: reset;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
  }

  .checkout-widget .add-comments {
    padding: unset;
  }

  .checkout-widget .add-comments .actions {
    flex-direction: row;
    justify-content: flex-start;
    margin: 0;
    padding-top: 10px;
    display: flex;
  }

  .checkout-widget .add-comments .actions .cancel {
    text-align: left;
    color: #757575;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .checkout-widget .add-comments .actions .clear {
    text-align: center;
    color: #4cbba1;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .checkout-widget .add-comments .actions button {
    box-shadow: none;
    background-color: #0000;
    border: none;
  }

  .checkout-widget .add-comments .actions button span {
    color: #4cbba1;
  }

  .checkout-widget .mobile-number .name {
    padding: 5px 20px 10px;
  }

  .checkout-widget > .alert {
    margin: 5px 20px 20px;
  }

  .checkout-widget .StripeElement {
    margin-bottom: 10px;
  }

  .checkout-widget .powered-by-stripe {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
  }

  .checkout-widget .powered-by-stripe .logo {
    padding-right: 10px;
  }

  .checkout-widget .powered-by-stripe .logo img {
    width: 50px;
  }

  .checkout-widget .powered-by-stripe .description {
    text-align: left;
    color: #9e9e9e;
    padding-right: 10px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .checkout-widget .sections {
    padding: 0 20px;
    box-shadow: none !important;
    border: none !important;
    margin-bottom: 0 !important;
  }

  .checkout-widget .step-buttons {
    padding: 20px 0 5px;
  }

  .checkout-widget .step-buttons button {
    padding: 15px !important;
  }

  .checkout-widget .order-view-widget {
    margin-top: 16px;
  }

  .checkout-widget .order-view-widget .amounts {
    row-gap: 10px;
    padding: 0;
    display: grid;
  }

  .checkout-widget .order-view-widget .amounts > div {
    grid-template-rows: max-content;
    grid-template-columns: 1fr min-content;
    display: grid;
  }

  .checkout-widget .order-view-widget .amounts > div .label, .checkout-widget .order-view-widget .amounts > div .value {
    color: #757575;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .checkout-widget .order-view-widget .amounts > div:last-child {
    margin-top: 8px;
  }

  .checkout-widget .order-view-widget .amounts > div:last-child .label, .checkout-widget .order-view-widget .amounts > div:last-child .value {
    color: #27283e;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .swal2-modal {
    width: 327px !important;
    border-radius: 16px !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .swal2-modal .swal2-header {
    padding: 10px 20px;
    position: relative;
  }

  .swal2-modal .swal2-content {
    white-space: pre-wrap;
    padding: 0 20px;
  }

  .swal2-modal .swal2-content #swal2-content {
    margin-bottom: 20px;
  }

  .swal2-modal .swal2-content #swal2-content > b {
    font-weight: 700;
  }

  .swal2-modal .swal2-title {
    width: 100%;
    color: #27283e;
    justify-content: center;
    margin-top: 15px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .swal2-modal .swal2-html-container {
    color: #27283e;
    text-align: center;
    margin: 0 auto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    position: relative;
  }

  .swal2-modal .swal2-actions {
    background: #fcfcfc;
    border-radius: 0 0 16px 16px;
    flex-direction: row-reverse;
    gap: 8px;
    margin: 0;
    padding: 17px;
    display: flex;
    box-shadow: inset 0 1px #e8ecf1;
    justify-content: space-between !important;
  }

  .swal2-modal .swal2-actions button {
    letter-spacing: 2px;
    text-transform: uppercase;
    text-transform: uppercase;
    padding: 14px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: center !important;
  }

  .swal2-modal .swal2-actions .confirm-button {
    flex: 1;
    background: #4cbba1 !important;
    border-radius: 8px !important;
  }

  .swal2-modal .swal2-actions .cancel-button {
    color: #757575;
    flex: 1;
    background: #fcfcfc !important;
    border-radius: 8px !important;
    box-shadow: inset 0 0 1px 2px #e8ecf1 !important;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

@media (max-width: 767px) {
  .basic-item-widget {
    height: 100vh;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
  }

  .basic-item-widget .item-body .close-icon {
    width: 20px;
    z-index: 1;
    background: #fff;
    border-radius: 100%;
    padding: 10px;
    position: fixed;
    top: 19px;
    right: 19px;
    box-shadow: 0 2px 2px #31303e14, 0 6px 10px #31303e1a;
  }

  .basic-item-widget .item-body .close-icon.no-image {
    background: #eee;
    top: 52px;
  }

  .basic-item-widget .item-footer {
    padding-top: 18px;
  }

  .basic-item-widget .item-name {
    color: #27283e;
    background: #fff;
    padding: 25px 65px 0 20px;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }

  .basic-item-widget .item-name.no-image {
    margin-top: 35px;
  }

  .basic-item-widget.no-image-and-modifier {
    height: unset;
  }

  .basic-item-widget.no-image-and-modifier .item-body .close-icon {
    position: absolute;
    top: 17px;
  }

  .basic-item-widget.no-image-and-modifier .item-body .item-name {
    margin-top: 0;
  }

  .basic-item-widget.no-image-and-modifier .add-to-cart-widget {
    padding: 32px 20px !important;
  }

  .basic-item-widget .item-sold-out, .basic-item-widget .item-unavailable {
    color: #27283e;
    width: -moz-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background: #e0e0e0;
    border-radius: 4px;
    margin: 25px 65px 0 18px;
    padding: 2px 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .basic-item-widget .item-label {
    color: #27283e;
    background-color: #ffebcf;
    border-radius: 4px;
    margin: 20px 0 0 20px;
    padding: 3px 6px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    display: inline-block;
  }

  .basic-item-widget .item-name {
    color: #27283e;
    background: #fff;
    padding: 25px 65px 0 20px;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }

  .basic-item-widget .item-name.no-image {
    margin-top: 35px;
  }

  .basic-item-widget .item-details {
    background: #fff;
    padding: 10px 20px;
    font-style: normal;
  }

  .basic-item-widget .item-details * {
    margin: 0;
  }

  .basic-item-widget .item-details .item-description {
    color: #757575;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .basic-item-widget .item-details .item-price-and-dietary {
    flex-direction: row;
    align-items: baseline;
    padding-top: 15px;
    display: flex;
  }

  .basic-item-widget .item-details .item-price-and-dietary .item-price {
    color: #27283e;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .basic-item-widget .item-details .item-price-and-dietary .item-dietary {
    height: min-content;
    color: #757575;
    grid-column-start: 3;
    justify-self: flex-end;
    margin-left: auto;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .basic-item-widget .item-details .item-available-weekly-hours-widget {
    margin-top: 18px;
  }

  .basic-item-widget .modifiers {
    grid-gap: 35px;
    margin: 4px 0 32px;
  }

  .basic-item-widget .actions .add-to-cart-widget {
    background: #eff8f9;
    padding: 32px 20px 122px;
  }

  .basic-item-widget .spinner-container {
    box-sizing: border-box;
    height: 51px;
    background: #ffebcf;
    border: 1px solid #f8bd79;
    border-radius: 8px;
    flex-direction: row;
    gap: 10px;
    padding: 14px;
    display: flex;
    position: absolute;
    top: 42px;
    left: calc(50% - 140px);
  }

  .basic-item-widget .spinner-container .message {
    text-align: center;
    color: #d67300;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .basic-item-widget .spinner-container .spinner {
    animation: 2s linear infinite spin;
  }

  .add-to-cart-widget {
    grid-template-columns: 100%;
    display: grid;
  }

  .add-to-cart-widget .quantity {
    color: #27283e;
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 14px 13px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
  }

  .add-to-cart-widget .quantity .quantity-actions {
    gap: 18px;
    display: flex;
  }

  .add-to-cart-widget .quantity .quantity-actions .inc-icon, .add-to-cart-widget .quantity .quantity-actions .dec-icon {
    width: 24px;
    text-align: center;
    color: #3a3a4e;
    background: #eee;
    border-radius: 50%;
  }

  .add-to-cart-widget .quantity .quantity-actions .number {
    grid-area: number;
  }

  .add-to-cart-widget .total-price {
    color: #27283e;
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    justify-content: space-between;
    margin-bottom: 30px;
    padding: 14px 13px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
  }

  .add-to-cart-widget .add-to-cart-button {
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #fff;
    border-radius: 8px;
    padding: 14px 0;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    background: #4cbba1 !important;
  }

  .add-to-cart-widget .add-to-cart-button.disabled {
    opacity: .5;
    pointer-events: none;
    color: #fff !important;
  }

  .add-to-cart-widget .add-to-cart-button span {
    margin-left: .25em;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@media (max-width: 767px) {
  .session-create-modal > .header {
    z-index: 99;
    background: #fff;
    border-bottom: 1px solid #e0e0e0;
    justify-content: space-between;
    padding: 20px 20px 17px;
    display: flex;
    position: sticky;
    top: 0;
  }

  .session-create-modal > .header .title {
    color: #27283e;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

@media (max-width: 767px) and (min-width: 768px) {
  .session-create-modal > .header .title {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (max-width: 767px) {
  .session-create-modal > .header .close-icon {
    width: 16px;
  }

  .session-create-modal > .header .title {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

@media (max-width: 767px) and (min-width: 768px) {
  .session-create-modal > .header .title {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (max-width: 767px) {
  .session-create-modal .main-display {
    padding-top: 30px;
  }

  .session-create-modal .main-display .collection-type-selector {
    margin-bottom: 72px;
    padding: 0 20px;
  }

  .session-create-modal .main-display .collection-type-selector .title {
    color: #27283e;
    margin-bottom: 17px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .session-create-modal .main-display .collection-type-selector .collection-types {
    gap: 16px;
    display: grid;
  }

  .session-create-modal .main-display .collection-type-selector .collection-types .collection-type {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    grid-template-areas: "collection-type-icon text . caret-right-icon";
    grid-auto-columns: min-content min-content 1fr min-content;
    grid-auto-flow: column;
    align-items: center;
    gap: 15px;
    padding: 12px;
    display: grid;
  }

  .session-create-modal .main-display .collection-type-selector .collection-types .collection-type .collection-type-icon {
    height: 38px;
    width: 38px;
    grid-area: collection-type-icon;
  }

  .session-create-modal .main-display .collection-type-selector .collection-types .collection-type .text {
    color: #27283e;
    white-space: nowrap;
    grid-area: text;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
  }

  .session-create-modal .main-display .collection-type-selector .collection-types .collection-type .caret-right.icon {
    grid-area: caret-right-icon;
  }

  .session-create-modal .main-display .table-selector {
    grid-template-columns: 1fr max-content;
    grid-template-areas: "title image"
                         "table-list table-list";
    margin-bottom: 72px;
    padding: 0 20px;
    display: grid;
  }

  .session-create-modal .main-display .table-selector .title {
    color: #27283e;
    grid-area: title;
    align-self: center;
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    display: flex;
  }

  .session-create-modal .main-display .table-selector .image {
    width: 38px;
    grid-area: image;
    margin-bottom: 15px;
  }

  .session-create-modal .main-display .table-selector .title {
    color: #27283e;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .session-create-modal .main-display .table-selector .table-list {
    grid-gap: 15px;
    grid-area: table-list;
    display: grid;
  }

  .session-create-modal .main-display .table-selector .table-list .table {
    color: #27283e;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    grid-template-areas: "name caret-right-icon";
    align-items: center;
    padding: 14px 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: grid;
  }

  .session-create-modal .main-display .table-selector .table-list .table .name {
    grid-area: name;
    margin-right: 15px;
  }

  .session-create-modal .main-display .table-selector .table-list .table .caret-right.icon {
    grid-area: caret-right-icon;
    margin-left: auto;
  }

  .session-create-modal .main-display .collection-date-and-time-selector {
    grid-template-columns: 1fr max-content;
    grid-template-areas: "title image"
                         "holiday-info holiday-info"
                         "date-list date-list";
    margin-bottom: 72px;
    padding: 0 20px;
    display: grid;
  }

  .session-create-modal .main-display .collection-date-and-time-selector .title {
    color: #27283e;
    grid-area: title;
    align-self: center;
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    display: flex;
  }

  .session-create-modal .main-display .collection-date-and-time-selector .image {
    width: 38px;
    grid-area: image;
    margin-bottom: 15px;
  }

  .session-create-modal .main-display .collection-date-and-time-selector .title {
    color: #27283e;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .session-create-modal .main-display .collection-date-and-time-selector .third-party-delivery-banner {
    grid-gap: 8px;
    width: 100%;
    box-sizing: border-box;
    background: #ffebcf;
    border-radius: 4px;
    flex-flow: row;
    margin: 13px 0 8px;
    padding: 16px 15px 16px 16px;
    display: flex;
  }

  .session-create-modal .main-display .collection-date-and-time-selector .third-party-delivery-banner .message {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .session-create-modal .main-display .collection-date-and-time-selector .third-party-delivery-banner .message > div {
    color: #d67300;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    display: inline;
  }

  .session-create-modal .main-display .collection-date-and-time-selector .collection-dates-and-times {
    grid-area: date-list;
  }

  .session-create-modal .main-display .collection-date-and-time-selector .holiday-info {
    color: #27283e;
    background: #ffebcf;
    border-radius: 4px;
    grid-area: holiday-info;
    margin: 12px 0 16px;
    padding: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .session-create-modal .main-display .action-buttons {
    grid-gap: 17px;
    box-sizing: border-box;
    width: 100%;
    background: #fafafa;
    border-top: 1px solid #e0e0e0;
    padding: 20px;
    display: grid;
  }

  .session-create-modal .main-display .action-buttons .start-order {
    color: #fff;
    width: 100%;
    letter-spacing: 2px;
    text-transform: uppercase;
    background: #4cbba1;
    padding: 14px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .session-create-modal .main-display .action-buttons .start-order.disabled {
    opacity: .5;
    color: #fff !important;
    border-color: unset !important;
    background: #4cbba1 !important;
  }

  .session-create-modal .section .get-order-message {
    padding-top: 30px;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@media (max-width: 767px) {
  .customer-login-flow-widget {
    height: 100%;
    grid-template-rows: min-content 1fr;
    display: grid;
    position: relative;
  }

  .customer-login-flow-widget .top-header {
    grid-template-areas: "back-button step-indicator close-button";
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px 17px;
    display: grid;
  }

  .customer-login-flow-widget .top-header .back-button {
    grid-area: back-button;
  }

  .customer-login-flow-widget .top-header .close-button {
    grid-area: close-button;
  }

  .customer-login-flow-widget .top-header .close-button img {
    height: 18px;
  }

  .customer-login-flow-widget .top-header .step-indicator {
    text-align: left;
    color: #757575;
    grid-area: step-indicator;
    margin: 0 auto;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    top: 10px;
  }

  .customer-login-flow-widget .body {
    height: 100%;
    padding: 0 20px;
  }

  .customer-login-flow-widget .body .intro {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 40px;
    display: flex;
  }

  .customer-login-flow-widget .body .intro .title {
    text-align: center;
    color: #27283e;
    margin-top: 6px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .customer-login-flow-widget .body .intro .subtitle {
    text-align: center;
    color: #757575;
    margin-top: 5px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .customer-login-flow-widget button {
    width: 100%;
    letter-spacing: 2px;
    text-transform: uppercase;
    border-radius: 8px;
    margin-bottom: 16px;
    padding: 14px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .customer-login-flow-widget button span {
    color: #fff;
  }

  .customer-login-flow-widget button.disabled {
    opacity: .5;
    cursor: not-allowed;
    background-color: #4cbba1 !important;
  }

  .customer-login-flow-widget .form-buttons {
    margin-top: 25px;
  }

  .customer-login-flow-widget .grecaptcha-badge {
    visibility: hidden;
  }

  .customer-login-flow-widget #recaptcha-wrapper {
    height: 75vh;
    bottom: 0;
    left: 50%;
    overflow: auto;
    transform: translateX(-50%);
    position: fixed !important;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@media (max-width: 767px) {
  .deal-item .modifier-widget .header {
    background-color: #0000;
  }

  .deal-item .modifier-widget .option {
    padding: 0 20px;
  }

  .modifier-widget {
    color: #27283e;
  }

  .modifier-widget input[type="checkbox"] {
    display: none;
  }

  .modifier-widget input[type="checkbox"] + label {
    color: #27283e;
    margin-left: 25px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    position: relative;
  }

  .modifier-widget input[type="checkbox"] + label:before {
    content: "";
    width: 16px;
    height: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    position: absolute;
    top: 0;
    left: -28px;
  }

  .modifier-widget input[type="checkbox"]:checked + label:before {
    background: #4cbba1 url("icon-check-light.3e3cc582.svg") center no-repeat;
    border: 1px solid #4cbba1;
  }

  .modifier-widget input[type="radio"] {
    display: none;
  }

  .modifier-widget input[type="radio"] + label {
    color: #27283e;
    margin-left: 25px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    position: relative;
  }

  .modifier-widget input[type="radio"] + label:before {
    content: "";
    width: 15px;
    height: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: -28px;
  }

  .modifier-widget input[type="radio"]:checked + label:before {
    background: #4cbba1;
    border: 1px solid #4cbba1;
  }

  .modifier-widget .header {
    background-color: #fafafa;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
    padding: 16px 20px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    display: flex;
  }

  .modifier-widget .header .title {
    justify-content: space-between;
    margin-bottom: 3px;
    display: flex;
  }

  .modifier-widget .header .title .modifier-name {
    margin-right: 8px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .modifier-widget .header .title .requiredness {
    color: #757575;
    justify-content: center;
    align-items: center;
    padding: 3px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    display: flex;
  }

  .modifier-widget .header .title .selected:before {
    content: url("green-check.23f410e7.svg");
    padding-right: 5px;
  }

  .modifier-widget .header .title .required {
    color: #086e37;
  }

  .modifier-widget .header .title .error {
    color: #b0204b;
    background-color: #ffe7df;
  }

  .modifier-widget .header .max-quantity {
    color: #757575;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .modifier-widget .options {
    grid-gap: 8px;
    padding: 16px 20px 20px;
    display: grid;
  }

  .modifier-widget .options .option {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    grid-template-columns: 1fr min-content min-content;
    padding: 14px 8px 14px 14px;
    display: grid;
  }

  .modifier-widget .options .option .option-form-input {
    width: 100%;
  }

  .modifier-widget .options .option .wrapper {
    grid-gap: 16px;
    display: flex;
  }

  .modifier-widget .options .option .wrapper .quantity {
    height: -moz-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    grid-gap: 10px;
    align-self: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
  }

  .modifier-widget .options .option .wrapper .quantity .inc-icon, .modifier-widget .options .option .wrapper .quantity .dec-icon {
    width: 24px;
    text-align: center;
    color: #3a3a4e;
    background: #eee;
    border-radius: 50%;
  }

  .modifier-widget .options .option .wrapper .extra-charge {
    white-space: nowrap;
    color: #757575;
    align-self: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
  }

  .modifier-widget .options .option.selected {
    border: 1px solid #4cbba1;
  }

  .loyalty-card-widget {
    width: 100%;
    aspect-ratio: 4 / 2.5;
    background-color: #9191a1;
    border-radius: 10px;
    position: relative;
    box-shadow: 1px 3px 4px 1px #00000040;
  }

  .loyalty-card-widget .background img, .loyalty-card-widget .background .optimized-image img {
    width: 100%;
    border-radius: 10px;
    position: absolute;
    top: 0;
  }

  .loyalty-card-widget .details {
    width: calc(100% - 53px);
    height: 100%;
    grid-template-areas: "balance emv-chip"
                         "count-progress count-progress"
                         "customer-info customer-info";
    padding: 0 27px;
    display: grid;
    position: absolute;
    top: 0;
  }

  .loyalty-card-widget .details .balance {
    height: inherit;
    color: #fff;
    text-shadow: 0 1px 1px #000c;
    letter-spacing: 2px;
    grid-area: balance;
    align-items: end;
    font-family: larabie;
    font-size: 35px;
    font-style: normal;
    font-weight: 500;
    display: flex;
  }

  .loyalty-card-widget .details .mock-emv-chip {
    grid-area: emv-chip;
    justify-content: end;
    align-items: end;
    display: flex;
  }

  .loyalty-card-widget .details .mock-emv-chip img {
    width: 32px;
  }

  .loyalty-card-widget .details .count-progress {
    grid-area: count-progress;
    grid-auto-flow: column;
    align-items: center;
    column-gap: 5px;
    display: grid;
  }

  .loyalty-card-widget .details .count-progress .count {
    height: 10px;
    background-color: #e0e0e0;
    border-radius: 10px;
    box-shadow: inset 0 2px 4px #0000004a;
  }

  .loyalty-card-widget .details .count-progress .count.active {
    background-color: #33d169;
    border: 1px solid #00000036;
    box-shadow: inset 0 -2px 4px #0003, inset 0 -3px 3px #00000080;
  }

  .loyalty-card-widget .details .customer-info {
    color: #fff;
    text-shadow: 1px 1px 1px #000;
    text-transform: uppercase;
    letter-spacing: 2px;
    grid-area: customer-info;
    margin-left: 3px;
    font-family: kredit;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }

  .loyalty-card-widget .details .customer-info .name {
    -webkit-line-clamp: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

@media (max-width: 767px) {
  .pizza-widget {
    height: 100vh;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
  }

  .pizza-widget .item-body .close-icon {
    width: 20px;
    z-index: 1;
    background: #fff;
    border-radius: 100%;
    padding: 10px;
    position: fixed;
    top: 19px;
    right: 19px;
    box-shadow: 0 2px 2px #31303e14, 0 6px 10px #31303e1a;
  }

  .pizza-widget .item-body .close-icon.no-image {
    background: #eee;
    top: 52px;
  }

  .pizza-widget .item-footer {
    padding-top: 18px;
  }

  .pizza-widget .item-name {
    color: #27283e;
    background: #fff;
    padding: 25px 65px 0 20px;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }

  .pizza-widget .item-name.no-image {
    margin-top: 35px;
  }

  .pizza-widget.no-image-and-modifier {
    height: unset;
  }

  .pizza-widget.no-image-and-modifier .item-body .close-icon {
    position: absolute;
    top: 17px;
  }

  .pizza-widget.no-image-and-modifier .item-body .item-name {
    margin-top: 0;
  }

  .pizza-widget.no-image-and-modifier .add-to-cart-widget {
    padding: 32px 20px !important;
  }

  .pizza-widget .item-sold-out, .pizza-widget .item-unavailable {
    color: #27283e;
    width: -moz-fit-content;
    width: fit-content;
    background: #e0e0e0;
    border-radius: 4px;
    margin: 25px 65px 0 18px;
    padding: 2px 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .pizza-widget .item-label {
    color: #27283e;
    background-color: #ffebcf;
    border-radius: 4px;
    margin: 20px 0 0 20px;
    padding: 3px 6px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    display: inline-block;
  }

  .pizza-widget .item-name {
    color: #27283e;
    background: #fff;
    padding: 25px 65px 0 20px;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }

  .pizza-widget .item-name.no-image {
    margin-top: 35px;
  }

  .pizza-widget .item-details {
    background: #fff;
    padding: 10px 20px;
    font-style: normal;
  }

  .pizza-widget .item-details * {
    margin: 0;
  }

  .pizza-widget .item-details .item-description {
    color: #757575;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .pizza-widget .item-details .item-price-and-dietary {
    flex-direction: row;
    align-items: baseline;
    padding-top: 15px;
    display: flex;
  }

  .pizza-widget .item-details .item-price-and-dietary .item-price {
    color: #27283e;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .pizza-widget .item-details .item-price-and-dietary .item-dietary {
    height: min-content;
    color: #757575;
    grid-column-start: 3;
    justify-self: flex-end;
    margin-left: auto;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .pizza-widget .item-details .item-available-weekly-hours-widget {
    margin-top: 18px;
  }

  .pizza-widget .pizza {
    padding-top: 18px;
  }

  .pizza-widget .modifiers {
    grid-gap: 35px;
    margin-bottom: 32px;
  }

  .pizza-widget .actions .add-to-cart-widget {
    background: #eff8f9;
    padding: 32px 20px 122px;
  }

  .pizza-widget .spinner-container {
    box-sizing: border-box;
    height: 51px;
    background: #ffebcf;
    border: 1px solid #f8bd79;
    border-radius: 8px;
    flex-direction: row;
    gap: 10px;
    padding: 14px;
    display: flex;
    position: absolute;
    top: 42px;
    left: calc(50% - 140px);
  }

  .pizza-widget .spinner-container .message {
    text-align: center;
    color: #d67300;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .pizza-widget .spinner-container .spinner {
    animation: 2s linear infinite spin;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@media (max-width: 767px) {
  .pizza-customize-widget {
    height: 100%;
    flex-flow: column;
    display: flex;
  }

  .pizza-customize-widget .item-header {
    z-index: 99;
    background: #fff;
    border-bottom: 1px solid #e0e0e0;
    justify-content: space-between;
    padding: 20px 20px 17px;
    display: flex;
    position: sticky;
    top: 0;
  }

  .pizza-customize-widget .item-header .title {
    color: #27283e;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

@media (max-width: 767px) and (min-width: 768px) {
  .pizza-customize-widget .item-header .title {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (max-width: 767px) {
  .pizza-customize-widget .item-header .done-button {
    color: #4cbba1;
    padding-top: 5px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .pizza-customize-widget .item-body {
    margin-bottom: 26px;
    overflow: scroll;
  }

  .pizza-customize-widget .item-body .modifiers {
    grid-gap: 36px;
    flex-flow: column;
    margin-bottom: 8px;
    padding-top: 21px;
    display: flex;
    overflow: auto;
  }

  .pizza-customize-widget .item-body .modifiers .remove-toppings .extra-charge {
    display: none;
  }

  .restaurant-header-widget, .store-header-widget {
    flex-direction: horizontal;
    width: 80%;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  .restaurant-header-widget .image, .store-header-widget .image {
    width: 33px;
    border: 3px solid #f1f1f5;
    border-radius: 10px;
    margin-right: 10px;
    overflow: hidden;
  }

  .restaurant-header-widget .name, .store-header-widget .name {
    text-align: left;
    color: #27283e;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    overflow: hidden;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@media (max-width: 767px) {
  .suburbs-list-widget {
    background: #fff;
    grid-template-columns: 1fr max-content;
    grid-template-areas: "title image"
                         "suburb-search suburb-search"
                         "suburb-list suburb-list";
    margin-bottom: 72px;
    padding: 0 20px;
    display: grid;
  }

  .suburbs-list-widget .title {
    color: #27283e;
    grid-area: title;
    align-self: center;
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    display: flex;
  }

  .suburbs-list-widget .image {
    width: 38px;
    grid-area: image;
    margin-bottom: 15px;
  }

  .suburbs-list-widget .title {
    color: #27283e;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .suburbs-list-widget .suburb-search {
    grid-area: suburb-search;
    position: relative;
  }

  .suburbs-list-widget .suburb-search .search-icon {
    z-index: 1;
    opacity: .55;
    position: absolute;
    top: 15px;
    left: 15px;
  }

  .suburbs-list-widget .suburb-search input {
    width: -webkit-fill-available;
    color: #27283e;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    outline: none;
    margin-bottom: 8px;
    padding: 15px 42px;
    font-family: Chivo;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .suburbs-list-widget .suburbs {
    grid-area: suburb-list;
    gap: 8px;
    display: grid;
  }

  .suburbs-list-widget .suburbs .no-suburb-found {
    color: #757575;
    text-align: center;
    margin-top: 30px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .suburbs-list-widget .suburbs .suburb {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    grid-template-columns: max-content min-content 1fr min-content min-content;
    grid-template-areas: "name postcode . delivery-charge caret-right-icon";
    align-items: center;
    padding: 14px 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: grid;
  }

  .suburbs-list-widget .suburbs .suburb .name {
    grid-area: name;
  }

  .suburbs-list-widget .suburbs .suburb .postcode {
    color: #757575;
    grid-area: postcode;
    margin-left: 8px;
  }

  .suburbs-list-widget .suburbs .suburb .delivery-charge {
    color: #757575;
    white-space: nowrap;
    grid-area: delivery-charge;
    margin-right: 8px;
  }

  .suburbs-list-widget .suburbs .suburb .caret-right.icon {
    grid-area: caret-right-icon;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@media (max-width: 767px) {
  .store-search-page .search-page .search-box {
    box-sizing: border-box;
    background: #fff;
    border: 1px solid #bdbdbd;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    margin: 18px 20px;
    padding: 10px 16px;
    line-height: 22px;
    display: flex;
  }

  .store-search-page .search-page .search-box .inputbox {
    width: 110%;
    letter-spacing: .1px;
    border: none;
    outline: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .store-search-page .search-page .empty-results {
    text-align: center;
    margin-top: 15vh;
  }

  .store-search-page .search-page .empty-results .logo-search {
    height: 60px;
    width: 60px;
    margin-bottom: 10px;
  }

  .store-search-page .search-page .empty-results .text {
    color: #757575;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .store-search-page .search-page .no-results {
    text-align: center;
    margin-top: 15vh;
  }

  .store-search-page .search-page .no-results > .logo-no-results {
    margin-bottom: 10px;
  }

  .store-search-page .search-page .no-results > .text {
    color: #7d7d7d;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .store-search-page .search-page .no-results > .text span {
    margin-left: .25em;
    font-weight: bold;
  }

  .store-search-page .search-page .search-results .search-results-message {
    text-align: center;
    color: #9e9e9e;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .store-search-page .search-page .search-results .category-summary {
    grid-gap: 10px;
    flex-flow: column;
    padding: 25px 0;
    display: flex;
  }

  .store-search-page .search-page .search-results .category-summary .link-anchor {
    width: 100%;
    grid-area: link-anchor;
    position: relative;
    top: -170px;
  }

  .store-search-page .search-page .search-results .category-summary .name {
    text-align: left;
    word-break: break-word;
    color: #27283e;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

@media (max-width: 767px) and (min-width: 768px) {
  .store-search-page .search-page .search-results .category-summary .name {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (max-width: 767px) {
  .store-search-page .search-page .search-results .category-summary .description {
    display: none;
  }

  .store-search-page .search-page .search-results > .items {
    width: 89.5%;
    grid-area: items;
    margin: 0 auto;
  }

  .store-search-page .search-page .search-results > .items .header-mobile {
    text-align: center;
    color: #9e9e9e;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .store-search-page .search-page .search-results > .items .items-per-category:last-of-type {
    margin-bottom: 30px;
  }

  .store-search-page .search-page .search-results .items-container {
    gap: 20px;
    display: grid;
  }

  .store-search-page .search-page .search-results .items-container .item.no-image {
    height: auto;
    padding-top: 0;
  }

  .store-search-page .search-page .search-results .items-container .item.no-image .body {
    position: relative;
  }

  .store-search-page .search-page .search-results .items-container .item {
    height: auto;
    box-sizing: border-box;
    height: 0;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding-top: 43.75%;
    position: relative;
    overflow: hidden;
    box-shadow: 0 2px 4px #0000001a;
  }

  .store-search-page .search-page .search-results .items-container .item .anchor {
    position: absolute;
    top: -120px;
  }

  .store-search-page .search-page .search-results .items-container .item .body {
    width: 100%;
    height: 100%;
    flex-flow: row;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
  }

  .store-search-page .search-page .search-results .items-container .item .body.no-image {
    grid-template-columns: 1fr;
  }

  .store-search-page .search-page .search-results .items-container .item .body.no-image .item-info > .description {
    -webkit-line-clamp: unset !important;
  }

  .store-search-page .search-page .search-results .items-container .item .body .image {
    height: auto;
    flex: 1;
  }

  .store-search-page .search-page .search-results .items-container .item .body .image img {
    object-fit: cover;
  }

  .store-search-page .search-page .search-results .items-container .item .body .item-info {
    flex-direction: column;
    flex: 1.55;
    padding: 15px;
    display: flex;
  }

  .store-search-page .search-page .search-results .items-container .item .body .item-info .name {
    -webkit-line-clamp: 2;
    text-align: left;
    word-wrap: break-word;
    color: #27283e;
    -webkit-box-orient: vertical;
    flex-shrink: 0;
    align-self: start;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    display: -webkit-box;
    overflow: hidden;
  }

  .store-search-page .search-page .search-results .items-container .item .body .item-info .description {
    color: #757575;
    -webkit-box-orient: vertical;
    flex-shrink: 1;
    align-self: start;
    margin-bottom: 17.6px;
    display: -webkit-box;
    overflow: hidden;
  }

  .store-search-page .search-page .search-results .items-container .item .body .item-info .description.line-clamp-1 {
    -webkit-line-clamp: 1;
  }

  .store-search-page .search-page .search-results .items-container .item .body .item-info .description.line-clamp-2 {
    -webkit-line-clamp: 2;
  }

  .store-search-page .search-page .search-results .items-container .item .body .item-info .price-and-sold-out {
    flex-direction: row;
    align-items: center;
    margin-top: auto;
    display: flex;
  }

  .store-search-page .search-page .search-results .items-container .item .body .item-info .price-and-sold-out .price {
    text-align: left;
    color: #27283e;
    flex-shrink: 0;
    margin-top: auto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .store-search-page .search-page .search-results .items-container .item .body .item-info .price-and-sold-out .soldout, .store-search-page .search-page .search-results .items-container .item .body .item-info .price-and-sold-out .unavailable {
    color: #27283e;
    background: #e0e0e0;
    border-radius: 4px;
    margin-left: 8px;
    padding: 3px 6px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .store-search-page .search-page .search-results .items-container .item .body .item-info.no-image {
    display: unset;
    flex: unset;
  }

  .store-search-page .search-page .search-results .items-container .item .action {
    background-color: #fafafb;
    border-top: 1px solid #f1f1f5;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    flex-direction: row;
    grid-area: e;
    justify-content: start;
    align-items: start;
    padding: 12px 18px;
    display: flex;
  }

  .store-search-page .search-page .search-results .items-container .item .action img {
    margin-top: 5px;
    margin-right: 10px;
  }

  .store-search-page .search-page .search-results .items-container .item .action span {
    color: #426ed9;
    white-space: nowrap;
    font-family: Fira Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .footer-widget {
    display: none;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@media (max-width: 767px) {
  .top-nav-bar-widget {
    z-index: 100;
    width: 100%;
    background: linear-gradient(178.96deg, #ececed .92%, #fff 112.41%);
    position: sticky;
    top: 0;
    box-shadow: inset 0 -1px #e8ecf1;
  }

  .top-nav-bar-widget .wrapper {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    display: flex;
  }

  .top-nav-bar-widget .wrapper .actions {
    align-items: center;
    gap: 16px;
    display: flex;
  }

  .top-nav-bar-widget .wrapper .actions .search-icon, .top-nav-bar-widget .wrapper .actions .icon, .top-nav-bar-widget .wrapper .actions svg {
    display: block;
  }

  .top-nav-bar-widget .wrapper .back-button, .top-nav-bar-widget .wrapper .store-header-widget .image, .top-nav-bar-widget .wrapper .menu, .top-nav-bar-widget .wrapper .actions .cart {
    display: none;
  }

  .top-nav-bar-widget .wrapper .actions .sidebar-menu-button svg path {
    stroke: #27283e;
  }

  .top-nav-bar-widget.store-closed, .top-nav-bar-widget.platform-maintenance {
    top: 60px;
  }

  .online-ordering header.table-booking-history-page {
    position: fixed !important;
  }

  .online-ordering header.on-top {
    opacity: 0;
    transition: visibility 0s linear .2s, opacity .2s linear;
    display: none;
  }

  .online-ordering #top-of-site-pixel-anchor {
    width: 1px;
    height: 1px;
    position: absolute;
    top: 100px;
    left: 0;
  }

  .table-booking-history-page.page > .header {
    box-shadow: none;
    padding: 0;
  }

  .table-booking-history-page .header {
    margin-top: 0;
    padding: 0;
  }

  .table-booking-history-page .header.on-top {
    opacity: 0;
    transition: visibility 0s linear .2s, opacity .2s linear;
    display: none;
  }

  .table-booking-history-page .header .wrapper {
    position: relative;
  }

  .table-booking-history-page .header .wrapper .nav {
    width: 100%;
    z-index: 1;
    box-sizing: border-box;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    display: flex;
    position: absolute;
  }

  .table-booking-history-page .header .wrapper .nav .actions {
    align-items: center;
    gap: 16px;
    display: flex;
  }

  .table-booking-history-page .header .wrapper .nav .actions .search-icon, .table-booking-history-page .header .wrapper .nav .actions .icon, .table-booking-history-page .header .wrapper .nav .actions svg {
    display: block;
  }

  .table-booking-history-page .header .wrapper .nav .page-title {
    color: var(--bg);
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .table-booking-history-page .header .wrapper .banner-image {
    width: 100vw;
    position: relative;
  }

  .table-booking-history-page .header .wrapper .banner-image .desktop {
    display: none;
  }

  .table-booking-history-page .header .wrapper .store-title {
    box-sizing: border-box;
    color: var(--bg);
    width: 100%;
    background-blend-mode: multiply;
    background: linear-gradient(#2a2a3100 11.39%, #2a2a311a 24.94%, #2a2a31cc 100%);
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px;
    display: flex;
    position: absolute;
    bottom: 0;
  }

  .table-booking-history-page .header .wrapper .store-title .store-name {
    margin: 4px 0;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
  }

  .table-booking-history-page .header .wrapper .store-title .store-location {
    width: 100%;
    text-align: left;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    display: flex;
  }

  .table-booking-history-page .header .wrapper .store-title .store-location .address {
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0 9px;
    overflow: hidden;
  }

  .table-booking-history-page .header .wrapper .store-title .store-location .icon {
    width: 17px;
  }

  .table-booking-history-page .header .wrapper #page-title-anchor {
    width: 1px;
    height: 1px;
    position: absolute;
    left: 0;
  }

  .table-booking-history-page .header .wrapper #page-title-anchor.open {
    top: 375px;
  }

  .table-booking-history-page .header .wrapper #page-title-anchor.closed {
    top: 360px;
  }

  .table-booking-history-page .wrapper .login {
    margin: 24px 24px 125px;
    padding: 0 20px;
  }

  .table-booking-history-page .wrapper .login .text {
    text-align: center;
    color: #757575;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .table-booking-history-page .wrapper .login .text a {
    color: #1faa89;
    text-decoration: none;
  }

  .table-booking-history-page .wrapper .bottom-spacer {
    margin-bottom: 135px;
  }

  .table-booking-history-page .wrapper .desktop {
    display: none;
  }

  .table-booking-history-page .wrapper .banner {
    padding: 0;
  }

  .table-booking-history-page .wrapper .banner .table-booking-title {
    color: #27283e;
    background: #fff;
    padding: 15px 20px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    display: block;
    box-shadow: inset 0 -1px #e8ecf1;
  }

  .table-booking-history-page .wrapper .banner .table-booking-title.hide {
    display: none;
  }

  .table-booking-history-page .wrapper .banner .not-available-banner {
    background: #ffe7df;
    padding: 15.5px 20px;
  }

  .table-booking-history-page .wrapper .banner .not-available-banner .message {
    color: #b0204b;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .table-booking-history-page .wrapper .banner .not-available-banner .message b {
    font-weight: 600;
  }

  .table-booking-history-page .wrapper .banner .book-table-banner {
    background: #ffebcf;
    place-content: center space-between;
    align-items: center;
    padding: 16px 20px;
    display: flex;
  }

  .table-booking-history-page .wrapper .banner .book-table-banner .message {
    color: #d67300;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .table-booking-history-page .wrapper .banner .book-table-banner button {
    letter-spacing: 2px;
    text-transform: uppercase;
    text-transform: uppercase;
    color: #d67300;
    box-sizing: border-box;
    background: #ffebcf;
    border: 1.5px solid #d67300;
    border-radius: 4px;
    padding: 7.5px 16px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .table-booking-history-page .wrapper .banner.hide {
    display: none;
  }

  .table-booking-history-page .wrapper .empty-table-booking {
    text-align: center;
    padding: 42px 62.5px 40px;
  }

  .table-booking-history-page .wrapper .empty-table-booking .icon {
    margin: 0 auto 29px;
  }

  .table-booking-history-page .wrapper .empty-table-booking .icon-desktop {
    display: none;
  }

  .table-booking-history-page .wrapper .empty-table-booking .message {
    text-align: center;
    color: #757575;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .table-booking-history-page .wrapper .empty-table-booking .message:after {
    content: "You don’t have any table bookings yet";
  }

  .table-booking-history-page .wrapper .table-bookings {
    margin: 30px 20px;
  }

  .table-booking-history-page .wrapper .table-bookings .section {
    margin-bottom: 24px;
  }

  .table-booking-history-page .wrapper .table-bookings .section.hide {
    display: none;
  }

  .table-booking-history-page .wrapper .table-bookings .section .heading {
    color: #27283e;
    margin-bottom: 24px;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

@media (max-width: 767px) and (min-width: 768px) {
  .table-booking-history-page .wrapper .table-bookings .section .heading {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (max-width: 767px) {
  .table-booking-history-page .wrapper .table-bookings .section .table-booking {
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    grid-template: "booked-for status-icon" max-content
                   "customer customer"
                   "details details"
                   "hr hr"
                   "status status"
                   / 1fr min-content;
    margin-bottom: 24px;
    padding: 15px 16px 20px;
    display: grid;
    box-shadow: 0 2px 4px #0000001a;
  }

  .table-booking-history-page .wrapper .table-bookings .section .table-booking .bookedFor {
    color: #27283e;
    grid-area: booked-for;
    margin-bottom: 7px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .table-booking-history-page .wrapper .table-bookings .section .table-booking .status-icon {
    grid-area: status-icon;
    margin-bottom: 7px;
  }

  .table-booking-history-page .wrapper .table-bookings .section .table-booking .customer {
    color: #27283e;
    grid-area: customer;
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .table-booking-history-page .wrapper .table-bookings .section .table-booking .details {
    color: #27283e;
    grid-area: details;
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .table-booking-history-page .wrapper .table-bookings .section .table-booking hr {
    width: 100%;
    border: 1px solid #e0e0e0;
    grid-area: hr;
    margin: 0 0 16px;
  }

  .table-booking-history-page .wrapper .table-bookings .section .table-booking .status {
    color: #9e9e9e;
    grid-area: status;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .table-booking-history-page .wrapper .table-bookings .section .table-booking .status b {
    font-weight: 700;
  }

  .table-booking-history-page .wrapper .table-bookings .section .table-booking .status .success {
    color: #086e37;
  }

  .table-booking-history-page .wrapper .table-bookings .section .table-booking .status .warning {
    color: #d67300;
  }

  .table-booking-history-page .wrapper .table-bookings .section .table-booking .status .danger {
    color: #b0204b;
  }

  .table-booking-history-page .wrapper .table-bookings .section .table-booking:last-child {
    margin-bottom: 31px;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@media (max-width: 767px) {
  .table-booking-page {
    background: #fff;
  }

  .table-booking-page form .form-single-select-pill.optional > .label:after {
    content: "(Optional)";
    color: #757575;
    margin-left: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .table-booking-page form .form-buttons button {
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    width: 100%;
    background: #4cbba1;
    border-radius: 8px;
    padding: 14px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .table-booking-page form .form-buttons button.disabled {
    cursor: unset;
    opacity: .5;
    color: #fff !important;
    background: #4cbba1 !important;
  }

  .table-booking-summary-modal > .header {
    z-index: 99;
    background: #fff;
    border-bottom: 1px solid #e0e0e0;
    justify-content: space-between;
    padding: 20px 20px 17px;
    display: flex;
    position: sticky;
    top: 0;
  }

  .table-booking-summary-modal > .header .title {
    color: #27283e;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

@media (max-width: 767px) and (min-width: 768px) {
  .table-booking-summary-modal > .header .title {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (max-width: 767px) {
  .table-booking-summary-modal > .header .close-icon {
    width: 16px;
  }

  .table-booking-summary-modal .buttons {
    margin: 20px;
  }

  .table-booking-summary-modal .buttons .confirm-button {
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    width: 100%;
    background: #4cbba1;
    border-radius: 8px;
    padding: 14px 0 13px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .table-booking-summary-modal .buttons .back-button {
    text-align: center;
    text-transform: uppercase;
    color: #757575;
    box-shadow: none;
    width: 100%;
    background: #fff;
    border-radius: 8px;
    margin-top: 18px;
    padding: 14px 0 13px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .form-select-pax {
    box-sizing: border-box;
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 20px 16px 16px;
  }

  .form-select-pax .label {
    color: #27283e;
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .form-select-pax .sub-text {
    color: #757575;
    margin-bottom: 12px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .form-select-pax .options {
    grid-template-columns: minmax(100px, min-content) min-content min-content;
    align-items: center;
    column-gap: 16px;
    display: grid;
  }

  .form-select-pax .options .pax {
    color: #27283e;
    text-align: center;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 15px 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .form-single-select-pill .label {
    color: #27283e;
    margin-bottom: 7px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .form-single-select-pill .options.large {
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    display: grid;
  }

  .form-single-select-pill .options.small {
    flex-wrap: wrap;
    display: flex;
  }

  .form-single-select-pill .options .option {
    text-align: center;
    color: #757575;
    box-sizing: border-box;
    cursor: pointer;
    background: #fff;
    border: 1.5px solid #e0e0e0;
    border-radius: 50px;
    margin: 0 8px 8px 0;
    padding: 8.5px 15px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .form-single-select-pill .options .option.selected {
    color: #fff;
    background: #4cbba1;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@media (max-width: 767px) {
  .third-party-delivery-widget {
    grid-gap: 16px;
    flex-flow: column;
    display: flex;
  }

  .third-party-delivery-widget a {
    width: 100%;
    height: 52px;
    box-sizing: border-box;
    text-align: center;
    border: 2px solid #e0e0e0;
    border-radius: 8px;
    text-decoration: none;
  }

  .third-party-delivery-widget a .service-name {
    color: #27283e;
    border-radius: 8px;
    padding: 12px 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: block;
  }

  .third-party-delivery-widget a .service-logo {
    height: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 14px 0;
    display: block;
  }

  .third-party-delivery-widget a #ubereats {
    padding: 17px 0;
  }

  .third-party-delivery-widget a #deliveroo {
    padding: 8px 0;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@media (max-width: 767px) {
  .third-party-delivery-services-modal {
    padding-bottom: 28px;
  }

  .third-party-delivery-services-modal .header {
    z-index: 99;
    background: #fff;
    border-bottom: 1px solid #e0e0e0;
    justify-content: space-between;
    padding: 20px 20px 17px;
    display: flex;
    position: sticky;
    top: 0;
  }

  .third-party-delivery-services-modal .header .title {
    color: #27283e;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

@media (max-width: 767px) and (min-width: 768px) {
  .third-party-delivery-services-modal .header .title {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (max-width: 767px) {
  .third-party-delivery-services-modal .section {
    padding: 0 20px 20px;
  }

  .third-party-delivery-services-modal .section .unavailability-message {
    color: #27283e;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .third-party-delivery-services-modal .section .get-order-message {
    color: #27283e;
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .third-party-delivery-services-modal .actions > .back-button {
    cursor: pointer;
    box-shadow: none;
    color: #757575;
    background: none;
    margin: 10px auto;
    padding: 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .table-booking-success-page.page > .header {
    box-shadow: none;
    padding: 0;
  }

  .table-booking-success-page .body .wrapper .banner {
    text-align: center;
    margin: 40px 20px 5px;
  }

  .table-booking-success-page .body .wrapper .banner .icon {
    margin-bottom: 14px;
  }

  .table-booking-success-page .body .wrapper .banner .icon.desktop {
    display: none;
  }

  .table-booking-success-page .body .wrapper .banner .text {
    color: #27283e;
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

@media (max-width: 767px) and (min-width: 768px) {
  .table-booking-success-page .body .wrapper .banner .text {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (max-width: 767px) {
  .table-booking-success-page .body .wrapper .banner .sub-text {
    text-align: center;
    color: #757575;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .table-booking-success-page .body .wrapper > .status {
    box-sizing: border-box;
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin: 0 20px 24px;
    padding: 24px;
    box-shadow: 0 2px 4px #9281811a;
  }

  .table-booking-success-page .body .wrapper > .status .progress-bar {
    width: 100%;
    height: 8px;
    background-color: #f5f5f5;
    margin-bottom: 16px;
    display: flex;
  }

  .table-booking-success-page .body .wrapper > .status .progress-bar .progress.success {
    background-color: #086e37;
  }

  .table-booking-success-page .body .wrapper > .status .progress-bar .progress.danger {
    background-color: #b0204b;
  }

  .table-booking-success-page .body .wrapper > .status .progress-bar .progress.warning {
    background-color: #d67300;
  }

  .table-booking-success-page .body .wrapper > .status .caption {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 16px;
    display: flex;
  }

  .table-booking-success-page .body .wrapper > .status .caption .status {
    text-transform: uppercase;
    color: #757575;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .table-booking-success-page .body .wrapper > .status .caption .step {
    color: #757575;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .table-booking-success-page .body .wrapper > .status .text {
    color: #27283e;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .table-booking-success-page .body .wrapper > .status .sub-text {
    color: #757575;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .table-booking-success-page .body .wrapper .summary {
    color: #27283e;
    box-sizing: border-box;
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin: 16px 20px 24px;
    padding: 24px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    box-shadow: 0 2px 4px #0000001a;
  }

  .table-booking-success-page .body .wrapper .summary .section-name {
    color: #27283e;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .table-booking-success-page .body .wrapper .summary .customer-unverified-email {
    word-break: break-word;
  }

  .table-booking-success-page .body .wrapper .summary .contact-details, .table-booking-success-page .body .wrapper .summary .special-requests {
    margin-top: 15px;
  }

  .table-booking-success-page .body .wrapper .buttons {
    margin: 20px;
  }

  .table-booking-success-page .body .wrapper .buttons .view-menu-button {
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    width: 100%;
    background: #4cbba1;
    border-radius: 8px;
    padding: 14px 0 13px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .table-booking-success-page .body .wrapper .buttons .back-button {
    text-align: center;
    text-transform: uppercase;
    color: #757575;
    box-shadow: none;
    width: 100%;
    background: #fff;
    border-radius: 8px;
    margin-top: 18px;
    padding: 14px 0 13px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  nav {
    z-index: 999;
    width: calc(100% - 50px);
    max-width: 325px;
    scrollbar-width: none;
    scrollbar-color: transparent transparent;
    background-color: #fff;
    flex-direction: column;
    grid-area: nav;
    margin-left: 50px;
    transition: transform .3s;
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    overflow-y: scroll;
    transform: translateX(120%);
    box-shadow: 0 0 3px #687a9b40;
  }

  nav::-webkit-scrollbar {
    width: 1px;
  }

  nav::-webkit-scrollbar-track {
    background: none;
  }

  nav::-webkit-scrollbar-thumb {
    background-color: #0000;
  }

  nav.show {
    transform: none;
  }

  .profile {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 60px;
    display: flex;
  }

  .profile .avatar span {
    font-family: Chivo;
  }

  .profile .name {
    color: #27283e;
    margin-top: 17px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .profile .email, .profile .phone-number {
    color: #757575;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .nav-links {
    gap: 5px;
    padding: 24px 20px 0;
    display: grid;
  }

  .nav-links .link {
    cursor: pointer;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    grid-template-columns: 28px 1fr;
    grid-auto-flow: column;
    align-items: center;
    margin: 5px 0;
    padding: 17px 17px 17px 16px;
    display: grid;
    box-shadow: 0 2px 4px #0000001a;
  }

  .nav-links .link .icon {
    color: #92929d;
    margin-right: 9px;
    font-size: 21px;
  }

  .nav-links .link .label {
    color: #27283e;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: inline-block;
  }

  .powered-by-restoplus {
    text-align: center;
    margin-top: auto;
    padding-bottom: 50px;
  }

  .powered-by-restoplus .text {
    color: #757575;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .overlay.show {
    z-index: 998;
    background: #0006;
    justify-content: center;
    align-items: center;
    display: flex;
    position: fixed;
    inset: 0;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@media (max-width: 767px) {
  .table-booking-edit-booking-details-widget {
    margin: 32px 20px 0;
    padding-bottom: 64px;
  }

  .table-booking-edit-booking-details-widget .form-group {
    margin: 32px 0 64px;
  }

  .table-booking-edit-booking-details-widget .form-group .section-name {
    color: #27283e;
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .table-booking-edit-booking-details-widget .form-buttons {
    margin-top: 14px;
  }

  .table-booking-date-picker-modal > .header {
    z-index: 99;
    background: #fff;
    border-bottom: 1px solid #e0e0e0;
    justify-content: space-between;
    padding: 20px 20px 17px;
    display: flex;
    position: sticky;
    top: 0;
  }

  .table-booking-date-picker-modal > .header .title {
    color: #27283e;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

@media (max-width: 767px) and (min-width: 768px) {
  .table-booking-date-picker-modal > .header .title {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (max-width: 767px) {
  .table-booking-date-picker-modal > .header .close-icon {
    width: 16px;
  }

  .table-booking-date-picker-modal .note {
    color: #27283e;
    background-color: #ffebcf;
    margin: 20px 20px 0;
    padding: 10px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .table-booking-date-picker-modal .note .store-hours-link {
    color: #d67300;
    cursor: pointer;
  }

  .table-booking-date-picker-modal > .date-picker-widget {
    margin: 17px 24px 0;
  }

  .table-booking-time-picker-modal > .header {
    z-index: 99;
    background: #fff;
    border-bottom: 1px solid #e0e0e0;
    justify-content: space-between;
    padding: 20px 20px 17px;
    display: flex;
    position: sticky;
    top: 0;
  }

  .table-booking-time-picker-modal > .header .title {
    color: #27283e;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

@media (max-width: 767px) and (min-width: 768px) {
  .table-booking-time-picker-modal > .header .title {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (max-width: 767px) {
  .table-booking-time-picker-modal > .header .close-icon {
    width: 16px;
  }

  .table-booking-time-picker-modal .time-of-day-list {
    padding: 28px 20px;
  }

  .table-booking-time-picker-modal .time-of-day-list .time-of-day {
    box-sizing: border-box;
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    align-items: center;
    margin-bottom: 8px;
    padding: 14.5px 12.5px;
    display: flex;
  }

  .table-booking-time-picker-modal .time-of-day-list .time-of-day input[type="radio"] {
    filter: opacity(.8);
    margin: 0 14px 0 0;
  }

  .table-booking-time-picker-modal .time-of-day-list .time-of-day .value {
    color: #27283e;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .table-booking-time-picker-modal .time-of-day-list .time-of-day.unavailable {
    background-color: #f4f4f6;
    border: 1px solid #f4f4f6;
  }

  .table-booking-time-picker-modal .time-of-day-list .time-of-day.unavailable .value {
    color: #adafbc;
  }

  .table-booking-edit-contact-details-widget {
    padding-bottom: 64px;
  }

  .table-booking-edit-contact-details-widget .booking-details-banner {
    background: #ffebcf;
    grid-template-columns: 1fr min-content;
    grid-template-areas: "booked-for edit"
                         "pax pax"
                         "seating-area seating-area";
    padding: 23px 24px 22.39px;
    display: grid;
  }

  .table-booking-edit-contact-details-widget .booking-details-banner .booked-for {
    color: #27283e;
    grid-area: booked-for;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .table-booking-edit-contact-details-widget .booking-details-banner .edit {
    color: #d67300;
    grid-area: edit;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .table-booking-edit-contact-details-widget .booking-details-banner .pax {
    color: #27283e;
    grid-area: pax;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .table-booking-edit-contact-details-widget .booking-details-banner .seating-area {
    color: #27283e;
    grid-area: seating-area;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .table-booking-edit-contact-details-widget .form-group {
    margin: 32px 20px 64px;
  }

  .table-booking-edit-contact-details-widget .form-group .section-name {
    color: #27283e;
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .table-booking-edit-contact-details-widget .form-group .notes-character-limit .notes-length {
    float: right;
    color: #7d7e92;
    font-size: 12px;
    position: relative;
    top: -16px;
  }

  .table-booking-edit-contact-details-widget .form-buttons {
    margin-top: 4px;
  }

  .table-booking-summary-widget {
    color: #27283e;
    box-sizing: border-box;
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin: 16px 20px 24px;
    padding: 24px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    box-shadow: 0 2px 4px #0000001a;
  }

  .table-booking-summary-widget .section-name {
    color: #27283e;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .table-booking-summary-widget .customer-unverified-email {
    word-break: break-word;
  }

  .table-booking-summary-widget .contact-details, .table-booking-summary-widget .special-requests {
    margin-top: 24px;
  }

  #toasts-container {
    grid-gap: 15px;
    z-index: 1001;
    display: grid;
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
  }

  #toasts-container .wrapper {
    text-align: center;
  }

  .toast {
    box-sizing: border-box;
    border-radius: 8px;
    grid-template-columns: min-content max-content;
    align-items: center;
    padding: 15px 20px;
    display: inline-grid;
    box-shadow: 0 10px 16px #31313b1a;
  }

  .toast .icon {
    margin-top: -4px;
    margin-right: 10px;
  }

  .toast .message {
    text-align: left;
    max-width: 220px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .toast button.close {
    box-shadow: none;
    opacity: .5;
    color: #000;
    background-color: #0000;
    border: 0;
    margin-left: 10px;
    padding: 0;
    font-size: 1.4rem;
  }

  .toast.success {
    background-color: var(--message-success-bg);
    color: var(--message-success-text);
    border: 1px solid #8bc1a4;
  }

  .toast.info {
    background-color: var(--message-warning-bg);
    color: var(--message-warning-text);
    border: 1px solid #d67300;
  }

  .toast.error {
    background-color: var(--message-error-bg);
    color: var(--message-error-text);
    border: 1px solid #d9657a;
  }

  .toast.warning {
    background-color: var(--message-warning-bg);
    color: var(--message-warning-text);
  }

  .toast.loading {
    color: #d67300;
    background-color: #ffebcf;
    border: 1px solid #d67300;
  }

  .toast.loading .spinner {
    width: 22px;
    height: 22px;
    color: #d67300;
    margin-right: 8px;
    animation: 1s linear infinite spin;
  }

  .spinner-container {
    text-align: center;
  }

  .spinner-container .spinner {
    width: 30px;
    height: 30px;
    margin: 0 auto;
    animation: 1s linear infinite spin;
  }

  .spinner-container .message {
    text-align: center;
    margin-top: 15px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .collection-time-selector-widget {
    gap: 8px;
    display: grid;
  }

  .collection-time-selector-widget .collection-date-and-time {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    grid-template-areas: "date-and-time . caret-right-icon";
    grid-auto-columns: max-content 1fr min-content;
    gap: 15px;
    padding: 14px 16px;
    display: grid;
  }

  .collection-time-selector-widget .collection-date-and-time .text {
    color: #27283e;
    grid-area: date-and-time;
    align-self: center;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
  }

  .collection-time-selector-widget .collection-date-and-time .caret-right.icon {
    grid-area: caret-right-icon;
    margin: auto 0;
  }

  .collection-time-selector-widget .collection-date-and-time.disabled {
    cursor: unset;
    background: #f5f5f5;
  }

  .collection-time-selector-widget .collection-date-and-time.disabled .text {
    color: #757575;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@media (max-width: 767px) {
  .item-list-widget {
    padding: 0 20px 30px;
  }

  .item-list-widget .category-summary {
    grid-gap: 10px;
    flex-flow: column;
    padding: 25px 0;
    display: flex;
  }

  .item-list-widget .category-summary .link-anchor {
    width: 100%;
    grid-area: link-anchor;
    position: relative;
    top: -170px;
  }

  .item-list-widget .category-summary .name {
    text-align: left;
    word-break: break-word;
    color: #27283e;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }

  .item-list-widget .category-summary .description {
    color: #7d7d7d;
    margin: 5px 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .item-list-widget .items-container {
    gap: 20px;
    display: grid;
  }

  .item-list-widget .items-container .item.no-image {
    height: auto;
    padding-top: 0;
  }

  .item-list-widget .items-container .item.no-image .body {
    position: relative;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@media (max-width: 767px) {
  .item-card-widget {
    box-sizing: border-box;
    grid-row-gap: 8px;
    background-color: #fff;
    border: 1px solid #e2e2e2;
    border-radius: 8px;
    grid-template: "image image" min-content
                   "item-tag item-tag" min-content
                   "name name" min-content
                   "description description" 1fr
                   "price dietary" min-content
                   / min-content auto;
    padding-bottom: 16px;
    display: grid;
    overflow: hidden;
    box-shadow: 0 2px 4px #0000000d;
  }

  .item-card-widget .anchor {
    position: absolute;
    top: -120px;
  }

  .item-card-widget .soldout, .item-card-widget .unavailable {
    color: #27283e;
    text-align: center;
    width: -moz-fit-content;
    width: -moz-fit-content;
    width: -moz-fit-content;
    width: -moz-fit-content;
    width: -moz-fit-content;
    width: -moz-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background: #e0e0e0;
    border-radius: 4px;
    grid-area: item-tag;
    align-items: center;
    margin-top: 12px;
    margin-left: 16px;
    padding: 3px 6px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .item-card-widget .item-label {
    color: #27283e;
    width: -moz-fit-content;
    width: -moz-fit-content;
    width: -moz-fit-content;
    width: -moz-fit-content;
    width: -moz-fit-content;
    width: -moz-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: #ffebcf;
    border-radius: 4px;
    grid-area: item-tag;
    margin: 12px 16px 0;
    padding: 3px 6px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .item-card-widget .name {
    -webkit-line-clamp: 2;
    text-align: left;
    word-wrap: break-word;
    color: #27283e;
    -webkit-box-orient: vertical;
    grid-area: name;
    align-self: start;
    margin-top: 8px;
    padding: 0 16px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    display: -webkit-box;
    overflow: hidden;
  }

  .item-card-widget .description {
    color: #757575;
    height: min-content;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    grid-area: description;
    align-self: start;
    padding: 0 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: -webkit-box;
    overflow: hidden;
  }

  .item-card-widget .price {
    text-align: left;
    color: #27283e;
    grid-area: price;
    align-self: center;
    padding-left: 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .item-card-widget .item-dietary {
    color: #757575;
    grid-area: dietary;
    place-self: center flex-end;
    padding-right: 16px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .item-card-widget .image {
    grid-area: image;
  }

  .item-card-widget .body.no-image {
    grid-template-columns: unset;
    height: unset;
  }

  .item-card-widget .body.no-image .item-info > .description > .content {
    -webkit-line-clamp: unset;
  }

  .item-card-widget.disabled {
    cursor: auto;
  }

  .menu-search-widget {
    display: block;
  }

  .menu-search-widget .searchbox .search-icon {
    width: 21px;
    filter: invert(.6);
  }

  .menu-search-widget .searchbox input, .menu-search-widget .searchbox .clear-input-icon {
    display: none;
  }

  .category-list-widget {
    width: 100vw;
    z-index: 1;
    background-color: #fff;
    gap: 10px;
    position: sticky;
    top: 58px;
    overflow: visible;
  }

  .category-list-widget #categories-container {
    width: 100%;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    box-shadow: inset 0 -1px #e3ebec;
  }

  .category-list-widget #categories-container::-webkit-scrollbar {
    display: none;
  }

  .category-list-widget #categories-container #categories-wrapper {
    grid-auto-flow: column;
    display: grid;
  }

  .category-list-widget #categories-container #categories-wrapper .category {
    white-space: nowrap;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    word-wrap: break-word;
    padding: 14px 11px 10px;
  }

  .category-list-widget #categories-container #categories-wrapper .category:first-child {
    margin-left: 7px;
  }

  .category-list-widget #categories-container #categories-wrapper .category.selected {
    border-bottom: 2px solid #27283e;
  }

  .category-list-widget #categories-container #categories-wrapper .category .name {
    text-align: left;
    color: #757575;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .category-list-widget #categories-container #categories-wrapper .category .name.selected {
    color: #27283e;
    font-weight: 700;
  }

  .category-list-widget.store-closed {
    top: 118px;
  }

  .store-closed-info-widget {
    height: 30px;
    text-align: left;
    color: #b0204b;
    background: #ffe7df;
    grid-area: store-closed;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 20px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    display: flex;
  }

  .store-closed-info-widget span {
    text-align: left;
    margin-right: 7px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .item-available-weekly-hours-widget {
    color: #d67300;
    background: #ffebcf;
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .otp-for-mobile-number-request-widget .error {
    display: none;
  }

  .otp-for-mobile-number-request-widget .supporting-text {
    text-align: center;
    color: #757575;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .otp-for-mobile-number-request-widget .supporting-text a {
    color: #4cbba1;
    text-decoration: none;
  }

  .otp-verifier-widget .otp-input-wrapper .otp-input input {
    box-sizing: border-box;
    letter-spacing: 24px;
    text-align: center;
    width: 100%;
    border: 1px solid #bdbdbd;
    border-radius: 6px;
    padding: 16px;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
  }

  .otp-verifier-widget .otp-input-wrapper .otp-input input::placeholder {
    color: #757575;
    letter-spacing: normal;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .otp-verifier-widget .error-banner {
    box-sizing: border-box;
    width: 335px;
    grid-gap: 10.95px;
    background: #ffe7df;
    flex-flow: row;
    margin: 20px auto 0;
    padding: 16px;
    display: flex;
  }

  .otp-verifier-widget .error-banner .red-warning-icon {
    height: min-content;
    display: block;
  }

  .otp-verifier-widget .error-banner .error-message {
    color: #27283e;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .otp-verifier-widget .resend-otp {
    text-align: center;
    margin-top: 25px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .otp-verifier-widget .resend-otp .resend {
    color: #4cbba1;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .otp-verifier-widget .resend-otp .resend.disabled {
    color: #adafbc;
    cursor: default;
  }

  .otp-verifier-widget .recaptcha-branding {
    width: 328px;
    text-align: center;
    color: #757575;
    margin: 20px auto 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .otp-verifier-widget .recaptcha-branding a {
    color: #4cbba1;
    text-decoration: none;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@media (max-width: 767px) {
  .customer-display-name-update-widget, .customer-display-name-update-widget form {
    height: 100%;
    grid-template-rows: min-content 1fr;
    display: grid;
  }

  .customer-display-name-update-widget form .form-buttons {
    margin-top: auto;
    margin-bottom: 30px;
  }

  .store-close-banner {
    z-index: 100;
    height: 60px;
    text-align: center;
    color: #b0204b;
    background-color: #ffe7df;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    position: sticky;
    top: 0;
  }

  .store-close-banner span {
    font-weight: 700;
  }

  .table-page .body .table-ordering-welcome-screen, .table-page .body .table-ordering-qr-scanner {
    z-index: 100;
    height: 100vh;
    background: #fff;
    position: absolute;
    inset: 0;
  }

  .table-page .body .table-ordering-welcome-screen .has-banner .image, .table-page .body .table-ordering-welcome-screen .has-banner .welcome, .table-page .body .table-ordering-qr-scanner .has-banner .image, .table-page .body .table-ordering-qr-scanner .has-banner .welcome {
    visibility: hidden;
  }

  .table-page .body .table-ordering-welcome-screen .table-ordering-banner, .table-page .body .table-ordering-qr-scanner .table-ordering-banner {
    z-index: -1;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  .table-page .body .table-ordering-welcome-screen .table-seat-info, .table-page .body .table-ordering-welcome-screen .table-ordering-info, .table-page .body .table-ordering-qr-scanner .table-seat-info, .table-page .body .table-ordering-qr-scanner .table-ordering-info {
    background: #fff;
  }

  .table-ordering-welcome-screen {
    text-align: center;
    flex-direction: column;
    justify-content: center;
    display: flex;
  }

  .table-ordering-welcome-screen .table-ordering-details {
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    display: flex;
  }

  .table-ordering-welcome-screen .table-ordering-details .optimized-image img {
    height: 100px;
    width: 100px;
  }

  .table-ordering-welcome-screen .table-ordering-details .welcome {
    color: #27283e;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

@media (max-width: 767px) and (min-width: 768px) {
  .table-ordering-welcome-screen .table-ordering-details .welcome {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (max-width: 767px) {
  .table-ordering-welcome-screen .table-ordering-details .table-seat-info, .table-ordering-welcome-screen .table-ordering-details .table-ordering-info {
    width: 100%;
    border: 1px solid #e2e2e2;
    border-radius: 8px;
    margin-top: 24px;
    margin-bottom: 46px;
    padding: 30px 0;
  }

  .table-ordering-welcome-screen .table-ordering-details .table-seat-info .table-seat-name, .table-ordering-welcome-screen .table-ordering-details .table-seat-info .table-not-found, .table-ordering-welcome-screen .table-ordering-details .table-seat-info .dinein-not-available, .table-ordering-welcome-screen .table-ordering-details .table-ordering-info .table-seat-name, .table-ordering-welcome-screen .table-ordering-details .table-ordering-info .table-not-found, .table-ordering-welcome-screen .table-ordering-details .table-ordering-info .dinein-not-available {
    margin-top: 5px;
    margin-bottom: 3px;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

@media (max-width: 767px) and (min-width: 768px) {
  .table-ordering-welcome-screen .table-ordering-details .table-seat-info .table-seat-name, .table-ordering-welcome-screen .table-ordering-details .table-seat-info .table-not-found, .table-ordering-welcome-screen .table-ordering-details .table-seat-info .dinein-not-available, .table-ordering-welcome-screen .table-ordering-details .table-ordering-info .table-seat-name, .table-ordering-welcome-screen .table-ordering-details .table-ordering-info .table-not-found, .table-ordering-welcome-screen .table-ordering-details .table-ordering-info .dinein-not-available {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (max-width: 767px) {
  .table-ordering-welcome-screen .table-ordering-details .table-seat-info .table-not-found, .table-ordering-welcome-screen .table-ordering-details .table-ordering-info .table-not-found {
    color: #b0204b;
  }

  .table-ordering-welcome-screen .table-ordering-details .table-seat-info .dinein-not-available, .table-ordering-welcome-screen .table-ordering-details .table-ordering-info .dinein-not-available {
    color: #27283e;
  }

  .table-ordering-welcome-screen .table-ordering-details .table-seat-info .table-seat-name, .table-ordering-welcome-screen .table-ordering-details .table-ordering-info .table-seat-name {
    color: #4cbba1;
  }

  .table-ordering-welcome-screen .table-ordering-details .table-seat-info .table-seat-text, .table-ordering-welcome-screen .table-ordering-details .table-ordering-info .table-seat-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .table-ordering-welcome-screen .table-ordering-details .table-seat-info .table-not-found-text, .table-ordering-welcome-screen .table-ordering-details .table-seat-info .dinein-not-available-text, .table-ordering-welcome-screen .table-ordering-details .table-ordering-info .table-not-found-text, .table-ordering-welcome-screen .table-ordering-details .table-ordering-info .dinein-not-available-text {
    padding: 0 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .table-ordering-welcome-screen .actions {
    flex-direction: column;
    justify-content: center;
    margin-bottom: 14px;
    padding: 0 20px;
    display: flex;
  }

  .table-ordering-welcome-screen .actions .start-order-btn, .table-ordering-welcome-screen .actions .view-menu-btn {
    border-radius: 8px;
    margin-bottom: 17px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .table-ordering-welcome-screen .actions .view-orders-btn, .table-ordering-welcome-screen .actions .re-scan-qr-btn {
    color: #4cbba1;
    background-color: #fff;
    border: 2px solid #4cbba1;
    border-radius: 8px;
    padding: 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .table-ordering-welcome-screen .actions .scan-qr, .table-ordering-welcome-screen .actions .go-to-menu {
    color: #4cbba1;
    margin-top: 33px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.table-change-widget {
  max-height: 655px;
}

.table-change-widget .header-section {
  z-index: 99;
  background: #fff;
  border-bottom: 1px solid #e0e0e0;
  justify-content: space-between;
  padding: 0 20px;
  display: flex;
  position: sticky;
  top: 0;
}

.table-change-widget .header-section .title {
  color: #27283e;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  .table-change-widget .header-section .title {
    font-size: 20px;
    line-height: 28px;
  }
}

.table-change-widget .header-section .close-icon {
  width: 15px;
  margin-right: 5px;
}

.table-change-widget .actions {
  flex-direction: column;
  justify-content: center;
  margin: 21px 0;
  padding: 0 20px;
  display: flex;
}

.table-change-widget .actions .change-table-btn {
  letter-spacing: 2px;
  text-transform: uppercase;
  border-radius: 8px;
  margin-bottom: 17px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

.table-change-widget .actions .cancel-session-btn {
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #4cbba1;
  background-color: #fff;
  border: 2px solid #4cbba1;
  border-radius: 8px;
  padding: 12px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

.table-change-widget .table-selector {
  grid-template-columns: 1fr max-content;
  grid-template-areas: "title image"
                       "table-list table-list";
  margin-top: 28px;
  padding: 0 20px 28px;
  display: grid;
}

.table-change-widget .table-selector .title {
  color: #27283e;
  grid-area: title;
  align-self: center;
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  display: flex;
}

.table-change-widget .table-selector .image {
  width: 38px;
  grid-area: image;
  margin-bottom: 15px;
}

.table-change-widget .table-selector .title {
  margin-bottom: 15px;
}

.table-change-widget .table-selector .table-list {
  grid-gap: 15px;
  grid-area: table-list;
  margin-top: 8px;
  display: grid;
}

.table-change-widget .table-selector .table-list .table {
  color: #27283e;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  grid-template-areas: "name caret-right-icon";
  align-items: center;
  padding: 14px 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: grid;
}

.table-change-widget .table-selector .table-list .table .name {
  grid-area: name;
  margin-right: 15px;
}

.table-change-widget .table-selector .table-list .table .caret-right.icon {
  grid-area: caret-right-icon;
  margin-left: auto;
}

@media (max-width: 767px) {
  .table-ordering-qr-scanner {
    flex-direction: column;
    justify-content: center;
    margin-bottom: 14px;
    padding: 0 20px;
    display: flex;
  }

  .table-ordering-qr-scanner .cancel-btn {
    letter-spacing: 1.25px;
    border-radius: 8px;
    margin-bottom: 17px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .table-ordering-qr-scanner .barcode-scanner-component {
    align-self: center;
    padding-bottom: 100px;
  }

  .table-ordering-qr-scanner .barcode-scanner-component video {
    object-fit: cover;
    background-color: #0000;
    background-image: linear-gradient(to right, #838393 4px, #0000 4px), linear-gradient(to right, #838393 4px, #0000 4px), linear-gradient(to left, #838393 4px, #0000 4px), linear-gradient(to left, #838393 4px, #0000 4px), linear-gradient(#838393 4px, #0000 4px), linear-gradient(#838393 4px, #0000 4px), linear-gradient(to top, #838393 4px, #0000 4px), linear-gradient(to top, #838393 4px, #0000 4px);
    background-position: 0 0, 0 100%, 100% 0, 100% 100%, 0 0, 100% 0, 0 100%, 100% 100%;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-attachment: scroll, scroll, scroll, scroll, scroll, scroll, scroll, scroll;
    background-origin: padding-box, padding-box, padding-box, padding-box, padding-box, padding-box, padding-box, padding-box;
    background-clip: border-box, border-box, border-box, border-box, border-box, border-box, border-box, border-box;
    padding: 10px;
  }

  .platform-maintenance-banner {
    z-index: 100;
    height: 60px;
    letter-spacing: 0;
    text-align: center;
    color: #b0204b;
    background-color: #ffe7df;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    position: sticky;
    top: 0;
  }

  .platform-maintenance-banner span {
    font-weight: 700;
  }

  .platform-maintenance-banner + .top-nav-bar-widget {
    top: 60px;
  }

  .upsell-items-widget .header {
    height: 60px;
    width: 100%;
    background-color: #fff;
    justify-content: space-between;
    align-items: center;
    display: flex;
    box-shadow: inset 0 -1px #f0f0f0;
  }

  .upsell-items-widget .header .title {
    color: #27283e;
    margin-left: 20px;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

@media (max-width: 767px) and (min-width: 768px) {
  .upsell-items-widget .header .title {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (max-width: 767px) {
  .upsell-items-widget .header .close-icon {
    width: 15px;
    cursor: pointer;
    margin-right: 20px;
  }

  .upsell-items-widget .upsell-items {
    max-height: calc(75vh - 170px);
    gap: 18px;
    margin: 20px;
    display: grid;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .upsell-items-widget .actions {
    width: calc(100% - 40px);
    padding: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .upsell-items-widget .actions button {
    width: 100%;
  }

  .uber-delivery-info-view-widget {
    grid-template-rows: 180px max-content;
    display: grid;
  }

  .uber-delivery-info-view-widget .tracking-map {
    position: relative;
    top: -35px;
    overflow: hidden;
  }

  .uber-delivery-info-view-widget .tracking-map .map-container {
    width: 100%;
    height: 210px;
    overflow: hidden;
  }

  .uber-delivery-info-view-widget .tracking-map .map-container > iframe {
    width: 350%;
    height: 420px;
    transform-origin: 0 0;
    position: relative;
    top: -65%;
    right: 90%;
    overflow: hidden;
    transform: scale(.8);
  }

  .uber-delivery-info-view-widget .courier-info {
    box-sizing: border-box;
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    grid-template-columns: 90px 200px;
    grid-template-areas: "courier-image courier-additional-info";
    align-items: center;
    margin: 0 20px 24px;
    padding: 20px 15px;
    display: grid;
    box-shadow: 0 2px 4px #0000001a;
  }

  .uber-delivery-info-view-widget .courier-info .courier-image {
    grid-area: courier-image;
  }

  .uber-delivery-info-view-widget .courier-info .courier-image img {
    width: 80%;
    border-radius: 50%;
  }

  .uber-delivery-info-view-widget .courier-info .courier-additional-info {
    grid-area: courier-additional-info;
  }

  .uber-delivery-info-view-widget .courier-info .courier-additional-info .courier-name {
    font-weight: 700;
  }

  .uber-delivery-info-view-widget .courier-info .courier-additional-info .courier-vehicle {
    color: #7d7e92;
  }

  .uber-delivery-info-view-widget .courier-info .courier-additional-info .courier-phone-number {
    color: #7d7e92;
    font-weight: 700;
  }

  .uber-delivery-info-view-widget .courier-info .courier-additional-info .call-courier {
    color: #4cbba1;
    font-weight: 400;
    text-decoration: none;
  }

  .customer-review-page {
    min-height: 100vh;
    background-color: #fff;
    padding: 0;
    font-family: Poppins, sans-serif;
    position: relative;
    overflow: hidden;
  }

  .customer-review-page > .header {
    padding: 0;
  }

  .customer-review-page > .body {
    max-width: unset;
  }

  .customer-review-page > .body > .spinner-container {
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .customer-review-page > .body .store-header {
    z-index: 100;
    background-color: #92400e;
    justify-content: center;
    align-items: center;
    padding: 16px 0;
    display: flex;
    position: sticky;
    top: 0;
  }

  .customer-review-page > .body .store-header .restaurant-logo {
    padding-right: 16px;
  }

  .customer-review-page > .body .store-header .restaurant-logo img {
    width: 48px;
    height: 48px;
    border-radius: 8px;
  }

  .customer-review-page > .body .store-header .name {
    color: #fff;
    font-size: 16px;
  }

  .customer-review-page > .body .main-display {
    height: calc(100vh - 80px);
  }

  .customer-review-page > .body .main-display.sad-rating-feedback-form {
    height: 100vh;
  }

  .customer-review-page > .body .main-display .rating-selector {
    text-align: center;
    z-index: 2;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 282px;
    transition: opacity .5s ease-in, transform .5s ease-in;
    display: flex;
    position: relative;
  }

  .customer-review-page > .body .main-display .rating-selector.hidden {
    opacity: 0;
    pointer-events: none;
    transform: translateY(-100px);
  }

  .customer-review-page > .body .main-display .rating-selector.show {
    opacity: 1;
    transform: translateY(0);
  }

  .customer-review-page > .body .main-display .rating-selector .message {
    font-size: 34px;
    font-weight: 600;
    line-height: 40.8px;
  }

  .customer-review-page > .body .main-display .rating-selector .selectors {
    margin-top: 40px;
  }

  .customer-review-page > .body .main-display .rating-selector .selectors .happy-rating, .customer-review-page > .body .main-display .rating-selector .selectors .sad-rating {
    width: 112px;
    height: 112px;
    cursor: pointer;
  }

  .customer-review-page > .body .main-display .rating-selector .selectors .sad-rating {
    margin-right: 24px;
  }

  .customer-review-page > .body .main-display .happy-rating-feedback-message {
    text-align: center;
    z-index: 2;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -459px;
    padding-top: 131px;
    transition: opacity .5s ease-in;
    display: flex;
    position: relative;
  }

  .customer-review-page > .body .main-display .happy-rating-feedback-message.hidden {
    opacity: 0;
    height: 0;
    pointer-events: none;
    overflow: hidden;
  }

  .customer-review-page > .body .main-display .happy-rating-feedback-message.hidden .message {
    transform: scale(1.3);
  }

  .customer-review-page > .body .main-display .happy-rating-feedback-message.hidden .description {
    transform: translateY(-10px);
  }

  .customer-review-page > .body .main-display .happy-rating-feedback-message.hidden .happy-rating-animation, .customer-review-page > .body .main-display .happy-rating-feedback-message.hidden .google-review-button {
    transform: translateY(50px);
  }

  .customer-review-page > .body .main-display .happy-rating-feedback-message.show {
    opacity: 1;
  }

  .customer-review-page > .body .main-display .happy-rating-feedback-message.show .message {
    transform: scale(1);
  }

  .customer-review-page > .body .main-display .happy-rating-feedback-message.show .happy-rating-animation, .customer-review-page > .body .main-display .happy-rating-feedback-message.show .description, .customer-review-page > .body .main-display .happy-rating-feedback-message.show .google-review-button {
    transform: translateY(0);
  }

  .customer-review-page > .body .main-display .happy-rating-feedback-message .happy-rating-animation {
    transition: transform .5s ease-in;
  }

  .customer-review-page > .body .main-display .happy-rating-feedback-message .message {
    padding: 32px 0 22px;
    font-size: 24px;
    font-weight: 600;
    transition: transform .5s ease-in;
  }

  .customer-review-page > .body .main-display .happy-rating-feedback-message .description {
    width: 260px;
    text-align: center;
    font-size: 14px;
    transition: transform .5s ease-in;
  }

  .customer-review-page > .body .main-display .happy-rating-feedback-message .google-review-button {
    background-color: #2563eb;
    border-radius: 4px;
    margin-top: 32px;
    padding: 14px 32px;
    text-decoration: none;
    transition: transform .5s ease-in;
    display: flex;
  }

  .customer-review-page > .body .main-display .happy-rating-feedback-message .google-review-button span {
    color: #fff;
    padding-right: 6px;
    font-size: 16px;
  }

  .customer-review-page > .body .main-display .happy-rating-feedback-message .google-review-button img {
    width: 60.76px;
    height: 20px;
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-form {
    z-index: 2;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -180px;
    padding: 28px 30px 0;
    transition: opacity .5s ease-in, transform .5s ease-in;
    display: flex;
    position: relative;
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-form.hidden {
    opacity: 0;
    pointer-events: none;
    transform: translateY(100px);
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-form.show {
    opacity: 1;
    transform: translateY(0);
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-form .title {
    color: #1c1d3e;
    padding: 32px 0 20px;
    font-size: 24px;
    font-weight: 600;
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-form .sub-title {
    color: #1c1d3e;
    text-align: center;
    padding-bottom: 32px;
    font-size: 14px;
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-form form > .form-items {
    margin-left: calc(50% - 163px);
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-form form > .form-items .form-item textarea, .customer-review-page > .body .main-display .sad-rating-feedback-form form > .form-items .form-item input {
    width: 326px;
    margin-top: 6px;
    font-family: Poppins, sans-serif;
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-form form > .form-items .form-item textarea {
    height: 128px;
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-form form > .form-items .form-item label {
    color: #7d7e92;
    font-weight: 500;
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-form form > .form-items .form-item .meta {
    align-items: center;
    display: flex;
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-form form > .form-items .form-item .meta:before {
    content: url("exclamation-circle-icon-filled.d818762b.svg");
    width: 12.8px;
    height: 12.8px;
    padding-bottom: 2px;
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-form form > .form-items .form-item .meta .error {
    color: #f43f5e;
    padding-left: 5px;
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-form form > .form-items .checkbox {
    color: #1c1d3e;
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-form form > .form-items .checkbox input {
    -webkit-appearance: none;
    appearance: none;
    width: 17px;
    height: 17px;
    cursor: pointer;
    background-color: #f5f5f5;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    margin-bottom: 15px;
    position: relative;
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-form form > .form-items .checkbox input:checked {
    background-color: #4cbba1;
    border: 1px solid #4cbba1;
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-form form > .form-items .checkbox input:checked:after {
    content: "";
    width: 3px;
    height: 7px;
    transform-origin: center;
    border: 2px solid #fff;
    border-width: 0 2px 2px 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)rotate(45deg);
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-form form > .form-items .checkbox label {
    white-space: unset;
    width: 260px;
    padding-left: 10px;
    font-size: 14px;
    line-height: 21px;
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-form .actions {
    margin-top: 32px;
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-form .actions .submit-button {
    color: #fff;
    text-transform: unset;
    letter-spacing: 1px;
    background-color: #2563eb;
    border-radius: 4px;
    padding: 14px 80px;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: 500;
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-form .actions .google-review-link {
    color: #7d7e92;
    justify-content: center;
    margin-top: 27px;
    font-size: 16px;
    font-weight: 500;
    display: flex;
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-message {
    text-align: center;
    z-index: 2;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -885px;
    padding: 135px 30px 0;
    transition: opacity .5s ease-in, transform .5s ease-in;
    display: flex;
    position: relative;
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-message.hidden {
    opacity: 0;
    pointer-events: none;
    transform: translateY(-100px);
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-message.show {
    opacity: 1;
    transform: translateY(0);
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-message .message {
    padding: 49px 0 22px;
    font-size: 24px;
    font-weight: 600;
    line-height: 28.8px;
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-message .description {
    font-size: 14px;
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-message .google-review-link {
    color: #7d7e92;
    margin-top: 47px;
    font-size: 16px;
    font-weight: 500;
  }

  .customer-review-page > .body .happy-rating-message-background, .customer-review-page > .body .sad-rating-feedback-form-background, .customer-review-page > .body .sad-rating-feedback-message-background {
    width: 100vmax;
    height: 100vmax;
    transform-origin: center;
    position: absolute;
    top: 50%;
    left: 50%;
  }

  .customer-review-page > .body .happy-rating-message-background {
    background: url("happy-rating-message-background.53b56f48.svg") center / contain no-repeat;
    transition: transform .7s ease-in-out;
    transform: translate(-50%, -50%)scale(0)rotate(-50deg);
  }

  .customer-review-page > .body .happy-rating-message-background.show {
    transform: translate(-50%, -50%)scale(2)rotate(0);
  }

  .customer-review-page > .body .sad-rating-feedback-form-background {
    background: url("sad-rating-form-background.f1e88dc7.svg") center / contain no-repeat;
    transition: transform .5s ease-in-out;
    transform: translate(-50%, -50%)scale(0);
  }

  .customer-review-page > .body .sad-rating-feedback-form-background.show {
    transform: translate(-50%, -50%)scale(3);
  }

  .customer-review-page > .body .sad-rating-feedback-message-background {
    background: url("sad-rating-message-background.9c985d36.svg") center / contain no-repeat;
    transition: transform .5s ease-in-out;
    transform: translate(-50%, -50%)scale(0);
  }

  .customer-review-page > .body .sad-rating-feedback-message-background.show {
    transform: translate(-50%, -50%)scale(3);
  }
}

@media only screen and (max-height: 700px) {
  .customer-review-page > .body .main-display.sad-rating-feedback-form {
    height: 130vh;
  }

  .customer-review-page > .body .main-display .rating-selector {
    padding-top: 230px;
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-form {
    margin-top: -107px;
  }
}

@media (min-width: 768px) {
  .store-trading-hours-page > .header {
    margin-top: -4px;
  }

  .store-trading-hours-page > .header .wrapper > .title {
    text-align: center;
    font-size: 26px;
    font-weight: 700;
    line-height: 32px;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .store-trading-hours-page > .header .wrapper > .title {
    font-size: 32px;
    line-height: 48px;
  }
}

@media (min-width: 768px) {
  .trading-hours-page {
    max-width: 630px;
    background-color: #fafafa;
    margin: 0 auto;
    padding-bottom: 90px;
  }

  .trading-hours-page .store-logo-and-address {
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    grid-template: "logo find-us" min-content
                   "logo need-help" 1fr
                   / 200px 1fr;
    margin-top: 36px;
    margin-bottom: 12px;
    display: grid;
    box-shadow: 0 0 8px #31303e1a;
  }

  .trading-hours-page .store-logo-and-address .restaurant-logo {
    grid-area: logo;
  }

  .trading-hours-page .store-logo-and-address .restaurant-logo img {
    border-radius: 8px 0 0 8px;
  }

  .trading-hours-page .store-logo-and-address .info.find-us {
    grid-area: find-us;
    margin: 33px 29px 0;
  }

  .trading-hours-page .store-logo-and-address .info.need-help {
    word-break: break-all;
    grid-area: need-help;
    margin: 20px 29px;
  }

  .trading-hours-page .store-logo-and-address .info .title {
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #27283e;
    margin-bottom: 10px;
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
  }

  .trading-hours-page .store-logo-and-address .info .text {
    color: #757575;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .trading-hours-page .store-logo-and-address .info .text .link {
    color: #4cbba1;
    cursor: pointer;
  }

  .trading-hours-page .date-and-trading-hours-today {
    padding-top: 18px;
    display: grid;
  }

  .trading-hours-page .date-and-trading-hours-today .date-today {
    grid-gap: 5px;
    margin: 0 auto 30px;
    display: grid;
  }

  .trading-hours-page .date-and-trading-hours-today .date-today .trading-hours-label {
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;
    text-align: center;
    color: #27283e;
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
  }

  .trading-hours-page .date-and-trading-hours-today .date-today .date {
    text-align: center;
    color: #27283e;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .trading-hours-page .date-and-trading-hours-today .date-today .date {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (min-width: 768px) {
  .trading-hours-page .date-and-trading-hours-today .date-today .closed-banner {
    color: #b0204b;
    min-width: 582px;
    background: #ffe7df;
    border-radius: 8px;
    flex-direction: row;
    margin-top: 40px;
    padding: 16px 20px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    display: inline-flex;
  }

  .trading-hours-page .date-and-trading-hours-today .date-today .closed-banner .closed-label {
    margin-right: .25em;
    font-weight: bold;
  }

  .trading-hours-page .date-and-trading-hours-today .collection-type-trading-hours-today {
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    grid-template-rows: auto 1fr 1fr;
    padding: 30px 10px;
    display: grid;
    box-shadow: 0 2px 4px #0000001a;
  }

  .trading-hours-page .date-and-trading-hours-today .collection-type-trading-hours-today .collection-type {
    text-align: center;
    color: #40415b;
    padding-top: 23px;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .trading-hours-page .date-and-trading-hours-today .collection-type-trading-hours-today .collection-type {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (min-width: 768px) {
  .trading-hours-page .date-and-trading-hours-today .collection-type-trading-hours-today .logo {
    height: 62px;
    width: 62px;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;
    position: relative;
  }

  .trading-hours-page .date-and-trading-hours-today .collection-type-trading-hours-today .logo.pickup {
    background-image: url("pickup.21dd0bb4.svg");
  }

  .trading-hours-page .date-and-trading-hours-today .collection-type-trading-hours-today .logo.delivery {
    background-image: url("delivery.17d62165.svg");
    margin-bottom: -2px;
  }

  .trading-hours-page .date-and-trading-hours-today .collection-type-trading-hours-today .logo.dineIn {
    background-image: url("dine-in.ea337d61.svg");
  }

  .trading-hours-page .date-and-trading-hours-today .collection-type-trading-hours-today .sessions {
    flex-direction: column;
    align-items: center;
    padding-top: 8px;
    display: flex;
  }

  .trading-hours-page .date-and-trading-hours-today .collection-type-trading-hours-today .sessions .unavailable {
    text-align: center;
    color: #b0204b;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .trading-hours-page .date-and-trading-hours-today .collection-type-trading-hours-today .sessions .available {
    text-align: center;
    color: #27283e;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .trading-hours-page .date-and-trading-hours-today .date-today.closed {
    border-bottom: unset;
  }

  .trading-hours-page .date-and-trading-hours-today.closed {
    grid-auto-flow: column;
  }
}

@media (min-width: 768px) {
  .trading-hours-page .trading-hours-today {
    grid-gap: 20px;
    margin-bottom: 15px;
    display: flex;
  }

  .trading-hours-page .trading-hours-today.closed .unavailable {
    margin-bottom: 0 !important;
  }

  .trading-hours-page .label {
    width: -moz-fit-content;
    width: -moz-fit-content;
    width: -moz-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    grid-gap: 7px;
    border-radius: 8px;
    grid-auto-flow: column;
    align-items: center;
    margin-bottom: 10px;
    padding: 3px 10px 3px 3px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    display: grid;
  }

  .trading-hours-page .collection-type-selector {
    margin: 20px 0 40px;
    padding: 0 17px;
  }

  .trading-hours-page .collection-type-selector .collection-types-tab-selector {
    justify-content: center;
    display: flex;
  }

  .trading-hours-page .collection-type-selector .collection-types-tab-selector .collection-type {
    text-align: center;
    color: #757575;
    cursor: pointer;
    padding: 10px 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    box-shadow: inset 0 -1px #e3ebec;
  }

  .trading-hours-page .collection-type-selector .collection-types-tab-selector .collection-type.selected {
    color: #27283e;
    border-bottom: 1px solid #27283e;
    font-weight: 700;
  }

  .trading-hours-page .trading-hours {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    box-shadow: 0 2px 4px #0000001a;
  }

  .trading-hours-page .time-item {
    grid-gap: 10px;
    grid-row-gap: 5px;
    border-bottom: 1px solid #e0e0e0;
    grid-template-rows: 1fr;
    grid-template-columns: max-content 1fr;
    padding: 5px 23px;
    display: grid;
  }

  .trading-hours-page .time-item:last-child {
    border-bottom: none;
  }

  .trading-hours-page .time-item .dow {
    text-align: left;
    color: #27283e;
    padding: 10px 0;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .trading-hours-page .time-item .session {
    text-align: right;
    text-align: right;
    color: #27283e;
    justify-items: end;
    padding: 10px 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .trading-hours-page .time-item .session.closed {
    color: #b0204b;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@media (min-width: 768px) {
  .online-ordering .menu-page > .header {
    padding: 0;
  }

  .online-ordering .menu-page > .header .wrapper {
    position: relative;
  }

  .online-ordering .menu-page > .header .wrapper .nav {
    display: none;
  }

  .online-ordering .menu-page > .header .wrapper .banner-image {
    max-width: 1280px;
    position: relative;
  }

  .online-ordering .menu-page > .header .wrapper .banner-image .mobile {
    display: none;
  }

  .online-ordering .menu-page > .header .wrapper .banner-image .restaurant-info {
    z-index: 1;
    grid-gap: 43px;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    display: none;
    position: absolute;
    inset: 0;
  }

  .online-ordering .menu-page > .header .wrapper .banner-image .restaurant-info.desktop-banner .restaurant-logo {
    display: none;
  }

  .online-ordering .menu-page > .header .wrapper .banner-image .restaurant-info.desktop-banner-default {
    display: flex;
  }

  .online-ordering .menu-page > .header .wrapper .banner-image .restaurant-info .restaurant-logo {
    width: 162px;
    background-color: #fff;
    border-radius: 24px;
    margin: 0 auto;
  }

  .online-ordering .menu-page > .header .wrapper .banner-image .restaurant-info .restaurant-logo > .image {
    border-radius: 24px;
    overflow: hidden;
  }

  .online-ordering .menu-page > .header .wrapper .banner-image .restaurant-info .store-title {
    color: #fff;
    text-align: center;
    box-sizing: border-box;
  }

  .online-ordering .menu-page > .header .wrapper .banner-image .restaurant-info .store-title .store-name {
    margin-bottom: 6px;
    font-size: 26px;
    font-weight: 700;
    line-height: 32px;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .online-ordering .menu-page > .header .wrapper .banner-image .restaurant-info .store-title .store-name {
    font-size: 32px;
    line-height: 48px;
  }
}

@media (min-width: 768px) {
  .online-ordering .menu-page > .header .wrapper .banner-image .restaurant-info .store-title .store-location {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .online-ordering .menu-page > .header .wrapper .banner-image .restaurant-info .store-title .store-location .icon {
    margin-bottom: -3px;
    margin-right: 10px;
  }

  .online-ordering .menu-page > .body > .menu {
    grid-template: "collection-type-selector collection-type-selector collection-type-selector" 1fr
                   "session-view-widget session-view-widget session-view-widget" max-content
                   "store-closed-info store-closed-info store-closed-info"
                   "categories items cart-summary"
                   / minmax(min-content, 229px) 1fr minmax(min-content, 299px);
    display: grid;
  }

  .online-ordering .menu-page > .body > .menu .collection-type-selector-widget {
    grid-area: collection-type-selector;
  }

  .online-ordering .menu-page > .body > .menu .session-view-widget {
    grid-area: session-view-widget;
  }

  .online-ordering .menu-page > .body > .menu .store-closed-info-widget {
    grid-area: store-closed-info;
  }

  .online-ordering .menu-page > .body > .menu .category-list-widget {
    grid-area: categories;
  }

  .online-ordering .menu-page > .body > .menu .item-list-widget {
    grid-area: items;
  }

  .online-ordering .menu-page > .body > .menu .item-list-widget.no-items-found {
    grid-area: 4 / 1 / span 1 / span 2;
  }

  .online-ordering .menu-page > .body > .menu .cart-summary {
    grid-area: cart-summary;
    padding-left: 20px;
  }

  .online-ordering .menu-page > .body > .menu > #collection-type-selector-widget-anchor {
    margin-top: -59px;
    position: absolute;
  }

  .top-nav-bar-widget {
    z-index: 100;
    width: 100%;
    background-color: #27283e;
    position: sticky;
    top: 0;
  }

  .top-nav-bar-widget .wrapper {
    box-sizing: border-box;
    max-width: 1320px;
    grid-template-columns: minmax(20%, auto) 1fr minmax(20%, auto);
    grid-template-areas: "store-header-widget menu actions";
    margin: 0 auto;
    padding: 3.5px 20px;
    display: grid;
  }

  .top-nav-bar-widget .wrapper .back-button {
    display: none;
  }

  .top-nav-bar-widget .wrapper .store-header-widget {
    white-space: nowrap;
    cursor: pointer;
    grid-area: store-header-widget;
  }

  .top-nav-bar-widget .wrapper .menu {
    flex-direction: row;
    grid-area: menu;
    justify-content: center;
    display: flex;
  }

  .top-nav-bar-widget .wrapper .menu .item, .top-nav-bar-widget .wrapper .menu .item-with-counter {
    cursor: pointer;
    height: 53px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 12.5px;
    display: flex;
    position: relative;
  }

  .top-nav-bar-widget .wrapper .menu .item .label, .top-nav-bar-widget .wrapper .menu .item-with-counter .label {
    text-align: center;
    color: #a9a9b8;
    font-family: SF Pro Display;
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .top-nav-bar-widget .wrapper .menu .item .label, .top-nav-bar-widget .wrapper .menu .item-with-counter .label {
    font-family: Chivo;
    font-size: 14px;
    line-height: 20px;
  }
}

@media (min-width: 768px) {
  .top-nav-bar-widget .wrapper .menu .item.selected .label, .top-nav-bar-widget .wrapper .menu .item-with-counter.selected .label {
    color: #fff;
  }

  .top-nav-bar-widget .wrapper .menu .item .count, .top-nav-bar-widget .wrapper .menu .item-with-counter .count {
    color: #fff;
    background: red;
    border-radius: 5px;
    padding: 1px 5px 0 6px;
    font-size: 10px;
    font-weight: 500;
    position: absolute;
    top: 9px;
    right: -13px;
  }

  .top-nav-bar-widget .wrapper .actions {
    flex-direction: row;
    grid-area: actions;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    display: flex;
  }

  .top-nav-bar-widget .wrapper .actions .sidebar-menu-button {
    cursor: pointer;
    display: flex;
  }

  .top-nav-bar-widget .wrapper .actions .login-icon {
    width: 28px;
    height: 28px;
    cursor: pointer;
    font-size: 30px;
  }

  .top-nav-bar-widget .wrapper .actions .cart {
    cursor: pointer;
    margin-top: 2px;
    margin-right: 25px;
    position: relative;
  }

  .top-nav-bar-widget .wrapper .actions .cart .cart-icon {
    width: 28px;
    height: 28px;
    opacity: .45;
  }

  .top-nav-bar-widget .wrapper .actions .cart .count {
    text-align: left;
    color: #fff;
    background: red;
    border-radius: 50%;
    grid-area: count;
    padding: 10px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    position: absolute;
    top: -4px;
    right: calc(50% - 23px);
  }

  .top-nav-bar-widget .wrapper .actions .cart .count > span {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 1px;
    left: 0;
  }

  .top-nav-bar-widget .wrapper .actions .chevron-down-icon {
    cursor: pointer;
    grid-area: chevron-down-icon;
    align-self: center;
    padding-left: 6px;
  }

  .top-nav-bar-widget.store-closed, .top-nav-bar-widget.platform-maintenance {
    top: 60px;
  }

  .cart-widget {
    grid-template-areas: "cart-header"
                         "collection-info"
                         "items"
                         "totals"
                         "checkout";
    padding-top: 68px;
    padding-bottom: 100px;
    padding-left: 10px;
    display: grid;
  }

  .cart-widget .collection-info {
    grid-area: collection-info;
  }

  .cart-widget .collection-info .session-info {
    display: none;
  }

  .cart-widget .collection-info .minimum-order-value-info {
    align-items: flex-start;
    gap: 6px;
    margin-top: 25px;
    display: flex;
  }

  .cart-widget .collection-info .minimum-order-value-info .text {
    color: #d67300;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .cart-widget .empty-cart-header, .cart-widget .cart-header {
    flex-direction: row;
    grid-area: cart-header;
    justify-content: space-between;
    align-items: center;
    padding-left: 5px;
    display: flex;
  }

  .cart-widget .empty-cart-header.empty-cart, .cart-widget .cart-header.empty-cart {
    padding-top: 0;
  }

  .cart-widget .empty-cart-header .item-count, .cart-widget .cart-header .item-count {
    text-align: left;
    color: #757575;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .cart-widget .empty-cart-header .cart-header-text, .cart-widget .cart-header .cart-header-text {
    text-align: left;
    color: #27283e;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .cart-widget .cart {
    background-color: var(--card-bg);
    border: 1px solid #e2e2ea;
    border-radius: 10px;
    box-shadow: 0 0 3px #687a9b40;
  }

  .cart-widget .empty-cart {
    text-align: center;
    padding-top: 55px;
  }

  .cart-widget .empty-cart > .cart-image {
    margin-bottom: 29px;
  }

  .cart-widget .empty-cart > .text {
    color: #9e9e9e;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .cart-widget .empty-cart > .sub-text {
    color: #757575;
    margin-top: 11px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .cart-widget .totals {
    grid-area: totals;
  }

  .cart-widget .totals .sub-total, .cart-widget .totals .delivery-charges, .cart-widget .totals .public-holiday-surcharge, .cart-widget .totals .grand-total, .cart-widget .totals .gst-amount {
    text-align: left;
    color: #27283e;
    border-bottom: 1px solid #e3ebec;
    grid-auto-flow: column;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 15px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: grid;
  }

  .cart-widget .totals .grand-total {
    letter-spacing: .25px;
    border: none;
    padding-top: 10px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .cart-widget .totals .gst-amount {
    padding-bottom: 40px;
  }

  .cart-widget .checkout {
    grid-area: checkout;
    grid-auto-flow: column;
    align-items: center;
    display: grid;
  }

  .cart-widget .checkout .button {
    color: #fff;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    background: #0162ff;
    border-radius: 10px;
    padding: 8px 0;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .cart-widget .checkout .button.disabled {
    color: #bcbcc5;
    cursor: not-allowed;
    background: #f1f1f5;
  }

  .cart-widget .checkout .quantity {
    text-align: end;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .cart-widget .select-all {
    grid-auto-flow: column;
    justify-content: space-between;
    padding: 10px 20px 10px 15px;
    display: grid;
  }

  .cart-widget .select-all > .check, .cart-widget .select-all > .remove {
    cursor: pointer;
    grid-auto-flow: column;
    align-items: center;
    display: grid;
  }

  .cart-widget .select-all .check > input {
    cursor: pointer;
    margin-right: 8px;
  }

  .cart-widget .select-all .check > .text {
    color: #426ed9;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .cart-widget .select-all .remove > i {
    font-size: 20px;
  }

  .cart-widget .select-all .remove {
    color: #fc6565;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .cart-widget .select-all .remove.disabled {
    color: #bcbcc5;
    cursor: not-allowed;
  }

  .cart-widget .items {
    grid-gap: 15px;
    grid-area: items;
    padding-top: 25px;
    padding-bottom: 15px;
    display: grid;
  }

  .cart-widget .items .item {
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid #e2e2e2;
    border-radius: 8px;
    grid-template-columns: 1fr min-content;
    grid-template-areas: "name . remove-icon"
                         "description description description"
                         "quantity deal price";
    padding: 15px 15px 19px;
    display: grid;
    box-shadow: 0 2px 4px #0000000d;
  }

  .cart-widget .items .deal {
    cursor: pointer;
    text-align: left;
    color: #d67300;
    text-transform: uppercase;
    grid-area: deal;
    align-items: center;
    padding: 20px 5px 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    display: flex;
  }

  .cart-widget .items .deal img {
    margin-top: -2px;
    margin-right: 2px;
  }

  .cart-widget .items .remove-icon {
    cursor: pointer;
    grid-area: remove-icon;
    justify-self: flex-end;
    margin: 4px;
  }

  .cart-widget .items .name {
    text-align: left;
    color: #27283e;
    grid-area: name;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .cart-widget .items .quantity {
    grid-area: quantity;
    grid-template-columns: min-content min-content min-content;
    gap: 22px;
    padding-top: 15px;
    display: grid;
  }

  .cart-widget .items .quantity > .number {
    text-align: center;
    color: #27283e;
    align-self: center;
    padding: 0 2px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: grid;
  }

  .cart-widget .items .quantity > .icon {
    width: 23px;
    height: 23px;
    text-align: center;
    color: #426ed9;
    cursor: pointer;
    border-radius: 5px;
    align-self: center;
    font-size: 20px;
    display: grid;
  }

  .cart-widget .items .price {
    text-align: right;
    color: #1faa89;
    grid-area: price;
    justify-self: flex-end;
    padding-top: 21px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .cart-widget .items .divider {
    height: 1px;
    background-color: #e2e2ea;
    grid-area: divider;
    margin-top: 20px;
  }

  .cart-widget .items .divider.hide {
    display: none;
  }

  .cart-widget .items .item-description-view-widget {
    text-align: left;
    color: #757575;
    grid-area: description;
    padding-top: 10px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .cart-widget .items .item-description-view-widget.hide {
    display: none;
  }

  .loyalty-card-page {
    padding-bottom: 90px;
  }

  .loyalty-card-page > .spinner {
    text-align: center;
    margin: 50px;
  }

  .loyalty-card-page > .alert {
    margin: 20px;
  }

  .loyalty-card-page .loyalty {
    flex-direction: column;
    margin: 42px 20px;
    display: flex;
  }

  .loyalty-card-page .loyalty .loyalty-card-widget {
    align-self: center;
  }

  .loyalty-card-page .loyalty .claim-info {
    text-align: center;
    margin: 58px 2px 0;
  }

  .loyalty-card-page .loyalty .claim-info .title {
    color: #27283e;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .loyalty-card-page .loyalty .claim-info .title {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (min-width: 768px) {
  .loyalty-card-page .loyalty .claim-info .subtitle {
    white-space: pre-wrap;
    color: #757575;
    width: 390px;
    margin: 5px auto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .session-view-widget {
    min-width: 0;
    cursor: pointer;
    height: 30px;
    color: #086e37;
    background: #def2d6;
    justify-content: center;
    align-items: center;
    padding: 14.5px 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
  }

  .session-view-widget .session {
    display: flex;
  }

  .session-view-widget .session .collection-info {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .datetime, .to, .address, .table-name {
    margin-left: 4px;
    font-weight: 700;
    display: inline;
  }

  .to {
    font-weight: 400;
  }

  .action-label {
    margin-left: 16px;
    font-weight: 700;
    display: inline;
  }

  .no-coupons {
    margin-top: 60px;
  }

  .no-coupons > .icon {
    margin: 0 auto;
    display: block;
  }

  .no-coupons > .text {
    color: #757575;
    width: 240px;
    text-align: center;
    margin: 15px auto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .coupons {
    max-width: 864px;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px 6px;
    margin: 29px auto 52px;
    display: grid;
  }

  .coupons .coupon {
    -webkit-clip-path: inset(-10px -10px -10px 0);
    clip-path: inset(-10px -10px -10px 0);
    width: 400px;
    min-height: 154px;
    position: relative;
    box-shadow: 0 0 11px #bdbdbd78;
  }

  .coupons .coupon .zigzag {
    width: 12px;
    height: 100%;
    background-color: #0000;
    background-image: linear-gradient(45deg, #fff0 45%, #c4c4c411 45% 55%, #fff 55%), linear-gradient(315deg, #fff 45%, #c4c4c411 45% 55%, #f4f4f45e 55%);
    background-position: 0 0, 0 0;
    background-repeat: repeat-y;
    background-size: 23px 20px;
    background-attachment: scroll, scroll;
    background-origin: padding-box, padding-box;
    background-clip: border-box, border-box;
    position: absolute;
    top: 0;
    left: 0;
  }

  .coupons .coupon .body {
    min-height: 120px;
    color: #27283e;
    grid-template: "code icon" min-content
                   "description icon" 1fr
                   "discount availability" min-content
                   / 1fr min-content;
    gap: 3px 5px;
    padding: 16px 13px 11px 21px;
    display: grid;
  }

  .coupons .coupon .body.used {
    filter: grayscale();
    color: #27283e69;
  }

  .coupons .coupon .body .code {
    text-overflow: ellipsis;
    letter-spacing: 2px;
    text-transform: uppercase;
    white-space: nowrap;
    width: 100%;
    grid-area: code;
    font-family: IBM Plex;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    overflow: hidden;
  }

  .coupons .coupon .body .icon {
    width: 40px;
    height: 32px;
    grid-area: icon;
    padding-left: 30px;
  }

  .coupons .coupon .body .icon.available {
    right: 0;
  }

  .coupons .coupon .body .description {
    max-height: 63px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    grid-area: description;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: -webkit-box;
    overflow: hidden;
  }

  .coupons .coupon .body .discount {
    color: #27283e;
    grid-area: discount;
    margin-top: auto;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .coupons .coupon .body .availability {
    text-transform: capitalize;
    color: #4cbba1;
    grid-area: availability;
    justify-self: end;
    margin-top: auto;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    position: relative;
  }

  .collection-type-selector-widget {
    background-color: #ffebcf;
    place-content: space-between;
    align-items: center;
    padding: 10px 20px;
    display: flex;
  }

  .collection-type-selector-widget .collection-types {
    width: 27%;
    min-width: 300px;
  }

  .collection-type-selector-widget .collection-types .collection-type-single {
    text-align: left;
    color: #d67300;
    padding: 10px 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .collection-type-selector-widget .collection-types .collection-type-single span {
    cursor: pointer;
    text-align: left;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .collection-type-selector-widget .collection-types .collection-type-single span:last-of-type {
    margin-left: 1em;
  }

  .collection-type-selector-widget .collection-types .collection-type-selector {
    background-color: #d673001a;
    border-radius: 10px;
    grid-template-columns: 1fr;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    gap: 5px;
    padding: 4px;
    display: grid;
    overflow: hidden;
  }

  .collection-type-selector-widget .collection-types .collection-type-selector .start-order, .collection-type-selector-widget .collection-types .collection-type-selector .collection-type {
    text-align: center;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    background-color: #0000;
    border-radius: 10px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 3px 0;
    display: flex;
    position: relative;
  }

  .collection-type-selector-widget .collection-types .collection-type-selector .start-order .collection-label, .collection-type-selector-widget .collection-types .collection-type-selector .collection-type .collection-label {
    padding: 1px;
  }

  .collection-type-selector-widget .collection-types .collection-type-selector .start-order span, .collection-type-selector-widget .collection-types .collection-type-selector .collection-type span {
    text-align: left;
    color: #d67300;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .collection-type-selector-widget .collection-types .collection-type-selector .start-order.selected, .collection-type-selector-widget .collection-types .collection-type-selector .collection-type.selected {
    background-color: #fff;
  }

  .collection-type-selector-widget .collection-types .collection-type-selector .start-order.selected .check, .collection-type-selector-widget .collection-types .collection-type-selector .collection-type.selected .check {
    width: 16px;
    margin-right: 10px;
    left: 33px;
  }

  .collection-type-selector-widget .collection-types .collection-type-selector .start-order {
    background-color: var(--button-success-bg);
    color: var(--button-success-fg);
  }

  .collection-type-selector-widget .start-ordering-link {
    text-align: left;
    color: #d67300;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .order-success-page {
    background-color: #fff;
  }

  .order-success-page.page > .header {
    box-shadow: none;
    padding: 0;
  }

  .order-success-page .order-success-page-wrapper {
    max-width: 1100px;
    margin: 0 auto;
  }

  .order-success-page .order-success-page-wrapper .checkout-banner {
    text-align: center;
    margin: 36px 20px;
  }

  .order-success-page .order-success-page-wrapper .checkout-banner .icon {
    width: 93px;
    margin-bottom: 24px;
  }

  .order-success-page .order-success-page-wrapper .checkout-banner .text {
    color: #27283e;
    font-size: 26px;
    font-weight: 700;
    line-height: 32px;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .order-success-page .order-success-page-wrapper .checkout-banner .text {
    font-size: 32px;
    line-height: 48px;
  }
}

@media (min-width: 768px) {
  .order-success-page .order-success-page-wrapper .order-tracking-label {
    color: #27283e;
    margin: 5px 20px;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }

  .order-success-page .order-success-page-wrapper .order-placed-time {
    text-align: center;
    color: #9191a1;
    margin: 0 auto;
    padding-top: 71px;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .order-success-page .order-success-page-wrapper .order-placed-time {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (min-width: 768px) {
  .order-success-page .order-success-page-wrapper .buttons {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 60px;
    display: flex;
  }

  .order-success-page .order-success-page-wrapper .buttons > .customer-review-link-button {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    margin-top: 44px;
    padding: 20px;
    box-shadow: 0 2px 4px #0000000d;
  }

  .order-success-page .order-success-page-wrapper .buttons > .customer-review-link-button > .label {
    align-items: center;
    display: flex;
  }

  .order-success-page .order-success-page-wrapper .buttons > .customer-review-link-button > .label img {
    width: 44px;
    height: 44px;
  }

  .order-success-page .order-success-page-wrapper .buttons > .customer-review-link-button > .label span {
    color: #1c1d3e;
    padding-left: 16px;
  }

  .order-success-page .order-success-page-wrapper .buttons .order-history-button {
    box-shadow: none;
    color: #4cbba1;
    background-color: #0000;
    border: 2px solid #4cbba1;
    margin-top: 44px;
    padding: 14px 50px;
  }

  .order-success-page .order-success-page-wrapper .buttons .back-to-menu-button {
    box-shadow: none;
    color: #9e9e9e;
    background-color: #0000;
    border: none;
    margin-top: 16px;
    padding: 14px 50px;
  }

  .loyalty-card-modal {
    background-color: #fff;
    width: 422px !important;
  }

  .loyalty-card-modal .loyalty-banner {
    text-align: center;
    margin: 21px;
  }

  .loyalty-card-modal .loyalty-banner .icon {
    margin-bottom: 8px;
  }

  .loyalty-card-modal .loyalty-banner .text {
    text-align: center;
    color: #27283e;
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .loyalty-card-modal .loyalty-banner .subtext {
    text-align: center;
    color: #757575;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .loyalty-card-modal .card {
    margin: 37px 54px 0;
  }

  .loyalty-card-modal .card .loyalty-card-widget {
    width: inherit !important;
  }

  .loyalty-card-modal .card .loyalty-card-widget .details .balance {
    font-size: 28px;
  }

  .loyalty-card-modal .card .loyalty-card-widget .details .customer-info {
    font-size: 16px;
  }

  .loyalty-card-modal .caption {
    text-align: center;
    color: #757575;
    margin: 30px 53px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .loyalty-card-modal .button {
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    cursor: pointer;
    background: #4cbba1;
    border-radius: 8px;
    align-content: flex-end;
    margin: 41px 30px 21px;
    padding: 8px 0;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .order-status-widget {
    box-sizing: border-box;
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin: 0 20px 24px;
    padding: 30px 31px;
    box-shadow: 0 2px 4px #0000001a;
  }

  .order-status-widget .website-order-delivery-timeline-widget, .order-status-widget .website-order-pick-up-timeline-widget, .order-status-widget .website-order-dine-in-timeline-widget {
    max-width: 470px;
    margin: 0 auto;
  }

  .order-status-widget .website-order-delivery-timeline-widget .in-house-delivery-timeline, .order-status-widget .website-order-delivery-timeline-widget .pick-up-timeline, .order-status-widget .website-order-delivery-timeline-widget .dine-in-timeline, .order-status-widget .website-order-pick-up-timeline-widget .in-house-delivery-timeline, .order-status-widget .website-order-pick-up-timeline-widget .pick-up-timeline, .order-status-widget .website-order-pick-up-timeline-widget .dine-in-timeline, .order-status-widget .website-order-dine-in-timeline-widget .in-house-delivery-timeline, .order-status-widget .website-order-dine-in-timeline-widget .pick-up-timeline, .order-status-widget .website-order-dine-in-timeline-widget .dine-in-timeline {
    justify-content: center;
    align-items: center;
    padding: 0 128px;
    display: flex;
  }

  .order-status-widget .website-order-delivery-timeline-widget .in-house-delivery-timeline .timeline-event-container, .order-status-widget .website-order-delivery-timeline-widget .pick-up-timeline .timeline-event-container, .order-status-widget .website-order-delivery-timeline-widget .dine-in-timeline .timeline-event-container, .order-status-widget .website-order-pick-up-timeline-widget .in-house-delivery-timeline .timeline-event-container, .order-status-widget .website-order-pick-up-timeline-widget .pick-up-timeline .timeline-event-container, .order-status-widget .website-order-pick-up-timeline-widget .dine-in-timeline .timeline-event-container, .order-status-widget .website-order-dine-in-timeline-widget .in-house-delivery-timeline .timeline-event-container, .order-status-widget .website-order-dine-in-timeline-widget .pick-up-timeline .timeline-event-container, .order-status-widget .website-order-dine-in-timeline-widget .dine-in-timeline .timeline-event-container {
    width: 100%;
  }

  .order-status-widget .website-order-delivery-timeline-widget .in-house-delivery-timeline .timeline-event-container:first-child, .order-status-widget .website-order-delivery-timeline-widget .pick-up-timeline .timeline-event-container:first-child, .order-status-widget .website-order-delivery-timeline-widget .dine-in-timeline .timeline-event-container:first-child, .order-status-widget .website-order-pick-up-timeline-widget .in-house-delivery-timeline .timeline-event-container:first-child, .order-status-widget .website-order-pick-up-timeline-widget .pick-up-timeline .timeline-event-container:first-child, .order-status-widget .website-order-pick-up-timeline-widget .dine-in-timeline .timeline-event-container:first-child, .order-status-widget .website-order-dine-in-timeline-widget .in-house-delivery-timeline .timeline-event-container:first-child, .order-status-widget .website-order-dine-in-timeline-widget .pick-up-timeline .timeline-event-container:first-child, .order-status-widget .website-order-dine-in-timeline-widget .dine-in-timeline .timeline-event-container:first-child {
    width: 60px;
  }

  .order-status-widget .website-order-delivery-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper, .order-status-widget .website-order-delivery-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper, .order-status-widget .website-order-delivery-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper, .order-status-widget .website-order-pick-up-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper, .order-status-widget .website-order-pick-up-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper, .order-status-widget .website-order-pick-up-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper, .order-status-widget .website-order-dine-in-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper, .order-status-widget .website-order-dine-in-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper, .order-status-widget .website-order-dine-in-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .order-status-widget .website-order-delivery-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-line, .order-status-widget .website-order-delivery-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-line, .order-status-widget .website-order-delivery-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-line, .order-status-widget .website-order-pick-up-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-line, .order-status-widget .website-order-pick-up-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-line, .order-status-widget .website-order-pick-up-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-line, .order-status-widget .website-order-dine-in-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-line, .order-status-widget .website-order-dine-in-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-line, .order-status-widget .website-order-dine-in-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-line {
    width: 100%;
    height: 2px;
  }

  .order-status-widget .website-order-delivery-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color, .order-status-widget .website-order-delivery-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color, .order-status-widget .website-order-delivery-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color, .order-status-widget .website-order-pick-up-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color, .order-status-widget .website-order-pick-up-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color, .order-status-widget .website-order-pick-up-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color, .order-status-widget .website-order-dine-in-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color, .order-status-widget .website-order-dine-in-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color, .order-status-widget .website-order-dine-in-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color {
    border-radius: 50%;
    margin: 0 5px;
    padding: 12px 13px;
  }

  .order-status-widget .website-order-delivery-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color img, .order-status-widget .website-order-delivery-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color img, .order-status-widget .website-order-delivery-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color img, .order-status-widget .website-order-pick-up-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color img, .order-status-widget .website-order-pick-up-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color img, .order-status-widget .website-order-pick-up-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color img, .order-status-widget .website-order-dine-in-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color img, .order-status-widget .website-order-dine-in-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color img, .order-status-widget .website-order-dine-in-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color img {
    width: 20px;
    display: block;
  }

  .order-status-widget .website-order-delivery-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper .default-status-color, .order-status-widget .website-order-delivery-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper .default-status-color, .order-status-widget .website-order-delivery-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper .default-status-color, .order-status-widget .website-order-pick-up-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper .default-status-color, .order-status-widget .website-order-pick-up-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper .default-status-color, .order-status-widget .website-order-pick-up-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper .default-status-color, .order-status-widget .website-order-dine-in-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper .default-status-color, .order-status-widget .website-order-dine-in-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper .default-status-color, .order-status-widget .website-order-dine-in-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper .default-status-color {
    background-color: #c8c8d0;
  }

  .order-status-widget .website-order-delivery-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper .success, .order-status-widget .website-order-delivery-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper .success, .order-status-widget .website-order-delivery-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper .success, .order-status-widget .website-order-pick-up-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper .success, .order-status-widget .website-order-pick-up-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper .success, .order-status-widget .website-order-pick-up-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper .success, .order-status-widget .website-order-dine-in-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper .success, .order-status-widget .website-order-dine-in-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper .success, .order-status-widget .website-order-dine-in-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper .success {
    background-color: #4cbba1;
  }

  .order-status-widget .website-order-delivery-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper .delivery-failed, .order-status-widget .website-order-delivery-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper .delivery-failed, .order-status-widget .website-order-delivery-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper .delivery-failed, .order-status-widget .website-order-pick-up-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper .delivery-failed, .order-status-widget .website-order-pick-up-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper .delivery-failed, .order-status-widget .website-order-pick-up-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper .delivery-failed, .order-status-widget .website-order-dine-in-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper .delivery-failed, .order-status-widget .website-order-dine-in-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper .delivery-failed, .order-status-widget .website-order-dine-in-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper .delivery-failed {
    background-color: #fbbf24;
  }

  .order-status-widget .website-order-delivery-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper .rejected, .order-status-widget .website-order-delivery-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper .rejected, .order-status-widget .website-order-delivery-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper .rejected, .order-status-widget .website-order-pick-up-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper .rejected, .order-status-widget .website-order-pick-up-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper .rejected, .order-status-widget .website-order-pick-up-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper .rejected, .order-status-widget .website-order-dine-in-timeline-widget .in-house-delivery-timeline .timeline-event-container .timeline-event-wrapper .rejected, .order-status-widget .website-order-dine-in-timeline-widget .pick-up-timeline .timeline-event-container .timeline-event-wrapper .rejected, .order-status-widget .website-order-dine-in-timeline-widget .dine-in-timeline .timeline-event-container .timeline-event-wrapper .rejected {
    background-color: #9f1239;
  }

  .order-status-widget .website-order-delivery-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house, .order-status-widget .website-order-pick-up-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house, .order-status-widget .website-order-dine-in-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .order-status-widget .website-order-delivery-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container, .order-status-widget .website-order-pick-up-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container, .order-status-widget .website-order-dine-in-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container {
    width: 100%;
  }

  .order-status-widget .website-order-delivery-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container:first-child, .order-status-widget .website-order-pick-up-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container:first-child, .order-status-widget .website-order-dine-in-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container:first-child {
    width: 60px;
  }

  .order-status-widget .website-order-delivery-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper, .order-status-widget .website-order-pick-up-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper, .order-status-widget .website-order-dine-in-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .order-status-widget .website-order-delivery-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper .timeline-event-line, .order-status-widget .website-order-pick-up-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper .timeline-event-line, .order-status-widget .website-order-dine-in-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper .timeline-event-line {
    width: 100%;
    height: 2px;
  }

  .order-status-widget .website-order-delivery-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper .timeline-event-color, .order-status-widget .website-order-pick-up-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper .timeline-event-color, .order-status-widget .website-order-dine-in-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper .timeline-event-color {
    border-radius: 50%;
    margin: 0 5px;
    padding: 12px 13px;
  }

  .order-status-widget .website-order-delivery-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper .timeline-event-color img, .order-status-widget .website-order-pick-up-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper .timeline-event-color img, .order-status-widget .website-order-dine-in-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper .timeline-event-color img {
    width: 20px;
    display: block;
  }

  .order-status-widget .website-order-delivery-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper .default-status-color, .order-status-widget .website-order-pick-up-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper .default-status-color, .order-status-widget .website-order-dine-in-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper .default-status-color {
    background-color: #c8c8d0;
  }

  .order-status-widget .website-order-delivery-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper .success, .order-status-widget .website-order-pick-up-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper .success, .order-status-widget .website-order-dine-in-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper .success {
    background-color: #4cbba1;
  }

  .order-status-widget .website-order-delivery-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper .delivery-failed, .order-status-widget .website-order-pick-up-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper .delivery-failed, .order-status-widget .website-order-dine-in-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper .delivery-failed {
    background-color: #fbbf24;
  }

  .order-status-widget .website-order-delivery-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper .rejected, .order-status-widget .website-order-pick-up-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper .rejected, .order-status-widget .website-order-dine-in-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-unabled-in-house .timeline-event-container .timeline-event-wrapper .rejected {
    background-color: #9f1239;
  }

  .order-status-widget .website-order-delivery-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house, .order-status-widget .website-order-pick-up-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house, .order-status-widget .website-order-dine-in-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house {
    justify-content: center;
    align-items: center;
    padding: 0 128px;
    display: flex;
  }

  .order-status-widget .website-order-delivery-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container, .order-status-widget .website-order-pick-up-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container, .order-status-widget .website-order-dine-in-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container {
    width: 100%;
  }

  .order-status-widget .website-order-delivery-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container:first-child, .order-status-widget .website-order-pick-up-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container:first-child, .order-status-widget .website-order-dine-in-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container:first-child {
    width: 60px;
  }

  .order-status-widget .website-order-delivery-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper, .order-status-widget .website-order-pick-up-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper, .order-status-widget .website-order-dine-in-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .order-status-widget .website-order-delivery-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper .timeline-event-line, .order-status-widget .website-order-pick-up-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper .timeline-event-line, .order-status-widget .website-order-dine-in-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper .timeline-event-line {
    width: 100%;
    height: 2px;
  }

  .order-status-widget .website-order-delivery-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper .timeline-event-color, .order-status-widget .website-order-pick-up-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper .timeline-event-color, .order-status-widget .website-order-dine-in-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper .timeline-event-color {
    border-radius: 50%;
    margin: 0 5px;
    padding: 12px 13px;
  }

  .order-status-widget .website-order-delivery-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper .timeline-event-color img, .order-status-widget .website-order-pick-up-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper .timeline-event-color img, .order-status-widget .website-order-dine-in-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper .timeline-event-color img {
    width: 20px;
    display: block;
  }

  .order-status-widget .website-order-delivery-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper .default-status-color, .order-status-widget .website-order-pick-up-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper .default-status-color, .order-status-widget .website-order-dine-in-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper .default-status-color {
    background-color: #c8c8d0;
  }

  .order-status-widget .website-order-delivery-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper .success, .order-status-widget .website-order-pick-up-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper .success, .order-status-widget .website-order-dine-in-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper .success {
    background-color: #4cbba1;
  }

  .order-status-widget .website-order-delivery-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper .delivery-failed, .order-status-widget .website-order-pick-up-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper .delivery-failed, .order-status-widget .website-order-dine-in-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper .delivery-failed {
    background-color: #fbbf24;
  }

  .order-status-widget .website-order-delivery-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper .rejected, .order-status-widget .website-order-pick-up-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper .rejected, .order-status-widget .website-order-dine-in-timeline-widget .no-delivery-channel-timeline .enabled-uber-direct-and-in-house .timeline-event-container .timeline-event-wrapper .rejected {
    background-color: #9f1239;
  }

  .order-status-widget .website-order-delivery-timeline-widget .uber-delivery-timeline, .order-status-widget .website-order-pick-up-timeline-widget .uber-delivery-timeline, .order-status-widget .website-order-dine-in-timeline-widget .uber-delivery-timeline {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .order-status-widget .website-order-delivery-timeline-widget .uber-delivery-timeline .timeline-event-container, .order-status-widget .website-order-pick-up-timeline-widget .uber-delivery-timeline .timeline-event-container, .order-status-widget .website-order-dine-in-timeline-widget .uber-delivery-timeline .timeline-event-container {
    width: 100%;
  }

  .order-status-widget .website-order-delivery-timeline-widget .uber-delivery-timeline .timeline-event-container:first-child, .order-status-widget .website-order-pick-up-timeline-widget .uber-delivery-timeline .timeline-event-container:first-child, .order-status-widget .website-order-dine-in-timeline-widget .uber-delivery-timeline .timeline-event-container:first-child {
    width: 60px;
  }

  .order-status-widget .website-order-delivery-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper, .order-status-widget .website-order-pick-up-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper, .order-status-widget .website-order-dine-in-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .order-status-widget .website-order-delivery-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-line, .order-status-widget .website-order-pick-up-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-line, .order-status-widget .website-order-dine-in-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-line {
    width: 100%;
    height: 2px;
  }

  .order-status-widget .website-order-delivery-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color, .order-status-widget .website-order-pick-up-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color, .order-status-widget .website-order-dine-in-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color {
    border-radius: 50%;
    margin: 0 5px;
    padding: 12px 13px;
  }

  .order-status-widget .website-order-delivery-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color img, .order-status-widget .website-order-pick-up-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color img, .order-status-widget .website-order-dine-in-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper .timeline-event-color img {
    width: 20px;
    display: block;
  }

  .order-status-widget .website-order-delivery-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper .default-status-color, .order-status-widget .website-order-pick-up-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper .default-status-color, .order-status-widget .website-order-dine-in-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper .default-status-color {
    background-color: #c8c8d0;
  }

  .order-status-widget .website-order-delivery-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper .success, .order-status-widget .website-order-pick-up-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper .success, .order-status-widget .website-order-dine-in-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper .success {
    background-color: #4cbba1;
  }

  .order-status-widget .website-order-delivery-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper .delivery-failed, .order-status-widget .website-order-pick-up-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper .delivery-failed, .order-status-widget .website-order-dine-in-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper .delivery-failed {
    background-color: #fbbf24;
  }

  .order-status-widget .website-order-delivery-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper .rejected, .order-status-widget .website-order-pick-up-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper .rejected, .order-status-widget .website-order-dine-in-timeline-widget .uber-delivery-timeline .timeline-event-container .timeline-event-wrapper .rejected {
    background-color: #9f1239;
  }

  .order-status-widget .website-order-delivery-timeline-widget .delivery-status-label, .order-status-widget .website-order-delivery-timeline-widget .pick-up-status-label, .order-status-widget .website-order-delivery-timeline-widget .dine-in-status-label, .order-status-widget .website-order-pick-up-timeline-widget .delivery-status-label, .order-status-widget .website-order-pick-up-timeline-widget .pick-up-status-label, .order-status-widget .website-order-pick-up-timeline-widget .dine-in-status-label, .order-status-widget .website-order-dine-in-timeline-widget .delivery-status-label, .order-status-widget .website-order-dine-in-timeline-widget .pick-up-status-label, .order-status-widget .website-order-dine-in-timeline-widget .dine-in-status-label {
    text-align: center;
    margin-top: 20px;
  }

  .order-status-widget .website-order-delivery-timeline-widget .delivery-status-label .title, .order-status-widget .website-order-delivery-timeline-widget .pick-up-status-label .title, .order-status-widget .website-order-delivery-timeline-widget .dine-in-status-label .title, .order-status-widget .website-order-pick-up-timeline-widget .delivery-status-label .title, .order-status-widget .website-order-pick-up-timeline-widget .pick-up-status-label .title, .order-status-widget .website-order-pick-up-timeline-widget .dine-in-status-label .title, .order-status-widget .website-order-dine-in-timeline-widget .delivery-status-label .title, .order-status-widget .website-order-dine-in-timeline-widget .pick-up-status-label .title, .order-status-widget .website-order-dine-in-timeline-widget .dine-in-status-label .title {
    text-align: center;
    color: #4cbba1;
    margin: 8px 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
  }

  .order-status-widget .website-order-delivery-timeline-widget .delivery-status-label .rejected, .order-status-widget .website-order-delivery-timeline-widget .pick-up-status-label .rejected, .order-status-widget .website-order-delivery-timeline-widget .dine-in-status-label .rejected, .order-status-widget .website-order-pick-up-timeline-widget .delivery-status-label .rejected, .order-status-widget .website-order-pick-up-timeline-widget .pick-up-status-label .rejected, .order-status-widget .website-order-pick-up-timeline-widget .dine-in-status-label .rejected, .order-status-widget .website-order-dine-in-timeline-widget .delivery-status-label .rejected, .order-status-widget .website-order-dine-in-timeline-widget .pick-up-status-label .rejected, .order-status-widget .website-order-dine-in-timeline-widget .dine-in-status-label .rejected {
    color: #9f1239;
  }

  .order-status-widget .website-order-delivery-timeline-widget .delivery-status-label .delivery-failed, .order-status-widget .website-order-delivery-timeline-widget .pick-up-status-label .delivery-failed, .order-status-widget .website-order-delivery-timeline-widget .dine-in-status-label .delivery-failed, .order-status-widget .website-order-pick-up-timeline-widget .delivery-status-label .delivery-failed, .order-status-widget .website-order-pick-up-timeline-widget .pick-up-status-label .delivery-failed, .order-status-widget .website-order-pick-up-timeline-widget .dine-in-status-label .delivery-failed, .order-status-widget .website-order-dine-in-timeline-widget .delivery-status-label .delivery-failed, .order-status-widget .website-order-dine-in-timeline-widget .pick-up-status-label .delivery-failed, .order-status-widget .website-order-dine-in-timeline-widget .dine-in-status-label .delivery-failed {
    color: #fbbf24;
  }

  .order-status-widget .website-order-delivery-timeline-widget .delivery-status-label .sub-title, .order-status-widget .website-order-delivery-timeline-widget .pick-up-status-label .sub-title, .order-status-widget .website-order-delivery-timeline-widget .dine-in-status-label .sub-title, .order-status-widget .website-order-pick-up-timeline-widget .delivery-status-label .sub-title, .order-status-widget .website-order-pick-up-timeline-widget .pick-up-status-label .sub-title, .order-status-widget .website-order-pick-up-timeline-widget .dine-in-status-label .sub-title, .order-status-widget .website-order-dine-in-timeline-widget .delivery-status-label .sub-title, .order-status-widget .website-order-dine-in-timeline-widget .pick-up-status-label .sub-title, .order-status-widget .website-order-dine-in-timeline-widget .dine-in-status-label .sub-title {
    color: #9191a1;
    font-size: 14px;
  }

  .order-status-widget .website-order-delivery-timeline-widget .delivery-status-label .sub-title .store-phone-number, .order-status-widget .website-order-delivery-timeline-widget .pick-up-status-label .sub-title .store-phone-number, .order-status-widget .website-order-delivery-timeline-widget .dine-in-status-label .sub-title .store-phone-number, .order-status-widget .website-order-pick-up-timeline-widget .delivery-status-label .sub-title .store-phone-number, .order-status-widget .website-order-pick-up-timeline-widget .pick-up-status-label .sub-title .store-phone-number, .order-status-widget .website-order-pick-up-timeline-widget .dine-in-status-label .sub-title .store-phone-number, .order-status-widget .website-order-dine-in-timeline-widget .delivery-status-label .sub-title .store-phone-number, .order-status-widget .website-order-dine-in-timeline-widget .pick-up-status-label .sub-title .store-phone-number, .order-status-widget .website-order-dine-in-timeline-widget .dine-in-status-label .sub-title .store-phone-number {
    color: #4cbba1;
  }

  .order-status-widget .website-order-delivery-timeline-widget .delivery-status-label .sub-title .request-proof-of-delivery, .order-status-widget .website-order-delivery-timeline-widget .pick-up-status-label .sub-title .request-proof-of-delivery, .order-status-widget .website-order-delivery-timeline-widget .dine-in-status-label .sub-title .request-proof-of-delivery, .order-status-widget .website-order-pick-up-timeline-widget .delivery-status-label .sub-title .request-proof-of-delivery, .order-status-widget .website-order-pick-up-timeline-widget .pick-up-status-label .sub-title .request-proof-of-delivery, .order-status-widget .website-order-pick-up-timeline-widget .dine-in-status-label .sub-title .request-proof-of-delivery, .order-status-widget .website-order-dine-in-timeline-widget .delivery-status-label .sub-title .request-proof-of-delivery, .order-status-widget .website-order-dine-in-timeline-widget .pick-up-status-label .sub-title .request-proof-of-delivery, .order-status-widget .website-order-dine-in-timeline-widget .dine-in-status-label .sub-title .request-proof-of-delivery {
    color: #9191a1;
    text-decoration: underline;
  }

  .order-view-widget {
    justify-content: space-between;
    display: flex;
  }

  .order-view-widget .order-summary {
    width: 100%;
    height: -moz-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    box-sizing: border-box;
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    row-gap: 9px;
    margin: 0 10px 0 20px;
    padding: 33px 31px 26px;
    display: grid;
    box-shadow: 0 2px 4px #0000001a;
  }

  .order-view-widget .order-summary .title {
    justify-content: space-between;
    display: flex;
  }

  .order-view-widget .order-summary .title .summary {
    color: #27283e;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .order-view-widget .order-summary .title .order-id {
    color: #9191a1;
    font-size: 13px;
  }

  .order-view-widget .order-summary .collection-type-and-time {
    padding-bottom: 2px;
  }

  .order-view-widget .order-summary .collection-type-and-time .value > * {
    display: inline-block;
  }

  .order-view-widget .order-summary .pickup-info {
    padding-bottom: 5px;
  }

  .order-view-widget .order-summary .delivery-info, .order-view-widget .order-summary .payment-method {
    padding-bottom: 2px;
  }

  .order-view-widget .order-summary .label {
    text-align: left;
    color: #27283e;
    margin-bottom: 1px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .order-view-widget .order-summary .value {
    text-align: left;
    color: #27283e;
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .order-view-widget .order-summary .payment-method .value {
    display: flex;
  }

  .order-view-widget .order-summary .payment-method .wallet, .order-view-widget .order-summary .payment-method .brand {
    height: 25px;
    margin-right: 5px;
  }

  .order-view-widget .order-summary .payment-method .status, .order-view-widget .order-summary .transaction-fee .status {
    color: #757575;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .order-view-widget .cart-summary {
    box-sizing: border-box;
    width: 100%;
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin: 0 20px 0 10px;
    padding: 31px 30px 30px 32px;
    box-shadow: 0 2px 4px #0000001a;
  }

  .order-view-widget .cart-summary .cart-items {
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 16px;
    padding-bottom: 16px;
  }

  .order-view-widget .cart-summary .cart-items > .item-summary-view-widget {
    margin-top: 16px;
  }

  .order-view-widget .cart-summary .cart-items > .item-summary-view-widget:first-child {
    margin-top: 0;
  }

  .order-view-widget .cart-summary .amounts {
    row-gap: 9px;
    margin-bottom: 20px;
    display: grid;
  }

  .order-view-widget .cart-summary .amounts > div {
    grid-template-rows: max-content;
    grid-template-columns: 1fr min-content;
    display: grid;
  }

  .order-view-widget .cart-summary .amounts > div .label, .order-view-widget .cart-summary .amounts > div .value {
    color: #1c1d3e;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .order-view-widget .cart-summary .amounts .discount .label {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .order-view-widget .cart-summary .amounts .grand-total {
    margin: 8px 0;
  }

  .order-view-widget .cart-summary .amounts .grand-total .label, .order-view-widget .cart-summary .amounts .grand-total .value {
    color: #27283e;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .page {
    min-height: calc(100vh - 309px);
    background: #fafafa;
    position: relative;
  }
}

@media (min-width: 768px) and (max-width: 1280px) {
  .page {
    padding: 0 32px;
  }
}

@media (min-width: 768px) {
  .page > .header {
    width: 100%;
    z-index: 2;
    background: #fafafa;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 102px;
    padding-bottom: 22px;
    display: flex;
  }

  .page > .header .wrapper {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
  }

  .page > .header .wrapper .title {
    color: #27283e;
    font-size: 26px;
    font-weight: 700;
    line-height: 32px;
    text-align: center !important;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .page > .header .wrapper .title {
    font-size: 32px;
    line-height: 48px;
  }
}

@media (min-width: 768px) {
  .page > .header .actions button {
    background-color: #fff;
    padding: 7px 14px;
  }

  .page > .header .actions button .icon {
    color: #92929d;
    font-size: 20px;
  }

  .page > .header .actions button .label {
    color: var(--color-primary);
  }

  .page > .header .actions a:link {
    text-decoration: none;
  }

  .page > .body {
    max-width: 1280px;
    margin: 0 auto;
  }

  .page > .body > .spinner {
    text-align: center;
    margin: 50px;
  }

  .ReactModalPortal .ReactModal__Overlay {
    z-index: 1000;
    height: 100vh !important;
    background: #525252b8 !important;
  }

  .ReactModalPortal .ReactModal__Overlay .ReactModal__Content {
    border: unset !important;
    background: none !important;
    border-radius: 0 !important;
    padding: 0 !important;
    top: 120px !important;
  }

  .ReactModalPortal .ReactModal__Overlay .ReactModal__Content .modal {
    max-height: calc(100vh - 240px);
    width: 525px;
    background-color: #fff;
    border-radius: 16px;
    margin: 0 auto;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .ReactModalPortal .ReactModal__Overlay .ReactModal__Content .modal::-webkit-scrollbar {
    width: 5px;
  }

  .ReactModalPortal .ReactModal__Overlay .ReactModal__Content .modal::-webkit-scrollbar-track {
    background: none;
  }

  .ReactModalPortal .ReactModal__Overlay .ReactModal__Content .modal::-webkit-scrollbar-thumb {
    background-color: #27283e50;
    border-radius: 20px;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

@media (min-width: 768px) {
  .deal-widget {
    max-height: calc(100vh - 240px);
    flex-flow: column;
    display: flex;
  }

  .deal-widget .item-body {
    background-color: #0000;
    background-image: linear-gradient(#fff 30%, #fff0), linear-gradient(#fff0, #fff 70%), radial-gradient(farthest-side at 50% 0, #0003, #0000), radial-gradient(farthest-side at 50% 100%, #0003, #0000);
    background-position: 0 0, 0 100%, 0 0, 0 100%;
    background-repeat: no-repeat;
    background-size: 100% 40px, 100% 40px, 100% 10px, 100% 10px;
    background-attachment: local, local, scroll, scroll;
    background-origin: padding-box, padding-box, padding-box, padding-box;
    background-clip: border-box, border-box, border-box, border-box;
    padding-bottom: 72.25px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .deal-widget .item-body::-webkit-scrollbar {
    width: 5px;
  }

  .deal-widget .item-body::-webkit-scrollbar-track {
    background: none;
  }

  .deal-widget .item-body::-webkit-scrollbar-thumb {
    background-color: #27283e50;
    border-radius: 20px;
  }

  .deal-widget .item-body::-webkit-scrollbar {
    width: 0;
  }

  .deal-widget .item-body .close-icon {
    width: 16px;
    z-index: 2;
    cursor: pointer;
    background: #fff;
    border-radius: 100%;
    margin-right: 19px;
    padding: 7px;
    position: absolute;
    top: 25px;
    right: 8px;
  }

  .deal-widget .item-body .close-icon.no-image {
    background: #eee;
    top: 23px;
    box-shadow: 0 2px 2px #31303e14, 0 6px 10px #31303e1a;
  }

  .deal-widget .item-image.no-image {
    margin-top: unset;
  }

  .deal-widget .item-name {
    color: #27283e;
    z-index: 1;
    background: #fff;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    position: sticky;
    top: 0;
  }

  .deal-widget .item-name:before {
    content: "";
    height: 16px;
    -webkit-position: sticky;
    display: block;
    position: sticky;
    top: 57.2px;
    box-shadow: 0 2px 5px #00000024;
  }

  .deal-widget .item-name.no-image:before {
    margin-top: 10px;
  }

  .deal-widget .item-name > .container {
    -webkit-position: sticky;
    z-index: 3;
    background-color: #fff;
    margin-top: -16px;
    padding: 25px 32px 18px;
    position: sticky;
    top: 0;
  }

  .deal-widget .item-image.no-image {
    margin-top: unset;
  }

  .deal-widget .item-sold-out, .deal-widget .item-unavailable {
    color: #27283e;
    width: -moz-fit-content;
    width: fit-content;
    z-index: 5;
    background: #e0e0e0;
    border-radius: 4px;
    margin: 25px 65px 0 30px;
    padding: 2px 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    position: sticky;
  }

  .deal-widget .item-label {
    color: #27283e;
    z-index: 5;
    background-color: #ffebcf;
    border-radius: 4px;
    margin: 25px 65px 0 30px;
    padding: 3px 6px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    display: inline-block;
    position: sticky;
  }

  .deal-widget .item-name {
    color: #27283e;
    z-index: 1;
    background: #fff;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    position: sticky;
    top: 0;
  }

  .deal-widget .item-name:before {
    content: "";
    height: 10px;
    -webkit-position: sticky;
    display: block;
    position: sticky;
    top: 57.2px;
    box-shadow: 0 2px 5px #00000024;
  }

  .deal-widget .item-name.no-image:before {
    margin-top: 10px;
  }

  .deal-widget .item-name > .container {
    -webkit-position: sticky;
    z-index: 3;
    background-color: #fff;
    margin-top: -16px;
    padding: 25px 32px 18px;
    position: sticky;
    top: 0;
  }

  .deal-widget .item-details {
    background: #fff;
    padding: 0 32px 10px;
  }

  .deal-widget .item-details * {
    margin: 0;
  }

  .deal-widget .item-details .item-description {
    color: #757575;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .deal-widget .item-details .item-price-and-dietary {
    flex-direction: row;
    align-items: baseline;
    padding-top: 17px;
    display: flex;
  }

  .deal-widget .item-details .item-price-and-dietary .item-price {
    color: #27283e;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .deal-widget .item-details .item-price-and-dietary .item-dietary {
    height: min-content;
    color: #757575;
    grid-column-start: 3;
    justify-self: flex-end;
    margin-left: auto;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .deal-widget .item-details .item-available-weekly-hours-widget, .deal-widget .deal-body {
    margin-top: 18px;
  }

  .deal-widget .deal-body .deal-pizzas {
    padding-top: 0;
  }

  .deal-widget .deal-body .deal-pizzas .deal-pizza {
    margin-top: 31px;
  }

  .deal-widget .deal-body .deal-pizzas .deal-pizza:first-child {
    margin-top: 0;
  }

  .deal-widget .deal-body .deal-pizzas .deal-pizza .header {
    background-color: #fafafa;
    flex-direction: column;
    grid-template-columns: 1fr max-content;
    grid-auto-flow: column;
    justify-content: space-between;
    padding: 16px 32px;
    display: flex;
  }

  .deal-widget .deal-body .deal-pizzas .deal-pizza .header .title {
    color: #27283e;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .deal-widget .deal-body .deal-pizzas .deal-pizza .header .title .requiredness {
    color: #757575;
    justify-content: center;
    align-items: center;
    padding: 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    display: flex;
  }

  .deal-widget .deal-body .deal-pizzas .deal-pizza .header .title .selected:before {
    content: url("green-check.23f410e7.svg");
    padding-right: 5px;
  }

  .deal-widget .deal-body .deal-pizzas .deal-pizza .header .title .required {
    color: #086e37;
  }

  .deal-widget .deal-body .deal-pizzas .deal-pizza .header .title .error {
    color: #b0204b;
    background-color: #ffe7df;
  }

  .deal-widget .deal-body .deal-pizzas .deal-pizza .header > .form-item.switch label {
    color: #27283e;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .deal-widget .deal-body .deal-pizzas .deal-pizza .header .title {
    justify-content: space-between;
    margin-bottom: 4px;
    display: flex;
  }

  .deal-widget .deal-body .deal-pizzas .deal-pizza .header > .switch {
    justify-content: flex-start;
    display: flex;
  }

  .deal-widget .deal-body .deal-pizzas .deal-pizza .header > .switch > label {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #27283e !important;
  }

  .deal-widget .deal-body .deal-pizzas .deal-pizza .body {
    padding: 16px 32px;
  }

  .deal-widget .deal-body .deal-pizzas .deal-pizza .body .deal-pizza-entry {
    margin-top: 8px;
  }

  .deal-widget .deal-body .deal-pizzas .deal-pizza .body .deal-pizza-entry:first-child {
    margin-top: 0;
  }

  .deal-widget .deal-body .deal-pizzas .deal-pizza .body .deal-pizza-entry .choose-container .choose-pizza-button {
    cursor: pointer;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    grid-auto-columns: 1fr min-content min-content;
    grid-auto-flow: column;
    align-items: center;
    gap: 15px;
    padding: 18px 16px;
    display: grid;
  }

  .deal-widget .deal-body .deal-pizzas .deal-pizza .body .deal-pizza-entry .choose-container .choose-pizza-button .text {
    color: #27283e;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .deal-widget .deal-body .deal-pizzas .deal-pizza .body .deal-pizza-entry .choose-container .choose-pizza-button .text.no-pizza {
    color: #9e9e9e;
  }

  .deal-widget .deal-body .deal-pizzas .deal-pizza .body .deal-pizza-entry .choose-container .choose-pizza-button .extra-charge {
    white-space: nowrap;
    color: #9e9e9e;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .deal-widget .deal-body .deal-pizzas .deal-pizza .body .deal-pizza-entry .choose-container .summary > pre {
    color: #757575;
    white-space: pre-wrap;
    word-wrap: break-word;
    margin: 17px 2px 12px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    overflow-x: auto;
  }

  .deal-widget .deal-body .deal-pizzas .deal-pizza .body .deal-pizza-entry .choose-container .customize-pizza-button {
    cursor: pointer;
    color: #4cbba1;
    margin-bottom: 28px;
    margin-left: 2px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .deal-widget .deal-body .deal-pizzas .deal-pizza .body .deal-pizza-entry .choose-container .customize-pizza-button.customize {
    margin-top: 20px;
  }

  .deal-widget .deal-body .deal-items {
    grid-gap: 35px;
    padding-bottom: 12px;
    display: grid;
  }

  .deal-widget .deal-body .deal-items .deal-item > .header {
    background-color: #fafafa;
    grid-template-columns: 1fr max-content;
    grid-auto-flow: column;
    align-items: center;
    margin-bottom: 11px;
    padding: 16px 32px;
    display: grid;
  }

  .deal-widget .deal-body .deal-items .deal-item > .header .title {
    color: #27283e;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .deal-widget .deal-body .deal-items .deal-item > .modifiers .modifier-widget .header .title .modifier-name {
    font-weight: 700;
  }

  .deal-widget .deal-body .deal-modifiers {
    padding: 20px 0;
  }

  .deal-widget .actions .add-to-cart-widget {
    padding: 24px 30px;
  }

  .deal-widget .spinner-container {
    box-sizing: border-box;
    height: 51px;
    z-index: 2;
    background: #ffebcf;
    border: 1px solid #f8bd79;
    border-radius: 8px;
    flex-direction: row;
    gap: 10px;
    padding: 14px;
    display: flex;
    position: absolute;
    top: 42px;
    left: calc(50% - 140px);
  }

  .deal-widget .spinner-container .message {
    color: #d67300;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .deal-widget .spinner-container .spinner {
    animation: 2s linear infinite spin;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@media (min-width: 768px) {
  .deal-pizza-list-widget {
    height: 100%;
    flex-flow: column;
    display: flex;
  }

  .deal-pizza-list-widget input[type="radio"] {
    cursor: pointer;
    display: none;
  }

  .deal-pizza-list-widget input[type="radio"] + label {
    color: #27283e;
    cursor: pointer;
    align-items: center;
    margin-left: 25px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    position: relative;
  }

  .deal-pizza-list-widget input[type="radio"] + label:before {
    content: "";
    width: 14px;
    height: 14px;
    border: 1px solid #e0e0e0;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: -28px;
  }

  .deal-pizza-list-widget input[type="radio"]:checked + label:before {
    background: #4cbba1;
    border: 1px solid #4cbba1;
  }

  .deal-pizza-list-widget .item-header {
    z-index: 99;
    background: #fff;
    justify-content: space-between;
    padding: 20px 32px;
    display: flex;
    position: sticky;
    top: 0;
  }

  .deal-pizza-list-widget .item-header .title {
    color: #27283e;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .deal-pizza-list-widget .item-header .title {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (min-width: 768px) {
  .deal-pizza-list-widget .item-header .close-icon {
    width: 15px;
    cursor: pointer;
    position: relative;
    right: -4px;
  }

  .deal-pizza-list-widget .item-header .close {
    cursor: pointer;
    text-transform: uppercase;
    color: #4cbba1;
    margin-bottom: 5px;
    margin-right: 4px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .deal-pizza-list-widget .item-body {
    height: 100%;
    margin-bottom: 26px;
    overflow: hidden;
  }

  .deal-pizza-list-widget .pizzas {
    max-height: calc(100vh - 352px);
    background-color: #0000;
    background-image: linear-gradient(#fff 30%, #fff0), linear-gradient(#fff0, #fff 70%), radial-gradient(farthest-side at 50% 0, #0003, #0000), radial-gradient(farthest-side at 50% 100%, #0003, #0000);
    background-position: 0 0, 0 100%, 0 0, 0 100%;
    background-repeat: no-repeat;
    background-size: 100% 40px, 100% 40px, 100% 10px, 100% 10px;
    background-attachment: local, local, scroll, scroll;
    background-origin: padding-box, padding-box, padding-box, padding-box;
    background-clip: border-box, border-box, border-box, border-box;
    gap: 15px;
    padding: 10px 32px;
    display: grid;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .deal-pizza-list-widget .pizzas::-webkit-scrollbar {
    width: 5px;
  }

  .deal-pizza-list-widget .pizzas::-webkit-scrollbar-track {
    background: none;
  }

  .deal-pizza-list-widget .pizzas::-webkit-scrollbar-thumb {
    background-color: #27283e50;
    border-radius: 20px;
  }

  .deal-pizza-list-widget .pizzas .pizza {
    cursor: pointer;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 14px 0;
    position: relative;
    overflow: hidden;
  }

  .deal-pizza-list-widget .pizzas .pizza .soldout-overlay {
    width: 100%;
    height: 100%;
    cursor: not-allowed;
    background: #ababab36;
    position: absolute;
    top: 0;
    left: 0;
  }

  .deal-pizza-list-widget .pizzas .pizza .header {
    color: #27283e;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px 0 15px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
  }

  .deal-pizza-list-widget .pizzas .pizza .header .name {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .deal-pizza-list-widget .pizzas .pizza .price {
    color: #757575;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .deal-pizza-list-widget .pizzas .pizza .description {
    color: #757575;
    margin: 0;
    padding: 5px 15px 0 40px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

@media (min-width: 768px) {
  .half-and-half-widget {
    max-height: calc(100vh - 240px);
    flex-flow: column;
    display: flex;
  }

  .half-and-half-widget .item-body {
    background-color: #0000;
    background-image: linear-gradient(#fff 30%, #fff0), linear-gradient(#fff0, #fff 70%), radial-gradient(farthest-side at 50% 0, #0003, #0000), radial-gradient(farthest-side at 50% 100%, #0003, #0000);
    background-position: 0 0, 0 100%, 0 0, 0 100%;
    background-repeat: no-repeat;
    background-size: 100% 40px, 100% 40px, 100% 10px, 100% 10px;
    background-attachment: local, local, scroll, scroll;
    background-origin: padding-box, padding-box, padding-box, padding-box;
    background-clip: border-box, border-box, border-box, border-box;
    padding-bottom: 72.25px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .half-and-half-widget .item-body::-webkit-scrollbar {
    width: 5px;
  }

  .half-and-half-widget .item-body::-webkit-scrollbar-track {
    background: none;
  }

  .half-and-half-widget .item-body::-webkit-scrollbar-thumb {
    background-color: #27283e50;
    border-radius: 20px;
  }

  .half-and-half-widget .item-body::-webkit-scrollbar {
    width: 0;
  }

  .half-and-half-widget .item-body .close-icon {
    width: 16px;
    z-index: 2;
    cursor: pointer;
    background: #fff;
    border-radius: 100%;
    margin-right: 19px;
    padding: 7px;
    position: absolute;
    top: 25px;
    right: 8px;
  }

  .half-and-half-widget .item-body .close-icon.no-image {
    background: #eee;
    top: 23px;
    box-shadow: 0 2px 2px #31303e14, 0 6px 10px #31303e1a;
  }

  .half-and-half-widget .item-image.no-image {
    margin-top: unset;
  }

  .half-and-half-widget .item-name {
    color: #27283e;
    z-index: 1;
    background: #fff;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    position: sticky;
    top: 0;
  }

  .half-and-half-widget .item-name:before {
    content: "";
    height: 16px;
    -webkit-position: sticky;
    display: block;
    position: sticky;
    top: 57.2px;
    box-shadow: 0 2px 5px #00000024;
  }

  .half-and-half-widget .item-name.no-image:before {
    margin-top: 10px;
  }

  .half-and-half-widget .item-name > .container {
    -webkit-position: sticky;
    z-index: 3;
    background-color: #fff;
    margin-top: -16px;
    padding: 25px 32px 18px;
    position: sticky;
    top: 0;
  }

  .half-and-half-widget .item-image.no-image {
    margin-top: unset;
  }

  .half-and-half-widget .item-sold-out, .half-and-half-widget .item-unavailable {
    color: #27283e;
    width: -moz-fit-content;
    width: fit-content;
    z-index: 5;
    background: #e0e0e0;
    border-radius: 4px;
    margin: 25px 65px 0 30px;
    padding: 2px 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    position: sticky;
  }

  .half-and-half-widget .item-label {
    color: #27283e;
    z-index: 5;
    background-color: #ffebcf;
    border-radius: 4px;
    margin: 25px 65px 0 30px;
    padding: 3px 6px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    display: inline-block;
    position: sticky;
  }

  .half-and-half-widget .item-name {
    color: #27283e;
    z-index: 1;
    background: #fff;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    position: sticky;
    top: 0;
  }

  .half-and-half-widget .item-name:before {
    content: "";
    height: 10px;
    -webkit-position: sticky;
    display: block;
    position: sticky;
    top: 57.2px;
    box-shadow: 0 2px 5px #00000024;
  }

  .half-and-half-widget .item-name.no-image:before {
    margin-top: 10px;
  }

  .half-and-half-widget .item-name > .container {
    -webkit-position: sticky;
    z-index: 3;
    background-color: #fff;
    margin-top: -16px;
    padding: 25px 32px 18px;
    position: sticky;
    top: 0;
  }

  .half-and-half-widget .item-details {
    background: #fff;
    padding: 0 32px 10px;
  }

  .half-and-half-widget .item-details * {
    margin: 0;
  }

  .half-and-half-widget .item-details .item-description {
    color: #757575;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .half-and-half-widget .item-details .item-price-and-dietary {
    flex-direction: row;
    align-items: baseline;
    padding-top: 17px;
    display: flex;
  }

  .half-and-half-widget .item-details .item-price-and-dietary .item-price {
    color: #27283e;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .half-and-half-widget .item-details .item-price-and-dietary .item-dietary {
    height: min-content;
    color: #757575;
    grid-column-start: 3;
    justify-self: flex-end;
    margin-left: auto;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .half-and-half-widget .item-details .item-available-weekly-hours-widget {
    margin-top: 18px;
  }

  .half-and-half-widget .half-and-half-pizzas {
    grid-gap: 28px;
    margin-top: 18px;
    display: grid;
  }

  .half-and-half-widget .half-and-half-pizzas p {
    margin: 0 0 11px;
  }

  .half-and-half-widget .half-and-half-pizzas .header {
    background-color: #fafafa;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
    padding: 16px 32px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    display: flex;
  }

  .half-and-half-widget .half-and-half-pizzas .header .title {
    justify-content: space-between;
    margin-bottom: 4px;
    display: flex;
  }

  .half-and-half-widget .half-and-half-pizzas .header .title .pizza-half {
    color: #27283e;
    margin-right: 8px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .half-and-half-widget .half-and-half-pizzas .header .title .requiredness {
    color: #757575;
    justify-content: center;
    align-items: center;
    padding: 0 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    display: flex;
  }

  .half-and-half-widget .half-and-half-pizzas .header .title .selected:before {
    content: url("green-check.23f410e7.svg");
    padding-right: 5px;
  }

  .half-and-half-widget .half-and-half-pizzas .header .title .required {
    color: #086e37;
  }

  .half-and-half-widget .half-and-half-pizzas .header .title .error {
    color: #b0204b;
    background-color: #ffe7df;
  }

  .half-and-half-widget .half-and-half-pizzas .half-and-half-pizza-entry {
    padding: 16px 32px;
  }

  .half-and-half-widget .half-and-half-pizzas .choose-pizza-button {
    width: 100%;
    box-sizing: border-box;
    color: #9e9e9e;
    cursor: pointer;
    background: #fff url("right-arrow-head-icon.ebb9d916.svg") right 14px bottom 19px no-repeat;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    justify-content: space-between;
    padding: 14px 30px 14px 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
  }

  .half-and-half-widget .half-and-half-pizzas .choose-pizza-button .label.with-input {
    color: #27283e;
  }

  .half-and-half-widget .half-and-half-pizzas .choose-pizza-button .extra-charge {
    grid-gap: 5px;
    display: flex;
  }

  .half-and-half-widget .half-and-half-pizzas .summary {
    white-space: pre-wrap;
    color: #757575;
    margin: 0;
    padding: 16px 0 0 2px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .half-and-half-widget .half-and-half-pizzas .customize-pizza-button {
    text-transform: none;
    color: #4cbba1;
    box-shadow: none;
    letter-spacing: unset;
    background: #fff;
    padding: 20px 3px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .half-and-half-widget .half-and-half-pizzas .edit-pizza-button {
    text-transform: none;
    color: #4cbba1;
    box-shadow: none;
    letter-spacing: unset;
    background: #fff;
    padding: 12px 0 0 2px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .half-and-half-widget .actions {
    margin-top: 33px;
  }

  .half-and-half-widget .actions .add-to-cart-widget {
    padding: 24px 30px;
  }

  .half-and-half-widget .spinner-container {
    box-sizing: border-box;
    height: 51px;
    z-index: 2;
    background: #ffebcf;
    border: 1px solid #f8bd79;
    border-radius: 8px;
    flex-direction: row;
    gap: 10px;
    padding: 14px;
    display: flex;
    position: absolute;
    top: 42px;
    left: calc(50% - 140px);
  }

  .half-and-half-widget .spinner-container .message {
    color: #d67300;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .half-and-half-widget .spinner-container .spinner {
    animation: 2s linear infinite spin;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@media (min-width: 768px) {
  .half-and-half-pizza-list-widget {
    height: 100%;
    flex-flow: column;
    display: flex;
  }

  .half-and-half-pizza-list-widget input[type="radio"] {
    cursor: pointer;
    display: none;
  }

  .half-and-half-pizza-list-widget input[type="radio"] + label {
    color: #27283e;
    cursor: pointer;
    align-items: center;
    margin-left: 25px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    position: relative;
  }

  .half-and-half-pizza-list-widget input[type="radio"] + label:before {
    content: "";
    width: 14px;
    height: 14px;
    border: 1px solid #e0e0e0;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: -28px;
  }

  .half-and-half-pizza-list-widget input[type="radio"]:checked + label:before {
    background: #4cbba1;
    border: 1px solid #4cbba1;
  }

  .half-and-half-pizza-list-widget .item-header {
    z-index: 99;
    background: #fff;
    justify-content: space-between;
    padding: 20px 32px;
    display: flex;
    position: sticky;
    top: 0;
  }

  .half-and-half-pizza-list-widget .item-header .title {
    color: #27283e;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .half-and-half-pizza-list-widget .item-header .title {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (min-width: 768px) {
  .half-and-half-pizza-list-widget .item-header .close-icon {
    width: 15px;
    cursor: pointer;
    position: relative;
    right: -4px;
  }

  .half-and-half-pizza-list-widget .item-header .close {
    cursor: pointer;
    text-transform: uppercase;
    color: #4cbba1;
    margin-bottom: 5px;
    margin-right: 4px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .half-and-half-pizza-list-widget .item-body {
    height: 100%;
    margin-bottom: 26px;
    overflow: hidden;
  }

  .half-and-half-pizza-list-widget .pizzas {
    max-height: calc(100vh - 352px);
    background-color: #0000;
    background-image: linear-gradient(#fff 30%, #fff0), linear-gradient(#fff0, #fff 70%), radial-gradient(farthest-side at 50% 0, #0003, #0000), radial-gradient(farthest-side at 50% 100%, #0003, #0000);
    background-position: 0 0, 0 100%, 0 0, 0 100%;
    background-repeat: no-repeat;
    background-size: 100% 40px, 100% 40px, 100% 10px, 100% 10px;
    background-attachment: local, local, scroll, scroll;
    background-origin: padding-box, padding-box, padding-box, padding-box;
    background-clip: border-box, border-box, border-box, border-box;
    gap: 15px;
    padding: 10px 32px;
    display: grid;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .half-and-half-pizza-list-widget .pizzas::-webkit-scrollbar {
    width: 5px;
  }

  .half-and-half-pizza-list-widget .pizzas::-webkit-scrollbar-track {
    background: none;
  }

  .half-and-half-pizza-list-widget .pizzas::-webkit-scrollbar-thumb {
    background-color: #27283e50;
    border-radius: 20px;
  }

  .half-and-half-pizza-list-widget .pizzas .pizza {
    cursor: pointer;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 14px 0;
    position: relative;
    overflow: hidden;
  }

  .half-and-half-pizza-list-widget .pizzas .pizza .soldout-overlay {
    width: 100%;
    height: 100%;
    cursor: not-allowed;
    background: #ababab36;
    position: absolute;
    top: 0;
    left: 0;
  }

  .half-and-half-pizza-list-widget .pizzas .pizza .header {
    color: #27283e;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px 0 15px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
  }

  .half-and-half-pizza-list-widget .pizzas .pizza .header .name {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .half-and-half-pizza-list-widget .pizzas .pizza .price {
    color: #757575;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .half-and-half-pizza-list-widget .pizzas .pizza .description {
    color: #757575;
    margin: 0;
    padding: 5px 15px 0 40px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .order-history-page {
    background: #fafafa;
  }

  .order-history-page .list {
    max-width: 630px;
    margin: 0 auto;
    padding-bottom: 100px;
  }

  .order-history-page .list .order-list-view {
    box-sizing: border-box;
    cursor: pointer;
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    grid-template-columns: 1fr auto;
    grid-template-areas: "collection-type status"
                         "time time"
                         "address address"
                         "customer customer"
                         "grand-total grand-total";
    margin: 22px 0;
    padding: 24px;
    display: grid;
    box-shadow: 0 2px 4px #0000001a;
  }

  .order-history-page .list .order-list-view .status {
    grid-area: status;
    margin-bottom: 9px;
    display: block;
  }

  .order-history-page .list .order-list-view .collection-type {
    color: #27283e;
    grid-area: collection-type;
    margin-bottom: 9px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .order-history-page .list .order-list-view .time {
    color: #27283e;
    grid-area: time;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .order-history-page .list .order-list-view .address {
    color: #27283e;
    grid-area: address;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .order-history-page .list .order-list-view .customer {
    color: #757575;
    grid-area: customer;
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .order-history-page .list .order-list-view .grand-total {
    color: #757575;
    grid-area: grand-total;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .order-history-page .body .message span {
    text-decoration: underline;
  }

  .order-view-page > .header {
    padding: 0;
  }

  .order-view-page > .header .wrapper {
    display: none;
  }

  .order-view-page .order-tracking-label {
    color: #27283e;
    margin: 5px 20px;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }

  .order-view-page .body {
    max-width: 1280px;
    padding-bottom: 92px;
  }

  .order-view-page .body .order-placed-time {
    text-align: center;
    color: #9191a1;
    margin: 0 auto;
    padding-top: 71px;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .order-view-page .body .order-placed-time {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (min-width: 768px) {
  .order-view-page .body .cancel-order-info {
    text-align: center;
    color: #9e9e9e;
    margin: 0 20px;
    padding-top: 42px;
  }

  .order-view-page .body .cancel-order-info span {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .order-view-page .body .cancel-order-info .contact {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .order-view-page .body .cancel-order-info .contact a {
    color: #4cbba1;
    margin-left: 3px;
  }

  .order-view-page .body .buttons {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .order-view-page .body .buttons > .customer-review-link-button {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    margin-top: 44px;
    padding: 20px;
    box-shadow: 0 2px 4px #0000000d;
  }

  .order-view-page .body .buttons > .customer-review-link-button > .label {
    align-items: center;
    display: flex;
  }

  .order-view-page .body .buttons > .customer-review-link-button > .label img {
    width: 44px;
    height: 44px;
  }

  .order-view-page .body .buttons > .customer-review-link-button > .label span {
    color: #1c1d3e;
    padding-left: 16px;
  }

  .order-view-page .body .buttons .back-to-menu-button {
    box-shadow: none;
    color: #9e9e9e;
    background-color: #0000;
    border: none;
    margin-top: 16px;
    padding: 14px 50px;
  }

  .order-view-page .body .order-view-header {
    margin-top: 87px;
  }

  .order-view-page .body .order-view-header > .header {
    text-align: left;
    color: #27283e;
    margin: 0 20px 23px;
    font-size: 26px;
    font-weight: 700;
    line-height: 32px;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .order-view-page .body .order-view-header > .header {
    font-size: 32px;
    line-height: 48px;
  }
}

@media (min-width: 768px) {
  .order-view-page .body .order-view-header .order-view-crumbs {
    flex-direction: row;
    margin: 0 20px -1px;
    display: flex;
    position: relative;
  }

  .order-view-page .body .order-view-header .order-view-crumbs > span {
    text-align: left;
    color: #27283e;
    align-self: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .order-view-page .body .order-view-header .order-view-crumbs .caret-right {
    height: 14px;
    width: 14px;
    background-image: url("caret-right-icon.5e06ea29.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 14px;
  }

  .badge {
    border-radius: 4px;
    padding: 6.5px 15px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: inline;
  }

  .badge.success {
    color: #086e37;
    background: #def2d6;
  }

  .badge.failure {
    color: #b0204b;
    background: #ffe7df;
  }

  .badge.primary, .badge.warning {
    color: #d67300;
    background: #ffebcf;
  }

  .confirm-dialog.discounted-items-alert .swal2-content {
    padding: 0;
  }

  .confirm-dialog.discounted-items-alert .swal2-title {
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .confirm-dialog.discounted-items-alert .swal2-html-container {
    text-align: left;
    margin: 0 30px;
    font-size: 14px;
  }

  .checkout-widget {
    height: calc(100vh - 240px);
    box-sizing: border-box;
    flex-flow: column;
    display: flex;
    padding: 0 !important;
  }

  .checkout-widget .spinner-container {
    margin-top: 120px;
  }

  .checkout-widget img {
    object-fit: cover;
  }

  .checkout-widget input[type="text"] {
    border-radius: 8px;
    padding: 13px 22px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .checkout-widget .checkout-header {
    color: #27283e;
    padding: 21px 32px;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .checkout-widget .checkout-header {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (min-width: 768px) {
  .checkout-widget .checkout-header .close-icon {
    width: 15px;
    cursor: pointer;
    position: absolute;
    top: 22px;
    right: 28px;
  }

  .checkout-widget .firebaseui-phone-number .firebaseui-country-selector {
    padding-top: 10px;
    height: auto !important;
  }

  .checkout-widget .firebaseui-phone-number button {
    box-shadow: none !important;
  }

  .checkout-widget .form {
    width: 90%;
    margin: 0 auto;
    padding-top: 10px;
  }

  .checkout-widget .body {
    margin-top: 20px;
  }

  .checkout-widget .sections {
    height: 100%;
    flex-flow: column;
    flex-grow: 1;
    justify-content: space-between;
    margin-top: 30px;
    padding: 0 32px;
    display: flex;
  }

  .checkout-widget .sections div:only-child {
    height: inherit;
    flex-flow: column;
    display: grid;
  }

  .checkout-widget .sections .title {
    text-align: center;
  }

  .checkout-widget .sections .title .header {
    color: #27283e;
    margin-top: 11px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    display: block;
  }

  .checkout-widget .sections .title .subtitle {
    width: 90%;
    box-sizing: border-box;
    color: #757575;
    margin: 6px auto 11px;
    padding: 0 32px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: block;
  }

  .checkout-widget .sections .display-name .subtitle {
    width: 70%;
    margin-bottom: 0;
  }

  .checkout-widget .sections .icon {
    height: 80px;
    width: 80px;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0 auto;
  }

  .checkout-widget .sections .icon.customer-display-name {
    background-image: url("customer-name-icon-desktop.06b296e7.svg");
  }

  .checkout-widget .sections .icon.coupon {
    background-image: url("coupon-icon-desktop.9ec3da6d.svg");
  }

  .checkout-widget .sections .icon.comment {
    background-image: url("comment-icon.1e308300.svg");
  }

  .checkout-widget .sections .icon.payment {
    background-image: url("payment-icon-desktop.d2f71ae7.svg");
  }

  .checkout-widget .sections .update-display-name {
    margin-top: 35px;
  }

  .checkout-widget .sections .custom-coupon {
    grid-template-columns: 1fr min-content;
    grid-template-areas: "input button"
                         "note note";
    display: grid;
  }

  .checkout-widget .sections .custom-coupon form .default {
    grid-area: input;
  }

  .checkout-widget .sections .custom-coupon input {
    border-radius: 8px 0 0 8px;
  }

  .checkout-widget .sections .custom-coupon .apply-button {
    cursor: pointer;
    height: calc(100% - 2px);
    letter-spacing: 2px;
    text-transform: uppercase;
    background-color: #f5f5f5;
    border: 1px solid #e0e0e0;
    border-left: none;
    border-radius: 0 8px 8px 0;
    grid-area: button;
    justify-content: center;
    align-items: center;
    padding: 0 22px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    display: flex;
    position: relative;
  }

  .checkout-widget .sections .custom-coupon .note {
    text-align: left;
    letter-spacing: unset;
    margin: unset;
    text-transform: unset;
    color: #27283e;
    grid-area: note;
    margin: 10px 0;
    padding: 0 15px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .checkout-widget .sections .custom-coupon .note .learn-more {
    color: #d67300;
    cursor: pointer;
    white-space: nowrap;
  }

  .checkout-widget .sections .suggested-coupon {
    background-color: #fff;
    border-top: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
    border-right: 1px solid #e9e9e9;
    grid-template-columns: 12px 1fr;
    grid-template-areas: "zigzag content";
    display: grid;
    box-shadow: 0 0 8px 1px #302f3a1a;
  }

  .checkout-widget .sections .suggested-coupon .content {
    grid-area: content;
  }

  .checkout-widget .sections .suggested-coupon .zigzag {
    width: 12px;
    background-color: #0000;
    background-image: linear-gradient(45deg, #fff0 47%, #e9e9e9 47% 52%, #fff 52%), linear-gradient(315deg, #fff 47%, #e9e9e9 47% 52%, #e1e1e14d 52%);
    background-position: 0 0, 0 0;
    background-repeat: repeat-y;
    background-size: 23px 23px;
    background-attachment: scroll, scroll;
    background-origin: padding-box, padding-box;
    background-clip: border-box, border-box;
    grid-area: zigzag;
  }

  .checkout-widget .sections .suggested-coupon .content {
    color: #27283e;
    grid-template-rows: repeat(3, min-content);
    grid-template-columns: 1fr min-content;
    grid-template-areas: "name actions"
                         "description actions"
                         "discount actions";
    align-items: center;
    gap: 0 5px;
    margin-left: 10px;
    display: grid;
  }

  .checkout-widget .sections .suggested-coupon .content .name {
    text-align: left;
    color: #27283e;
    text-overflow: ellipsis;
    grid-area: name;
    margin-top: 15px;
    font-family: IBM Plex;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    overflow: hidden;
  }

  .checkout-widget .sections .suggested-coupon .content .description {
    min-height: 34px;
    text-align: left;
    color: #757575;
    grid-area: description;
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    overflow: hidden;
  }

  .checkout-widget .sections .suggested-coupon .content .discount {
    text-align: left;
    color: #27283e;
    text-transform: lowercase;
    grid-area: discount;
    margin-bottom: 13px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .checkout-widget .sections .suggested-coupon .content .actions {
    grid-area: actions;
  }

  .checkout-widget .sections .suggested-coupon .content .actions button {
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #4cbba1;
    cursor: pointer;
    background-color: #fff;
    border: 2px solid #4cbba1;
    border-radius: 8px;
    margin-left: 15px;
    margin-right: 15px;
    padding: 4px 14px;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
  }

  .checkout-widget .sections .applied-coupon {
    background-color: #eff8f9;
    border-top: 1px solid #4cbba1;
    border-bottom: 1px solid #4cbba1;
    border-right: 1px solid #4cbba1;
    grid-template-columns: 12px 1fr;
    grid-template-areas: "zigzag content";
    display: grid;
    box-shadow: 0 0 8px 1px #302f3a1a;
  }

  .checkout-widget .sections .applied-coupon .content {
    grid-area: content;
  }

  .checkout-widget .sections .applied-coupon .zigzag {
    width: 12px;
    background-color: #0000;
    background-image: linear-gradient(45deg, #fff0 47%, #4cbba1 47% 52%, #eff8f9 52%), linear-gradient(315deg, #eff8f9 47%, #4cbba1 47% 52%, #e1e1e14d 52%);
    background-position: 0 0, 0 0;
    background-repeat: repeat-y;
    background-size: 23px 23px;
    background-attachment: scroll, scroll;
    background-origin: padding-box, padding-box;
    background-clip: border-box, border-box;
    grid-area: zigzag;
  }

  .checkout-widget .sections .applied-coupon .content {
    grid-template: "name icon" min-content
                   "desc icon" 1fr
                   "discount icon" min-content
                   "actions icon" min-content
                   / 1fr min-content;
    align-items: center;
    margin-left: 10px;
    display: grid;
  }

  .checkout-widget .sections .applied-coupon .content .name {
    text-align: left;
    color: #27283e;
    text-overflow: ellipsis;
    grid-area: name;
    margin-top: 15px;
    font-family: IBM Plex;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    overflow: hidden;
  }

  .checkout-widget .sections .applied-coupon .content .description {
    max-height: 34px;
    min-height: 48px;
    color: #757575;
    grid-area: desc;
    margin-top: 5px;
    margin-bottom: 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    overflow: hidden;
  }

  .checkout-widget .sections .applied-coupon .content .description .learn-more {
    color: #086e37;
    cursor: pointer;
    letter-spacing: 0;
    white-space: nowrap;
  }

  .checkout-widget .sections .applied-coupon .content .discount {
    text-align: left;
    color: #27283e;
    text-transform: uppercase;
    grid-area: discount;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .checkout-widget .sections .applied-coupon .content .icon {
    width: 16px;
    height: 16px;
    background-color: #4cbba1;
    border-radius: 50%;
    grid-area: icon;
    margin-left: 24px;
    margin-right: 24px;
    padding: 8px;
  }

  .checkout-widget .sections .applied-coupon .content .actions {
    grid-area: actions;
  }

  .checkout-widget .sections .applied-coupon .content .actions button {
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #4cbba1;
    box-shadow: none;
    cursor: pointer;
    background-color: #eff8f9;
    margin-top: 16px;
    margin-bottom: 17px;
    padding: 0;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
  }

  .checkout-widget .sections .ineligible-coupon {
    background: #fef4f0;
    grid-template-columns: min-content 1fr;
    grid-template-areas: "icon description"
                         ". reset";
    gap: 15px 10px;
    margin: 15px 0;
    padding: 19px 20px 20px 19px;
    display: grid;
  }

  .checkout-widget .sections .ineligible-coupon .error.icon {
    grid-area: icon;
    width: 22px !important;
    height: 20px !important;
  }

  .checkout-widget .sections .ineligible-coupon .description {
    text-align: left;
    color: #27283e;
    grid-area: description;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .checkout-widget .sections .ineligible-coupon .reset {
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: left;
    color: #b0204b;
    text-transform: uppercase;
    cursor: pointer;
    grid-area: reset;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
  }

  .checkout-widget .sections .use-text {
    text-align: center;
    color: #4cbba1;
    cursor: pointer;
    margin-top: 35px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .checkout-widget .sections .redeem-coupon .coupons {
    grid-gap: 10px;
    flex-flow: column;
    margin: 32px 0 0;
    padding-bottom: 20px;
    display: flex;
  }

  .checkout-widget .sections .redeem-coupon .coupons .recommended-coupon {
    text-transform: uppercase;
    color: #d67300;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin: 24px 25px 0 !important;
  }

  .checkout-widget .sections .redeem-coupon .coupons .note {
    text-align: left;
    letter-spacing: unset;
    margin: unset;
    text-transform: unset;
    color: #27283e;
    margin: 0 20px;
    padding: 0 15px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .checkout-widget .sections .redeem-coupon .coupons .note .learn-more {
    color: #d67300;
    cursor: pointer;
    white-space: nowrap;
  }

  .checkout-widget .sections .comments .add-comments textarea {
    letter-spacing: .25px;
    vertical-align: middle;
    word-break: break-word;
    scrollbar-width: none;
    scrollbar-color: transparent transparent;
    padding: 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }

  .checkout-widget .sections .comments .add-comments textarea::-webkit-scrollbar {
    width: 1px;
  }

  .checkout-widget .sections .comments .add-comments textarea::-webkit-scrollbar-track {
    background: none;
  }

  .checkout-widget .sections .comments .add-comments textarea::-webkit-scrollbar-thumb {
    background-color: #0000;
  }

  .checkout-widget .sections .comments .add-comments .actions {
    margin-top: 16px;
  }

  .checkout-widget .sections .comments .add-comments .actions .cancel {
    display: none;
  }

  .checkout-widget .sections .comments .add-comments .actions .clear {
    cursor: pointer;
    color: #4cbba1;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .checkout-widget .sections .comments .add-comments .form-input {
    margin: 0;
    padding: 0;
  }

  .checkout-widget .sections .comments .body {
    flex-flow: column;
    display: flex;
  }

  .checkout-widget .sections .comments .saved-comments .saved-comment {
    overflow-wrap: anywhere;
    background: #fff;
    border-radius: 8px;
    grid-template-columns: 1fr min-content min-content;
    margin-bottom: 3px;
    padding: 11px 5px 11px 11px;
    display: grid;
  }

  .checkout-widget .sections .comments .saved-comments .saved-comment.valid {
    border: 1px solid #e0e0e0;
  }

  .checkout-widget .sections .comments .saved-comments .saved-comment.valid > .comment {
    cursor: pointer;
  }

  .checkout-widget .sections .comments .saved-comments .saved-comment.invalid {
    border: 1px solid #fe5b5b;
  }

  .checkout-widget .sections .comments .saved-comments .saved-comment.invalid > .comment {
    color: #838393;
    cursor: not-allowed;
  }

  .checkout-widget .sections .comments .saved-comments .error-message {
    color: #fe5b5b;
    font-size: 12px;
  }

  .checkout-widget .sections .comments .saved-comments .comment {
    letter-spacing: .1px;
    color: #27283e;
    word-break: break-word;
    align-self: center;
    padding-left: 7px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .checkout-widget .sections .comments .saved-comments .icon {
    height: 28px;
    width: 28px;
    cursor: pointer;
  }

  .checkout-widget .sections .comments .saved-comments .icon.remove {
    background-image: url("trash-icon.1451820b.svg");
  }

  .checkout-widget .sections .comments .saved-comments .icon.edit {
    background-image: url("pen-icon.44caf3b1.svg");
  }

  .checkout-widget .sections .comments .add-new-comment {
    cursor: pointer;
    color: #4cbba1;
    margin-top: 5px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .checkout-widget .sections .payment .payment-types {
    grid-gap: 14px;
    flex-flow: column;
    display: flex;
  }

  .checkout-widget .sections .payment .payment-types .payment-type {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 14.5px;
  }

  .checkout-widget .sections .payment .payment-types .payment-type label {
    white-space: nowrap;
    width: 100%;
    display: flex;
  }

  .checkout-widget .sections .payment .payment-types .powered-by-stripe {
    margin-top: 2px;
    display: flex;
  }

  .checkout-widget .sections .payment .payment-types .powered-by-stripe .description {
    color: #757575;
    padding-right: 10px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .checkout-widget .sections .payment .payment-types .powered-by-stripe .logo {
    margin: auto 0;
  }

  .checkout-widget .sections .order-view-widget {
    grid-gap: 30px;
    flex-flow: column;
    margin-top: -25px;
    display: flex;
  }

  .checkout-widget .sections .order-view-widget .order-summary, .checkout-widget .sections .order-view-widget .cart-summary {
    margin: 0;
  }

  .checkout-widget .sections .order-view-widget .order-summary .collection-type-and-time .value > *, .checkout-widget .sections .order-view-widget .cart-summary .collection-type-and-time .value > * {
    display: inline-block;
  }

  .checkout-widget .sections form > .form-items {
    grid-gap: 16px;
  }

  .checkout-widget .alert {
    box-shadow: none;
    margin: 10px 0;
  }

  .checkout-widget .step-buttons {
    grid-gap: 6px;
    grid-template-areas: "previous continue";
    grid-auto-flow: column;
    margin-top: auto;
    margin-left: auto;
    padding-top: 55px;
    padding-bottom: 32px;
    display: grid;
  }

  .checkout-widget .step-buttons .button {
    width: 100%;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-transform: uppercase;
    padding: 12px 20px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .checkout-widget .step-buttons .spin.icon {
    height: unset;
    width: unset;
    margin: 0 7px 0 0;
  }

  .checkout-widget .step-buttons button.previous {
    width: 100%;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-transform: uppercase;
    box-shadow: none;
    color: #757575;
    background-color: #0000;
    grid-area: previous;
    padding: 12px 20px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .checkout-widget .step-buttons button.continue {
    width: 100%;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--button-success-fg);
    width: max-content;
    background-color: #4cbba1;
    border-radius: 8px;
    grid-area: continue;
    padding: 12px 20px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .checkout-widget .step-buttons button.place-order {
    width: 100%;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-transform: uppercase;
    padding: 12px 20px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@media (min-width: 768px) {
  .session-create-modal {
    width: 450px !important;
  }

  .session-create-modal .header {
    z-index: 99;
    background: #fff;
    justify-content: space-between;
    padding: 32px;
    display: flex;
    position: sticky;
    top: 0;
  }

  .session-create-modal .header .title {
    color: #27283e;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .session-create-modal .header .title {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (min-width: 768px) {
  .session-create-modal .header .close-icon {
    width: 15px;
    cursor: pointer;
    position: relative;
    right: -4px;
  }

  .session-create-modal .header .back-icon {
    cursor: pointer;
  }

  .session-create-modal .header .title {
    text-align: center;
  }

  .session-create-modal .main-display .collection-type-selector {
    margin-bottom: 32px;
    padding: 0 32px;
  }

  .session-create-modal .main-display .collection-type-selector .title {
    display: none;
  }

  .session-create-modal .main-display .collection-type-selector .collection-types {
    gap: 16px;
    display: grid;
  }

  .session-create-modal .main-display .collection-type-selector .collection-types .collection-type {
    cursor: pointer;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    grid-template-areas: "collection-type-icon text . caret-right-icon";
    grid-auto-columns: min-content 1fr min-content;
    gap: 15px;
    padding: 20px 25px;
    display: grid;
  }

  .session-create-modal .main-display .collection-type-selector .collection-types .collection-type .collection-type-icon {
    width: 42px;
    grid-area: collection-type-icon;
  }

  .session-create-modal .main-display .collection-type-selector .collection-types .collection-type .text {
    color: #27283e;
    white-space: nowrap;
    grid-area: text;
    align-self: center;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
  }

  .session-create-modal .main-display .collection-type-selector .collection-types .collection-type .caret-right.icon {
    grid-area: caret-right-icon;
    margin: auto 0;
  }

  .session-create-modal .main-display .table-selector {
    grid-template-columns: 1fr max-content;
    grid-template-areas: "title image"
                         "table-list table-list";
    padding: 0 32px 32px;
    display: grid;
  }

  .session-create-modal .main-display .table-selector .title {
    color: #27283e;
    grid-area: title;
    align-self: center;
    margin-top: 11px;
    margin-bottom: 25px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    display: flex;
  }

  .session-create-modal .main-display .table-selector .image {
    width: 39px;
    grid-area: image;
    position: relative;
    top: 7px;
    right: -7px;
  }

  .session-create-modal .main-display .table-selector .table-list {
    max-height: calc(100vh - 474px);
    grid-gap: 15px;
    background-color: #0000;
    background-image: linear-gradient(#fff 30%, #fff0), linear-gradient(#fff0, #fff 70%), radial-gradient(farthest-side at 50% 0, #0003, #0000), radial-gradient(farthest-side at 50% 100%, #0003, #0000);
    background-position: 0 0, 0 100%, 0 0, 0 100%;
    background-repeat: no-repeat;
    background-size: 100% 40px, 100% 40px, 100% 10px, 100% 10px;
    background-attachment: local, local, scroll, scroll;
    background-origin: padding-box, padding-box, padding-box, padding-box;
    background-clip: border-box, border-box, border-box, border-box;
    grid-area: table-list;
    display: grid;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .session-create-modal .main-display .table-selector .table-list::-webkit-scrollbar {
    width: 5px;
  }

  .session-create-modal .main-display .table-selector .table-list::-webkit-scrollbar-track {
    background: none;
  }

  .session-create-modal .main-display .table-selector .table-list::-webkit-scrollbar-thumb {
    background-color: #27283e50;
    border-radius: 20px;
  }

  .session-create-modal .main-display .table-selector .table-list .table {
    cursor: pointer;
    color: #27283e;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    grid-template-areas: "name caret-right-icon";
    align-items: center;
    padding: 17px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: grid;
  }

  .session-create-modal .main-display .table-selector .table-list .table .name {
    grid-area: name;
    margin-right: 15px;
  }

  .session-create-modal .main-display .table-selector .table-list .table .caret-right.icon {
    grid-area: caret-right-icon;
    margin-left: auto;
  }

  .session-create-modal .main-display .collection-date-and-time-selector {
    grid-template-columns: 1fr max-content;
    grid-template-areas: "title image"
                         "date-list date-list";
    margin-bottom: 30px;
    padding: 0 32px;
    display: grid;
  }

  .session-create-modal .main-display .collection-date-and-time-selector .title {
    color: #27283e;
    grid-area: title;
    align-self: center;
    margin-top: 11px;
    margin-bottom: 25px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    display: flex;
  }

  .session-create-modal .main-display .collection-date-and-time-selector .image {
    width: 39px;
    grid-area: image;
    position: relative;
    top: 7px;
    right: -7px;
  }

  .session-create-modal .main-display .collection-date-and-time-selector .third-party-delivery-banner {
    grid-gap: 7px;
    width: 100%;
    box-sizing: border-box;
    background: #ffebcf;
    border-radius: 4px;
    flex-flow: row;
    margin-bottom: 8px;
    padding: 16px 12px 16px 16px;
    display: flex;
  }

  .session-create-modal .main-display .collection-date-and-time-selector .third-party-delivery-banner .message {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .session-create-modal .main-display .collection-date-and-time-selector .third-party-delivery-banner .message > div {
    color: #d67300;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    display: inline;
  }

  .session-create-modal .main-display .collection-date-and-time-selector .third-party-delivery-banner .arrow-link {
    cursor: pointer;
  }

  .session-create-modal .main-display .collection-date-and-time-selector .collection-dates-and-times {
    max-height: calc(100vh - 498px);
    background-color: #0000;
    background-image: linear-gradient(#fff 30%, #fff0), linear-gradient(#fff0, #fff 70%), radial-gradient(farthest-side at 50% 0, #0003, #0000), radial-gradient(farthest-side at 50% 100%, #0003, #0000);
    background-position: 0 0, 0 100%, 0 0, 0 100%;
    background-repeat: no-repeat;
    background-size: 100% 40px, 100% 40px, 100% 10px, 100% 10px;
    background-attachment: local, local, scroll, scroll;
    background-origin: padding-box, padding-box, padding-box, padding-box;
    background-clip: border-box, border-box, border-box, border-box;
    grid-area: date-list;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .session-create-modal .main-display .collection-date-and-time-selector .collection-dates-and-times::-webkit-scrollbar {
    width: 5px;
  }

  .session-create-modal .main-display .collection-date-and-time-selector .collection-dates-and-times::-webkit-scrollbar-track {
    background: none;
  }

  .session-create-modal .main-display .collection-date-and-time-selector .collection-dates-and-times::-webkit-scrollbar-thumb {
    background-color: #27283e50;
    border-radius: 20px;
  }

  .session-create-modal .main-display .collection-date-and-time-selector .holiday-info {
    color: #27283e;
    background: #ffebcf;
    border-radius: 4px;
    margin-bottom: 8px;
    padding: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .session-create-modal .main-display .delivery-address-editor .action-buttons {
    grid-gap: 17px;
    box-sizing: border-box;
    width: 100%;
    padding: 32px;
    display: grid;
  }

  .session-create-modal .main-display .delivery-address-editor .action-buttons .start-order {
    color: #fff;
    width: 100%;
    letter-spacing: 2px;
    text-transform: uppercase;
    background: #4cbba1;
    padding: 14px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .session-create-modal .main-display .delivery-address-editor .action-buttons .start-order.disabled {
    opacity: .5;
    cursor: not-allowed;
    color: #fff !important;
    border-color: unset !important;
    background: #4cbba1 !important;
  }

  .loyalty-card-widget {
    width: 400px;
    aspect-ratio: 4 / 2.5;
    background-color: #9191a1;
    border-radius: 10px;
    position: relative;
    box-shadow: 1px 3px 4px 1px #00000040;
  }

  .loyalty-card-widget .background img, .loyalty-card-widget .background .optimized-image img {
    width: 100%;
    border-radius: 10px;
    position: absolute;
    top: 0;
  }

  .loyalty-card-widget .details {
    width: calc(100% - 53px);
    height: 100%;
    grid-template-areas: "balance emv-chip"
                         "count-progress count-progress"
                         "customer-info customer-info";
    padding: 0 27px;
    display: grid;
    position: absolute;
    top: 0;
  }

  .loyalty-card-widget .details .balance {
    height: inherit;
    color: #fff;
    text-shadow: 0 1px 1px #000c;
    letter-spacing: 2px;
    grid-area: balance;
    align-items: end;
    font-family: larabie;
    font-size: 35px;
    font-style: normal;
    font-weight: 500;
    display: flex;
  }

  .loyalty-card-widget .details .mock-emv-chip {
    grid-area: emv-chip;
    justify-content: end;
    align-items: end;
    display: flex;
  }

  .loyalty-card-widget .details .mock-emv-chip img {
    width: 32px;
  }

  .loyalty-card-widget .details .count-progress {
    grid-area: count-progress;
    grid-auto-flow: column;
    align-items: center;
    column-gap: 5px;
    display: grid;
  }

  .loyalty-card-widget .details .count-progress .count {
    height: 10px;
    background-color: #e0e0e0;
    border-radius: 10px;
    box-shadow: inset 0 2px 4px #0000004a;
  }

  .loyalty-card-widget .details .count-progress .count.active {
    background-color: #33d169;
    border: 1px solid #00000036;
    box-shadow: inset 0 -2px 4px #0003, inset 0 -3px 3px #00000080;
  }

  .loyalty-card-widget .details .customer-info {
    color: #fff;
    text-shadow: 1px 1px 1px #000;
    text-transform: uppercase;
    letter-spacing: 2px;
    grid-area: customer-info;
    font-family: kredit;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }

  .loyalty-card-widget .details .customer-info .name {
    -webkit-line-clamp: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

@media (min-width: 768px) {
  .pizza-widget {
    max-height: calc(100vh - 240px);
    flex-flow: column;
    display: flex;
  }

  .pizza-widget .item-body {
    background-color: #0000;
    background-image: linear-gradient(#fff 30%, #fff0), linear-gradient(#fff0, #fff 70%), radial-gradient(farthest-side at 50% 0, #0003, #0000), radial-gradient(farthest-side at 50% 100%, #0003, #0000);
    background-position: 0 0, 0 100%, 0 0, 0 100%;
    background-repeat: no-repeat;
    background-size: 100% 40px, 100% 40px, 100% 10px, 100% 10px;
    background-attachment: local, local, scroll, scroll;
    background-origin: padding-box, padding-box, padding-box, padding-box;
    background-clip: border-box, border-box, border-box, border-box;
    padding-bottom: 72.25px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .pizza-widget .item-body::-webkit-scrollbar {
    width: 5px;
  }

  .pizza-widget .item-body::-webkit-scrollbar-track {
    background: none;
  }

  .pizza-widget .item-body::-webkit-scrollbar-thumb {
    background-color: #27283e50;
    border-radius: 20px;
  }

  .pizza-widget .item-body::-webkit-scrollbar {
    width: 0;
  }

  .pizza-widget .item-body .close-icon {
    width: 16px;
    z-index: 2;
    cursor: pointer;
    background: #fff;
    border-radius: 100%;
    margin-right: 19px;
    padding: 7px;
    position: absolute;
    top: 25px;
    right: 8px;
  }

  .pizza-widget .item-body .close-icon.no-image {
    background: #eee;
    top: 23px;
    box-shadow: 0 2px 2px #31303e14, 0 6px 10px #31303e1a;
  }

  .pizza-widget .item-image.no-image {
    margin-top: unset;
  }

  .pizza-widget .item-name {
    color: #27283e;
    z-index: 1;
    background: #fff;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    position: sticky;
    top: 0;
  }

  .pizza-widget .item-name:before {
    content: "";
    height: 16px;
    -webkit-position: sticky;
    display: block;
    position: sticky;
    top: 57.2px;
    box-shadow: 0 2px 5px #00000024;
  }

  .pizza-widget .item-name.no-image:before {
    margin-top: 10px;
  }

  .pizza-widget .item-name > .container {
    -webkit-position: sticky;
    z-index: 3;
    background-color: #fff;
    margin-top: -16px;
    padding: 25px 32px 18px;
    position: sticky;
    top: 0;
  }

  .pizza-widget .item-image.no-image {
    margin-top: unset;
  }

  .pizza-widget .item-sold-out, .pizza-widget .item-unavailable {
    color: #27283e;
    width: -moz-fit-content;
    width: fit-content;
    z-index: 5;
    background: #e0e0e0;
    border-radius: 4px;
    margin: 25px 65px 0 30px;
    padding: 2px 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    position: sticky;
  }

  .pizza-widget .item-label {
    color: #27283e;
    z-index: 5;
    background-color: #ffebcf;
    border-radius: 4px;
    margin: 25px 65px 0 30px;
    padding: 3px 6px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    display: inline-block;
    position: sticky;
  }

  .pizza-widget .item-name {
    color: #27283e;
    z-index: 1;
    background: #fff;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    position: sticky;
    top: 0;
  }

  .pizza-widget .item-name:before {
    content: "";
    height: 10px;
    -webkit-position: sticky;
    display: block;
    position: sticky;
    top: 57.2px;
    box-shadow: 0 2px 5px #00000024;
  }

  .pizza-widget .item-name.no-image:before {
    margin-top: 10px;
  }

  .pizza-widget .item-name > .container {
    -webkit-position: sticky;
    z-index: 3;
    background-color: #fff;
    margin-top: -16px;
    padding: 25px 32px 18px;
    position: sticky;
    top: 0;
  }

  .pizza-widget .item-details {
    background: #fff;
    padding: 0 32px 10px;
  }

  .pizza-widget .item-details * {
    margin: 0;
  }

  .pizza-widget .item-details .item-description {
    color: #757575;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .pizza-widget .item-details .item-price-and-dietary {
    flex-direction: row;
    align-items: baseline;
    padding-top: 17px;
    display: flex;
  }

  .pizza-widget .item-details .item-price-and-dietary .item-price {
    color: #27283e;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .pizza-widget .item-details .item-price-and-dietary .item-dietary {
    height: min-content;
    color: #757575;
    grid-column-start: 3;
    justify-self: flex-end;
    margin-left: auto;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .pizza-widget .item-details .item-available-weekly-hours-widget {
    margin-top: 18px;
  }

  .pizza-widget .pizza {
    padding: 18px 0;
  }

  .pizza-widget .actions .add-to-cart-widget {
    padding: 24px 30px;
  }

  .pizza-widget .spinner-container {
    box-sizing: border-box;
    height: 51px;
    z-index: 2;
    background: #ffebcf;
    border: 1px solid #f8bd79;
    border-radius: 8px;
    flex-direction: row;
    gap: 10px;
    padding: 14px;
    display: flex;
    position: absolute;
    top: 42px;
    left: calc(50% - 140px);
  }

  .pizza-widget .spinner-container .message {
    color: #d67300;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .pizza-widget .spinner-container .spinner {
    animation: 2s linear infinite spin;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

@media (min-width: 768px) {
  .basic-item-widget {
    max-height: calc(100vh - 240px);
    flex-flow: column;
    display: flex;
  }

  .basic-item-widget .item-body {
    background-color: #0000;
    background-image: linear-gradient(#fff 30%, #fff0), linear-gradient(#fff0, #fff 70%), radial-gradient(farthest-side at 50% 0, #0003, #0000), radial-gradient(farthest-side at 50% 100%, #0003, #0000);
    background-position: 0 0, 0 100%, 0 0, 0 100%;
    background-repeat: no-repeat;
    background-size: 100% 40px, 100% 40px, 100% 10px, 100% 10px;
    background-attachment: local, local, scroll, scroll;
    background-origin: padding-box, padding-box, padding-box, padding-box;
    background-clip: border-box, border-box, border-box, border-box;
    padding-bottom: 72.25px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .basic-item-widget .item-body::-webkit-scrollbar {
    width: 5px;
  }

  .basic-item-widget .item-body::-webkit-scrollbar-track {
    background: none;
  }

  .basic-item-widget .item-body::-webkit-scrollbar-thumb {
    background-color: #27283e50;
    border-radius: 20px;
  }

  .basic-item-widget .item-body::-webkit-scrollbar {
    width: 0;
  }

  .basic-item-widget .item-body .close-icon {
    width: 16px;
    z-index: 2;
    cursor: pointer;
    background: #fff;
    border-radius: 100%;
    margin-right: 19px;
    padding: 7px;
    position: absolute;
    top: 25px;
    right: 8px;
  }

  .basic-item-widget .item-body .close-icon.no-image {
    background: #eee;
    top: 23px;
    box-shadow: 0 2px 2px #31303e14, 0 6px 10px #31303e1a;
  }

  .basic-item-widget .item-image.no-image {
    margin-top: unset;
  }

  .basic-item-widget .item-name {
    color: #27283e;
    z-index: 1;
    background: #fff;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    position: sticky;
    top: 0;
  }

  .basic-item-widget .item-name:before {
    content: "";
    height: 16px;
    -webkit-position: sticky;
    display: block;
    position: sticky;
    top: 57.2px;
    box-shadow: 0 2px 5px #00000024;
  }

  .basic-item-widget .item-name.no-image:before {
    margin-top: 10px;
  }

  .basic-item-widget .item-name > .container {
    -webkit-position: sticky;
    z-index: 3;
    background-color: #fff;
    margin-top: -16px;
    padding: 25px 32px 18px;
    position: sticky;
    top: 0;
  }

  .basic-item-widget .item-image.no-image {
    margin-top: unset;
  }

  .basic-item-widget .item-sold-out, .basic-item-widget .item-unavailable {
    color: #27283e;
    width: -moz-fit-content;
    width: -moz-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    z-index: 5;
    background: #e0e0e0;
    border-radius: 4px;
    margin: 25px 65px 0 30px;
    padding: 2px 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    position: sticky;
  }

  .basic-item-widget .item-label {
    color: #27283e;
    z-index: 5;
    background-color: #ffebcf;
    border-radius: 4px;
    margin: 25px 65px 0 30px;
    padding: 3px 6px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    display: inline-block;
    position: sticky;
  }

  .basic-item-widget .item-name {
    color: #27283e;
    z-index: 1;
    background: #fff;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    position: sticky;
    top: 0;
  }

  .basic-item-widget .item-name:before {
    content: "";
    height: 10px;
    -webkit-position: sticky;
    display: block;
    position: sticky;
    top: 57.2px;
    box-shadow: 0 2px 5px #00000024;
  }

  .basic-item-widget .item-name.no-image:before {
    margin-top: 10px;
  }

  .basic-item-widget .item-name > .container {
    -webkit-position: sticky;
    z-index: 3;
    background-color: #fff;
    margin-top: -16px;
    padding: 25px 32px 18px;
    position: sticky;
    top: 0;
  }

  .basic-item-widget .item-details {
    background: #fff;
    padding: 0 32px 10px;
  }

  .basic-item-widget .item-details * {
    margin: 0;
  }

  .basic-item-widget .item-details .item-description {
    color: #757575;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .basic-item-widget .item-details .item-price-and-dietary {
    flex-direction: row;
    align-items: baseline;
    padding-top: 17px;
    display: flex;
  }

  .basic-item-widget .item-details .item-price-and-dietary .item-price {
    color: #27283e;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .basic-item-widget .item-details .item-price-and-dietary .item-dietary {
    height: min-content;
    color: #757575;
    grid-column-start: 3;
    justify-self: flex-end;
    margin-left: auto;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .basic-item-widget .item-details .item-available-weekly-hours-widget {
    margin-top: 18px;
  }

  .basic-item-widget .modifiers {
    margin-top: 30.5px;
  }

  .basic-item-widget .actions .add-to-cart-widget {
    padding: 24px 30px;
  }

  .basic-item-widget .spinner-container {
    box-sizing: border-box;
    height: 51px;
    z-index: 2;
    background: #ffebcf;
    border: 1px solid #f8bd79;
    border-radius: 8px;
    flex-direction: row;
    gap: 10px;
    padding: 14px;
    display: flex;
    position: absolute;
    top: 42px;
    left: calc(50% - 140px);
  }

  .basic-item-widget .spinner-container .message {
    color: #d67300;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .basic-item-widget .spinner-container .spinner {
    animation: 2s linear infinite spin;
  }

  .footer-widget {
    height: 100%;
    background-color: #27283e;
  }

  .footer-widget .wrapper {
    max-width: 1280px;
    height: 138px;
    grid-template-columns: min-content 1fr max-content;
    margin: 0 auto;
    padding: 56px 20px;
    display: grid;
  }

  .footer-widget .wrapper .powered-by .text {
    color: #fff;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .footer-widget .wrapper .content {
    margin-top: 16px;
    margin-left: 17%;
  }

  .footer-widget .wrapper .content .text {
    color: #fff;
    width: 55%;
    margin-bottom: 28px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .footer-widget .wrapper .content .cta {
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #4cbba1;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    text-decoration: none;
  }

  .footer-widget .wrapper .links {
    gap: 16px;
    display: flex;
  }

  .footer-widget .wrapper .links > a {
    color: #838393;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-decoration: none;
  }

  .restaurant-header-widget, .store-header-widget {
    flex-direction: horizontal;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  .restaurant-header-widget .name, .store-header-widget .name {
    text-align: left;
    color: #fff;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    overflow: hidden;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@media (min-width: 768px) {
  .suburbs-list-widget {
    background: #fff;
    grid-template-columns: 1fr max-content;
    grid-template-areas: "title image"
                         "suburb-search suburb-search"
                         "suburb-list suburb-list";
    padding: 0 32px 32px;
    display: grid;
  }

  .suburbs-list-widget .title {
    color: #27283e;
    grid-area: title;
    align-self: center;
    margin-top: 11px;
    margin-bottom: 25px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    display: flex;
  }

  .suburbs-list-widget .image {
    width: 39px;
    grid-area: image;
    position: relative;
    top: 7px;
    right: -7px;
  }

  .suburbs-list-widget .suburb-search {
    grid-area: suburb-search;
    position: relative;
  }

  .suburbs-list-widget .suburb-search .search-icon {
    z-index: 1;
    opacity: .55;
    width: 16px;
    position: absolute;
    top: 17px;
    left: 15px;
  }

  .suburbs-list-widget .suburb-search input {
    width: -webkit-fill-available;
    color: #27283e;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    outline: none;
    margin-bottom: 8px;
    padding: 15px 42px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .suburbs-list-widget .suburbs {
    max-height: calc(100vh - 474px);
    background-color: #0000;
    background-image: linear-gradient(#fff 30%, #fff0), linear-gradient(#fff0, #fff 70%), radial-gradient(farthest-side at 50% 0, #0003, #0000), radial-gradient(farthest-side at 50% 100%, #0003, #0000);
    background-position: 0 0, 0 100%, 0 0, 0 100%;
    background-repeat: no-repeat;
    background-size: 100% 40px, 100% 40px, 100% 10px, 100% 10px;
    background-attachment: local, local, scroll, scroll;
    background-origin: padding-box, padding-box, padding-box, padding-box;
    background-clip: border-box, border-box, border-box, border-box;
    grid-area: suburb-list;
    gap: 9px;
    display: grid;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .suburbs-list-widget .suburbs::-webkit-scrollbar {
    width: 5px;
  }

  .suburbs-list-widget .suburbs::-webkit-scrollbar-track {
    background: none;
  }

  .suburbs-list-widget .suburbs::-webkit-scrollbar-thumb {
    background-color: #27283e50;
    border-radius: 20px;
  }

  .suburbs-list-widget .suburbs .no-suburb-found {
    color: #757575;
    text-align: center;
    margin-top: 30px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .suburbs-list-widget .suburbs .suburb {
    cursor: pointer;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    grid-template-columns: max-content min-content 1fr min-content min-content;
    grid-template-areas: "name postcode . delivery-charge caret-right-icon";
    align-items: center;
    padding: 16px 17px 17px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: grid;
  }

  .suburbs-list-widget .suburbs .suburb .name {
    color: #27283e;
    grid-area: name;
    font-weight: 400;
  }

  .suburbs-list-widget .suburbs .suburb .postcode {
    color: #757575;
    grid-area: postcode;
    margin-left: 8px;
    font-weight: 400;
  }

  .suburbs-list-widget .suburbs .suburb .delivery-charge {
    color: #757575;
    white-space: nowrap;
    grid-area: delivery-charge;
    margin-right: 8px;
  }

  .suburbs-list-widget .suburbs .suburb .caret-right.icon {
    grid-area: caret-right-icon;
  }

  .delivery-address-edit-widget {
    flex-direction: column;
    padding: 0 32px 32px;
    display: flex;
  }

  .delivery-address-edit-widget .section {
    flex-direction: column;
    margin-bottom: 24px;
    display: flex;
  }

  .delivery-address-edit-widget .section .title {
    color: #27283e;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .delivery-address-edit-widget .section-header {
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    display: flex;
  }

  .delivery-address-edit-widget .section-header .image {
    width: 39px;
    display: flex;
  }

  .delivery-address-edit-widget .delivery-address {
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 24px;
  }

  .delivery-address-edit-widget .unavailable-address {
    background-color: #ffe7df;
    gap: 10px;
    margin-bottom: 12px;
    padding: 12px;
    display: flex;
  }

  .delivery-address-edit-widget .unavailable-address .image {
    width: 24px;
    grid-area: auto;
    top: 0;
    right: 0;
  }

  .delivery-address-edit-widget .unavailable-address .text {
    align-items: center;
    display: flex;
  }

  .delivery-address-edit-widget .delivery-charge {
    color: #9191a1;
    background-color: #fff;
    gap: 10px;
    margin-bottom: 17px;
    display: flex;
  }

  .delivery-address-edit-widget .invalid {
    border-color: #e0e0e0 !important;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@media (min-width: 768px) {
  .update-delivery-address-widget {
    letter-spacing: .25px;
    color: #27283e;
    height: 100%;
    width: 450px !important;
  }

  .update-delivery-address-widget .header-section {
    z-index: 99;
    background: #fff;
    justify-content: space-between;
    padding: 0 32px;
    display: flex;
    position: sticky;
    top: 0;
  }

  .update-delivery-address-widget .header-section .title {
    color: #27283e;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .update-delivery-address-widget .header-section .title {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (min-width: 768px) {
  .update-delivery-address-widget .header-section .close-icon {
    width: 15px;
    cursor: pointer;
    width: 15px;
    margin-right: 5px;
    position: relative;
    right: -4px;
  }

  .update-delivery-address-widget .body {
    display: grid;
  }

  .update-delivery-address-widget .body .action-buttons {
    box-sizing: border-box;
    width: 100%;
    flex-flow: row-reverse;
    gap: 17px;
    padding: 0 32px 24px;
    display: flex;
  }

  .update-delivery-address-widget .body .action-buttons .update-address-button {
    color: #fff;
    width: 100%;
    letter-spacing: 2px;
    text-transform: uppercase;
    background: #4cbba1;
    padding: 14px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .update-delivery-address-widget .body .action-buttons .cancel-order-button {
    color: #4cbba1;
    width: 100%;
    letter-spacing: 2px;
    text-transform: uppercase;
    background: #fff;
    border: 2px solid #4cbba1;
    padding: 13px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .swal2-modal {
    width: 400px !important;
    border-radius: 8px !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .swal2-modal .swal2-header {
    padding: 10px 32px;
    position: relative;
  }

  .swal2-modal .swal2-content {
    white-space: pre-wrap;
    padding: 0 32px;
  }

  .swal2-modal .swal2-content #swal2-content {
    margin-bottom: 20px;
  }

  .swal2-modal .swal2-content #swal2-content > b {
    font-weight: 700;
  }

  .swal2-modal .swal2-title {
    width: 100%;
    color: #27283e;
    justify-content: center;
    margin-top: 15px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .swal2-modal .swal2-html-container {
    color: #27283e;
    text-align: center;
    margin: 0 auto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    position: relative;
  }

  .swal2-modal .swal2-actions {
    background: #fcfcfc;
    border-radius: 0 0 16px 16px;
    flex-direction: row-reverse;
    gap: 8px;
    margin: 0;
    padding: 15px 28px 20px;
    display: flex;
    box-shadow: inset 0 1px #e8ecf1;
    justify-content: space-between !important;
  }

  .swal2-modal .swal2-actions button {
    letter-spacing: 2px;
    text-transform: uppercase;
    text-transform: uppercase;
    padding: 12px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: center !important;
  }

  .swal2-modal .swal2-actions .confirm-button {
    flex: 1;
    background: #4cbba1 !important;
    border-radius: 8px !important;
  }

  .swal2-modal .swal2-actions .cancel-button {
    color: #757575;
    flex: 1;
    background: #fcfcfc !important;
    border-radius: 8px !important;
    box-shadow: inset 0 0 1px 2px #e8ecf1 !important;
  }

  .add-to-cart-widget {
    background-color: linear-gradient(0deg, #fff, #fff);
    grid-template-columns: 1fr auto;
    display: grid;
  }

  .add-to-cart-widget .quantity {
    align-self: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .add-to-cart-widget .quantity label {
    display: none;
  }

  .add-to-cart-widget .quantity .quantity-actions {
    gap: 18px;
    display: flex;
  }

  .add-to-cart-widget .quantity .quantity-actions .inc-icon, .add-to-cart-widget .quantity .quantity-actions .dec-icon {
    width: 24px;
    text-align: center;
  }

  .add-to-cart-widget .quantity .quantity-actions .number {
    grid-area: number;
    align-self: center;
  }

  .add-to-cart-widget .total-price {
    display: none;
  }

  .add-to-cart-widget > .add-to-cart-button {
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;
    color: #fff;
    border-radius: 8px;
    padding: 12px 17px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    background: #4cbba1 !important;
  }

  .add-to-cart-widget > .add-to-cart-button span {
    margin-left: .5em;
    font-weight: 700;
  }

  .add-to-cart-widget > .add-to-cart-button.disabled {
    opacity: .5;
    pointer-events: none;
    color: #fff !important;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@media (min-width: 768px) {
  .deal-item .modifier-widget .header {
    background-color: #0000;
  }

  .deal-item .modifier-widget .options {
    padding: 0 32px;
  }

  .modifier-widget {
    color: #27283e;
  }

  .modifier-widget input[type="checkbox"] {
    display: none;
  }

  .modifier-widget input[type="checkbox"] + label {
    margin-left: 25px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    position: relative;
  }

  .modifier-widget input[type="checkbox"] + label:before {
    content: "";
    width: 16px;
    height: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    position: absolute;
    top: -1px;
    left: -28px;
  }

  .modifier-widget input[type="checkbox"]:checked + label:before {
    background: #4cbba1 url("icon-check-light.3e3cc582.svg") center no-repeat;
    border: 1px solid #4cbba1;
  }

  .modifier-widget input[type="radio"] {
    cursor: pointer;
    display: none;
  }

  .modifier-widget input[type="radio"] + label {
    color: #27283e;
    cursor: pointer;
    align-items: center;
    margin-left: 25px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    position: relative;
  }

  .modifier-widget input[type="radio"] + label:before {
    content: "";
    width: 14px;
    height: 14px;
    border: 1px solid #e0e0e0;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: -28px;
  }

  .modifier-widget input[type="radio"]:checked + label:before {
    background: #4cbba1;
    border: 1px solid #4cbba1;
  }

  .modifier-widget .header {
    background-color: #fafafa;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
    padding: 16px 32px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    display: flex;
  }

  .modifier-widget .header .title {
    justify-content: space-between;
    margin-bottom: 4px;
    display: flex;
  }

  .modifier-widget .header .title .modifier-name {
    margin-right: 8px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .modifier-widget .header .title .requiredness {
    color: #757575;
    justify-content: center;
    align-items: center;
    padding: 0 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    display: flex;
  }

  .modifier-widget .header .title .selected:before {
    content: url("green-check.23f410e7.svg");
    padding-right: 5px;
  }

  .modifier-widget .header .title .required {
    color: #086e37;
  }

  .modifier-widget .header .title .error {
    color: #b0204b;
    background-color: #ffe7df;
  }

  .modifier-widget .header .max-quantity {
    color: #757575;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .modifier-widget .options {
    grid-gap: 8px;
    padding: 16px 32px 32px;
    display: grid;
  }

  .modifier-widget .options .option {
    cursor: pointer;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    grid-template-columns: 1fr min-content min-content;
    display: grid;
  }

  .modifier-widget .options .option .option-form-input {
    width: 100%;
    padding: 14px 8px 14px 14px;
  }

  .modifier-widget .options .option .wrapper {
    grid-gap: 20px;
    height: -moz-fit-content;
    height: fit-content;
    align-self: center;
    margin-right: 10px;
    display: flex;
  }

  .modifier-widget .options .option .wrapper .quantity {
    grid-gap: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
  }

  .modifier-widget .options .option .wrapper .quantity .inc-icon, .modifier-widget .options .option .wrapper .quantity .dec-icon {
    width: 24px;
    text-align: center;
    color: #3a3a4e;
    background: #eee;
    border-radius: 50%;
  }

  .modifier-widget .options .option .wrapper .extra-charge {
    white-space: nowrap;
    color: #757575;
    align-self: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .modifier-widget .options .option.selected {
    border: 1px solid #4cbba1;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@media (min-width: 768px) {
  .pizza-customize-widget {
    flex-flow: column;
    display: flex;
  }

  .pizza-customize-widget .item-header {
    z-index: 99;
    background: #fff;
    justify-content: space-between;
    padding: 20px 32px;
    display: flex;
    position: sticky;
    top: 0;
  }

  .pizza-customize-widget .item-header .title {
    color: #27283e;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .pizza-customize-widget .item-header .title {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (min-width: 768px) {
  .pizza-customize-widget .item-header .close-icon {
    width: 15px;
    cursor: pointer;
    position: relative;
    right: -4px;
  }

  .pizza-customize-widget .item-header .done-button {
    color: #4cbba1;
    cursor: pointer;
    padding-top: 5px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .pizza-customize-widget .item-body {
    max-height: calc(100vh - 330px);
    background-color: #0000;
    background-image: linear-gradient(#fff 30%, #fff0), linear-gradient(#fff0, #fff 70%), radial-gradient(farthest-side at 50% 0, #0003, #0000), radial-gradient(farthest-side at 50% 100%, #0003, #0000);
    background-position: 0 0, 0 100%, 0 0, 0 100%;
    background-repeat: no-repeat;
    background-size: 100% 40px, 100% 40px, 100% 10px, 100% 10px;
    background-attachment: local, local, scroll, scroll;
    background-origin: padding-box, padding-box, padding-box, padding-box;
    background-clip: border-box, border-box, border-box, border-box;
    margin-bottom: 26px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .pizza-customize-widget .item-body::-webkit-scrollbar {
    width: 5px;
  }

  .pizza-customize-widget .item-body::-webkit-scrollbar-track {
    background: none;
  }

  .pizza-customize-widget .item-body::-webkit-scrollbar-thumb {
    background-color: #27283e50;
    border-radius: 20px;
  }

  .pizza-customize-widget .item-body .modifiers {
    grid-gap: 36px;
    flex-flow: column;
    margin-bottom: 8px;
    padding-top: 14px;
    display: flex;
    overflow: auto;
  }

  .pizza-customize-widget .item-body .modifiers .remove-toppings .extra-charge {
    display: none;
  }

  .table-booking-history-page {
    background: #fafafa;
  }

  .table-booking-history-page.page > .header {
    box-shadow: none;
    padding: 0;
  }

  .table-booking-history-page .header {
    padding: 0;
  }

  .table-booking-history-page .header .wrapper {
    position: relative;
  }

  .table-booking-history-page .header .wrapper .nav {
    display: none;
  }

  .table-booking-history-page .header .wrapper .banner-image {
    max-width: 1280px;
    position: relative;
  }

  .table-booking-history-page .header .wrapper .banner-image .mobile, .table-booking-history-page .header .wrapper .store-title, .table-booking-history-page .header .wrapper #page-title-anchor {
    display: none;
  }

  .table-booking-history-page .wrapper .banner {
    padding: 0;
  }

  .table-booking-history-page .wrapper .banner .table-booking-title, .table-booking-history-page .wrapper .banner .table-booking-title.hide {
    display: none;
  }

  .table-booking-history-page .wrapper .banner .not-available-banner {
    background: #ffe7df;
    padding: 22.5px 20px;
  }

  .table-booking-history-page .wrapper .banner .not-available-banner .message {
    color: #b0204b;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .table-booking-history-page .wrapper .banner .not-available-banner .message b {
    font-weight: 600;
  }

  .table-booking-history-page .wrapper .banner .book-table-banner {
    background: #ffebcf;
    place-content: center space-between;
    align-items: center;
    padding: 16px 20px;
    display: flex;
  }

  .table-booking-history-page .wrapper .banner .book-table-banner .message {
    color: #d67300;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .table-booking-history-page .wrapper .banner .book-table-banner button {
    letter-spacing: 2px;
    text-transform: uppercase;
    text-transform: uppercase;
    color: #d67300;
    box-sizing: border-box;
    background: #ffebcf;
    border: 1.5px solid #d67300;
    border-radius: 4px;
    padding: 7.5px 16px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .table-booking-history-page .wrapper .banner.hide {
    display: none;
  }

  .table-booking-history-page .wrapper .table-bookings {
    grid-template-columns: 1fr 1fr;
    column-gap: 81px;
    margin-top: 44px;
    display: grid;
  }

  .table-booking-history-page .wrapper .table-bookings .section {
    margin-bottom: 24px;
  }

  .table-booking-history-page .wrapper .table-bookings .section .heading {
    color: #27283e;
    margin-bottom: 42px;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .table-booking-history-page .wrapper .table-bookings .section .heading {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (min-width: 768px) {
  .table-booking-history-page .wrapper .table-bookings .section .table-booking {
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    grid-template: "booked-for status-icon" max-content
                   "customer customer"
                   "details details"
                   "hr hr"
                   "status status"
                   / 1fr min-content;
    margin-bottom: 24px;
    padding: 15px 16px 20px;
    display: grid;
    box-shadow: 0 2px 4px #0000001a;
  }

  .table-booking-history-page .wrapper .table-bookings .section .table-booking .bookedFor {
    color: #27283e;
    grid-area: booked-for;
    margin-bottom: 7px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .table-booking-history-page .wrapper .table-bookings .section .table-booking .status-icon {
    grid-area: status-icon;
    margin-bottom: 7px;
  }

  .table-booking-history-page .wrapper .table-bookings .section .table-booking .customer {
    color: #27283e;
    grid-area: customer;
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .table-booking-history-page .wrapper .table-bookings .section .table-booking .details {
    color: #27283e;
    grid-area: details;
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .table-booking-history-page .wrapper .table-bookings .section .table-booking hr {
    width: 100%;
    border: 1px solid #e0e0e0;
    grid-area: hr;
    margin: 0 0 16px;
  }

  .table-booking-history-page .wrapper .table-bookings .section .table-booking .status {
    color: #9e9e9e;
    grid-area: status;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .table-booking-history-page .wrapper .table-bookings .section .table-booking .status b {
    font-weight: 600;
  }

  .table-booking-history-page .wrapper .table-bookings .section .table-booking .status .success {
    color: #086e37;
  }

  .table-booking-history-page .wrapper .table-bookings .section .table-booking .status .warning {
    color: #d67300;
  }

  .table-booking-history-page .wrapper .table-bookings .section .table-booking .status .danger {
    color: #b0204b;
  }

  .table-booking-history-page .wrapper .table-bookings .section .table-booking:last-child {
    margin-bottom: 31px;
  }

  .table-booking-history-page .wrapper .table-bookings .section.upcoming-table-bookings .empty-table-booking .message:after {
    content: "No upcoming bookings yet";
  }

  .table-booking-history-page .wrapper .table-bookings .section.past-table-bookings .empty-table-booking .message:after {
    content: "No previous bookings yet";
  }

  .table-booking-history-page .wrapper .table-bookings .section .empty-table-booking {
    text-align: center;
    box-sizing: border-box;
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 32px 0;
    box-shadow: 0 2px 4px #0000001a;
  }

  .table-booking-history-page .wrapper .table-bookings .section .empty-table-booking .icon {
    margin: 0 auto 21.96px;
  }

  .table-booking-history-page .wrapper .table-bookings .section .empty-table-booking .icon-mobile {
    display: none;
  }

  .table-booking-history-page .wrapper .table-bookings .section .empty-table-booking .message {
    text-align: center;
    color: #757575;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .table-booking-history-page .wrapper .table-bookings .section:last-child {
    margin-bottom: 109px;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@media (min-width: 768px) {
  .table-booking-page {
    background: #fafafa;
  }

  .table-booking-page.page > .header .title {
    line-height: 20px;
  }

  .table-booking-page form .form-items > .form-buttons {
    margin-top: 21px;
  }

  .table-booking-page form .form-input input {
    letter-spacing: 0;
    font-size: 14px;
    line-height: 21px;
  }

  .table-booking-page form .form-select-pax .label {
    font-size: 14px;
    line-height: 20px;
  }

  .table-booking-page form .form-select-pax .sub-text {
    letter-spacing: .25px;
    margin-bottom: 24px;
    font-size: 12px;
    line-height: 18px;
  }

  .table-booking-page form .form-single-select-pill .label {
    font-size: 14px;
    line-height: 30px;
  }

  .table-booking-page form .form-single-select-pill.optional > .label:after {
    content: "(Optional)";
    color: #757575;
    margin-left: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .table-booking-page form .form-single-select-pill .option {
    padding: 8px 14px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .table-booking-page form .form-text-area label {
    font-size: 14px;
    line-height: 50px;
  }

  .table-booking-page form .form-text-area .sub-text {
    letter-spacing: .25px;
    font-size: 12px;
    line-height: 5px;
  }

  .table-booking-page form .form-text-area textarea {
    letter-spacing: 0;
    font-size: 15px;
  }

  .table-booking-page form .form-select-icon input, .table-booking-page form .form-select-pax img {
    cursor: pointer;
  }

  .table-booking-page form .form-buttons {
    justify-content: flex-end;
    margin-top: 37px;
    display: flex;
  }

  .table-booking-page form .form-buttons button {
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    background: #4cbba1;
    border-radius: 8px;
    padding: 14px 42px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .table-booking-page form .form-buttons button.disabled {
    cursor: unset;
    opacity: .5;
    color: #fff !important;
    background: #4cbba1 !important;
  }

  .table-booking-page .body > .wrapper {
    box-sizing: border-box;
    max-width: 799px;
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    grid-template-columns: 1fr 1fr;
    column-gap: 64.25px;
    margin: 35px auto;
    padding: 37.5px 32px 32px;
    display: grid;
    box-shadow: 0 2px 4px #0000001a;
  }

  .table-booking-summary-modal > .header {
    z-index: 99;
    background: #fff;
    justify-content: space-between;
    padding: 32px;
    display: flex;
    position: sticky;
    top: 0;
  }

  .table-booking-summary-modal > .header .title {
    color: #27283e;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .table-booking-summary-modal > .header .title {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (min-width: 768px) {
  .table-booking-summary-modal > .header .close-icon {
    width: 15px;
    cursor: pointer;
    width: 16px;
    position: relative;
    right: -4px;
  }

  .table-booking-summary-modal .buttons {
    justify-content: flex-end;
    padding: 24px 32px;
    display: flex;
  }

  .table-booking-summary-modal .buttons .confirm-button {
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    background: #4cbba1;
    border-radius: 8px;
    order: 2;
    padding: 12px 20px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .table-booking-summary-modal .buttons .back-button {
    text-align: center;
    text-transform: uppercase;
    color: #757575;
    box-shadow: none;
    background: #fff;
    border-radius: 8px;
    order: 1;
    padding: 12px 20px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .form-select-pax {
    box-sizing: border-box;
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 20px 16px 16px;
  }

  .form-select-pax .label {
    color: #27283e;
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .form-select-pax .sub-text {
    color: #757575;
    margin-bottom: 12px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .form-select-pax .options {
    grid-template-columns: minmax(100px, min-content) min-content min-content;
    align-items: center;
    column-gap: 16px;
    display: grid;
  }

  .form-select-pax .options .pax {
    color: #27283e;
    text-align: center;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 15px 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .form-single-select-pill .label {
    color: #27283e;
    margin-bottom: 7px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .form-single-select-pill .options.large {
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    display: grid;
  }

  .form-single-select-pill .options.small {
    flex-wrap: wrap;
    display: flex;
  }

  .form-single-select-pill .options .option {
    text-align: center;
    color: #757575;
    box-sizing: border-box;
    cursor: pointer;
    background: #fff;
    border: 1.5px solid #e0e0e0;
    border-radius: 50px;
    margin: 0 8px 8px 0;
    padding: 8.5px 15px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .form-single-select-pill .options .option.selected {
    color: #fff;
    background: #4cbba1;
  }

  .customer-login-flow-widget {
    box-sizing: border-box;
    height: calc(100vh - 240px);
    grid-template-rows: min-content 1fr;
    padding-bottom: 32px;
    display: grid;
    position: relative;
  }

  .customer-login-flow-widget .top-header {
    box-sizing: border-box;
    grid-template-areas: "back-button step-indicator close-button";
    justify-content: space-between;
    align-items: center;
    padding: 23px 26px;
    display: grid;
  }

  .customer-login-flow-widget .top-header .back-button {
    cursor: pointer;
    grid-area: back-button;
  }

  .customer-login-flow-widget .top-header .back-button img {
    height: 17px;
    width: 17px;
  }

  .customer-login-flow-widget .top-header .close-button {
    cursor: pointer;
    grid-area: close-button;
  }

  .customer-login-flow-widget .top-header .close-button img {
    height: 17px;
    width: 17px;
  }

  .customer-login-flow-widget .top-header .step-indicator {
    text-align: left;
    color: #757575;
    grid-area: step-indicator;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .customer-login-flow-widget .body {
    margin: 0 32px;
  }

  .customer-login-flow-widget .body .intro {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 29px 0 32px;
    display: flex;
  }

  .customer-login-flow-widget .body .intro .title {
    text-align: center;
    color: #27283e;
    margin-top: 7px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .customer-login-flow-widget .body .intro .subtitle {
    text-align: center;
    color: #757575;
    margin-top: 6px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .customer-login-flow-widget .form {
    width: 90%;
    margin: 0 auto;
    padding-top: 10px;
  }

  .customer-login-flow-widget form {
    box-sizing: border-box;
    height: 100%;
    flex-flow: column;
    display: flex;
    padding-top: 0 !important;
  }

  .customer-login-flow-widget form .form-items {
    grid-gap: 20px;
    padding-bottom: 10px;
  }

  .customer-login-flow-widget form .form-items .form-input input {
    height: 52px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .customer-login-flow-widget form .form-items .form-input input::placeholder {
    color: #9e9e9e;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .customer-login-flow-widget form .form-items .form-input ::-webkit-input-placeholder {
    color: #757575;
  }

  .customer-login-flow-widget form .form-items .form-input ::-moz-placeholder {
    color: #757575;
  }

  .customer-login-flow-widget form .form-items .form-input :-ms-input-placeholder {
    color: #757575;
  }

  .customer-login-flow-widget form .form-items .form-input :-moz-placeholder {
    color: #757575;
  }

  .customer-login-flow-widget form .form-buttons {
    margin-top: auto;
  }

  .customer-login-flow-widget button {
    width: 100%;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;
    text-transform: uppercase;
    border-radius: 8px;
    margin: 16px 0;
    padding: 16px 20px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .customer-login-flow-widget button span {
    color: #fff;
  }

  .customer-login-flow-widget button.disabled {
    opacity: .5;
    cursor: not-allowed;
    background-color: #4cbba1 !important;
  }

  .customer-login-flow-widget .grecaptcha-badge {
    visibility: hidden;
  }

  .customer-login-flow-widget #recaptcha-wrapper {
    height: 75vh;
    bottom: 0;
    left: 50%;
    overflow: auto;
    transform: translateX(-50%);
    position: fixed !important;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@media (min-width: 768px) {
  .third-party-delivery-widget {
    grid-gap: 16px;
    flex-flow: column;
    display: flex;
  }

  .third-party-delivery-widget a {
    width: 100%;
    height: 52px;
    box-sizing: border-box;
    text-align: center;
    border: 2px solid #e0e0e0;
    border-radius: 8px;
    text-decoration: none;
  }

  .third-party-delivery-widget a .service-name {
    color: #27283e;
    padding: 12px 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: block;
  }

  .third-party-delivery-widget a .service-logo {
    height: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 14px 0;
    display: block;
  }

  .third-party-delivery-widget a #ubereats {
    padding: 17px 0;
  }

  .third-party-delivery-widget a #deliveroo {
    padding: 8px 0;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@media (min-width: 768px) {
  .third-party-delivery-services-modal {
    padding-bottom: 32px;
    width: 450px !important;
  }

  .third-party-delivery-services-modal .header {
    z-index: 99;
    background: #fff;
    justify-content: space-between;
    padding: 32px;
    display: flex;
    position: sticky;
    top: 0;
  }

  .third-party-delivery-services-modal .header .title {
    color: #27283e;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .third-party-delivery-services-modal .header .title {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (min-width: 768px) {
  .third-party-delivery-services-modal .header .close-icon {
    width: 15px;
    cursor: pointer;
    position: relative;
    right: -4px;
  }

  .third-party-delivery-services-modal .section {
    padding: 0 32px 32px;
  }

  .third-party-delivery-services-modal .section .unavailability-message {
    color: #27283e;
    margin-top: 0;
    margin-bottom: 25px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .third-party-delivery-services-modal .section .get-order-message {
    color: #27283e;
    margin-top: 0;
    margin-bottom: 25px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .third-party-delivery-services-modal .actions > .back-button {
    cursor: pointer;
    box-shadow: none;
    color: #757575;
    background: none;
    margin: 0 auto;
    padding: 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .table-booking-success-page.page > .header {
    box-shadow: none;
    padding: 0;
  }

  .table-booking-success-page .body .wrapper {
    margin-top: 90px;
  }

  .table-booking-success-page .body .wrapper .banner {
    text-align: center;
    margin-bottom: 30px;
    padding: 0;
  }

  .table-booking-success-page .body .wrapper .banner .icon {
    margin-bottom: 14px;
  }

  .table-booking-success-page .body .wrapper .banner .icon.mobile {
    display: none;
  }

  .table-booking-success-page .body .wrapper .banner .text {
    color: #27283e;
    margin-bottom: 16px;
    font-size: 26px;
    font-weight: 700;
    line-height: 32px;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .table-booking-success-page .body .wrapper .banner .text {
    font-size: 32px;
    line-height: 48px;
  }
}

@media (min-width: 768px) {
  .table-booking-success-page .body .wrapper .banner .sub-text {
    text-align: center;
    color: #757575;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .table-booking-success-page .body .wrapper > .status {
    box-sizing: border-box;
    max-width: 630px;
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin: 0 auto 24px;
    padding: 24px;
    box-shadow: 0 2px 4px #9281811a;
  }

  .table-booking-success-page .body .wrapper > .status .progress-bar {
    width: 100%;
    height: 8px;
    background-color: #f5f5f5;
    margin-bottom: 16px;
    display: flex;
  }

  .table-booking-success-page .body .wrapper > .status .progress-bar .progress.success {
    background-color: #086e37;
  }

  .table-booking-success-page .body .wrapper > .status .progress-bar .progress.danger {
    background-color: #b0204b;
  }

  .table-booking-success-page .body .wrapper > .status .progress-bar .progress.warning {
    background-color: #d67300;
  }

  .table-booking-success-page .body .wrapper > .status .caption {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 16px;
    display: flex;
  }

  .table-booking-success-page .body .wrapper > .status .caption .status {
    text-transform: uppercase;
    color: #757575;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .table-booking-success-page .body .wrapper > .status .caption .step {
    color: #757575;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .table-booking-success-page .body .wrapper > .status .text {
    color: #27283e;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .table-booking-success-page .body .wrapper > .status .sub-text {
    color: #757575;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .table-booking-success-page .body .wrapper .summary {
    color: #27283e;
    box-sizing: border-box;
    max-width: 630px;
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin: 0 auto 48px;
    padding: 24px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    box-shadow: 0 2px 4px #0000001a;
  }

  .table-booking-success-page .body .wrapper .summary .section-name {
    color: #27283e;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .table-booking-success-page .body .wrapper .summary .contact-details, .table-booking-success-page .body .wrapper .summary .special-requests {
    margin-top: 15px;
  }

  .table-booking-success-page .body .wrapper .buttons .view-menu-button {
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    min-width: 335px;
    background: #4cbba1;
    border-radius: 8px;
    margin: 0 auto;
    padding: 14px 0 13px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .table-booking-success-page .body .wrapper .buttons .back-button {
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #757575;
    box-shadow: none;
    min-width: 335px;
    background: none;
    border-radius: 8px;
    margin: 10px auto 0;
    padding: 14px 0 13px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  nav {
    z-index: 999;
    width: 400px;
    scrollbar-width: none;
    scrollbar-color: transparent transparent;
    background-color: #fff;
    flex-direction: column;
    grid-area: nav;
    margin-left: 50px;
    transition: transform .3s;
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    overflow-y: scroll;
    transform: translateX(120%);
    box-shadow: 0 0 3px #687a9b40;
  }

  nav::-webkit-scrollbar {
    width: 1px;
  }

  nav::-webkit-scrollbar-track {
    background: none;
  }

  nav::-webkit-scrollbar-thumb {
    background-color: #0000;
  }

  nav.show {
    transform: none;
  }

  .profile {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 60px;
    display: flex;
  }

  .profile .avatar span {
    font-family: Chivo;
  }

  .profile .name {
    color: #27283e;
    margin-top: 17px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .profile .email, .profile .phone-number {
    color: #757575;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .nav-links {
    gap: 5px;
    padding: 24px 20px 0;
    display: grid;
  }

  .nav-links .link {
    cursor: pointer;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    flex-direction: row;
    align-items: center;
    margin: 5px 0;
    padding: 17px 17px 17px 16px;
    display: flex;
    box-shadow: 0 2px 4px #0000001a;
  }

  .nav-links .link .icon {
    color: #92929d;
    margin-right: 9px;
    font-size: 21px;
  }

  .nav-links .link .label {
    color: #27283e;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: inline-block;
  }

  .powered-by-restoplus {
    text-align: center;
    margin-top: auto;
    padding-bottom: 50px;
  }

  .powered-by-restoplus .text {
    color: #757575;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .overlay.show {
    z-index: 998;
    background: #0006;
    justify-content: center;
    align-items: center;
    display: flex;
    position: fixed;
    inset: 0;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@media (min-width: 768px) {
  .table-booking-edit-booking-details-widget .form-group .section-name {
    color: #27283e;
    margin-bottom: 24px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .table-booking-edit-booking-details-widget .form-buttons {
    margin-top: 14px;
  }

  .table-booking-date-picker-modal > .header {
    z-index: 99;
    background: #fff;
    justify-content: space-between;
    padding: 32px;
    display: flex;
    position: sticky;
    top: 0;
  }

  .table-booking-date-picker-modal > .header .title {
    color: #27283e;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .table-booking-date-picker-modal > .header .title {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (min-width: 768px) {
  .table-booking-date-picker-modal > .header .close-icon {
    width: 15px;
    cursor: pointer;
    width: 16px;
    position: relative;
    right: -4px;
  }

  .table-booking-date-picker-modal .note {
    color: #27283e;
    background-color: #ffebcf;
    margin: 0 32px;
    padding: 10px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .table-booking-date-picker-modal .note .store-hours-link {
    color: #d67300;
    cursor: pointer;
  }

  .table-booking-date-picker-modal > .date-picker-widget {
    margin: 7px 86px 41px;
  }

  .table-booking-time-picker-modal > .header {
    z-index: 99;
    background: #fff;
    justify-content: space-between;
    padding: 32px;
    display: flex;
    position: sticky;
    top: 0;
  }

  .table-booking-time-picker-modal > .header .title {
    color: #27283e;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .table-booking-time-picker-modal > .header .title {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (min-width: 768px) {
  .table-booking-time-picker-modal > .header .close-icon {
    width: 15px;
    cursor: pointer;
    width: 16px;
    position: relative;
    right: -4px;
  }

  .table-booking-time-picker-modal .time-of-day-list {
    padding: 0 32px 32px;
  }

  .table-booking-time-picker-modal .time-of-day-list .time-of-day {
    box-sizing: border-box;
    cursor: pointer;
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    align-items: center;
    margin-bottom: 8px;
    padding: 14.5px 12.5px;
    display: flex;
  }

  .table-booking-time-picker-modal .time-of-day-list .time-of-day input[type="radio"] {
    filter: opacity(.8);
    margin: 0 14px 0 0;
  }

  .table-booking-time-picker-modal .time-of-day-list .time-of-day .value {
    color: #27283e;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .table-booking-time-picker-modal .time-of-day-list .time-of-day.unavailable {
    background-color: #f4f4f6;
    border: 1px solid #f4f4f6;
  }

  .table-booking-time-picker-modal .time-of-day-list .time-of-day.unavailable .value {
    color: #adafbc;
  }

  .table-booking-edit-contact-details-widget .booking-details-banner {
    background: #ffebcf;
    grid-template-columns: 1fr min-content;
    grid-template-areas: "booked-for edit"
                         "pax pax"
                         "seating-area seating-area";
    padding: 23px 24px 22.39px;
    display: grid;
  }

  .table-booking-edit-contact-details-widget .booking-details-banner .booked-for {
    color: #d67300;
    grid-area: booked-for;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .table-booking-edit-contact-details-widget .booking-details-banner .edit {
    color: #d67300;
    grid-area: edit;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .table-booking-edit-contact-details-widget .booking-details-banner .pax {
    color: #27283e;
    grid-area: pax;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .table-booking-edit-contact-details-widget .booking-details-banner .seating-area {
    color: #27283e;
    grid-area: seating-area;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .table-booking-edit-contact-details-widget .form-group .section-name {
    color: #27283e;
    margin-bottom: 24px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .table-booking-edit-contact-details-widget .form-group .notes-character-limit .notes-length {
    float: right;
    color: #7d7e92;
    font-size: 12px;
    position: relative;
    top: -16px;
  }

  .table-booking-edit-contact-details-widget .form-buttons {
    margin-top: 20px;
  }

  .table-booking-summary-widget {
    color: #27283e;
    box-sizing: border-box;
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin: 0 32px 24px;
    padding: 24px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    box-shadow: 0 2px 4px #0000001a;
  }

  .table-booking-summary-widget .section-name {
    color: #27283e;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .table-booking-summary-widget .contact-details, .table-booking-summary-widget .special-requests {
    margin-top: 24px;
  }

  #toasts-container {
    grid-gap: 15px;
    z-index: 1001;
    display: grid;
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
  }

  #toasts-container .wrapper {
    text-align: center;
  }

  .toast {
    box-sizing: border-box;
    border-radius: 8px;
    grid-template-columns: min-content max-content;
    align-items: center;
    padding: 15px 20px;
    display: inline-grid;
    box-shadow: 0 10px 16px #31313b1a;
  }

  .toast .icon {
    margin-top: -4px;
    margin-right: 10px;
  }

  .toast .message {
    text-align: left;
    max-width: 60vw;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .toast button.close {
    box-shadow: none;
    opacity: .5;
    color: #000;
    background-color: #0000;
    border: 0;
    margin-left: 10px;
    padding: 0;
    font-size: 1.4rem;
  }

  .toast.success {
    background-color: var(--message-success-bg);
    color: var(--message-success-text);
    border: 1px solid #8bc1a4;
  }

  .toast.info {
    background-color: var(--message-warning-bg);
    color: var(--message-warning-text);
    border: 1px solid #d67300;
  }

  .toast.error {
    background-color: var(--message-error-bg);
    color: var(--message-error-text);
    border: 1px solid #d9657a;
  }

  .toast.warning {
    background-color: var(--message-warning-bg);
    color: var(--message-warning-text);
  }

  .toast.loading {
    color: #d67300;
    background-color: #ffebcf;
    border: 1px solid #d67300;
  }

  .toast.loading .spinner {
    width: 22px;
    height: 22px;
    color: #d67300;
    margin-right: 8px;
    animation: 1s linear infinite spin;
  }

  .spinner-container {
    text-align: center;
  }

  .spinner-container .spinner {
    width: 30px;
    height: 30px;
    margin: 0 auto;
    animation: 1s linear infinite spin;
  }

  .spinner-container .message {
    text-align: center;
    margin-top: 15px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .collection-time-selector-widget {
    gap: 9px;
    display: grid;
  }

  .collection-time-selector-widget .collection-date-and-time {
    cursor: pointer;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    grid-template-areas: "date-and-time . caret-right-icon";
    grid-auto-columns: max-content 1fr min-content;
    gap: 15px;
    padding: 14px 16px 14px 20.75px;
    display: grid;
  }

  .collection-time-selector-widget .collection-date-and-time .text {
    color: #27283e;
    grid-area: date-and-time;
    align-self: center;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
  }

  .collection-time-selector-widget .collection-date-and-time .caret-right.icon {
    grid-area: caret-right-icon;
    margin: auto 0;
  }

  .collection-time-selector-widget .collection-date-and-time.disabled {
    cursor: unset;
    background: #f5f5f5;
  }

  .collection-time-selector-widget .collection-date-and-time.disabled .text {
    color: #757575;
  }

  .menu-search-widget {
    display: block;
  }

  .menu-search-widget.hidden {
    display: none;
  }

  .menu-search-widget .searchbox {
    box-sizing: border-box;
    width: 194px;
    background: #fff;
    border-radius: 100px;
    flex-direction: row;
    margin-right: 25px;
    padding: 6px 6px 6px 8.13px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
  }

  .menu-search-widget .searchbox.inactive {
    background: #ffffff1a;
  }

  .menu-search-widget .searchbox.inactive input {
    color: #a9a9b8;
  }

  .menu-search-widget .searchbox.active {
    background: #fff;
  }

  .menu-search-widget .searchbox.active input {
    color: #27283e;
  }

  .menu-search-widget .searchbox .search-icon {
    height: 16.41px;
    filter: invert(.6);
    pointer-events: none;
    margin: auto 0 auto 3px;
  }

  .menu-search-widget .searchbox .clear-input-icon {
    cursor: pointer;
    margin-left: auto;
    padding-right: 5px;
  }

  .menu-search-widget .searchbox input {
    height: 20px;
    width: 128.08px;
    background: none;
    border: none;
    margin: auto 0 auto 6px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .menu-search-widget .searchbox input:focus {
    outline: none;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@media (min-width: 768px) {
  .item-list-widget {
    grid-gap: 33px;
    border-right: 1px solid #e0e0e0;
    flex-flow: column;
    grid-area: items;
    margin-top: 38px;
    margin-bottom: 31.5px;
    padding: 31.5px 32px 37vh;
    display: flex;
  }

  .item-list-widget .items-per-category .category-summary {
    flex-flow: column;
    display: flex;
  }

  .item-list-widget .items-per-category .category-summary .link-anchor {
    width: 100%;
    position: relative;
    top: -200px;
  }

  .item-list-widget .items-per-category .category-summary .name {
    word-break: break-word;
    color: #27283e;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }

  .item-list-widget .items-per-category .category-summary .description {
    color: #757575;
    grid-area: description;
    margin-top: 14px;
    margin-right: 75px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .item-list-widget .items-per-category .items-container {
    grid-template-columns: repeat(auto-fill, minmax(335px, 1fr));
    gap: 16px;
    margin: 27.5px 0 20px;
    display: grid;
  }

  .item-list-widget.no-items-found .no-results {
    text-align: center;
    color: #757575;
    margin: auto;
    padding-top: 153px;
    padding-bottom: 265px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}

@media (min-width: 768px) and (max-width: 767px) {
  .item-list-widget.no-items-found .no-results {
    padding-top: 20px;
  }
}

@media (min-width: 768px) {
  .item-list-widget.no-items-found .no-results .text {
    margin-top: 7px;
  }

  .item-list-widget.no-items-found .no-results span {
    font-weight: 700;
    line-height: 22.1px;
  }

  .item-list-widget .search-results-message {
    color: #27283e;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .item-list-widget .search-results-message .collection-type {
    letter-spacing: unset;
    font-weight: 700;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@media (min-width: 768px) {
  .item-card-widget {
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #e2e2e2;
    border-radius: 8px;
    grid-template: "item-tag item-tag image" min-content
                   "name name image" min-content
                   "description description image" 1fr
                   "price dietary image" min-content
                   / min-content 1fr min-content;
    padding: 16px;
    display: grid;
    position: relative;
    overflow: hidden;
    box-shadow: 0 2px 4px #0000000d;
  }

  .item-card-widget .anchor {
    position: absolute;
    top: -120px;
  }

  .item-card-widget .soldout, .item-card-widget .unavailable {
    color: #27283e;
    text-align: center;
    white-space: nowrap;
    width: -moz-fit-content;
    width: -moz-fit-content;
    width: -moz-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 8px;
    margin-left: 8px;
    margin-left: unset;
    background: #e0e0e0;
    border-radius: 4px;
    grid-area: item-tag;
    align-self: center;
    padding: 3px 6px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .item-card-widget .item-label {
    color: #27283e;
    width: -moz-fit-content;
    width: -moz-fit-content;
    width: -moz-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: #ffebcf;
    border-radius: 4px;
    grid-area: item-tag;
    margin-bottom: 8px;
    padding: 3px 6px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .item-card-widget .name {
    -webkit-line-clamp: 2;
    word-wrap: break-word;
    color: #27283e;
    -webkit-box-orient: vertical;
    grid-area: name;
    margin-bottom: 8px;
    padding-right: 12px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    display: -webkit-box;
    overflow: hidden;
  }

  .item-card-widget .description {
    word-wrap: break-word;
    color: #757575;
    height: min-content;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    grid-area: description;
    margin-bottom: 8px;
    padding-right: 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: -webkit-box;
    overflow: hidden;
  }

  .item-card-widget .price {
    height: min-content;
    color: #27283e;
    grid-area: price;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .item-card-widget .item-dietary {
    color: #757575;
    grid-area: dietary;
    justify-self: flex-end;
    padding-right: 12px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .item-card-widget .image {
    width: 120px;
    grid-area: image;
    display: grid;
  }

  .item-card-widget .image img {
    border-radius: 4px;
  }

  .item-card-widget.disabled {
    cursor: auto;
  }

  .category-list-widget {
    width: 100%;
    grid-area: categories;
    gap: 10px;
    margin-top: 38px;
    margin-bottom: 31.5px;
    padding-top: 31.5px;
    padding-bottom: 37vh;
    position: sticky;
    top: 53px;
    overflow: visible;
  }

  .category-list-widget .header-desktop {
    color: #27283e;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    display: block;
  }

  .category-list-widget #categories-container {
    width: 100%;
    gap: unset;
    padding-bottom: unset;
    flex-direction: column;
    margin-top: -1px;
    display: flex;
    overflow: hidden;
  }

  .category-list-widget #categories-container::-webkit-scrollbar {
    display: none;
  }

  .category-list-widget #categories-container .category {
    white-space: nowrap;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    word-wrap: break-word;
    margin-right: 0;
    padding: 0;
    border: unset !important;
    color: #000 !important;
  }

  .category-list-widget #categories-container .category.selected {
    border-bottom: 2px solid #27283e;
    background-color: unset !important;
  }

  .category-list-widget #categories-container .category .name {
    text-align: left;
    width: max-content;
    color: #757575;
    padding: 20px 0 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .category-list-widget #categories-container .category .name.selected {
    color: #27283e;
    border-bottom: 3px solid #27283e;
    padding: 21px 0 6px;
    font-weight: 700;
  }

  .store-closed-info-widget {
    height: 30px;
    text-align: left;
    color: #b0204b;
    background: #ffe7df;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
  }

  .store-closed-info-widget span {
    margin-right: 7px;
    font-weight: 700;
  }

  .item-available-weekly-hours-widget {
    color: #d67300;
    background: #ffebcf;
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@media (min-width: 768px) {
  .otp-for-mobile-number-request-widget {
    height: 100%;
    grid-template-rows: min-content 1fr min-content;
    display: grid;
  }

  .otp-for-mobile-number-request-widget .error {
    display: none;
  }

  .otp-for-mobile-number-request-widget .supporting-text {
    text-align: center;
    color: #757575;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .otp-for-mobile-number-request-widget .supporting-text a {
    color: #4cbba1;
    text-decoration: none;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@media (min-width: 768px) {
  .otp-verifier-widget {
    height: 100%;
    display: grid;
  }

  .otp-verifier-widget .otp-input-wrapper .otp-input input {
    box-sizing: border-box;
    letter-spacing: 31px;
    text-align: center;
    width: 100%;
    border: 1px solid #bdbdbd;
    border-radius: 6px;
    padding: 16px;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
  }

  .otp-verifier-widget .otp-input-wrapper .otp-input input::placeholder {
    color: #757575;
    letter-spacing: normal;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .otp-verifier-widget .error-banner {
    width: 335px;
    box-sizing: border-box;
    grid-gap: 10.95px;
    background: #ffe7df;
    flex-flow: row;
    align-self: start;
    margin: 20px auto 0;
    padding: 16px;
    display: flex;
  }

  .otp-verifier-widget .error-banner .red-warning-icon {
    height: min-content;
    display: block;
  }

  .otp-verifier-widget .error-banner .error-message {
    color: #27283e;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .otp-verifier-widget .resend-otp {
    text-align: center;
    margin: 32px 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .otp-verifier-widget .resend-otp .resend {
    color: #4cbba1;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .otp-verifier-widget .resend-otp .resend.disabled {
    color: #adafbc;
    cursor: default;
  }

  .otp-verifier-widget .recaptcha-branding {
    width: 328px;
    text-align: center;
    color: #757575;
    margin: auto auto 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .otp-verifier-widget .recaptcha-branding a {
    color: #4cbba1;
    text-decoration: none;
  }
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

pre {
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@media (min-width: 768px) {
  .customer-display-name-update-widget {
    height: 100%;
    grid-template-rows: min-content 1fr;
    display: grid;
  }

  .store-close-banner {
    z-index: 100;
    height: 60px;
    text-align: center;
    color: #b0204b;
    background-color: #ffe7df;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    position: sticky;
    top: 0;
  }

  .store-close-banner span {
    font-weight: 700;
  }

  .table-page .body .table-ordering-welcome-screen, .table-page .body .table-ordering-qr-scanner {
    z-index: 100;
    height: 100vh;
    background: #fff;
    position: absolute;
    inset: 0;
  }

  .table-page .body .table-ordering-welcome-screen .has-banner .image, .table-page .body .table-ordering-welcome-screen .has-banner .welcome, .table-page .body .table-ordering-qr-scanner .has-banner .image, .table-page .body .table-ordering-qr-scanner .has-banner .welcome {
    visibility: hidden;
  }

  .table-page .body .table-ordering-welcome-screen .table-ordering-banner, .table-page .body .table-ordering-qr-scanner .table-ordering-banner {
    z-index: -1;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  .table-page .body .table-ordering-welcome-screen .table-seat-info, .table-page .body .table-ordering-welcome-screen .table-ordering-info, .table-page .body .table-ordering-qr-scanner .table-seat-info, .table-page .body .table-ordering-qr-scanner .table-ordering-info {
    background: #fff;
  }

  .table-ordering-welcome-screen {
    text-align: center;
    flex-direction: column;
    justify-content: center;
    display: flex;
  }

  .table-ordering-welcome-screen .table-ordering-details {
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    display: flex;
  }

  .table-ordering-welcome-screen .table-ordering-details .optimized-image img {
    height: 100px;
    width: 100px;
  }

  .table-ordering-welcome-screen .table-ordering-details .welcome {
    color: #27283e;
    margin-top: 16px;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .table-ordering-welcome-screen .table-ordering-details .welcome {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (min-width: 768px) {
  .table-ordering-welcome-screen .table-ordering-details .table-seat-info, .table-ordering-welcome-screen .table-ordering-details .table-ordering-info {
    width: 100%;
    border: 1px solid #e2e2e2;
    border-radius: 8px;
    margin-top: 24px;
    margin-bottom: 46px;
    padding: 30px 0;
  }

  .table-ordering-welcome-screen .table-ordering-details .table-seat-info .table-seat-name, .table-ordering-welcome-screen .table-ordering-details .table-seat-info .table-not-found, .table-ordering-welcome-screen .table-ordering-details .table-seat-info .dinein-not-available, .table-ordering-welcome-screen .table-ordering-details .table-ordering-info .table-seat-name, .table-ordering-welcome-screen .table-ordering-details .table-ordering-info .table-not-found, .table-ordering-welcome-screen .table-ordering-details .table-ordering-info .dinein-not-available {
    margin-top: 5px;
    margin-bottom: 3px;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .table-ordering-welcome-screen .table-ordering-details .table-seat-info .table-seat-name, .table-ordering-welcome-screen .table-ordering-details .table-seat-info .table-not-found, .table-ordering-welcome-screen .table-ordering-details .table-seat-info .dinein-not-available, .table-ordering-welcome-screen .table-ordering-details .table-ordering-info .table-seat-name, .table-ordering-welcome-screen .table-ordering-details .table-ordering-info .table-not-found, .table-ordering-welcome-screen .table-ordering-details .table-ordering-info .dinein-not-available {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (min-width: 768px) {
  .table-ordering-welcome-screen .table-ordering-details .table-seat-info .table-not-found, .table-ordering-welcome-screen .table-ordering-details .table-ordering-info .table-not-found {
    color: #b0204b;
  }

  .table-ordering-welcome-screen .table-ordering-details .table-seat-info .dinein-not-available, .table-ordering-welcome-screen .table-ordering-details .table-ordering-info .dinein-not-available {
    color: #27283e;
  }

  .table-ordering-welcome-screen .table-ordering-details .table-seat-info .table-seat-name, .table-ordering-welcome-screen .table-ordering-details .table-ordering-info .table-seat-name {
    color: #4cbba1;
  }

  .table-ordering-welcome-screen .table-ordering-details .table-seat-info .table-seat-text, .table-ordering-welcome-screen .table-ordering-details .table-ordering-info .table-seat-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .table-ordering-welcome-screen .table-ordering-details .table-seat-info .table-not-found-text, .table-ordering-welcome-screen .table-ordering-details .table-seat-info .dinein-not-available-text, .table-ordering-welcome-screen .table-ordering-details .table-ordering-info .table-not-found-text, .table-ordering-welcome-screen .table-ordering-details .table-ordering-info .dinein-not-available-text {
    padding: 0 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .table-ordering-welcome-screen .actions {
    flex-direction: column;
    justify-content: center;
    margin-bottom: 14px;
    padding: 0 20px;
    display: flex;
  }

  .table-ordering-welcome-screen .actions .start-order-btn, .table-ordering-welcome-screen .actions .view-menu-btn {
    border-radius: 8px;
    margin-bottom: 17px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .table-ordering-welcome-screen .actions .view-orders-btn, .table-ordering-welcome-screen .actions .re-scan-qr-btn {
    color: #4cbba1;
    background-color: #fff;
    border: 2px solid #4cbba1;
    border-radius: 8px;
    padding: 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .table-ordering-welcome-screen .actions .scan-qr, .table-ordering-welcome-screen .actions .go-to-menu {
    cursor: pointer;
    color: #4cbba1;
    margin-top: 33px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .table-ordering-qr-scanner {
    flex-direction: column;
    justify-content: center;
    margin-bottom: 14px;
    padding: 0 20px;
    display: flex;
  }

  .table-ordering-qr-scanner .cancel-btn {
    border-radius: 8px;
    margin-bottom: 17px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .table-ordering-qr-scanner .barcode-scanner-component {
    align-self: center;
    padding-bottom: 100px;
  }

  .table-ordering-qr-scanner .barcode-scanner-component video {
    object-fit: cover;
    background-color: #0000;
    background-image: linear-gradient(to right, #838393 4px, #0000 4px), linear-gradient(to right, #838393 4px, #0000 4px), linear-gradient(to left, #838393 4px, #0000 4px), linear-gradient(to left, #838393 4px, #0000 4px), linear-gradient(#838393 4px, #0000 4px), linear-gradient(#838393 4px, #0000 4px), linear-gradient(to top, #838393 4px, #0000 4px), linear-gradient(to top, #838393 4px, #0000 4px);
    background-position: 0 0, 0 100%, 100% 0, 100% 100%, 0 0, 100% 0, 0 100%, 100% 100%;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-attachment: scroll, scroll, scroll, scroll, scroll, scroll, scroll, scroll;
    background-origin: padding-box, padding-box, padding-box, padding-box, padding-box, padding-box, padding-box, padding-box;
    background-clip: border-box, border-box, border-box, border-box, border-box, border-box, border-box, border-box;
    padding: 10px;
  }

  .platform-maintenance-banner {
    z-index: 100;
    height: 60px;
    text-align: center;
    color: #b0204b;
    background-color: #ffe7df;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    position: sticky;
    top: 0;
  }

  .platform-maintenance-banner + .top-nav-bar-widget {
    top: 60px;
  }

  .upsell-items-widget {
    padding: 24px;
  }

  .upsell-items-widget .header {
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    display: flex;
  }

  .upsell-items-widget .header .title {
    color: #27283e;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .upsell-items-widget .header .title {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (min-width: 768px) {
  .upsell-items-widget .header .close-icon {
    width: 15px;
    cursor: pointer;
  }

  .upsell-items-widget .upsell-items {
    max-height: calc(100vh - 420px);
    gap: 18px;
    margin: 20px 0;
    display: grid;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .upsell-items-widget .upsell-items::-webkit-scrollbar {
    width: 5px;
  }

  .upsell-items-widget .upsell-items::-webkit-scrollbar-track {
    background: none;
  }

  .upsell-items-widget .upsell-items::-webkit-scrollbar-thumb {
    background-color: #27283e50;
    border-radius: 20px;
  }

  .upsell-items-widget .actions button {
    width: 100%;
  }

  .uber-delivery-info-view-widget {
    box-sizing: border-box;
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin: 0 20px 24px;
    padding: 30px 31px;
    box-shadow: 0 2px 4px #0000001a;
  }

  .uber-delivery-info-view-widget .tracking-map {
    overflow: hidden;
  }

  .uber-delivery-info-view-widget .tracking-map .map-container {
    width: 100%;
    height: 310px;
    margin-bottom: 20px;
    overflow: hidden;
  }

  .uber-delivery-info-view-widget .tracking-map .map-container > iframe {
    width: 170%;
    height: 550px;
    transform-origin: 0 0;
    position: relative;
    top: -80px;
    left: -12px;
    overflow: hidden;
    transform: scale(.8);
  }

  .uber-delivery-info-view-widget .courier-info {
    grid-template-columns: 80px 200px;
    grid-template-areas: "courier-image courier-additional-info";
    align-items: center;
    gap: 15px;
    display: grid;
  }

  .uber-delivery-info-view-widget .courier-info .courier-image {
    grid-area: courier-image;
  }

  .uber-delivery-info-view-widget .courier-info .courier-image img {
    width: 100%;
    border-radius: 50%;
  }

  .uber-delivery-info-view-widget .courier-info .courier-additional-info {
    grid-area: courier-additional-info;
  }

  .uber-delivery-info-view-widget .courier-info .courier-additional-info .courier-name {
    font-weight: 700;
  }

  .uber-delivery-info-view-widget .courier-info .courier-additional-info .courier-vehicle {
    color: #7d7e92;
  }

  .uber-delivery-info-view-widget .courier-info .courier-additional-info .courier-phone-number {
    color: #7d7e92;
    font-weight: 700;
  }

  .uber-delivery-info-view-widget .courier-info .courier-additional-info .call-courier {
    color: #4cbba1;
    font-weight: 400;
    text-decoration: none;
  }

  .customer-review-page {
    min-height: 100vh;
    background-color: #fff;
    padding: 0;
    font-family: Poppins, sans-serif;
    position: relative;
    overflow: hidden;
  }

  .customer-review-page > .header {
    padding: 0;
  }

  .customer-review-page > .body {
    max-width: unset;
  }

  .customer-review-page > .body > .spinner-container {
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .customer-review-page > .body .store-header {
    z-index: 100;
    background-color: #92400e;
    justify-content: center;
    align-items: center;
    padding: 16px 0;
    display: flex;
    position: sticky;
    top: 0;
  }

  .customer-review-page > .body .store-header .restaurant-logo {
    padding-right: 16px;
  }

  .customer-review-page > .body .store-header .restaurant-logo img {
    width: 48px;
    height: 48px;
    border-radius: 8px;
  }

  .customer-review-page > .body .store-header .name {
    color: #fff;
    font-size: 16px;
  }

  .customer-review-page > .body .main-display {
    height: calc(100vh - 80px);
  }

  .customer-review-page > .body .main-display.sad-rating-feedback-form {
    height: 100vh;
  }

  .customer-review-page > .body .main-display .rating-selector {
    z-index: 2;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 282px;
    transition: opacity .5s ease-in, transform .5s ease-in;
    display: flex;
    position: relative;
  }

  .customer-review-page > .body .main-display .rating-selector.hidden {
    opacity: 0;
    pointer-events: none;
    transform: translateY(-100px);
  }

  .customer-review-page > .body .main-display .rating-selector.show {
    opacity: 1;
    transform: translateY(0);
  }

  .customer-review-page > .body .main-display .rating-selector .message {
    font-size: 34px;
    font-weight: 600;
  }

  .customer-review-page > .body .main-display .rating-selector .selectors {
    margin-top: 40px;
  }

  .customer-review-page > .body .main-display .rating-selector .selectors .happy-rating, .customer-review-page > .body .main-display .rating-selector .selectors .sad-rating {
    width: 112px;
    height: 112px;
    cursor: pointer;
  }

  .customer-review-page > .body .main-display .rating-selector .selectors .sad-rating {
    margin-right: 24px;
  }

  .customer-review-page > .body .main-display .happy-rating-feedback-message {
    z-index: 2;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -459px;
    padding-top: 131px;
    transition: opacity .5s ease-in;
    display: flex;
    position: relative;
  }

  .customer-review-page > .body .main-display .happy-rating-feedback-message.hidden {
    opacity: 0;
    height: 0;
    pointer-events: none;
    overflow: hidden;
  }

  .customer-review-page > .body .main-display .happy-rating-feedback-message.hidden .message {
    transform: scale(1.3);
  }

  .customer-review-page > .body .main-display .happy-rating-feedback-message.hidden .description {
    transform: translateY(-10px);
  }

  .customer-review-page > .body .main-display .happy-rating-feedback-message.hidden .happy-rating-animation, .customer-review-page > .body .main-display .happy-rating-feedback-message.hidden .google-review-button {
    transform: translateY(50px);
  }

  .customer-review-page > .body .main-display .happy-rating-feedback-message.show {
    opacity: 1;
  }

  .customer-review-page > .body .main-display .happy-rating-feedback-message.show .message {
    transform: scale(1);
  }

  .customer-review-page > .body .main-display .happy-rating-feedback-message.show .happy-rating-animation, .customer-review-page > .body .main-display .happy-rating-feedback-message.show .description, .customer-review-page > .body .main-display .happy-rating-feedback-message.show .google-review-button {
    transform: translateY(0);
  }

  .customer-review-page > .body .main-display .happy-rating-feedback-message .happy-rating-animation {
    transition: transform .5s ease-in;
  }

  .customer-review-page > .body .main-display .happy-rating-feedback-message .message {
    padding: 32px 0 22px;
    font-size: 24px;
    font-weight: 600;
    transition: transform .5s ease-in;
  }

  .customer-review-page > .body .main-display .happy-rating-feedback-message .description {
    width: 260px;
    text-align: center;
    font-size: 14px;
    transition: transform .5s ease-in;
  }

  .customer-review-page > .body .main-display .happy-rating-feedback-message .google-review-button {
    background-color: #2563eb;
    border-radius: 4px;
    margin-top: 32px;
    padding: 14px 32px;
    text-decoration: none;
    transition: transform .5s ease-in;
    display: flex;
  }

  .customer-review-page > .body .main-display .happy-rating-feedback-message .google-review-button span {
    color: #fff;
    padding-right: 6px;
    font-size: 16px;
  }

  .customer-review-page > .body .main-display .happy-rating-feedback-message .google-review-button img {
    width: 60.76px;
    height: 20px;
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-form {
    z-index: 2;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -132px;
    padding: 28px 0 60px;
    transition: opacity .5s ease-in, transform .5s ease-in;
    display: flex;
    position: relative;
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-form.hidden {
    opacity: 0;
    pointer-events: none;
    transform: translateY(100px);
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-form.show {
    opacity: 1;
    transform: translateY(0);
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-form .title {
    color: #1c1d3e;
    padding: 32px 0 20px;
    font-size: 24px;
    font-weight: 600;
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-form .sub-title {
    color: #1c1d3e;
    padding-bottom: 32px;
    font-size: 14px;
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-form form > .form-items {
    margin-left: calc(50% - 163px);
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-form form > .form-items .form-item textarea, .customer-review-page > .body .main-display .sad-rating-feedback-form form > .form-items .form-item input {
    width: 326px;
    margin-top: 6px;
    font-family: Poppins, sans-serif;
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-form form > .form-items .form-item textarea {
    height: 128px;
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-form form > .form-items .form-item label {
    color: #7d7e92;
    font-weight: 500;
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-form form > .form-items .form-item .meta {
    align-items: center;
    display: flex;
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-form form > .form-items .form-item .meta:before {
    content: url("exclamation-circle-icon-filled.d818762b.svg");
    width: 12.8px;
    height: 12.8px;
    padding-bottom: 2px;
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-form form > .form-items .form-item .meta .error {
    color: #f43f5e;
    padding-left: 5px;
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-form form > .form-items .checkbox {
    color: #1c1d3e;
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-form form > .form-items .checkbox input {
    -webkit-appearance: none;
    appearance: none;
    width: 17px;
    height: 17px;
    cursor: pointer;
    background-color: #f5f5f5;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    margin-bottom: 15px;
    position: relative;
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-form form > .form-items .checkbox input:checked {
    background-color: #4cbba1;
    border: 1px solid #4cbba1;
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-form form > .form-items .checkbox input:checked:after {
    content: "";
    width: 3px;
    height: 7px;
    transform-origin: center;
    border: 2px solid #fff;
    border-width: 0 2px 2px 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)rotate(45deg);
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-form form > .form-items .checkbox label {
    white-space: unset;
    width: 260px;
    padding-left: 10px;
    font-size: 14px;
    line-height: 21px;
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-form .actions {
    margin-top: 32px;
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-form .actions .submit-button {
    color: #fff;
    text-transform: unset;
    letter-spacing: 1px;
    background-color: #2563eb;
    border-radius: 4px;
    padding: 14px 80px;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: 500;
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-form .actions .google-review-link {
    color: #7d7e92;
    justify-content: center;
    margin-top: 27px;
    font-size: 16px;
    font-weight: 500;
    display: flex;
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-message {
    text-align: center;
    z-index: 2;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -885px;
    padding-top: 135px;
    transition: opacity .5s ease-in, transform .5s ease-in;
    display: flex;
    position: relative;
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-message.hidden {
    opacity: 0;
    pointer-events: none;
    transform: translateY(-100px);
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-message.show {
    opacity: 1;
    transform: translateY(0);
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-message .message {
    padding: 49px 0 22px;
    font-size: 24px;
    font-weight: 600;
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-message .description {
    font-size: 14px;
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-message .google-review-link {
    color: #7d7e92;
    margin-top: 47px;
    font-size: 16px;
    font-weight: 500;
  }

  .customer-review-page > .body .happy-rating-message-background, .customer-review-page > .body .sad-rating-feedback-form-background, .customer-review-page > .body .sad-rating-feedback-message-background {
    width: 100vmax;
    height: 100vmax;
    transform-origin: center;
    position: absolute;
    top: 50%;
    left: 50%;
  }

  .customer-review-page > .body .happy-rating-message-background {
    background: url("happy-rating-message-background.53b56f48.svg") center / contain no-repeat;
    transition: transform .7s ease-in-out;
    transform: translate(-50%, -50%)scale(0)rotate(-50deg);
  }

  .customer-review-page > .body .happy-rating-message-background.show {
    transform: translate(-50%, -50%)scale(2)rotate(0);
  }

  .customer-review-page > .body .sad-rating-feedback-form-background {
    background: url("sad-rating-form-background.f1e88dc7.svg") center / contain no-repeat;
    transition: transform .5s ease-in-out;
    transform: translate(-50%, -50%)scale(0);
  }

  .customer-review-page > .body .sad-rating-feedback-form-background.show {
    transform: translate(-50%, -50%)scale(3);
  }

  .customer-review-page > .body .sad-rating-feedback-message-background {
    background: url("sad-rating-message-background.9c985d36.svg") center / contain no-repeat;
    transition: transform .5s ease-in-out;
    transform: translate(-50%, -50%)scale(0);
  }

  .customer-review-page > .body .sad-rating-feedback-message-background.show {
    transform: translate(-50%, -50%)scale(3);
  }
}

@media only screen and (max-height: 700px) {
  .customer-review-page > .body .main-display.sad-rating-feedback-form {
    height: 130vh;
  }

  .customer-review-page > .body .main-display .rating-selector {
    padding-top: 230px;
  }

  .customer-review-page > .body .main-display .sad-rating-feedback-form {
    margin-top: -107px;
  }
}

@font-face {
  font-family: Chivo;
  src: url("Chivo-BlackItalic.3b59a8f6.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: Chivo;
  src: url("Chivo-Black.d66ea686.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: Chivo;
  src: url("Chivo-BoldItalic.d69b55f2.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: Chivo;
  src: url("Chivo-Bold.dd0e10b0.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Chivo;
  src: url("Chivo-LightItalic.1a898f76.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Chivo;
  src: url("Chivo-Light.49c7d267.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Chivo;
  src: url("Chivo-Regular.4eee25cc.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Ocra;
  src: url("OCRA Medium.13761dac.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Ocra;
  src: url("OCRA Regular.7e268122.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: SF Pro Display;
  src: url("SFPRODISPLAYREGULAR.c475ffc5.OTF") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: SF Pro Display;
  src: url("SFPRODISPLAYMEDIUM.00d4b6d2.OTF") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: SF Pro Display;
  src: url("SFPRODISPLAYBOLD.ba4fa8a4.OTF") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: IBM Plex;
  src: url("IBMPlexMono-Thin.5422f77d.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: IBM Plex;
  src: url("IBMPlexMono-ThinItalic.697586ce.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: IBM Plex;
  src: url("IBMPlexMono-ExtraLight.4f30d83e.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: IBM Plex;
  src: url("IBMPlexMono-ExtraLightItalic.af3e8147.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: IBM Plex;
  src: url("IBMPlexMono-Light.3ca1eae8.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: IBM Plex;
  src: url("IBMPlexMono-LightItalic.2bad4264.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: IBM Plex;
  src: url("IBMPlexMono-Medium.6b5e0097.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: IBM Plex;
  src: url("IBMPlexMono-MediumItalic.bcd8a940.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: IBM Plex;
  src: url("IBMPlexMono-SemiBold.9ebb59fb.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: IBM Plex;
  src: url("IBMPlexMono-SemiBoldItalic.15002352.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: IBM Plex;
  src: url("IBMPlexMono-Italic.e1dd1e35.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: IBM Plex;
  src: url("IBMPlexMono-Bold.93baf956.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: IBM Plex;
  src: url("IBMPlexMono-BoldItalic.94d9bd8b.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: kredit;
  src: url("kredit.fcba7869.ttf") format("truetype");
}

@font-face {
  font-family: larabie;
  src: url("larabie.08226e3a.ttf") format("truetype");
}

* {
  touch-action: manipulation;
}

html, body {
  scroll-behavior: smooth;
  margin: 0;
}

body {
  overscroll-behavior-y: none;
  background-color: #fff;
  font-family: Chivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

a {
  color: var(--link-fg);
  cursor: pointer;
}

b {
  font-weight: 500;
}

textarea {
  resize: none;
}

.component {
  background-color: var(--color-bg);
}

button {
  cursor: pointer;
  box-shadow: 0 0 3px #687a9b40;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.firebase-emulator-warning {
  display: none;
}

.highlight:before {
  content: "";
  height: 16px;
  z-index: -1;
  background: #d1ebff;
  position: absolute;
  bottom: 2px;
  left: 0;
  right: -2px;
}

/*# sourceMappingURL=index.96e0209c.css.map */
