@use "../card"as card;

.document-edit-widget {
  @include card.container;

  // padding-bottom: 10px;
  // max-width: 700px;

  .title {
    @include card.header();
    @include card.header-font();
  }

  form {
    margin-top: 20px;
    @include card.content;

    .form-sub-header {
      margin: 20px 0 0;
      padding-bottom: 5px;
      border-bottom: 1px solid #f1f1f5;
      @include card.header-font();
    }
  }
}
