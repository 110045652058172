// --form-element-bg: #{default.$form-element-bg};
// --form-element-fg: #{default.$form-element-fg};
// --form-element-border: #{default.$form-element-border};
// --form-element-error: #{default.$form-element-error};
@use "constants"as constants;
@use "viewport"as viewport;
@use "switch"as switch;
@use "typography" as typography;
@use "./themes/default" as default-theme;

$font-size: 14px;
$padding: 10px 14px;

@mixin form-input {
  font-family: Chivo;
  @include typography.body-regular;
  color: #{default-theme.$restoplus-dark};

  padding: 14.5px 16px;
  background: white;
  border: 1px solid #{default-theme.$material-gray-300};
  box-sizing: border-box;
  border-radius: 8px;

  margin: 0;
  transition: all 0.3s;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
}

@mixin help-text {
  font-size: 12px;
  color: #6c6c6c;
}

form {
  //
  // padding-top: 20px;

  input[type='text'],
  input[type='number'],
  input[type='password'],
  input[type='tel'],
  input[type='email'],
  textarea {
    @include form-input();
  }

  input[type='file']::-webkit-file-upload-button {
    border: 1px solid var(--form-element-border);
    background: var(--form-element-bg);
    border-radius: 5px;
  }

  input[type='file'] {
    font-family: Poppins, sans-serif;
  }

  .preview {
    margin-bottom: 15px;

    img {
      border-radius: 20px;
      max-width: 100px;
      padding: 0;
      margin: 0;
      border: 3px solid #f1f1f5;
    }
  }

  .form-input {
    &.has-icon {
      @include form-input();
      padding: 0;
      display: flex;
      .icon {
        width: 30px;
        border-top-left-radius: constants.$border-radius;
        border-bottom-left-radius: constants.$border-radius;
        display: flex;
        padding-left: 12px;
        // background-color: white;
      }
      img {
        width: 100%;
        align-self: center;
      }
      input {
        margin: 0;
        border: 0;
      }
    }

    .prepend-text {
      @include typography.body-regular;
      color: #{default-theme.$restoplus-dark};
      background: #{default-theme.$material-gray-100};
      border: 1px solid #{default-theme.$material-gray-300};
      border-right: none;
      box-sizing: border-box;
      border-radius: 6px 0px 0px 6px;
      padding: 14.5px 16px;
    }

    .has-prepend {
      display: flex;

      input {
        border-radius: 0px 6px 6px 0px;
      }
    }
  }

  .form-text-area {
    label {
      @include typography.h4;
      color: #{default-theme.$restoplus-dark};
      margin-left: 0px;
      margin-bottom: 4px;
    }

    .sub-text {
      @include typography.caption-regular;
      letter-spacing: 0.4px;
      color: #{default-theme.$material-gray-600};
      margin-bottom: 19px;
    }

    textarea {
      @include typography.body-regular;
    }
  }

  .form-select-icon input {
    background-image: url('../images/form-select-icon.svg');
    background-repeat: no-repeat;
    background-position: right 13px bottom 20px;
    background-position-y: center;
  }

  .form-items {
    display: grid;
    row-gap: 16px;
  }

  .form-buttons {
    margin-top: 20px;
  }

  .select {
    @include form-input();
    padding: $padding;
  }

  .meta {
    margin-top: 5px;
    margin-left: 5px;
  }

  .error {
    font-size: 12px;
    color: var(--form-element-error);
  }

  .help {
    @include help-text();
  }

  select {
    font-size: $font-size;
    font-family: Poppins, sans-serif;
    border: none;
    box-sizing: none;
    margin: 0;
    padding: none;
    width: 100%;
    background: transparent;
  }

  .checkbox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  /* Style placeholder */
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #{default-theme.$material-gray-500};
    opacity: 1; /* Firefox */
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #{default-theme.$material-gray-500};
  }
  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #{default-theme.$material-gray-500};
  }

  /* Remove Arrows/Spinner */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* Chrome, Safari, Edge, Opera */
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    /* Firefox */
    -moz-appearance: textfield;
  }
}

@include viewport.tablet {
  form.responsive {
    display: grid;
    grid-template-columns: 180px 4fr 1fr;

    .form-items > .form-item > label {
      align-self: center;
      text-align: right;
      margin-right: 15px;
    }

    .form-items {
      row-gap: 25px;
      grid-row: 1;
      grid-column: 1 / -1;
    }

    .form-items > .form-item {
      display: grid;
      grid-template-columns: 180px 4fr 1fr;

      label {
        grid-row: 1;
        grid-column: 1 / 2;
      }

      .form-input {
        grid-row: 1;
        grid-column: 2 / 3;
      }

      .meta {
        grid-row: 2;
        grid-column: 2 / 3;
      }
    }
    .form-input {
      margin-top: 0px;
    }
    .form-buttons {
      padding-left: 4px;
      margin-top: 30px;
      grid-row: 2;
      grid-column: 2 / 3;
    }
  }

  form.horizontal {
    .form-items {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
      grid-column-gap: 20px;
    }
  }
}

@mixin inline {
  padding: 20px 15px;
  display: grid;
  grid-gap: 18px;

  // make switch display in same line
  .switch {
    @include switch.inline();
    label {
      text-align: left !important;
    }
  }
}

// invalid states

textarea,
input,
.input-box {
  &.invalid {
    border-color: var(--form-element-error) !important;
  }
}

input:disabled {
  background: #f9f9f9 !important;
}

.form-input.disabled {
  background: #f9f9f9 !important;
}

@mixin top-align-label {
  margin-top: 12px;
  align-self: flex-start !important;
}

.form-item {
  position: relative;

  .required-sign {
    color: #ff8080;
  }

  label {
    font-size: 14px;
    margin-left: 4px;
  }

  .input-icon {
    position: absolute;
    right: 8px;
    top: 28px;
    cursor: pointer;
    pointer-events: none;
  }

  .input-icon > img {
    width: 27px;
  }
}

.form-item.inline-title {
  background-color: #f9f9f9;
  padding: 8px 18px !important;
  font-weight: 400;
}

input {
  font-family: Chivo
}