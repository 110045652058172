@use '../viewport' as viewport;
@use '../themes/default' as default-theme;
@use '../typography' as typography;
@import './common-styles-desktop';

@include viewport.tablet {
  .otp-for-mobile-number-request-widget {
    height: 100%;
    display: grid;
    grid-template-rows: min-content 1fr min-content;

    .error {
      display: none;
    }

    .supporting-text {
      @include typography.caption-regular;
      text-align: center;
      color: #{default-theme.$material-gray-600};
      a {
        text-decoration: none;
        color: #{default-theme.$restoplus-teal};
      }
    }
  }
}
