@use "../typography"as typography;
@use "../constants"as constants;
@use "../viewport"as viewport;
@use "../utils"as utils;
@use "../buttons"as buttons;
@use "../themes/default" as default-theme;

@include viewport.mobile {
  .page {
    > .header {
      // margin-top: 56px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background-color: white;

      padding: 15px 20px;
      position: relative;
      box-shadow: inset 0px -1px 0px #e8ecf1;

      .wrapper {
        .title {
          @include typography.h1;
          text-align: left;
          color: #{default-theme.$restoplus-dark};
        }

        .search {
          .menu-search-widget {
            .text {
              display: none;
            }
          }
          .icon {
            filter: invert(1);
            width: 21px;
          }
        }
      }

      .actions {
        @include buttons.compact;

        a:link {
          text-decoration: none;
        }
      }
    }
    > .body {
      padding-top: 0px;
      margin-top: var(--safe-area-inset-top);

      > div {
        padding-bottom: max(85px, var(--safe-area-inset-bottom));
      }

      > .spinner {
        padding: 0;
        margin: 50px;
        text-align: center;
      }
    }

    > .footer {
      display: none;
    }
  }
}
