@use "../viewport"as viewport;
@use "../themes/default" as default-theme;
@use "../constants" as constants;
@use '../buttons' as buttons;
@use '../card'as card;
@import './common-styles-mobile';

@include viewport.mobile {
  .deal-pizza-list-widget {
    @include pizza-list-widget();
  }
}
