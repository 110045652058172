@use "viewport"as viewport;
@use "typography" as typography;

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/**
 * ----------------------------------------
 * animation fade-in-left
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.steps {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  align-items: center;
  height: fit-content;
  padding: 20px 0 10px;

  .container {
    display: grid;
    justify-content: center;
    position: relative;

    .step {
      display: grid;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      height: 40px;
      width: 40px;
      @include typography.h4;
      border-radius: 50%;
      background-color: #cacaca;
      color: var(--button-success-fg);
      z-index: 1;
      @include viewport.tablet {
        height: 45px;
        width: 45px;
      }
    }

    .step.passed {
      background-color: var(--message-info-fg);
    }

    .step.active {
      height: 50px;
      width: 50px;
      background-color: var(--button-success-bg);
      -webkit-animation: scale-up-center 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
      animation: scale-up-center 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
      @include viewport.tablet {
        height: 55px;
        width: 55px;
      }
    }

    .title {
      text-align: center;
      text-transform: capitalize;
      font-size: 10px;
      margin-top: 5px;
      @include viewport.tablet {
        font-size: 12px;
      }
    }

    .line {
      height: 3px;
      width: 60%;
      background: #bfbfbf;
      position: absolute;
      left: 74%;
      top: 33%;
    }

    .line.active {
      top: 35%;
    }

    .line.passed {
      background-color: var(--message-info-fg);
      -webkit-animation: fade-in-left 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
      animation: fade-in-left 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    }
  }

  .container:last-child {
    .line {
      display: none;
    }
  }
}
