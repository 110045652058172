@use '../card'as card;
@use '../constants'as constants;
@use '../typography'as typography;
@use '../viewport'as viewport;
@use '../buttons'as buttons;

.backoffice {
  .order-history-page {
    @include viewport.tablet {
      form > .form-items > .form-item {
        max-width: 300px;
      }
    }

    .date-range {
      margin-top: 12px;
      font-style: italic;
      font-size: 13px;
      padding-left: constants.$curve-padding;
    }

    .summary {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      grid-row-gap: 20px;
      grid-column-gap: 10px;
      margin: 20px 0px;

      .statistic {
        @include card.container;
        padding: 10px 20px 15px;

        .title {
          font-family: Poppins;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 24px;
          letter-spacing: 0.1px;
          color: #555555;
        }

        .value {
          display: grid;
          grid-template-columns: max-content max-content;
          justify-content: start;
          align-items: center;
          margin-top: 10px;
        }

        .prefix {
          font-family: Poppins;
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          color: #171725;
        }

        .suffix {
          font-family: Poppins;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 24px;
          letter-spacing: 0.1px;
          color: #777;
          margin-left: 8px;
        }

        &.cash .suffix,
        &.credit-card .suffix {
          color: #3dd598;
        }
        &.pickup .suffix,
        &.delivery .suffix {
          color: #ff974a;
        }
        &.dine-in .suffix {
          color: #a461d8;
        }
      }
    }

    .list {
      display: grid;
      grid-gap: 25px;
    }

    .order-list-view {
      @include card.container;
      display: grid;
      grid-template-areas:
        "status-indicator collection-type status"
        ".                time            grand-total"
        ".                message         message"
        ".                actions         actions";

      grid-template-columns: min-content 1fr min-content;
      padding: 18px 0;

      .status-indicator {
        grid-area: status-indicator;
        margin: 0 15px;
        align-self: center;
      }

      .collection-type {
        grid-area: collection-type;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #000521;
        align-self: center;
      }
      .grand-total {
        grid-area: grand-total;
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #fa7268;
        align-self: center;
        text-align: right;
      }
      .time {
        grid-area: time;
        white-space: nowrap;
        align-self: center;
        font-family: Poppins;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #696974;
      }
      .message {
        grid-area: message;
      }
      .status {
        grid-area: status;
        white-space: nowrap;
        justify-self: end;
        align-self: center;
      }
      .actions {
        grid-area: actions;
      }

      .status {
        margin-right: 20px;
      }

      .grand-total,
      .message {
        padding-right: 20px;
      }

      // 1st dotted line
      .time,
      .grand-total,
      .message {
        padding-bottom: 15px;
        border-bottom: constants.$line-dashed;
        margin-bottom: 15px;
      }

      // row-2
      .time,
      .grand-total {
        margin-top: 8px;
      }

      .actions {
        display: flex;
        flex-direction: row;
        button {
          @include buttons.primary-transparent;
          @include buttons.badge-button;
          margin-right: 10px;
        }
        .accept-order {
          @include buttons.success-transparent;
        }
        .reject-order {
          @include buttons.failure-transparent;
        }
      }
    }
  }
}
