@use "../card"as card;
@use "../constants"as constants;
@use "../scrollbar"as scrollbar;
@use "../typography"as typography;
@use "../viewport"as viewport;

.online-ordering {
  main {
    margin-top: var(--safe-area-inset-top);
  }

  footer {
    display: none;
  }

  @include viewport.mobile {
    footer {
      display: flex;
      position: fixed;
      bottom: 0;
      width: 100%;
    }
  }

  @include viewport.desktop {
    main {
      margin: 0 auto;
    }
  }
}
