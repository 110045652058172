@use '../viewport' as viewport;
@use '../card' as card;
@use '../themes/default' as default-theme;
@use '../typography' as typography;
@import './common-styles-desktop';

@include viewport.tablet {
  .item-card-widget {
    overflow: hidden;
    position: relative;
    width: 100%;
    border: 1px solid #e2e2e2;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 5%);
    box-sizing: border-box;
    border-radius: 8px;
    background-color: #{default-theme.$bg};
    cursor: pointer;
    background-color: #{default-theme.$bg};
    display: grid;
    padding: 16px;
    grid-template-areas:
      'item-tag item-tag image'
      'name name image'
      'description description image'
      'price  dietary image';
    grid-template-columns: min-content 1fr min-content;
    grid-template-rows: min-content min-content 1fr min-content;

    .anchor {
      position: absolute;
      top: -120px;
    }

    .soldout,
    .unavailable {
      @include info-tag;
      @include typography.caption-regular;
      text-align: center;
      grid-area: item-tag;
      white-space: nowrap;
      align-self: center;
      width: fit-content;
      margin-bottom: 8px;
      margin-left: unset;
    }

    .item-label {
      grid-area: item-tag;
      @include typography.caption-regular;
      color: #{default-theme.$restoplus-dark};
      background-color: #ffebcf;
      padding: 3px 6px;
      border-radius: 4px;
      width: fit-content;
      margin-bottom: 8px;
    }

    .name {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      @include typography.h4;
      word-wrap: break-word;
      color: #{default-theme.$restoplus-dark};
      grid-area: name;
      padding-right: 12px;
      margin-bottom: 8px;
    }

    .description {
      @include typography.body-regular;
      word-wrap: break-word;
      color: #{default-theme.$material-gray-600};
      grid-area: description;
      padding-right: 12px;
      overflow: hidden;
      height: min-content;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      margin-bottom: 8px;
    }

    .price {
      height: min-content;
      @include typography.body-regular;
      color: #{default-theme.$restoplus-dark};
      grid-area: price;
    }

    .item-dietary {
      @include typography.caption-regular;
      color: #757575;
      justify-self: flex-end;
      grid-area: dietary;
      padding-right: 12px;
    }

    .image {
      grid-area: image;
      display: grid;
      width: 120px;

      img {
        border-radius: 4px;
      }
    }

    &.disabled {
      cursor: auto;
    }
  }
}
