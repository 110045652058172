@use "../card"as card;
@use "../constants"as constants;
@use "../typography"as typography;
@use "../themes/default"as default-theme;
@use "../viewport" as viewport;

@include viewport.mobile {
  .restaurant-header-widget,
  .store-header-widget {
    display: flex;
    flex-direction: horizontal;
    justify-content: space-between;
    align-items: center;

    width: 80%;

    .image {
      width: constants.$header-height - 20;
      border-radius: constants.$border-radius;
      border: 3px solid #f1f1f5;
      margin-right: 10px;
      overflow: hidden;
    }

    .name {
      @include typography.h4;
      text-align: left;
      color: #{default-theme.$restoplus-dark};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
