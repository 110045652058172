@use "../viewport" as viewport;
@use "../constants" as constants;
@use "../themes/default" as default-theme;
@use "../typography" as typography;

@include viewport.mobile {
  .order-view-widget {
    .order-summary {
      display: grid;
      row-gap: 16px;
      padding: 24px;
      margin-bottom: 24px;

      background: white;
      border: 1px solid #{default-theme.$material-gray-300};
      box-sizing: border-box;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      border-radius: 8px;

      .title {
        display: flex;
        justify-content: space-between;
        .summary {
          margin-bottom: 16px;
          @include typography.h4;
          color: #{default-theme.$restoplus-dark};
        }
        .order-id {
          color: #9191a1;
          font-size: 13px;
        }
      }

      .label {
        @include typography.body-bold;
        color: #{default-theme.$restoplus-dark};
      }

      .value {
        @include typography.body-regular;
        color: #{default-theme.$restoplus-dark};
        margin-bottom: 4px;
      }

      .payment-method {
        .value {
          display: flex;
        }

        .wallet,
        .brand {
          height: 25px;
          margin-right: 5px;
        }

        .status {
          @include typography.caption-regular;
          color: #{default-theme.$material-gray-600};
        }
      }

      .transaction-fee {
        .status {
          @include typography.caption-regular;
          color: #{default-theme.$material-gray-600};
        }
      }
    }

    .cart-summary {
      padding: 24px;

      background: white;
      border: 1px solid #{default-theme.$material-gray-300};
      box-sizing: border-box;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      border-radius: 8px;

      .cart-items {
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid #{default-theme.$material-gray-300};

        > .item-summary-view-widget {
          margin-top: 16px;

          &:first-child {
            margin-top: 0;
          }
        }
      }

      .amounts {
        display: grid;
        row-gap: 10px;
        margin-bottom: 20px;
        > div {
          display: grid;
          grid-template-columns: 1fr min-content;
          grid-template-rows: max-content;

          .label,
          .value {
            @include typography.body-regular;
            color: #{default-theme.$material-gray-600};
          }
        }
        .discount {
          .label {
            overflow: hidden;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
          }
        }

        > div:last-child {
          margin-top: 8px;

          .label,
          .value {
            @include typography.body-bold;
            color: #{default-theme.$restoplus-dark};
          }
        }
      }
    }
  }
}
