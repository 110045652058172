@use '../viewport' as viewport;
@use '../themes/default' as default-theme;
@use '../constants' as constants;
@use '../typography' as typography;
@import './common-styles-mobile';
@import './_base-item-widget-mobile';

@include viewport.mobile {
  .deal-widget {
    @include item-widget();
    @include base-item-widget();

    .deal-body {
      padding-top: 18px;
      padding-bottom: 14px;
      .deal-pizzas {
        padding-top: 0;

        .deal-pizza {
          // margin-top: 31px;

          &:first-child {
            margin-top: 0;
          }

          .header {
            @include choose-pizza-header();
            padding: #{constants.$horizontal-gap};
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background-color: #{default-theme.$material-gray-50};
            .title {
              display: flex;
              justify-content: space-between;
              margin-bottom: 4px;
            }

            > .switch {
              display: flex;
              justify-content: flex-start;
              > label {
                margin: 0;
                @include typography.body-regular;
                color: #{default-theme.$restoplus-dark} !important;
              }
            }
          }

          .body {
            padding: constants.$horizontal-gap;
            .deal-pizza-entry {
              margin-top: 8px;

              &:first-child {
                margin-top: 0;
              }

              .choose-container {
                .choose-pizza-button {
                  @include choose-pizza-button();
                }

                .summary {
                  @include summary();
                }

                .customize-pizza-button {
                  @include customize-pizza-button();
                }
              }
            }
          }
        }
      }

      .deal-items {
        padding-bottom: 12px;
        display: grid;
        grid-gap: 35px;

        .deal-item {
          > .header {
            display: grid;
            grid-auto-flow: column;
            grid-template-columns: 1fr max-content;
            align-items: center;
            margin-bottom: 11px;
            padding: 16px constants.$horizontal-gap;
            background-color: #{default-theme.$material-gray-50};
            .title {
              @include typography.h4;
              color: #{default-theme.$restoplus-dark};
            }
          }

          > .modifiers {
            .modifier-widget {
              .header {
                .title {
                  .modifier-name {
                    font-weight: 700;
                  }
                }
              }
            }
          }
        }
      }

      .deal-modifiers {
        padding: constants.$horizontal-gap 0;
      }
    }

    .actions {
      @include item-actions();
    }

    .spinner-container {
      @include item-customisation-loading();
    }
  }
}
