@use "../viewport" as viewport;
@use "../themes/default" as default-theme;
@use '../typography' as typography;

@include viewport.mobile {
  .item-available-weekly-hours-widget {
    background: #{default-theme.$orange-light};
    @include typography.body-regular;
    color: #{default-theme.$orange-dark};

    border-radius: 4px;
    padding: 8px 16px;
  }
}
