@use '../viewport' as viewport;
@use '../themes/default' as default-theme;
@use "../typography" as typography;

@include viewport.tablet {
  .table-ordering-qr-scanner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;
    margin-bottom: 14px;

    .cancel-btn {
      @include typography.body-regular;
      border-radius: 8px;
      margin-bottom: 17px;
    }

    .barcode-scanner-component {
      align-self: center;
      padding-bottom: 100px;
      video {
        object-fit: cover;
        padding: 10px;
        background: linear-gradient(to right, #{default-theme.$secondary-fg} 4px, transparent 4px) 0 0,
          linear-gradient(to right, #{default-theme.$secondary-fg} 4px, transparent 4px) 0 100%,
          linear-gradient(to left, #{default-theme.$secondary-fg} 4px, transparent 4px) 100% 0,
          linear-gradient(to left, #{default-theme.$secondary-fg} 4px, transparent 4px) 100% 100%,
          linear-gradient(to bottom, #{default-theme.$secondary-fg} 4px, transparent 4px) 0 0,
          linear-gradient(to bottom, #{default-theme.$secondary-fg} 4px, transparent 4px) 100% 0,
          linear-gradient(to top, #{default-theme.$secondary-fg} 4px, transparent 4px) 0 100%,
          linear-gradient(to top, #{default-theme.$secondary-fg} 4px, transparent 4px) 100% 100%;
        background-repeat: no-repeat;
        background-size: 20px 20px;
      }
    }
  }
}
