@use '../viewport' as viewport;
@use '../constants' as constants;
@use '../themes/default' as default-theme;
@use '../typography' as typography;
@import './common-styles-desktop';

@include viewport.tablet {
  .update-delivery-address-widget {
    width: 450px !important;
    letter-spacing: 0.25px;
    color: #{default-theme.$restoplus-dark};
    height: 100%;

    .header-section {
      @include modal-header();
      padding: 0 #{constants.$horizontal-gap-desktop};

      .close-icon {
        width: 15px;
        margin-right: 5px;
      }
    }

    .body {
      display: grid;

      .action-buttons {
        display: flex;
        flex-flow: row-reverse;
        gap: 17px;
        padding: 0 constants.$horizontal-gap-desktop;
        box-sizing: border-box;
        width: 100%;
        padding: 24px constants.$horizontal-gap-desktop;
        padding-top: 0;

        .update-address-button {
          background: #{default-theme.$restoplus-teal};
          color: #fff;
          width: 100%;
          @include typography.button1;
          padding: 14px;
        }

        .cancel-order-button {
          background: #{default-theme.$bg};
          border: 2px solid #{default-theme.$restoplus-teal};
          color: #{default-theme.$restoplus-teal};
          width: 100%;
          @include typography.button1;
          padding: 13px;
        }
      }
    }
  }
}
