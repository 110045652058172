@use '../viewport' as viewport;
@use '../constants' as constants;
@use '../themes/default' as default-theme;
@use '../typography' as typography;
@import './common-styles-mobile';

.table-change-widget {
  max-height: 655px;

  .header-section {
    @include item-bottom-sheet-modal-header();
    padding: 0 #{constants.$horizontal-gap};
    .close-icon {
      width: 15px;
      margin-right: 5px;
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 #{constants.$horizontal-gap};
    margin: 21px 0;

    .change-table-btn {
      @include typography.button1;
      border-radius: 8px;
      margin-bottom: 17px;
    }

    .cancel-session-btn {
      @include typography.button1;
      border-radius: 8px;
      color: #{default-theme.$restoplus-teal};
      background-color: #{default-theme.$bg};
      border: 2px solid #{default-theme.$restoplus-teal};
      padding: 12px;
    }
  }

  .table-selector {
    @include session-create-widget-title-and-image();
    padding: 0 constants.$horizontal-gap;
    margin-top: 28px;
    padding-bottom: 28px;
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-template-areas:
      'title image'
      'table-list table-list';

    .title {
      margin-bottom: 15px;
    }

    .table-list {
      grid-area: table-list;
      display: grid;
      grid-gap: 15px;
      margin-top: 8px;

      .table {
        display: grid;
        grid-template-areas: 'name caret-right-icon';
        @include typography.body-regular;
        padding: 14px 16px;
        border-radius: 8px;
        color: #{default-theme.$restoplus-dark};
        border: 1px solid #{default-theme.$material-gray-300};
        align-items: center;
        .name {
          grid-area: name;
          margin-right: 15px;
        }
        .caret-right.icon {
          grid-area: caret-right-icon;
          margin-left: auto;
        }
      }
    }
  }
}
