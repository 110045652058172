@use "../viewport" as viewport;
@use "../themes/default" as default-theme;
@use "../typography" as typography;

@include viewport.mobile {
  .table-booking-summary-widget {
    @include typography.body-regular;
    color: #{default-theme.$restoplus-dark};
    background: white;
    border: 1px solid #{default-theme.$material-gray-300};
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin: 16px 20px 24px 20px;
    padding: 24px;

    .section-name {
      @include typography.body-bold;
      color: #{default-theme.$restoplus-dark};
    }

    .customer-unverified-email {
      word-break: break-word;
    }

    .contact-details,
    .special-requests {
      margin-top: 24px;
    }
  }
}
