@use "viewport"as viewport;

.form-switch {
  display: inline-block;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.form-switch i {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 21px;
  background-color: #e6e6e6;
  border-radius: 23px;
  vertical-align: text-bottom;
  transition: all 0.3s linear;
}

.form-switch i::before {
  content: '';
  position: absolute;
  left: 0;
  width: 36px;
  height: 20px;
  border-radius: 11px;
  transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
  transition: all 0.25s linear;
}

.form-switch i::after {
  content: '';
  position: absolute;
  left: 0;
  width: 17px;
  height: 17px;
  background-color: #fff;
  border-radius: 11px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
  transform: translate3d(2px, 2px, 0);
  transition: all 0.2s ease-in-out;
}

.form-switch:active i::after {
  width: 23px;
  transform: translate3d(2px, 2px, 0);
}

.form-switch:active input:checked + i::after {
  transform: translate3d(16px, 2px, 0);
}

.form-switch input {
  display: none;
}

.form-switch input:checked + i {
  background-color: #4bd763;
}

.form-switch input:checked + i::before {
  transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
}

.form-switch input:checked + i::after {
  transform: translate3d(22px, 2px, 0);
}

/**
 * A normal switch will render as a form item, where the meta is displayed below the form-value (which is the switch)
 * In an inline switch, we change the layout to render the meta below the label itself.
 */
@mixin inline {
  display: grid;
  grid-template-columns: auto max-content;
  grid-template-areas:
    'a b'
    'c d';

  label {
    grid-area: a;
    align-self: center;
    margin-left: 8px;
  }

  .form-input {
    grid-area: b;
    justify-self: end;
  }

  .meta {
    grid-area: c !important;
    margin-top: -3px;
  }
}

.form-item.switch {
  @include inline();
}
