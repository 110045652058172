@use "viewport"as viewport;
@use "constants"as constants;
@use "card"as card;
@use './typography' as typography;
@use "./themes/default" as default-theme;

@mixin primary {
  background-color: var(--button-primary-bg);
  color: var(--button-primary-fg);
}

@mixin primary-transparent {
  color: var(--button-primary-bg);
  background-color: transparent;
  border: 1px solid var(--button-primary-bg);
}

@mixin success-transparent {
  background-color: transparent;
  border: 1px solid var(--button-success-bg);
  color: #0e9347;
}

@mixin failure-transparent {
  background-color: transparent;
  border: 1px solid #fc5a5a;
  color: #fc5a5a;
}

@mixin secondary {
  background-color: var(--button-secondary-bg);
  color: var(--button-secondary-fg);
}

@mixin badge-button {
  border-radius: 4px;
  padding: 0 10px;
  @include typography.caption-regular;
  box-shadow: none;
}

@mixin place-order-button {
  font-family: Fira Sans;
  background-color: var(--button-success-bg);
  color: var(--button-success-fg);
  border-radius: constants.$border-radius;
  padding: 20px;
  white-space: nowrap;
}

@mixin add-to-cart-button {
  @include card.container();
  display: grid;
  grid-template-columns: min-content min-content;
  justify-content: space-between;
  background-color: var(--button-success-bg);
  color: var(--button-success-fg);
  border-radius: constants.$border-radius;
  padding: 20px;
  white-space: nowrap;
  font-weight: 400;
}

@mixin footer-button {
  @include card.container();
  background-color: var(--button-success-bg);
  color: var(--button-success-fg);
  border-radius: constants.$border-radius;
  padding: 20px;
  white-space: nowrap;
}

@mixin button-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  button {
    margin-right: 10px;
  }
  a {
    font-size: 0.75rem;
    font-weight: 400;
    margin-left: 6px;
  }

  a.cancel {
    color: var(--message-error-fg);
  }
}

button {
  border-radius: constants.$border-radius;
  font-family: Chivo;
  @include typography.button1;
  text-align: center;
  padding: 10px 18px;
  border: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .icon {
    font-size: 20px;
    margin-right: 7px;
  }
}

button.primary {
  @include primary();
}

button.success {
  background-color: #{default-theme.$restoplus-teal};
  color: #{default-theme.$bg};
  padding: 14px;
  text-align: center;
  @include typography.button1;
  cursor: pointer;
}

button.disabled {
  background-color: #f5f5f5 !important;
  border-color: #e2e2ea !important;
  color: #696974 !important;
  cursor: not-allowed;
}

@mixin compact {
  button {
    background-color: white;
    padding: 7px 14px;
    .icon {
      color: #92929d;
      font-size: 20px;
    }
    .label {
      color: var(--color-primary);
    }
  }
}
