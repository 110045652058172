@use "../viewport"as viewport;
@use "../themes/default" as default-theme;
@use "../constants" as constants;

@include viewport.tablet {
  .ReactModalPortal {
    .ReactModal__Overlay {
      background: rgba(82, 82, 82, 0.72) !important;
      z-index: 1000;
      height: 100vh !important;

      .ReactModal__Content {
        border-radius: 0 !important;
        padding: 0 !important;
        border: unset !important;
        background: none !important;
        top: 120px !important;

        .modal {
          position: relative;
          max-height: calc(100vh - 240px);
          overflow: hidden auto;
          background-color: #{default-theme.$bg};
          margin: 0 auto;
          border-radius: 16px;
          width: 525px;

          &::-webkit-scrollbar {
            width: 5px;
          }

          &::-webkit-scrollbar-track {
            background: transparent;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #{default-theme.$restoplus-dark}50;
            border-radius: 20px;
          }
        }
      }
    }
  }
}
