@use '../viewport' as viewport;
@use '../themes/default' as default-theme;
@use '../typography' as typography;
@import './common-styles-mobile';

@include viewport.mobile {
  .pizza-customize-widget {
    display: flex;
    flex-flow: column;
    height: 100%;

    .item-header {
      @include item-bottom-sheet-modal-header();

      .done-button {
        padding-top: 5px;
        @include typography.button2;
        color: #{default-theme.$restoplus-teal};
      }
    }

    .item-body {
      margin-bottom: 26px;
      overflow: scroll;

      .modifiers {
        display: flex;
        flex-flow: column;
        grid-gap: 36px;
        margin-bottom: 8px;
        padding-top: 21px;
        overflow: auto;

        .remove-toppings {
          .extra-charge {
            display: none;
          }
        }
      }
    }
  }
}
