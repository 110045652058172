@use "../viewport"as viewport;
@use "../themes/default" as default-theme;
@use "../constants" as constants;
@use "../typography" as typography;

@include viewport.mobile {
  .loyalty-card-page {
    margin-bottom: 100px;

    > .spinner {
      margin: 50px;
      text-align: center;
    }

    > .alert {
      margin: constants.$horizontal-gap;
    }

    .loyalty {
      margin: 40px auto;
      max-width: 400px;
      padding: 0 constants.$horizontal-gap;

      .claim-info {
        margin: 0 2px;
        margin-top: 50px;

        .title {
          @include typography.h4;
          color: #{default-theme.$restoplus-dark};
        }

        .subtitle {
          white-space: pre-wrap;
          @include typography.body-regular;
          color: #{default-theme.$material-gray-600};
          margin-top: 6px;
        }
      }
    }
  }
}
