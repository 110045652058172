@mixin coupon-body($border-color, $background-color) {
  display: grid;
  grid-template-areas: 'zigzag content';
  grid-template-columns: 12px 1fr;
  border-top: 1px solid $border-color;
  border-right: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  background-color: $background-color;
  box-shadow: 0px 0px 8px 1px rgba(48, 47, 58, 0.1);

  .content {
    grid-area: content;
  }

  .zigzag {
    grid-area: zigzag;
    width: 12px;
    background: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0) 47%,
        $border-color 47%,
        $border-color 52%,
        $background-color 52%
      ),
      linear-gradient(315deg, $background-color 47%, $border-color 47%, $border-color 52%, rgba(225, 225, 225, 30%) 52%);
    background-repeat: repeat-y;
    background-size: 23px 23px;
  }
}
