@use "../card"as card;
@use "../constants"as constants;
@use "../typography"as typography;
@use "../themes/default"as default-theme;
@use "../viewport" as viewport;

@include viewport.tablet {
  .restaurant-header-widget,
  .store-header-widget {
    display: flex;
    flex-direction: horizontal;
    justify-content: space-between;
    align-items: center;

    .name {
      @include typography.h4;
      text-align: left;
      color: #ffffff;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
