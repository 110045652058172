@use "../viewport" as viewport;
@use "../themes/default" as default-theme;
@use "../constants" as constants;
@use "../typography" as typography;

@include viewport.mobile {
  .trading-hours-page {
    background-color: #{default-theme.$material-gray-50};

    .store-logo-and-address {
      .restaurant-logo {
        display: none;
      }

      .info {
        padding: constants.$horizontal-gap;
        padding-bottom: 0;

        &.need-help {
          word-break: break-all;
          margin-top: 13px;
        }

        .title {
          @include typography.overline;
          color: #{default-theme.$restoplus-dark};
        }

        .text {
          @include typography.body-regular;
          color: #{default-theme.$material-gray-600};

          .link {
            color: #{default-theme.$restoplus-teal};
            cursor: pointer;
          }
        }
      }
    }

    .date-and-trading-hours-today {
      display: grid;
      padding-top: 36px;
      margin: 0 constants.$horizontal-gap;

      .date-today {
        display: grid;
        grid-gap: 5px;
        margin: auto 0;

        margin-bottom: 30px;

        .trading-hours-label {
          @include typography.overline;
          text-align: left;
          color: #{default-theme.$restoplus-dark};
          margin-bottom: 14px;
        }

        .date {
          @include typography.h3;
          text-align: left;
          color: #{default-theme.$restoplus-dark};
        }

        .closed-banner {
          background: #{default-theme.$red-light};
          color: #{default-theme.$red-dark};
          padding: 16px constants.$horizontal-gap;
          margin-top: 10px;

          @include typography.body-regular;

          display: inline-flex;
          flex-direction: row;

          .closed-label {
            font-weight: bold;
            margin-right: 0.25em;
          }
        }
      }

      .collection-type-trading-hours-today {
        width: 100%;
        // padding-top: 15px / 50px * 100%;
        padding: 18px 10px 18px 27px;
        display: grid;
        grid-template-columns: max-content 1fr;
        grid-template-areas:
          'logo  collection-type'
          'logo  sessions';
        column-gap: 45px;
        background-color: #{default-theme.$bg};
        border-radius: 8px;
        border: 1px solid #e0e0e0;
        box-sizing: border-box;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

        .collection-type {
          display: flex;
          grid-area: collection-type;
          @include typography.h4;
          text-align: center;
          color: #40415b;
          margin-bottom: 4px;
        }

        .logo {
          grid-area: logo;
          background-repeat: no-repeat;
          margin: 0 auto;
          position: relative;
          background-size: cover;
          height: 62px;
          width: 62px;
        }

        .logo.pickup {
          background-image: url('../images/pickup.svg');
        }

        .logo.delivery {
          background-image: url('../images/delivery.svg');
        }

        .logo.dineIn {
          background-image: url('../images/dine-in.svg');
        }

        .sessions {
          display: flex;
          grid-area: sessions;
          flex-direction: column;
          align-items: flex-start;

          .unavailable {
            @include typography.body-regular;
            text-align: center;
            color: #{default-theme.$red-dark};
          }

          .available {
            @include typography.body-regular;
            text-align: center;
            color: #{default-theme.$restoplus-dark};
          }
        }
      }

      .date-today.closed {
        @include viewport.tablet {
          border-bottom: unset;
        }
      }
    }

    .date-and-trading-hours-today.closed {
      @include viewport.tablet {
        grid-auto-flow: column;
      }
    }

    .trading-hours-today {
      display: grid;
      grid-gap: 15px;
      margin-bottom: 15px;
    }

    .trading-hours-today.closed {
      .unavailable {
        margin-bottom: 0 !important;
      }
    }

    // .trading-hours-today {
    //   padding: 20px;
    // }

    .label {
      width: fit-content;
      padding: 3px 10px 3px 3px;
      border-radius: 8px;
      margin-bottom: 10px;
      font-size: 13px;
      font-weight: 400;
      display: grid;
      align-items: center;
      grid-auto-flow: column;
      grid-gap: 7px;
    }

    // .trading-hours-today .unavailable {
    //   @extend .label;
    //   background-color: #ffeeee;
    //   color: #fc5a5a;
    //   & i {
    //     font-size: 23px;
    //   }
    // }

    // .trading-hours-today .available {
    //   @extend .label;
    //   background-color: #f4f9ed;
    //   & i {
    //     color: #82c43c;
    //     font-size: 23px;
    //   }
    // }

    .collection-type-selector {
      box-shadow: inset 0px -1px 0px #{default-theme.$border-light};
      padding: 0 17px;
      margin: constants.$horizontal-gap 0;
      margin-top: 5px;

      .collection-types-tab-selector {
        display: flex;

        .collection-type {
          @include typography.body-regular;
          color: #{default-theme.$material-gray-600};
          padding: 10px 11px;

          &.selected {
            font-weight: 700;
            color: #{default-theme.$restoplus-dark};
            border-bottom: 1px solid #{default-theme.$restoplus-dark};
          }
        }
      }
    }

    .trading-hours {
      background-color: white;
      border-radius: 10px;
      box-shadow: 0px 0px 8px rgba(49, 48, 62, 0.1);
      margin: 33px constants.$horizontal-gap;
    }

    .time-item {
      display: grid;
      grid-template-columns: max-content 1fr;
      grid-template-rows: 1fr;
      grid-gap: 10px;
      grid-row-gap: 5px;
      border-bottom: 1px solid #{default-theme.$material-gray-300};
      @include typography.body-regular;
      padding: 8px 20px;

      &:last-child {
        border-bottom: none;
      }

      .dow {
        padding: 10px 0;
        font-weight: 700;
        color: #{default-theme.$restoplus-dark};
      }
      .session {
        justify-items: end;
        text-align: right;
        padding-right: 5px;
        padding: 10px 0;
        font-weight: 400;
        color: #{default-theme.$restoplus-dark};

        &.closed {
          color: #{default-theme.$red-dark};
        }
      }
    }
  }
}
