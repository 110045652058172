@use '../viewport'as viewport;
@use '../themes/default'as default-theme;
@use '../typography' as typography;

@include viewport.tablet {
  .category-list-widget {
    grid-area: categories;
    gap: 10px;
    overflow: visible;
    position: sticky;
    top: 53px;
    width: 100%;
    margin-top: 38px;
    margin-bottom: 31.5px;
    padding-top: 31.5px;
    padding-bottom: 37vh;

    .header-desktop {
      display: block;
      @include typography.h4;
      color: #{default-theme.$restoplus-dark};
    }

    #categories-container {
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: unset;
      padding-bottom: unset;
      overflow: hidden;
      margin-top: -1px;

      &::-webkit-scrollbar {
        display: none;
      }

      .category {
        white-space: nowrap;
        -webkit-tap-highlight-color: transparent;
        cursor: pointer;
        word-wrap: break-word;
        padding: 0px;
        border: unset !important;
        color: black !important;
        margin-right: 0;

        &.selected {
          border-bottom: 2px solid #{default-theme.$restoplus-dark};
          background-color: unset !important;
        }

        .name {
          @include typography.body-regular;
          text-align: left;
          width: max-content;
          padding: 20px 0 10px 0;
          color: #{default-theme.$material-gray-600};

          &.selected {
            font-weight: 700;
            color: #{default-theme.$restoplus-dark};
            padding: 21px 0 6px 0;
            border-bottom: 3px solid #{default-theme.$restoplus-dark};
          }
        }
      }
    }
  }
}
