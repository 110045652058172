@use "themes/default"as default;

:root {
  --bg: #{default.$bg};
  --fg: #{default.$fg};

  --color-primary: #{default.$primary};
  --color-secondary: #{default.$secondary};

  --link-fg: #{default.$link-fg};
  --card-bg: #{default.$card-bg};

  --form-element-bg: #{default.$form-element-bg};
  --form-element-fg: #{default.$form-element-fg};
  --form-element-border: #{default.$form-element-border};
  --form-element-error: #{default.$form-element-error};

  --button-primary-bg: #{default.$button-primary-bg};
  --button-primary-fg: #{default.$button-primary-fg};

  --button-secondary-bg: #{default.$button-secondary-bg};
  --button-secondary-fg: #{default.$button-secondary-fg};

  --button-success-bg: #{default.$button-success-bg};
  --button-success-fg: #{default.$button-success-fg};

  --button-danger-bg: #{default.$button-danger-bg};
  --button-danger-fg: #{default.$button-danger-fg};

  --message-success-text: #{default.$message-success-text};
  --message-success-bg: #{default.$message-success-bg};
  --message-success-fg: #{default.$message-success-fg};

  --message-error-text: #{default.$message-error-text};
  --message-error-bg: #{default.$message-error-bg};
  --message-error-fg: #{default.$message-error-fg};

  --message-info-text: #{default.$message-info-text};
  --message-info-bg: #{default.$message-info-bg};
  --message-info-fg: #{default.$message-info-fg};

  --message-warning-text: #{default.$message-warning-text};
  --message-warning-bg: #{default.$message-warning-bg};
  --message-warning-fg: #{default.$message-warning-fg};

  --message-loading-text: #{default.$message-loading-text};
  --message-loading-bg: #{default.$message-loading-bg};
  --message-loading-fg: #{default.$message-loading-fg};
}

[data-theme='dark'] {
  --color-bg: #000000;
}

$white: #ffffff; // bg
$black: #000000;

// Brand colors
$teal-50: #ecf9f6;
$teal-100: #c4eae1;
$teal-200: #a1dcce;
$teal-300: #83d0be;
$teal-400: #6bc7b2;
$teal-500: #4cbba1; // primary
$teal-600: #3f9c86;
$teal-700: #368875;
$teal-800: #296a5b;
$teal-900: #1a463c;

$light-gray-50: #fafafa;
$light-gray-100: #f4f4f6;
$light-gray-200: #e9e9ec;
$light-gray-300: #dedee3;
$light-gray-400: #d3d3d9;
$light-gray-500: #c8c8d0;
$light-gray-600: #babac4;
$light-gray-700: #adadb8;
$light-gray-800: #9f9fad;
$light-gray-900: #9191a1;

$blue-gray-50: #f6f8fb; // secondary-bg
$blue-gray-100: #dee0e6; // border-light
$blue-gray-200: #c6c7d1;
$blue-gray-300: #adafbc; // disabled
$blue-gray-400: #9597a7;
$blue-gray-500: #7d7e92; // secondary-fg
$blue-gray-600: #65667d; // gray
$blue-gray-700: #4c4e68; // tertiary, form-element-fg
$blue-gray-800: #343553; // restoplus-gray-dark, used by some texts
$blue-gray-900: #1c1d3e; // fg

// Helper colors
$rose-50: #fff1f2;
$rose-100: #ffe4e6;
$rose-200: #fecdd3;
$rose-300: #fda4af;
$rose-400: #fb7185;
$rose-500: #f43f5e; // secondary
$rose-600: #e11d48;
$rose-700: #be123c;
$rose-800: #9f1239;
$rose-900: #88133a;

$green-50: #ecfdf5;
$green-100: #d1fae5;
$green-200: #a7f3d0;
$green-300: #6ee7b7;
$green-400: #34d399;
$green-500: #10b981; // highlight
$green-600: #059669;
$green-700: #047857;
$green-800: #065f46;
$green-900: #064e3b;

$blue-50: #eff6ff;
$blue-100: #dbeafe;
$blue-200: #bfdbfe;
$blue-300: #93c5fd;
$blue-400: #60a5fa;
$blue-500: #3b82f6;
$blue-600: #2563eb;
$blue-700: #1d4ed8;
$blue-800: #1e40af;
$blue-900: #1e3a8a;

$yellow-50: #fffbeb;
$yellow-100: #fef3c7;
$yellow-200: #fde68a;
$yellow-300: #fcd34d;
$yellow-400: #fbbf24;
$yellow-500: #f59e0b;
$yellow-600: #d97706;
$yellow-700: #b45309;
$yellow-800: #92400e;
$yellow-900: #78350f;

$orange-50: #ffe1c5;
$orange-100: #ffd3aa;
$orange-200: #ffbb7c;
$orange-300: #ffa249;
$orange-400: #ff8818;
$orange-500: #ff7c00;
$orange-600: #ed7301;
$orange-700: #c15e01;
$orange-800: #9d4c01;
$orange-900: #864100;

$violet-50: #f3e4ff;
$violet-100: #e3aeff;
$violet-200: #d47dff;
$violet-300: #c54eff;
$violet-400: #b71eff;
$violet-500: #a601f4;
$violet-600: #9204d5;
$violet-700: #7209a3;
$violet-800: #510f70;
$violet-900: #411158;

$sky-blue-50: #bcf2ff;
$sky-blue-100: #90e9ff;
$sky-blue-200: #68e1ff;
$sky-blue-300: #3ed8fe;
$sky-blue-400: #15cffe;
$sky-blue-500: #05c4f5;
$sky-blue-600: #0ab6e1;
$sky-blue-700: #0fa8cf;
$sky-blue-800: #149dc0;
$sky-blue-900: #1a8ca8;

$gold-50: #f2ebe1;
$gold-100: #eee4d7;
$gold-200: #e5d6c1;
$gold-300: #dbc8ac;
$gold-400: #d2b996;
$gold-500: #c9ab81;
$gold-600: #be9a68;
$gold-700: #b48a4f;
$gold-800: #9c7743;
$gold-900: #836438;

$yellow-green-50: #f5ffeb;
$yellow-green-100: #cbf2a3;
$yellow-green-200: #b2ec76;
$yellow-green-300: #80de1e;
$yellow-green-400: #73c71b;
$yellow-green-500: #66b118;
$yellow-green-600: #569614;
$yellow-green-700: #477b11;
$yellow-green-800: #37600d;
$yellow-green-900: #284509;

$material-gray-50: #fafafa;
$material-gray-100: #f5f5f5;
$material-gray-200: #eeeeee;
$material-gray-300: #e0e0e0;
$material-gray-450: #c4c4c4;
$material-gray-400: #bdbdbd;
$material-gray-500: #9e9e9e;
$material-gray-600: #757575;

// Gradient
$modifier-gradient-bg: linear-gradient(180deg, #f7f9fc 0%, #fff 100%);
$modifier-gradient-active-bg: linear-gradient(180deg, rgba(44, 45, 86, 1) 0%, rgba(12, 13, 52, 1) 100%);

// Shadow
$card-shadow: 0px 0px 2px rgb(0 0 0 / 10%), 0px 2px 3px rgb(0 0 0 / 20%);
$card-shadow-light: 1px 0 18px 0px rgba(228, 228, 228, 0.96);
