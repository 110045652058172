@use '../card'as card;
@use '../constants'as constants;
@use '../typography'as typography;

.coupon-card {
  @include card.container;
  @include card.stack;
  display: grid;
  grid-template-rows: 1fr min-content;
  cursor: pointer;
  background-color: #fafafb;
  margin: 15px 0;

  .body {
    @include card.upper;
    padding: 12px;
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-template-rows: min-content 1fr min-content;
    grid-template-areas:
      'a d'
      'b d'
      'c d';
  }

  .name {
    @include typography.h2;
    grid-area: a;
    align-self: flex-start;
  }

  .description {
    font-size: 13px;
    grid-area: b;
    align-self: flex-start;
  }

  .actions {
    @include card.lower;
    @include card.round-buttons;

    .action {
      @include card.round-button;
    }
  }
}
