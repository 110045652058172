@use "../card"as card;
@use "../constants"as constants;
@use "../scrollbar"as scrollbar;
@use "../viewport"as viewport;
@use '../typography' as typography;
@use "../themes/default" as default-theme;

@include viewport.mobile {
  nav {
    grid-area: nav;
    display: flex;
    overflow-y: scroll;
    background-color: white;
    box-shadow: 0px 0px 3px rgba(104, 122, 155, 0.25);
    flex-direction: column;
    z-index: 999;
    width: calc(100% - 50px);
    max-width: 325px;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    margin-left: 50px;
    transform: translateX(120%);
    transition: transform 300ms;
    @include scrollbar.hide;

    &.show {
      transform: none;
    }
  }

  .profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 60px;

    .avatar span {
      font-family: "Chivo";
    }

    .name {
      @include typography.h4;
      color: #{default-theme.$restoplus-dark};
      margin-top: 17px;
    }

    .email,
    .phone-number {
      @include typography.body-regular;
      color: #{default-theme.$material-gray-600};
    }
  }

  .nav-links {
    display: grid;
    gap: 5px;
    padding: 0 constants.$horizontal-gap;
    padding-top: 24px;

    .link {
      $bleed-width: 4px;
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: 28px 1fr;
      margin: 5px 0;
      padding: 17px;
      padding-left: constants.$horizontal-gap - $bleed-width;
      align-items: center;
      cursor: pointer;
      border-radius: 8px;
      border: 1px solid #{default-theme.$material-gray-300};
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

      .icon {
        color: #92929d;
        font-size: 21px;
        margin-right: 9px;
      }

      .label {
        @include typography.body-regular;
        color: #{default-theme.$restoplus-dark};
        display: inline-block;
        white-space: nowrap;
      }
    }
  }

  .powered-by-restoplus {
    text-align: center;
    margin-top: auto;
    padding-bottom: 50px;

    .text {
      margin-bottom: 10px;
      @include typography.caption-regular;
      color: #{default-theme.$material-gray-600};
    }
  }

  .overlay.show {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 998;
  }
}
