@use '../viewport' as viewport;
@use '../themes/default' as default-theme;
@use '../constants' as constants;
@import './common-styles-desktop';
@import './_base-item-widget-desktop';

@include viewport.tablet {
  .half-and-half-widget {
    @include item-widget();
    @include base-item-widget();

    .half-and-half-pizzas {
      @include half-and-half-pizzas();
    }

    .actions {
      @include item-actions();
      margin-top: 33px;
    }

    .spinner-container {
      @include item-customisation-loading();
    }
  }
}
