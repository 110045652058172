@use '../viewport'as viewport;

@include viewport.mobile {
  .menu-search-widget {
    display: block;

    .searchbox {
      .search-icon {
        width: 21px;
        filter: invert(0.6);
      }

      input {
        display: none;
      }

      .clear-input-icon {
        display: none;
      }
    }
  }
}
