$bg: #ffffff;
$fg: #1c1d3e;

$primary: #0062ff;
$secondary: #fa7268;

$card-bg: white;

$link-fg: #1e75ff;

$form-element-bg: white;
$form-element-fg: #44444f;
$form-element-border: #ddd;
$form-element-error: #fc5a5a;

$button-primary-bg: $primary;
$button-primary-fg: white;

$button-secondary-bg: white;
$button-secondary-fg: #696974;

$button-success-bg: #36b37e;
$button-success-fg: white;

$button-danger-bg: red;
$button-danger-fg: white;

$bg: #ffffff;
$fg: #1c1d3e;

$secondary-bg: #f6f8fb;
$secondary-fg: #838393;

$primary: #4cbba1;
$secondary: #da6565;
$tertiary: #595976;

$highlight: #34c759;

$card-bg: white;

$link-fg: #1e75ff;

$border: #4f506a;
$border-light: #e3ebec;
$border-gray: #e9e9e9;

$form-element-bg: white;
$form-element-fg: #44444f;
$form-element-border: #ddd;
$form-element-error: #fe5b5b;

$button-primary-bg: $primary;
$button-primary-fg: white;

$button-secondary-bg: #eee;
$button-secondary-fg: #454545;

$button-success-bg: #454545;
$button-success-fg: white;

$button-danger-bg: red;
$button-danger-fg: white;

$message-success-text: #086e37;
$message-success-bg: #def2d6;
$message-success-fg: #086e37;

$message-error-text: #b0204b;
$message-error-bg: #ffe7df;
$message-error-fg: #b0204b;

$message-info-text: #0071b3;
$message-info-bg: #e6edfa;
$message-info-fg: #00a3ff;

$message-warning-text: #d67300;
$message-warning-bg: #ffebcf;
$message-warning-fg: #d67300;

$message-loading-text: #505f79;
$message-loading-bg: white;
$message-loading-fg: #ffab00;

$loyalty-point-on: #33d169;
$loyalty-point-off: #373737;

$material-gray-50: #fafafa;
$material-gray-100: #f5f5f5;
$material-gray-200: #eeeeee;
$material-gray-300: #e0e0e0;
$material-gray-450: #c4c4c4;
$material-gray-400: #bdbdbd;
$material-gray-500: #9e9e9e;
$material-gray-600: #757575;

$red-dark: #b0204b;
$red-light: #ffe7df;
$orange-dark: #d67300;
$orange-light: #ffebcf;
$green-dark: #086e37;
$green-light: #def2d6;
$gray-dark: #3a3a4e;
$blue-light: #e5efff;
$blue-normal: #5c84e5;

$light-gray-200: #e9e9ec;
$light-gray-900: #9191a1;

$restoplus-dark: #27283e;
$restoplus-brand-green: #1faa89;
$restoplus-teal: #4cbba1;
$restoplus-light-green: #eff8f9;
$restoplus-cool-gray: #a9a9b8;

$banner-overlay-gradient: linear-gradient(
  180deg,
  rgba(42, 42, 49, 0) 11.39%,
  rgba(42, 42, 49, 0.1) 24.94%,
  rgba(42, 42, 49, 0.8) 100%
);
$header-gradient: linear-gradient(178.96deg, #ececed 0.92%, #ffffff 112.41%);
