.otp-modal {
  // max-width: 15rem;
  width: 15rem;

  .illustration {
    display: block;
    width: auto;
    margin: auto;
  }

  .instructions {
    margin-top: 1rem;
    text-align: center;
    .title {
      color: #000521;
      font-weight: 500;
      font-size: 16px;
    }
    .sub-title {
      font-size: 12px;
      color: #92929d;
    }
  }

  .otp-input {
    margin-top: 1rem;
    div {
      margin: 0 auto;
    }
  }

  .help-text {
    text-align: center;
    margin-top: 1rem;
    font-size: 12px;
    color: #92929d;
  }

  .resend {
    margin: 1rem auto;
    text-align: center;
    font-weight: 500;
    padding: 0.4rem;
    box-shadow: unset;
    background-color: unset;
    font-size: 12px;
    color: #0062ff;
  }

  .actions {
    border-top: 1px solid;
    padding-top: 1rem;
    border-color: #e1e1e9;
    margin-top: 1rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    button {
      // margin: 1rem auto;
      width: 100%;
    }
  }
}

.mobile-number-widget {
  #recaptcha-wrapper.hidden {
    display: none;
  }
  .primary {
    // margin: 0 auto;
    width: 100%;
  }
}
