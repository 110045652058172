@use '../card' as card;
@use '../viewport'as viewport;
@use "../constants" as constants;
@import './common-styles-mobile';

@include viewport.mobile {
  .session-view-widget {
    @include session-view-widget();
  }
}
