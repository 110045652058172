@use '../viewport'as viewport;
@use '../themes/default'as default-theme;
@use '../typography' as typography;

@include viewport.tablet {
  .menu-search-widget {
    display: block;

    &.hidden {
      display: none;
    }

    .searchbox {
      display: flex;
      flex-direction: row;
      background: #{default-theme.$bg};
      box-sizing: border-box;
      border-radius: 100px;
      width: 194px;
      margin-right: 25px;
      padding: 6px;
      padding-left: 8.13px;
      @include typography.body-regular;

      &.inactive {
        background: rgba(255, 255, 255, 0.1);
        input {
          color: #{default-theme.$restoplus-cool-gray};
        }
      }
      &.active {
        background: #{default-theme.$bg};
        input {
          color: #{default-theme.$restoplus-dark};
        }
      }
      .search-icon {
        margin: auto 0;
        margin-left: 3px;
        height: 16.41px;
        filter: invert(0.6);
        pointer-events: none; // disable go to search page
      }
      .clear-input-icon {
        cursor: pointer;
        padding-right: 5px;
        margin-left: auto;
      }

      input {
        margin: auto 0;
        margin-left: 6px;
        border: none;
        background: none;
        height: 20px;
        width: 128.08px;
        @include typography.body-regular;
        &:focus {
          outline: none;
        }
      }
    }
  }
}
