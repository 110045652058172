@use '../viewport' as viewport;
@use '../themes/default' as default-theme;
@use '../constants' as constants;
@use '../typography' as typography;

$max-modal-height: calc(100vh - 240px);

@mixin item-widget {
  display: flex;
  flex-flow: column;
  max-height: $max-modal-height;

  .item-body {
    padding-bottom: 72.25px;
    @include modal-scrollable-content-with-inner-shadow();

    &::-webkit-scrollbar {
      width: 0;
    }

    .close-icon {
      position: absolute;
      top: 25px;
      right: 8px;
      margin-right: 19px;
      background: #{default-theme.$bg};
      width: 16px;
      padding: 7px;
      border-radius: 100%;
      z-index: 2;
      cursor: pointer;

      &.no-image {
        background: #{default-theme.$material-gray-200};
        top: 23px;
        box-shadow: 0px 2px 2px rgb(49 48 62 / 8%), 0px 6px 10px rgb(49 48 62 / 10%);
      }
    }
  }

  .item-image {
    &.no-image {
      margin-top: unset;
    }
  }

  .item-name {
    position: sticky;
    top: 0;
    background: #{default-theme.$bg};
    @include typography.h2;
    color: #{default-theme.$restoplus-dark};
    z-index: 1;

    &::before {
      content: '';
      display: block;
      height: 16px;
      position: sticky;
      -webkit-position: sticky;
      top: 57.2px;
      box-shadow: 0px 2px 5px rgb(0 0 0 / 14%);
    }

    &.no-image {
      &::before {
        margin-top: 10px;
      }
    }

    > .container {
      background-color: #{default-theme.$bg};
      padding: 25px #{constants.$horizontal-gap-desktop};
      padding-bottom: 18px;
      position: sticky;
      -webkit-position: sticky;
      top: 0px;
      margin-top: -16px;
      z-index: 3;
    }
  }
}

@mixin item-details {
  background: white;
  padding: 0 #{constants.$horizontal-gap-desktop};

  * {
    margin: 0;
  }

  .item-description {
    @include typography.body-regular;
    color: #{default-theme.$material-gray-600};
  }

  .item-price-and-sold-out {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    padding-top: 17px;
    margin-bottom: 16px;

    .item-price {
      @include typography.body-regular;
      color: #{default-theme.$restoplus-dark};
    }

    .item-sold-out,
    .item-unavailable {
      background: #{default-theme.$material-gray-300};
      padding: 2px 8px;

      @include typography.body-regular;
      color: #{default-theme.$restoplus-dark};

      border-radius: 4px;
      margin: 0 16px;
    }
  }
}

@mixin item-actions {
  .add-to-cart-widget {
    padding: 24px 30px 24px 30px;
  }
}

@mixin choose-pizza-header {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr max-content;

  .title {
    @include typography.h4;
    color: #{default-theme.$restoplus-dark};

    .requiredness {
      padding: 4px 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      @include typography.caption-regular;
      color: #{default-theme.$material-gray-600};
    }

    .selected::before {
      content: url('../images/green-check.svg');
      padding-right: 5px;
    }

    .required {
      color: #{default-theme.$green-dark};
    }

    .error {
      background-color: #{default-theme.$red-light};
      color: #{default-theme.$red-dark};
    }
  }

  > .form-item.switch {
    label {
      @include typography.body-regular;
      color: #{default-theme.$restoplus-dark};
    }
  }
}

@mixin choose-pizza-button {
  cursor: pointer;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr min-content min-content;
  gap: 15px;
  padding: 18px 16px;
  border: 1px solid #{default-theme.$material-gray-300};
  border-radius: 8px;
  align-items: center;

  .text {
    align-items: center;
    @include typography.body-regular;
    color: #{default-theme.$restoplus-dark};

    &.no-pizza {
      color: #{default-theme.$material-gray-500};
    }
  }

  .extra-charge {
    white-space: nowrap;
    @include typography.body-regular;
    color: #{default-theme.$material-gray-500};
  }
}

@mixin customize-pizza-button {
  cursor: pointer;
  margin-left: 2px;
  @include typography.button2;
  color: #{default-theme.$restoplus-teal};
  margin-bottom: 28px;

  &.customize {
    margin-top: 20px;
  }
}

@mixin summary {
  > pre {
    @include typography.caption-regular;
    color: #{default-theme.$material-gray-600};
    margin: 17px 2px;
    margin-bottom: 12px;
    overflow-x: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
}

@mixin item-radio-button {
  input[type='radio'] {
    cursor: pointer;
    display: none;

    + label {
      position: relative;
      margin-left: 25px;
      align-items: center;
      @include typography.body-regular;
      color: #{default-theme.$restoplus-dark};
      cursor: pointer;

      &::before {
        content: '';
        position: absolute;
        top: 0px;
        left: -28px;
        border-radius: 50%;
        border: 1px solid #{default-theme.$material-gray-300};
        width: 14px;
        height: 14px;
      }
    }

    &:checked {
      + label::before {
        background: #{default-theme.$restoplus-teal};
        border: 1px solid #{default-theme.$restoplus-teal};
      }
    }
  }
}

@mixin item-checkbox {
  input[type='checkbox'] {
    display: none;

    + label {
      position: relative;
      margin-left: 25px;
      @include typography.body-regular;

      &::before {
        content: '';
        position: absolute;
        top: -1px;
        left: -28px;
        border-radius: 4px;
        border: 1px solid #{default-theme.$material-gray-300};
        width: 16px;
        height: 16px;
      }
    }

    &:checked {
      + label::before {
        background: #{default-theme.$restoplus-teal};
        border: 1px solid #{default-theme.$restoplus-teal};
        background-image: url('../images/icon-check-light.svg');
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
}

@mixin half-and-half-pizzas {
  display: grid;
  grid-gap: 28px;
  margin-top: 18px;

  p {
    margin: 0;
    margin-bottom: 11px;
  }

  .header {
    padding: 16px #{constants.$horizontal-gap-desktop};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
    @include typography.h4;
    background-color: #{default-theme.$material-gray-50};

    .title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 4px;
      .pizza-half {
        @include typography.h4;
        margin-right: 8px;
        color: #{default-theme.$restoplus-dark};
      }

      .requiredness {
        padding: 0px 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        @include typography.caption-regular;
        color: #{default-theme.$material-gray-600};
      }

      .selected::before {
        content: url('../images/green-check.svg');
        padding-right: 5px;
      }

      .required {
        color: #{default-theme.$green-dark};
      }

      .error {
        background-color: #{default-theme.$red-light};
        color: #{default-theme.$red-dark};
      }
    }
  }

  .half-and-half-pizza-entry {
    padding: 16px #{constants.$horizontal-gap-desktop};
  }

  .choose-pizza-button {
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    padding: 14px 30px 14px 16px;
    @include typography.body-regular;
    border-radius: 8px;
    border: 1px solid #{default-theme.$material-gray-300};
    background: #{default-theme.$bg};
    color: #{default-theme.$material-gray-500};
    background-image: url('../images/right-arrow-head-icon.svg');
    background-repeat: no-repeat;
    background-position: right 14px bottom 19px;
    cursor: pointer;

    .label.with-input {
      color: #{default-theme.$restoplus-dark};
    }

    .extra-charge {
      display: flex;
      grid-gap: 5px;
    }
  }

  .summary {
    margin: 0;
    padding: 16px 0 0px 2px;
    white-space: pre-wrap;
    @include typography.caption-regular;
    color: #{default-theme.$material-gray-600};
  }

  .customize-pizza-button {
    @include typography.button2;
    text-transform: none;
    background: #{default-theme.$bg};
    color: #{default-theme.$restoplus-teal};
    box-shadow: none;
    padding: 20px 3px;
    letter-spacing: unset;
  }

  .edit-pizza-button {
    @include typography.button2;
    text-transform: none;
    background: #{default-theme.$bg};
    color: #{default-theme.$restoplus-teal};
    box-shadow: none;
    padding: 12px 0 0 2px;
    letter-spacing: unset;
  }
}

@mixin pizza-list-widget {
  display: flex;
  flex-flow: column;
  height: 100%;
  @include item-radio-button();

  .item-header {
    @include modal-header();
    padding: constants.$horizontal-gap constants.$horizontal-gap-desktop;

    .close {
      cursor: pointer;
      margin-right: 4px;
      margin-bottom: 5px;
      @include typography.body-bold;
      text-transform: uppercase;
      color: #{default-theme.$restoplus-teal};
    }
  }

  .item-body {
    height: 100%;
    overflow: hidden;
    margin-bottom: 26px;
  }

  .pizzas {
    display: grid;
    gap: 15px;
    padding: 10px constants.$horizontal-gap-desktop;
    @include modal-scrollable-content-with-inner-shadow();
    max-height: calc(100vh - 352px);

    .pizza {
      padding: 14px 0;
      border-radius: 8px;
      border: 1px solid #{default-theme.$material-gray-300};
      overflow: hidden;
      position: relative;
      cursor: pointer;

      .soldout-overlay {
        background: #ababab36;
        width: 100%;
        height: 100%;
        position: absolute;
        cursor: not-allowed;
        left: 0;
        top: 0;
      }

      .header {
        padding: 0 10px 0 15px;
        @include typography.body-regular;
        color: #{default-theme.$restoplus-dark};
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .name {
          @include typography.body-regular;
        }
      }

      .price {
        @include typography.body-regular;
        color: #{default-theme.$material-gray-600};
        white-space: nowrap;
      }

      .description {
        @include typography.caption-regular;
        color: #{default-theme.$material-gray-600};
        margin: 0;
        padding: 5px 15px 0 40px;
      }
    }
  }
}

@mixin modal-header {
  display: flex;
  justify-content: space-between;
  padding: constants.$horizontal-gap-desktop;
  position: sticky;
  top: 0;
  background: #{default-theme.$bg};
  z-index: 99;

  .title {
    @include typography.h3;
    color: #{default-theme.$restoplus-dark};
  }

  .close-icon {
    width: 15px;
    position: relative;
    cursor: pointer;
    right: -4px;
  }
}

@mixin modal-scrollable-content-with-inner-shadow {
  overflow: hidden auto;
  background: /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 10px, 100% 10px;
  background-attachment: local, local, scroll, scroll;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #{default-theme.$restoplus-dark}50;
    border-radius: 20px;
  }
}

@mixin session-create-widget-title-and-image {
  .title {
    grid-area: title;
    display: flex;
    align-self: center;
    @include typography.h4;
    color: #{default-theme.$restoplus-dark};
    margin-bottom: 25px;
    margin-top: 11px;
  }

  .image {
    grid-area: image;
    position: relative;
    top: 7px;
    right: -7px;
    width: 39px;
  }
}

@mixin item-customisation-loading {
  position: absolute;
  top: 42px;
  left: calc(50% - 280px / 2);
  background: #{default-theme.$orange-light};
  border: 1px solid #f8bd79;
  box-sizing: border-box;
  border-radius: 8px;
  height: 51px;
  gap: 10px;
  padding: 14px;
  z-index: 2;

  display: flex;
  flex-direction: row;

  .message {
    @include typography.body-regular;
    color: #{default-theme.$orange-dark};
  }

  .spinner {
    animation: spin 2s linear infinite;
  }
}

@mixin info-tag {
  @include typography.caption-regular;
  padding: 3px 6px;
  margin-left: 8px;

  border-radius: 4px;
  color: #{default-theme.$restoplus-dark};
  background: #{default-theme.$material-gray-300};
}
