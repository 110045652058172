@use "../card"as card;
@use "../constants"as constants;
@use "../scrollbar"as scrollbar;
@use "../typography"as typography;
@use "../viewport"as viewport;

.online-ordering {
  .banner {
    padding: 20px;
  }

  @include viewport.desktop {
    .banner {
      max-width: constants.$max-body-width;
      margin: 0 auto;
    }
  }
}
