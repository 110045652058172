@use '../viewport' as viewport;
@use '../card' as card;
@use '../themes/default' as default-theme;
@use '../typography' as typography;
@use '../constants' as constants;

@mixin base-item-widget {
  .item-sold-out,
  .item-unavailable {
    background: #{default-theme.$material-gray-300};
    padding: 2px 8px;
    @include typography.caption-regular;
    color: #{default-theme.$restoplus-dark};
    border-radius: 4px;
    margin: 25px 65px 0px 18px;
    width: fit-content;
  }

  .item-label {
    @include typography.caption-regular;
    color: #{default-theme.$restoplus-dark};
    background-color: #ffebcf;
    padding: 3px 6px;
    border-radius: 4px;
    display: inline-block;
    margin: 20px 0px 0px 20px;
  }

  .item-name {
    padding: 0 #{constants.$horizontal-gap};
    padding-top: 25px;
    padding-right: 65px;
    background: white;
    @include typography.h2;
    color: #{default-theme.$restoplus-dark};

    &.no-image {
      margin-top: 35px;
    }
  }

  .item-details {
    padding: 10px #{constants.$horizontal-gap};
    font-style: normal;
    background: white;

    * {
      margin: 0;
    }

    .item-description {
      @include typography.body-regular;
      color: #{default-theme.$material-gray-600};
    }

    .item-price-and-dietary {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      padding-top: 15px;

      .item-price {
        @include typography.body-regular;
        color: #{default-theme.$restoplus-dark};
      }

      .item-dietary {
        margin-left: auto;
        height: min-content;
        @include typography.caption-regular;
        color: #757575;
        justify-self: flex-end;
        grid-column-start: 3;
        line-height: 18px;
      }
    }

    .item-available-weekly-hours-widget {
      margin-top: 18px;
    }
  }
}
