@use "../viewport"as viewport;
@use "../themes/default" as default-theme;
@use "../typography" as typography;

@include viewport.mobile {
  .upsell-items-widget {
    .header {
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      box-shadow: inset 0px -1px 0px #f0f0f0;
      background-color: #{default-theme.$bg};

      .title {
        margin-left: 20px;
        @include typography.h3;
        color: #{default-theme.$restoplus-dark};
      }

      .close-icon {
        margin-right: 20px;
        width: 15px;
        cursor: pointer;
      }
    }

    .upsell-items {
      margin: 20px;
      overflow: hidden scroll;
      max-height: calc(75vh - 170px);
      display: grid;
      gap: 18px;
    }

    .actions {
      padding: 20px;
      position: absolute;
      bottom: 0;
      left: 0;
      width: calc(100% - 40px);

      button {
        width: 100%;
      }
    }
  }

}