@use "../viewport" as viewport;
@use "../themes/default" as default-theme;
@use "../typography" as typography;

@include viewport.mobile {
  .form-select-pax {
    border: 1px solid #{default-theme.$material-gray-300};
    box-sizing: border-box;
    border-radius: 8px;
    background: white;
    padding: 20px 16px 16px 16px;

    .label {
      @include typography.h4;
      color: #{default-theme.$restoplus-dark};
      margin-bottom: 4px;
    }

    .sub-text {
      @include typography.caption-regular;
      color: #{default-theme.$material-gray-600};
      margin-bottom: 12px;
    }

    .options {
      display: grid;
      grid-template-columns: minmax(100px, min-content) min-content min-content;
      column-gap: 16px;
      align-items: center;

      .pax {
        @include typography.body-regular;
        color: #{default-theme.$restoplus-dark};
        text-align: center;
        padding: 15px 0px;
        border: 1px solid #{default-theme.$material-gray-300};
        border-radius: 8px;
      }
    }
  }
}
