@use "../typography"as typography;
@use "../constants"as constants;
@use "../viewport"as viewport;
@use "../themes/default" as default-theme;

@include viewport.mobile {
  .footer-widget {
    display: none;
  }
}
