@use "../card"as card;
@use "../constants"as constants;
@use "../form"as form;
@use "../viewport"as viewport;
@use "../buttons"as buttons;

$padding-horizontal: 15px;

.order-settings-page {
  .body {
    display: grid;
    grid-gap: 20px;
    @include viewport.tablet {
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    }
  }

  .checkout-settings,
  .order-settings,
  .printer-settings {
    form {
      padding: 20px 0;
    }
    .title {
      @include card.header();
      @include card.header-font();
    }

    @include card.container();

    .form-item {
      padding: 0 $padding-horizontal;
    }

    .actions {
      @include card.footer();
      @include buttons.button-group();
      button {
        margin-right: 10px;
      }
    }
  }
}
