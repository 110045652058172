@use "../card"as card;
@use "../constants"as constants;
@use "../coupon" as coupon;
@use "../form" as form;
@use "../typography"as typography;
@use "../buttons"as buttons;
@use "../utils"as utils;
@use "../selection_list"as selection_list;
@use "../viewport"as viewport;
@use "../themes/default" as default-theme;
@use "../scrollbar" as scrollbar;

$padding-horizontal: 20px;

@mixin section-body {
  padding: 5px $padding-horizontal 10px;
}

@mixin section-footer {
  display: grid;
  grid-template-columns: min-content;
  grid-auto-columns: min-content;
  grid-auto-flow: column;
  grid-gap: 10px;
  margin-top: 20px;
  margin-bottom: 15px;
  align-items: center;

  @include buttons.compact();

  button {
    img {
      margin-right: 10px;
    }

    span {
      white-space: nowrap;
      color: #426ed9;
    }
  }
  a {
    @include typography.button2;
    text-decoration: none;
    margin-left: 5px;
    color: var(--color-secondary);
    white-space: nowrap;
  }
}

@mixin note {
  @include typography.caption-regular;
  text-align: left;
  letter-spacing: unset;
  margin: unset;
  text-transform: unset;
  color: #{default-theme.$restoplus-dark};
  padding: 0 15px;
  margin-bottom: 15px;
  margin-top: 15px;

  .learn-more {
    color: #{default-theme.$orange-dark};
    cursor: pointer;
    white-space: nowrap;
  }
}

@include viewport.mobile {
  .confirm-dialog {
    &.discounted-items-alert {
      .swal2-content {
        padding: 0;
      }

      .swal2-title {
        text-align: left;
        @include typography.h4;
      }

      .swal2-html-container {
        text-align: left;
        margin: 0 30px;
        font-size: 14px;
      }
    }
  }

  .checkout-widget {
    padding: 0 !important;
    width: 100%;
    display: grid;

    &.keyboard-visible {
      //transform: translate(0, -20%);
      margin-bottom: 100px;
    }

    .spinner-container {
      margin-top: 120px;
    }

    .checkout-header {
      position: fixed;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 60px;
      box-shadow: inset 0px -1px 0px #f0f0f0;
      background-color: #{default-theme.$bg};
      border-radius: 16px 16px 0 0;
      z-index: 200;

      .header-text {
        @include typography.h3;
        text-align: left;
        margin-left: 20px;
        color: #{default-theme.$restoplus-dark};
      }
      .close-icon {
        position: absolute;
        right: 25px;
        width: 15px;
        cursor: pointer;
      }
    }

    .alert {
      margin: 10px 0;
      box-shadow: none;
    }

    form {
      padding-top: 0 !important;
      .form-items {
        grid-gap: 10px;

        .form-input {
          input {
            height: 53px;
            @include typography.body-regular;
          }
          textarea {
            padding-left: 15px;
            word-break: break-word;
            @include scrollbar.hide;
            padding-top: 18px;
            @include typography.body-regular;
            text-align: left;
          }

          ::-webkit-input-placeholder {
            color: #{default-theme.$material-gray-600};
          }
          ::-moz-placeholder {
            /* Firefox 19+ */
            color: #{default-theme.$material-gray-600};
          }
          :-ms-input-placeholder {
            /* IE 10+ */
            color: #{default-theme.$material-gray-600};
          }
          :-moz-placeholder {
            /* Firefox 18- */
            color: #{default-theme.$material-gray-600};
          }
        }
      }
    }

    .step-buttons {
      display: grid;
      grid-gap: 0;
      grid-auto-flow: row;

      .button {
        width: 100%;
        padding: 20px;
        margin-bottom: 10px;
        @include typography.button1;
        text-align: center;
        text-transform: uppercase;
      }

      button.previous {
        @extend .button;
        background-color: transparent;
        color: #{default-theme.$material-gray-600};
        box-shadow: none;
      }

      button.continue {
        @extend .button;
        background-color: #{default-theme.$restoplus-teal};
      }

      button.place-order {
        margin-top: 10px;
        @extend .button;
        background-color: #{default-theme.$restoplus-teal};
      }
    }

    .sections {
      @include card.container;
      margin-bottom: 30px;
      margin-top: 60px;
    }

    .section {
      height: auto;
      min-height: 47vh;
      padding-top: 8px;

      > .header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-bottom: 33px;

        .title {
          display: grid;

          .icon {
            margin: 0 auto;
            background-repeat: no-repeat;
            background-size: contain;
            height: 61px;
            width: 61px;
            padding-bottom: 15px;
          }

          .customer-display-name {
            background-image: url('../images/customer-name-icon.svg');
          }

          .coupon {
            background-image: url('../images/coupon-icon.svg');
          }

          .comment {
            background-image: url('../images/comment-icon.svg');
          }

          .payment {
            background-image: url('../images/payment-icon.svg');
          }

          .header {
            text-align: center;
            @include typography.h4;
            color: #{default-theme.$restoplus-dark};
            margin-bottom: 10px;
          }

          .subtitle {
            margin: 0 auto;
            width: 80%;
            text-align: center;
            @include typography.body-regular;
            color: #{default-theme.$material-gray-600};
          }
        }

        .title-add-action {
          display: flex;
          flex-direction: row;
          align-items: center;

          img {
            margin-right: 8px;
          }

          span {
            font-family: Fira Sans;
            @include typography.caption-regular;
            text-align: right;
            color: #426ed9;
          }
        }

        .title-value {
          font-family: Fira Sans;
          @include typography.caption-regular;
          text-align: right;
          color: #426ed9;
          &.close {
            color: var(--color-secondary);
          }
        }
      }
    }

    .items {
      .cart-item {
        padding: 0 $padding-horizontal 12px $padding-horizontal;
        border-bottom: constants.$line-dashed;
        margin-bottom: 20px;

        &:last-child {
          border-bottom: none;
          margin-bottom: 0px;
        }

        > .header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .name {
            @include typography.body-regular;
            color: #000521;
            word-break: break-all;
          }
        }
        > .footer {
          display: grid;
          grid-template-columns: 1fr min-content;
          justify-content: space-between;
          align-items: flex-end;
          margin: 8px 0px;

          .price {
            @include typography.h4;
            color: #fa7268;
          }

          .quantity {
            display: flex;
            flex-direction: row;
            align-items: center;

            .number {
              @include typography.body-regular;
              color: #000521;
            }

            .icon {
              cursor: pointer;
            }

            .dec {
              padding-right: 10px;
            }

            .inc {
              padding-left: 10px;
            }

            .icon {
              color: #8d92a3;
              font-size: 24px;
            }
          }
        }

        .summary {
          align-self: start;
          word-break: break-all;

          pre {
            overflow-x: auto;
            white-space: pre-wrap;
            word-wrap: break-word;
            @include typography.caption-regular;
            color: #8d92a3;
            margin: 0 !important;
          }
        }
      }
    }

    .amounts {
      padding: 10px $padding-horizontal;
      .amount {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 5px 0;

        font-family: Fira Sans;
        @include typography.body-regular;
        color: #565c65;
      }
      // .discount {
      //   div::before {
      //     content: '- ';
      //   }
      // }
      .grand-total {
        @include typography.h4;
        margin-top: 10px;
      }
    }

    .add-delivery-address {
      @include section-body();

      .delivery-suburbs {
        display: grid;
        margin-top: 10px;
        margin-bottom: 5px;

        .delivery-suburb {
          @include selection_list.item();
          grid-template-columns: min-content 1fr min-content;
          .charge {
            white-space: nowrap;
          }
        }
      }
    }

    .saved-addresses {
      @include section-body();
      .saved-address {
        @include selection_list.item();
        grid-template-columns: min-content 1fr min-content min-content !important;
      }

      .radio-button {
        top: 10px;
        position: relative;
      }

      .address {
        padding-top: 10px;
      }

      .icon {
        color: #8d92a3;
        font-size: 24px;
        cursor: pointer;
      }
    }

    .no-comments {
      text-align: center;
      padding: 50px 0;
    }

    .add-new-comment {
      @include typography.body-bold;
      text-align: left;
      color: #{default-theme.$restoplus-teal};
      padding: 10px 0px;
    }

    .saved-comments {
      padding-bottom: 10px;
      height: auto;
      .saved-comment {
        display: grid;
        grid-template-columns: 1fr min-content min-content;
        padding: 11px;
        padding-right: 5px;
        background: #{default-theme.$bg};
        border-radius: 8px;
        margin-bottom: 3px;
        overflow-wrap: anywhere;
        &.valid {
          border: 1px solid #{default-theme.$material-gray-300};
        }
        &.invalid {
          border: 1px solid #{default-theme.$form-element-error};
        }
      }
      .error-message {
        font-size: 12px;
        color: #{default-theme.$form-element-error};
      }

      .comment {
        align-self: center;
        padding-left: 7px;
        //styleName: Subtitle 2;
        @include typography.body-regular;
        text-align: left;
        color: #{default-theme.$restoplus-dark};
      }

      .icon {
        height: 28px;
        width: 28px;
        cursor: pointer;
      }

      .remove {
        background-image: url('../images/trash-icon.svg');
        background-repeat: no-repeat;
        background-position: center;
      }

      .edit {
        background-image: url('../images/pen-icon.svg');
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    .payment {
      margin-bottom: -10px;
    }

    .payment-types {
      display: grid;
      grid-gap: 6px;

      .payment-type {
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        padding: 14px;
      }
    }

    .redeem-coupon,
    .payment {
      .header {
        padding-bottom: 13px;
      }
    }

    .use-text {
      @include typography.button2;
      text-align: center;
      color: #{default-theme.$restoplus-teal};
      margin: 34px 0;
    }

    .coupons {
      margin: 0;

      .recommended-coupon {
        @include typography.caption-bold;
        letter-spacing: 1.5px;
        text-align: left;
        text-transform: uppercase;
        color: #{default-theme.$orange-dark};
        margin: 15px 0 5px 20px;
      }

      .note {
        @include note();
      }
    }

    .suggested-coupon {
      @include coupon.coupon-body(#{default-theme.$border-gray}, #{default-theme.$bg});

      .content {
        display: grid;
        align-items: center;
        grid-template-rows: repeat(3, min-content);
        grid-template-columns: 1fr min-content;
        grid-template-areas:
          'name          actions'
          'description   actions'
          'discount      actions';
        gap: 0px 5px;
        color: #{default-theme.$restoplus-dark};
        background-color: #{default-theme.$bg};
        margin-left: 10px;

        .name {
          grid-area: name;
          font-family: 'IBM Plex';
          @include typography.caption-bold;
          text-align: left;
          color: #{default-theme.$restoplus-dark};
          margin-top: 22px;
          overflow: hidden;
          -o-text-overflow: ellipsis;
          text-overflow: ellipsis;
        }

        .icon {
          grid-area: icon;
          position: absolute;
          width: 43px;
          top: -10px;

          &.available {
            right: 0;
          }
        }

        .description {
          grid-area: description;
          min-height: 50px;
          overflow: hidden;
          @include typography.caption-regular;
          text-align: left;
          color: #{default-theme.$material-gray-600};
          margin-top: 8px;
          margin-bottom: 8px;
        }

        .discount {
          grid-area: discount;
          @include typography.caption-regular;
          text-align: left;
          color: #{default-theme.$restoplus-dark};
          text-transform: lowercase;
          margin-bottom: 18px;
        }

        .actions {
          grid-area: actions;

          button {
            padding: 4px 14px;
            margin-right: 15px;
            @include typography.button2;
            color: #{default-theme.$restoplus-teal};
            background-color: #{default-theme.$bg};
            border: 2px solid #{default-theme.$restoplus-teal};
            border-radius: 8px;
          }
        }
      }

      &.used {
        filter: grayscale(1);
        color: #{default-theme.$restoplus-dark};
      }

      .code {
        grid-area: code;
        font-family: 'IBM Plex';
        @include typography.caption-regular;
      }

      .availability {
        grid-area: availability;
        text-transform: uppercase;
        @include typography.caption-regular;
        color: #{default-theme.$restoplus-teal};
        letter-spacing: 1px;
      }
    }

    .applied-coupon {
      @include coupon.coupon-body(#{default-theme.$restoplus-teal}, #{default-theme.$restoplus-light-green});

      .content {
        display: grid;
        align-items: center;
        grid-template-rows: min-content 1fr min-content min-content;
        grid-template-columns: 1fr min-content;
        margin-left: 10px;
        grid-template-areas:
          'name      icon'
          'desc      icon'
          'discount  icon'
          'actions   icon';

        .name {
          grid-area: name;
          font-family: 'IBM Plex';
          @include typography.caption-bold;
          text-align: left;
          color: #{default-theme.$restoplus-dark};
          margin-top: 13px;
          overflow: hidden;
          -o-text-overflow: ellipsis;
          text-overflow: ellipsis;
        }

        .description {
          grid-area: desc;
          min-height: 36px;
          overflow: hidden;
          @include typography.caption-regular;
          margin-top: 5px;
          margin-bottom: 5px;
          color: #{default-theme.$material-gray-600};

          .learn-more {
            color: #{default-theme.$green-dark};
            cursor: pointer;
            letter-spacing: 0;
            white-space: nowrap;
          }
        }

        .discount {
          grid-area: discount;
          @include typography.caption-regular;
          text-align: left;
          color: #{default-theme.$restoplus-dark};
          text-transform: uppercase;
        }

        .icon {
          grid-area: icon;
          width: 16px;
          height: 16px;
          background-color: #{default-theme.$restoplus-teal};
          border-radius: 50%;
          padding: 8px;
          margin-right: 22px;
          margin-left: 22px;
        }

        .actions {
          grid-area: actions;

          button {
            @include typography.button3;
            text-align: left;
            margin-top: 16px;
            margin-bottom: 13px;
            padding: 0;
            color: #{default-theme.$restoplus-teal};
            background-color: rgba(239, 248, 249, 255);
            box-shadow: none;
          }
        }
      }
    }

    .summary-title {
      padding: 0 $padding-horizontal 12px $padding-horizontal;
      margin: 0;
      @include typography.h4;
      color: #000521;
    }

    .summary-subTitle {
      padding: 0 $padding-horizontal 12px $padding-horizontal;
      margin: 0;
      @include typography.body-regular;
      color: #000521;
    }

    .collection-info {
      .collection-type {
        @extend .summary-title;
      }

      .delivery-address,
      .collection-date-time {
        @extend .summary-subTitle;
      }
    }

    .payment {
      .selected-payment-type {
        @extend .summary-subTitle;
      }
    }

    .display-name {
      .name {
        @extend .summary-subTitle;
      }
    }

    .mobile-number {
      .number {
        @extend .summary-subTitle;
      }
    }

    .credential-already-in-use,
    .mobile-update-widget,
    .unlink-mobile-number,
    .add-credit-card,
    .add-comments,
    .custom-coupon,
    .add-delivery-address,
    .update-display-name {
      @include section-body();
      .message {
        padding-left: constants.$curve-padding;
      }
      .actions {
        @include section-footer();
      }

      b {
        color: #426ed9;
        font-weight: 400;
        white-space: nowrap;
      }

      .warning-text {
        color: var(--color-secondary);
        font-size: 10.5px;
        margin-left: 4px;
      }
    }

    .custom-coupon {
      padding: unset;
      display: grid;
      grid-template-areas:
        'input   button'
        'note    note';
      grid-template-columns: 1fr min-content;

      form .default {
        grid-area: input;
      }

      input {
        border-radius: 8px 0 0 8px;
      }

      .apply-button {
        position: relative;
        border: 1px solid #{default-theme.$material-gray-300};
        border-left: none;
        cursor: pointer;
        border-radius: 0 8px 8px 0;
        display: flex;
        height: calc(100% - 2px);
        justify-content: center;
        align-items: center;
        padding: 0px 22px;
        @include typography.button1;
        background-color: #{default-theme.$material-gray-100};
      }

      .note {
        @include note();
        grid-area: note;
        margin: 10px 0;
      }
    }

    .ineligible-coupon {
      margin: 5px 0px;
      display: grid;
      grid-template-areas:
        'icon     description'
        '.        reset';
      grid-template-columns: min-content 1fr;
      gap: 15px 10px;
      padding: 19px 20px 20px 19px;
      background: #fef4f0;

      .error.icon {
        grid-area: icon;
      }

      .description {
        grid-area: description;
        @include typography.body-regular;
        text-align: left;
        color: #{default-theme.$restoplus-dark};
      }

      .reset {
        grid-area: reset;
        @include typography.button3;
        text-align: left;
        color: #{default-theme.$red-dark};
        text-transform: uppercase;
      }
    }

    .add-comments {
      padding: unset;
      .actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding-top: 10px;
        margin: 0;
        .cancel {
          @include typography.button2;
          text-align: left;
          color: #{default-theme.$material-gray-600};
        }
        .clear {
          @include typography.button2;
          text-align: center;
          color: #{default-theme.$restoplus-teal};
        }
        button {
          border: none;
          box-shadow: none;
          background-color: transparent;

          span {
            color: #{default-theme.$restoplus-teal};
          }
        }
      }
    }

    .mobile-number {
      .name {
        @include section-body();
      }
    }

    > .alert {
      margin: 5px $padding-horizontal 20px;
    }

    .StripeElement {
      margin-bottom: 10px;
    }

    .powered-by-stripe {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding-bottom: 10px;
      padding-top: 10px;

      .logo {
        padding-right: 10px;
        img {
          width: 50px;
        }
      }
      .description {
        // @include form.help-text();
        @include typography.caption-regular;
        text-align: left;
        color: #{default-theme.$material-gray-500};
        padding-right: 10px;
      }
    }

    .sections {
      margin-bottom: 0 !important;
      box-shadow: none !important;
      border: none !important;
      padding: 0 constants.$horizontal-gap;
    }

    .step-buttons {
      padding: 20px 0px 5px 0px;

      button {
        padding: 15px !important;
      }
    }

    .order-view-widget {
      margin-top: 16px;
      .amounts {
        display: grid;
        row-gap: 10px;
        padding: 0;

        > div {
          display: grid;
          grid-template-columns: 1fr min-content;
          grid-template-rows: max-content;

          .label,
          .value {
            @include typography.body-regular;
            color: #{default-theme.$material-gray-600};
          }
        }

        > div:last-child {
          margin-top: 8px;

          .label,
          .value {
            @include typography.body-bold;
            color: #{default-theme.$restoplus-dark};
          }
        }
      }
    }
  }
}
