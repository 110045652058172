@use '../viewport' as viewport;
@use '../themes/default' as default-theme;
@use '../constants' as constants;
@import './common-styles-mobile';
@import './_base-item-widget-mobile';

@include viewport.mobile {
  .basic-item-widget {
    @include item-widget();
    @include base-item-widget();

    .modifiers {
      grid-gap: 35px;
      margin: 4px 0px 32px 0px;
    }

    .actions {
      @include item-actions();
    }

    .spinner-container {
      @include item-customisation-loading();
    }
  }
}
