@use '../viewport' as viewport;
@use '../themes/default' as default-theme;
@use '../constants' as constants;
@use '../typography' as typography;
@import './common-styles-desktop';

@include viewport.tablet {
  .suburbs-list-widget {
    @include session-create-widget-title-and-image();
    padding: #{constants.$horizontal-gap-desktop};
    padding-top: 0;
    display: grid;
    grid-template-areas:
      'title             image'
      'suburb-search     suburb-search'
      'suburb-list       suburb-list';
    grid-template-columns: 1fr max-content;
    background: #{default-theme.$bg};

    .suburb-search {
      grid-area: suburb-search;
      position: relative;

      .search-icon {
        position: absolute;
        z-index: 1;
        top: 17px;
        left: 15px;
        opacity: 0.55;
        width: 16px;
      }

      input {
        border-radius: 8px;
        border: 1px solid #{default-theme.$material-gray-300};
        padding: 15px 42px;
        margin-bottom: 8px;
        outline: none;
        width: -webkit-fill-available;
        @include typography.body-regular;
        color: #{default-theme.$restoplus-dark};
      }
    }

    .suburbs {
      @include modal-scrollable-content-with-inner-shadow();
      max-height: calc(100vh - 474px);
      grid-area: suburb-list;
      display: grid;
      gap: 9px;

      .no-suburb-found {
        @include typography.body-regular;
        color: #{default-theme.$material-gray-600};
        text-align: center;
        margin-top: 30px;
      }

      .suburb {
        cursor: pointer;
        display: grid;
        grid-template-areas: 'name postcode . delivery-charge caret-right-icon';
        grid-template-columns: max-content min-content 1fr min-content min-content;
        padding: 16px 17px 17px;
        border: 1px solid #{default-theme.$material-gray-300};
        border-radius: 8px;
        @include typography.body-regular;
        align-items: center;

        .name {
          grid-area: name;
          color: #{default-theme.$restoplus-dark};
          font-weight: 400;
        }

        .postcode {
          grid-area: postcode;
          font-weight: 400;
          color: #{default-theme.$material-gray-600};
          margin-left: 8px;
        }

        .delivery-charge {
          grid-area: delivery-charge;
          color: #{default-theme.$material-gray-600};
          white-space: nowrap;
          margin-right: 8px;
        }

        .caret-right.icon {
          grid-area: caret-right-icon;
        }
      }
    }
  }
}
