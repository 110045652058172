@use "../viewport" as viewport;
@use "../themes/default" as default-theme;
@use "../typography" as typography;

@include viewport.tablet {
  .store-trading-hours-page {
    > .header {
      margin-top: -4px;
      .wrapper {
        > .title {
          @include typography.h1;
          text-align: center;
          // color: black !important;
        }
      }
    }
  }
  .trading-hours-page {
    max-width: 630px;
    margin: 0 auto;
    padding-bottom: 90px;
    // padding-bottom: max(50px, var(--safe-area-inset-bottom));
    background-color: #{default-theme.$material-gray-50};

    .store-logo-and-address {
      display: grid;
      grid-template-areas:
        'logo  find-us'
        'logo  need-help';
      grid-template-columns: 200px 1fr;
      grid-template-rows: min-content 1fr;
      margin-top: 36px;
      margin-bottom: 12px;
      border: 1px solid #{default-theme.$material-gray-300};
      box-sizing: border-box;
      box-shadow: 0px 0px 8px rgb(49 48 62 / 10%);
      border-radius: 8px;
      background-color: #{default-theme.$bg};

      .restaurant-logo {
        grid-area: logo;

        img {
          border-radius: 8px 0 0 8px;
        }
      }

      .info {
        &.find-us {
          grid-area: find-us;
          margin: 33px 29px;
          margin-bottom: 0;
        }

        &.need-help {
          word-break: break-all;
          grid-area: need-help;
          margin: 20px 29px;
        }

        .title {
          @include typography.overline;
          color: #{default-theme.$restoplus-dark};
          margin-bottom: 10px;
        }

        .text {
          @include typography.body-regular;
          color: #{default-theme.$material-gray-600};

          .link {
            color: #{default-theme.$restoplus-teal};
            cursor: pointer;
          }
        }
      }
    }

    .date-and-trading-hours-today {
      display: grid;
      padding-top: 18px;

      .date-today {
        margin: 0 auto;
        display: grid;
        grid-gap: 5px;
        margin-bottom: 30px;

        .trading-hours-label {
          @include typography.overline;
          text-align: center;

          text-align: center;
          color: #{default-theme.$restoplus-dark};
        }

        .date {
          @include typography.h3;
          text-align: center;
          color: #{default-theme.$restoplus-dark};
        }

        .closed-banner {
          background: #{default-theme.$red-light};
          color: #{default-theme.$red-dark};
          padding: 16px 20px;
          margin-top: 40px;
          border-radius: 8px;
          min-width: 582px;

          @include typography.caption-regular;

          display: inline-flex;
          flex-direction: row;

          .closed-label {
            font-weight: bold;
            margin-right: 0.25em;
          }
        }
      }

      .collection-type-trading-hours-today {
        width: 100%;
        // padding-top: 15px / 50px * 100%;
        padding: 30px 10px 30px 10px;
        display: grid;
        grid-template-rows: auto 1fr 1fr;
        background-color: #{default-theme.$bg};
        border-radius: 8px;
        border: 1px solid #e0e0e0;
        box-sizing: border-box;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

        .collection-type {
          padding-top: 23px;
          text-align: center;
          @include typography.h3;
          color: #40415b;
        }

        .logo {
          background-repeat: no-repeat;
          margin: 0 auto;
          position: relative;
          background-size: cover;
          height: 62px;
          width: 62px;
        }

        .logo.pickup {
          background-image: url('../images/pickup.svg');
        }

        .logo.delivery {
          background-image: url('../images/delivery.svg');
          margin-bottom: -2px;
        }

        .logo.dineIn {
          background-image: url('../images/dine-in.svg');
        }

        .sessions {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 8px;

          .unavailable {
            @include typography.body-regular;
            text-align: center;
            color: #{default-theme.$red-dark};
          }

          .available {
            @include typography.body-regular;
            text-align: center;
            color: #{default-theme.$restoplus-dark};
          }
        }
      }

      .date-today.closed {
        @include viewport.tablet {
          border-bottom: unset;
        }
      }
    }

    .date-and-trading-hours-today.closed {
      @include viewport.tablet {
        grid-auto-flow: column;
      }
    }

    .trading-hours-today {
      display: flex;
      grid-gap: 20px;
      margin-bottom: 15px;
    }

    .trading-hours-today.closed {
      .unavailable {
        margin-bottom: 0 !important;
      }
    }

    // .trading-hours-today {
    //   padding: 20px;
    // }

    .label {
      width: fit-content;
      padding: 3px 10px 3px 3px;
      border-radius: 8px;
      margin-bottom: 10px;
      @include typography.caption-regular;
      display: grid;
      align-items: center;
      grid-auto-flow: column;
      grid-gap: 7px;
    }

    // .trading-hours-today .unavailable {
    //   @extend .label;
    //   background-color: #ffeeee;
    //   color: #fc5a5a;
    //   & i {
    //     font-size: 23px;
    //   }
    // }

    // .trading-hours-today .available {
    //   @extend .label;
    //   background-color: #f4f9ed;
    //   & i {
    //     color: #82c43c;
    //     font-size: 23px;
    //   }
    // }

    .collection-type-selector {
      padding: 0 17px;
      margin: 20px 0 40px 0;

      .collection-types-tab-selector {
        display: flex;
        justify-content: center;

        .collection-type {
          @include typography.body-regular;
          text-align: center;
          color: #{default-theme.$material-gray-600};
          padding: 10px 8px;
          box-shadow: inset 0px -1px 0px #{default-theme.$border-light};
          cursor: pointer;

          &.selected {
            font-weight: 700;
            color: #{default-theme.$restoplus-dark};
            border-bottom: 1px solid #{default-theme.$restoplus-dark};
          }
        }
      }
    }

    .trading-hours {
      background-color: white;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      border: 1px solid #e0e0e0;
    }

    .time-item {
      display: grid;
      grid-template-columns: max-content 1fr;
      grid-template-rows: 1fr;
      grid-gap: 10px;
      grid-row-gap: 5px;
      border-bottom: 1px solid #{default-theme.$material-gray-300};
      padding: 5px 23px;

      &:last-child {
        border-bottom: none;
      }

      .dow {
        padding: 10px 0;
        @include typography.body-bold;
        text-align: left;
        color: #{default-theme.$restoplus-dark};
      }
      .session {
        justify-items: end;
        text-align: right;
        padding-right: 5px;
        padding: 10px 0;
        @include typography.body-regular;
        text-align: right;
        color: #{default-theme.$restoplus-dark};

        &.closed {
          color: #{default-theme.$red-dark};
        }
      }
    }
  }
}
