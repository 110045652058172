@use "../viewport" as viewport;
@use "../constants" as constants;
@use "../themes/default" as default-theme;
@use "../typography" as typography;

@include viewport.tablet {
  .table-booking-edit-contact-details-widget {
    .booking-details-banner {
      display: grid;
      grid-template-areas:
        'booked-for edit'
        'pax pax'
        'seating-area seating-area';
      grid-template-columns: 1fr min-content;
      background: #{default-theme.$orange-light};
      padding: 23px 24px 22.39px 24px;

      .booked-for {
        grid-area: booked-for;
        @include typography.body-bold;
        color: #{default-theme.$orange-dark};
      }

      .edit {
        grid-area: edit;
        @include typography.caption-bold;
        color: #{default-theme.$orange-dark};
      }

      .pax {
        grid-area: pax;
        @include typography.body-regular;
        color: #{default-theme.$restoplus-dark};
      }

      .seating-area {
        grid-area: seating-area;
        @include typography.body-regular;
        color: #{default-theme.$restoplus-dark};
      }
    }

    .form-group {
      .section-name {
        @include typography.body-bold;
        color: #{default-theme.$restoplus-dark};
        margin-bottom: 24px;
      }
      .notes-character-limit {
        .notes-length {
          position: relative;
          top: -16px;
          float: right;
          color: #7d7e92;
          font-size: 12px;
        }
      }
    }

    .form-buttons {
      margin-top: 20px;
    }
  }
}
