@use '../card'as card;
@use '../constants'as constants;
@use '../typography'as typography;
@use '../viewport'as viewport;
@use '../utils'as utils;
@use '../themes/default'as default-theme;
@import './common-styles-desktop';

@include viewport.tablet {
  .online-ordering .menu-page {
    > .header {
      padding: 0;
      .wrapper {
        position: relative;

        .nav {
          display: none;
        }
        .banner-image {
          position: relative;
          max-width: constants.$max-body-width;
          .mobile {
            display: none;
          }
          .restaurant-info {
            z-index: 1;
            flex-direction: column;
            justify-content: center;
            grid-gap: 43px;
            position: absolute;
            margin: auto;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            display: none;

            &.desktop-banner .restaurant-logo {
              display: none;
            }

            &.desktop-banner-default {
              display: flex;
            }

            .restaurant-logo {
              width: 162px;
              margin: 0 auto;
              background-color: #{default-theme.$bg};
              border-radius: 24px;

              > .image {
                border-radius: 24px;
                overflow: hidden;
              }
            }
            .store-title {
              color: white;
              text-align: center;
              box-sizing: border-box;

              .store-name {
                @include typography.h1;
                margin-bottom: 6px;
              }
              .store-location {
                @include typography.caption-regular;

                .icon {
                  margin-right: 10px;
                  margin-bottom: -3px;
                }
              }
            }
          }
        }
      }
    }

    > .body > .menu {
      display: grid;
      grid-template-columns: minmax(min-content, 229px) 1fr minmax(min-content, 299px);
      grid-template-rows: 1fr max-content;
      grid-template-areas:
        'collection-type-selector collection-type-selector collection-type-selector'
        'session-view-widget      session-view-widget      session-view-widget'
        'store-closed-info        store-closed-info        store-closed-info'
        'categories               items                    cart-summary';

      .collection-type-selector-widget {
        grid-area: collection-type-selector;
      }
      .session-view-widget {
        grid-area: session-view-widget;
      }
      .store-closed-info-widget {
        grid-area: store-closed-info;
      }
      .category-list-widget {
        grid-area: categories;
      }
      .item-list-widget {
        grid-area: items;
        &.no-items-found {
          grid-area: 4 / 1 / span 1 / span 2;
        }
      }
      .cart-summary {
        grid-area: cart-summary;
        padding-left: 20px;
      }
      > #collection-type-selector-widget-anchor {
        position: absolute;
        margin-top: -59px;
      }
    }
  }
}
