@use "../viewport" as viewport;
@use "../themes/default" as default-theme;
@use "../typography" as typography;

@include viewport.mobile {
  .table-ordering-welcome-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    .table-ordering-details {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 20px;

      .optimized-image {
        img {
          height: 100px;
          width: 100px;
        }
      }

      .welcome {
        @include typography.h3;
        color: #{default-theme.$restoplus-dark};
      }

      .table-seat-info,
      .table-ordering-info {
        border: 1px solid #e2e2e2;
        width: 100%;
        border-radius: 8px;
        padding: 30px 0;
        margin-top: 24px;
        margin-bottom: 46px;

        .table-seat-name,
        .table-not-found,
        .dinein-not-available {
          @include typography.h3;
          margin-top: 5px;
          margin-bottom: 3px;
        }

        .table-not-found {
          color: #{default-theme.$red-dark};
        }

        .dinein-not-available {
          color: #{default-theme.$restoplus-dark};
        }

        .table-seat-name {
          color: #{default-theme.$restoplus-teal};
        }

        .table-seat-text {
          @include typography.body-regular;
        }

        .table-not-found-text,
        .dinein-not-available-text {
          @include typography.body-regular;
          padding: 0 16px;
        }
      }
    }

    .actions {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 20px;
      margin-bottom: 14px;

      .start-order-btn,
      .view-menu-btn {
        @include typography.body-regular;
        border-radius: 8px;
        margin-bottom: 17px;
      }

      .view-orders-btn,
      .re-scan-qr-btn {
        @include typography.body-regular;
        border-radius: 8px;
        color: #{default-theme.$restoplus-teal};
        background-color: #{default-theme.$bg};
        border: 2px solid #{default-theme.$restoplus-teal};
        padding: 12px;
      }

      .scan-qr,
      .go-to-menu {
        @include typography.caption-regular;
        color: #{default-theme.$restoplus-teal};
        margin-top: 33px;
      }
    }
  }
}
