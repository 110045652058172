@use '../viewport' as viewport;
@use '../themes/default' as default-theme;
@use '../constants' as constants;
@use '../typography' as typography;
@import './common-styles-desktop';

@include viewport.tablet {
  .deal-item .modifier-widget {
    .header {
      background-color: transparent;
    }

    .options {
      padding: 0 #{constants.$horizontal-gap-desktop};
    }
  }

  .modifier-widget {
    @include item-checkbox();
    @include item-radio-button();
    color: #{default-theme.$restoplus-dark};

    .header {
      padding: 16px #{constants.$horizontal-gap-desktop};
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 0;
      @include typography.h4;
      background-color: #{default-theme.$material-gray-50};

      .title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;

        .modifier-name {
          @include typography.h4;
          margin-right: 8px;
        }

        .requiredness {
          padding: 0px 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #{default-theme.$material-gray-600};
          @include typography.caption-regular;
        }

        .selected::before {
          content: url('../images/green-check.svg');
          padding-right: 5px;
        }

        .required {
          color: #{default-theme.$green-dark};
        }

        .error {
          background-color: #{default-theme.$red-light};
          color: #{default-theme.$red-dark};
        }
      }

      .max-quantity {
        color: #{default-theme.$material-gray-600};
        @include typography.caption-regular;
      }
    }

    .options {
      padding: #{constants.$horizontal-gap-desktop};
      padding-top: 16px;
      display: grid;
      grid-gap: 8px;

      .option {
        display: grid;
        grid-template-columns: 1fr min-content min-content;
        border: 1px solid #{default-theme.$material-gray-300};
        border-radius: 8px;
        cursor: pointer;

        .option-form-input {
          width: 100%;
          padding: 14px;
          padding-right: 8px;
        }

        .wrapper {
          display: flex;
          grid-gap: 20px;
          height: fit-content;
          align-self: center;
          margin-right: 10px;

          .quantity {
            display: flex;
            grid-gap: 10px;
            @include typography.body-regular;

            .inc-icon,
            .dec-icon {
              background: #{default-theme.$material-gray-200};
              width: 24px;
              border-radius: 50%;
              text-align: center;
              color: #{default-theme.$gray-dark};
            }
          }

          .extra-charge {
            @include typography.body-regular;
            white-space: nowrap;
            color: #{default-theme.$material-gray-600};
            align-self: center;
          }
        }

        &.selected {
          border: 1px solid #{default-theme.$restoplus-teal};
        }
      }
    }
  }
}
