@use "../constants" as constants;
@use "../viewport" as viewport;
@use "../themes/default" as default-theme;
@use "../typography" as typography;

@mixin top-nav-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px constants.$horizontal-gap;

  .actions {
    display: flex;
    align-items: center;
    gap: 16px;

    .search-icon,
    .icon,
    svg {
      display: block;
    }
  }
}

@mixin banner-nav-bar {
  width: 100%;
  position: absolute;
  z-index: 1;
  box-sizing: border-box;
}

@mixin banner-nav-bar-title {
  .page-title {
    color: var(--bg);
    @include typography.h4;
  }
}

@include viewport.mobile {
  .top-nav-bar-widget {
    z-index: 100;
    position: sticky;
    top: 0;
    background: linear-gradient(178.96deg, #ececed 0.92%, #ffffff 112.41%);
    box-shadow: inset 0px -1px 0px #e8ecf1;
    width: 100%;

    .wrapper {
      @include top-nav-bar();
      .back-button {
        display: none;
      }

      .store-header-widget {
        .image {
          display: none;
        }
      }

      .menu {
        display: none;
      }

      .actions {
        .cart {
          display: none;
        }
        .sidebar-menu-button {
          svg {
            path {
              stroke: #27283e;
            }
          }
        }
      }
    }

    &.store-closed,
    &.platform-maintenance {
      top: constants.$store-close-banner-height;
    }
  }
}
