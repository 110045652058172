@use '../viewport' as viewport;
@use '../themes/default' as default-theme;
@use '../constants' as constants;
@import './common-styles-desktop';
@import './_base-item-widget-desktop';

@include viewport.tablet {
  .pizza-widget {
    @include item-widget();
    @include base-item-widget();

    .pizza {
      padding: 18px 0;
    }

    .actions {
      @include item-actions();
    }

    .spinner-container {
      @include item-customisation-loading();
    }
  }
}
