@use "../viewport"as viewport;
@use "../typography" as typography;

@include viewport.tablet {
  .spinner-container {
    text-align: center;

    .spinner {
      width: 30px;
      height: 30px;
      margin: 0 auto;
      animation: spin 1s infinite linear;
    }

    .message {
      text-align: center;
      margin-top: 15px;
      @include typography.body-regular;
    }
  }
}
