@use '../viewport'as viewport;
@use '../card' as card;
@use '../themes/default'as default-theme;
@use '../constants' as constants;
@use '../typography' as typography;
@import './common-styles-mobile';

@include viewport.mobile {
  .item-list-widget {
    padding: 0 #{constants.$horizontal-gap} 30px #{constants.$horizontal-gap};

    .category-summary {
      padding: 25px 0;
      display: flex;
      flex-flow: column;
      grid-gap: 10px;
      .link-anchor {
        grid-area: link-anchor;
        width: 100%;
        position: relative;
        top: -170px;
      }
      .name {
        @include typography.h2;
        text-align: left;
        word-break: break-word;
        color: #{default-theme.$restoplus-dark};
      }
      .description {
        @include typography.body-regular;
        margin: 5px 0;
        color: #7d7d7d;
      }
    }

    .items-container {
      display: grid;
      gap: 20px;
      .item.no-image {
        height: auto;
        padding-top: 0;
        .body {
          position: relative;
        }
      }
    }
  }
}
