@use '../card'as card;
@use '../constants'as constants;
@use '../typography'as typography;

.item-spec {
  @include card.container;
  @include card.stack;
  display: grid;
  grid-template-rows: 1fr min-content;
  cursor: pointer;
  background-color: #fafafb;

  .body {
    @include card.upper;
    padding: 12px;
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-template-rows: min-content 1fr min-content;
    grid-template-areas:
      'a d'
      'b d'
      'c d';
  }

  .image {
    overflow: hidden;
    grid-area: d;
    width: 100px;
    margin-left: 10px;

    img {
      border-radius: constants.$border-radius;
    }
  }

  .name {
    @include typography.h2;
    grid-area: a;
    align-self: flex-start;
  }

  .description {
    font-size: 13px;
    grid-area: b;
    align-self: flex-start;
  }

  .price {
    grid-area: c;
    @include typography.h2;
    color: var(--color-secondary);
    align-self: flex-end;
  }

  .actions {
    @include card.lower;
    @include card.round-buttons;

    .action {
      @include card.round-button;
    }
  }
}