@use "../colors";
@use "../viewport" as viewport;
@use "../constants" as constants;
@use "../themes/default" as default-theme;
@use "../typography" as typography;

@include viewport.tablet {
  .order-view-widget {
    display: flex;
    justify-content: space-between;

    .order-summary {
      display: grid;
      row-gap: 9px;

      padding: 33px 31px 26px 31px;
      margin: 0 10px 0px 20px;
      width: 100%;
      height: fit-content;
      background: white;
      border: 1px solid #{default-theme.$material-gray-300};
      box-sizing: border-box;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      border-radius: 8px;

      .title {
        display: flex;
        justify-content: space-between;
        .summary {
          margin-bottom: 16px;
          @include typography.h4;
          color: #{default-theme.$restoplus-dark};
        }
        .order-id {
          color: #9191a1;
          font-size: 13px;
        }
      }

      .collection-type-and-time {
        padding-bottom: 2px;
        .value > * {
          display: inline-block;
        }
      }

      .pickup-info {
        padding-bottom: 5px;
      }

      .delivery-info {
        padding-bottom: 2px;
      }

      .payment-method {
        padding-bottom: 2px;
      }

      .label {
        @include typography.body-bold;
        text-align: left;
        color: #{default-theme.$restoplus-dark};
        margin-bottom: 1px;
      }

      .value {
        @include typography.body-regular;
        text-align: left;
        color: #{default-theme.$restoplus-dark};
        margin-bottom: 4px;
      }

      .payment-method {
        .value {
          display: flex;
        }

        .wallet,
        .brand {
          height: 25px;
          margin-right: 5px;
        }

        .status {
          @include typography.caption-regular;
          color: #{default-theme.$material-gray-600};
        }
      }

      .transaction-fee {
        .status {
          @include typography.caption-regular;
          color: #{default-theme.$material-gray-600};
        }
      }
    }

    .cart-summary {
      padding: 31px 30px 30px 32px;
      margin: 0 20px 0px 10px;
      background: white;
      border: 1px solid #{default-theme.$material-gray-300};
      box-sizing: border-box;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      width: 100%;
      .cart-items {
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid #{default-theme.$material-gray-300};

        > .item-summary-view-widget {
          margin-top: 16px;

          &:first-child {
            margin-top: 0;
          }
        }
      }

      .amounts {
        display: grid;
        row-gap: 9px;
        margin-bottom: 20px;
        > div {
          display: grid;
          grid-template-columns: 1fr min-content;
          grid-template-rows: max-content;

          .label,
          .value {
            @include typography.body-regular;
            color: colors.$blue-gray-900;
          }
        }
        .discount {
          .label {
            overflow: hidden;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
          }
        }
        .grand-total {
          margin: 8px 0;

          .label,
          .value {
            @include typography.h4;
            color: #{default-theme.$restoplus-dark};
          }
        }
      }
    }
  }
}
