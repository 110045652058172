@use "../viewport" as viewport;
@use "../constants" as constants;
@use "../themes/default" as default-theme;
@use "../typography" as typography;

@include viewport.mobile {
  .table-booking-success-page {
    &.page > .header {
      padding: 0px;
      box-shadow: none;
    }

    .body .wrapper {
      .banner {
        text-align: center;
        margin: 40px constants.$horizontal-gap 5px constants.$horizontal-gap;

        .icon {
          margin-bottom: 14px;

          &.desktop {
            display: none;
          }
        }

        .text {
          @include typography.h3;
          color: #{default-theme.$restoplus-dark};
          margin-bottom: 16px;
        }

        .sub-text {
          @include typography.body-regular;
          text-align: center;
          color: #{default-theme.$material-gray-600};
        }
      }

      > .status {
        padding: 24px;
        margin: 0 constants.$horizontal-gap;
        margin-bottom: 24px;

        background: white;
        border: 1px solid #{default-theme.$material-gray-300};
        box-sizing: border-box;
        box-shadow: 0px 2px 4px rgba(146, 129, 129, 0.1);
        border-radius: 8px;

        .progress-bar {
          width: 100%;
          height: 8px; /* TODO verify height */
          background-color: #{default-theme.$material-gray-100};
          display: flex;
          margin-bottom: 16px;

          .progress {
            &.success {
              background-color: #{default-theme.$green-dark};
            }

            &.danger {
              background-color: #{default-theme.$red-dark};
            }

            &.warning {
              background-color: #{default-theme.$orange-dark};
            }
          }
        }

        .caption {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-bottom: 16px;

          .status {
            @include typography.caption-regular;
            text-transform: uppercase;
            color: #{default-theme.$material-gray-600};
          }

          .step {
            @include typography.caption-regular;
            color: #{default-theme.$material-gray-600};
          }
        }

        .text {
          @include typography.body-bold;
          color: #{default-theme.$restoplus-dark};
        }

        .sub-text {
          @include typography.caption-regular;
          color: #{default-theme.$material-gray-600};
        }
      }

      .summary {
        @include typography.body-regular;
        color: #{default-theme.$restoplus-dark};
        background: white;
        border: 1px solid #{default-theme.$material-gray-300};
        box-sizing: border-box;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        margin: 16px constants.$horizontal-gap 24px constants.$horizontal-gap;
        padding: 24px;

        .section-name {
          @include typography.body-bold;
          color: #{default-theme.$restoplus-dark};
        }

        .customer-unverified-email {
          word-break: break-word;
        }

        .contact-details,
        .special-requests {
          margin-top: 15px;
        }
      }

      .buttons {
        margin: 20px;

        .view-menu-button {
          @include typography.button1;
          text-align: center;
          text-transform: uppercase;
          color: white;
          background: #{default-theme.$restoplus-teal};
          border-radius: 8px;
          width: 100%;
          padding: 14px 0px 13px 0px;
        }

        .back-button {
          @include typography.caption-regular;
          text-align: center;
          text-transform: uppercase;
          color: #{default-theme.$material-gray-600};
          background: white;
          border-radius: 8px;
          box-shadow: none;
          width: 100%;
          padding: 14px 0px 13px 0px;
          margin-top: 18px;
        }
      }
    }
  }
}
