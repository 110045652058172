@use "../viewport" as viewport;
@use "../constants" as constants;
@use "../themes/default" as default-theme;
@use "../typography" as typography;
@use "../colors";

@include viewport.mobile {
  .order-success-page {
    background-color: white;

    &.page > .header {
      padding: 0px;
      box-shadow: none;
    }

    .checkout-banner {
      text-align: center;
      margin: 40px constants.$horizontal-gap;

      .icon {
        margin-bottom: 14px;
      }

      .text {
        @include typography.h3;
        color: #{default-theme.$restoplus-dark};
      }
    }

    .order-placed-time {
      margin: 20px constants.$horizontal-gap 0;

      @include typography.caption-regular;
      text-align: center;
      color: colors.$light-gray-900;
    }

    .buttons {
      margin: 0 constants.$horizontal-gap;
      margin-bottom: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      > .customer-review-link-button {
        border: 1px solid colors.$material-gray-300;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
        background-color: colors.$white;
        padding: 20px;
        margin-top: 20px;

        > .label {
          display: flex;
          align-items: center;

          img {
            width: 44px;
            height: 44px;
          }

          span {
            color: colors.$blue-gray-900;
            padding-left: 16px;
          }
        }
      }

      .order-history-button {
        width: 100%;
        background-color: transparent;
        border: 2px solid colors.$teal-500;
        box-shadow: none;
        color: colors.$teal-500;
        margin-top: 20px;
        padding: 14px 50px;
      }

      .back-to-menu-button {
        background-color: transparent;
        border: none;
        box-shadow: none;
        color: colors.$material-gray-500;
        margin-top: 16px;
        padding: 14px 50px;
      }
    }

    .order-success-page-wrapper {
      .order-view-widget {
        margin-left: 20px;
        margin-right: 20px;
      }
      .order-tracking-label {
        margin: 5px constants.$horizontal-gap;
        color: #27283e;
        line-height: 24px;
        font-size: 18px;
        font-weight: 600;
      }
    }
  }

  .loyalty-card-modal {
    background-color: white;

    .loyalty-banner {
      margin: 32px 20px 40px 20px;
      margin-top: 32px;
      text-align: center;

      .icon {
        margin-bottom: 16px;
      }

      .text {
        @include typography.h3;
        text-align: center;
        color: #{default-theme.$restoplus-dark};
        margin-bottom: 8px;
      }

      .subtext {
        @include typography.body-regular;
        text-align: center;
        color: #{default-theme.$material-gray-600};
      }
    }

    .card {
      margin: 0px 20px;
    }

    .caption {
      @include typography.body-regular;
      text-align: center;
      color: #{default-theme.$material-gray-600};
      margin: 30px 20px 20px 20px;
    }

    .button {
      background: #{default-theme.$restoplus-teal};
      border-radius: 8px;
      @include typography.button1;
      text-align: center;
      text-transform: uppercase;
      color: white;
      padding: 14px;
      margin: 0px 20px 16px 20px;
      align-content: flex-end;
    }
  }
}
