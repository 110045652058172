@use '../viewport' as viewport;
@use '../themes/default' as default-theme;
@use '../constants' as constants;
@use "../typography" as typography;
@import './common-styles-mobile';

@include viewport.mobile {
  .third-party-delivery-widget {
    display: flex;
    flex-flow: column;
    grid-gap: 16px;

    a {
      width: 100%;
      height: 52px;
      box-sizing: border-box;
      text-decoration: none;
      border-radius: 8px;
      border: 2px solid #{default-theme.$material-gray-300};
      text-align: center;

      .service-name {
        display: block;
        padding: 12px 0;
        @include typography.body-regular;
        color: #{default-theme.$restoplus-dark};
        border-radius: 8px;
      }
      .service-logo {
        display: block;
        height: 100%;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 14px 0;
      }
      #ubereats {
        padding: 17px 0;
      }
      #deliveroo {
        padding: 8px 0;
      }
    }
  }
}
