@use "../viewport" as viewport;
@use "../constants" as constants;
@use "../themes/default" as default-theme;
@use "../typography" as typography;
@import './common-styles-mobile';

@include viewport.mobile {
  .table-booking-page {
    background: white;

    form {
      .form-single-select-pill {
        &.optional > .label::after {
          content: '(Optional)';
          @include typography.body-regular;
          color: #{default-theme.$material-gray-600};
          margin-left: 8px;
        }
      }

      .form-buttons {
        button {
          @include typography.button1;
          text-align: center;
          text-transform: uppercase;
          color: white;

          background: #{default-theme.$restoplus-teal};
          border-radius: 8px;
          width: 100%;
          padding: 14px;

          &.disabled {
            cursor: unset;
            opacity: 0.5;
            color: white !important;
            background: #{default-theme.$restoplus-teal} !important;
          }
        }
      }
    }
  }

  .table-booking-summary-modal {
    > .header {
      @include item-bottom-sheet-modal-header();

      .close-icon {
        width: 16px;
      }
    }

    .buttons {
      margin: 20px;

      .confirm-button {
        @include typography.button1;
        text-align: center;
        text-transform: uppercase;
        color: white;
        background: #{default-theme.$restoplus-teal};
        border-radius: 8px;
        width: 100%;
        padding: 14px 0px 13px 0px;
      }

      .back-button {
        @include typography.caption-regular;
        text-align: center;
        text-transform: uppercase;
        color: #{default-theme.$material-gray-600};
        background: white;
        border-radius: 8px;
        box-shadow: none;
        width: 100%;
        padding: 14px 0px 13px 0px;
        margin-top: 18px;
      }
    }
  }
}
