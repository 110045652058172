@use '../viewport' as viewport;
@use '../themes/default' as default-theme;
@use '../constants' as constants;
@import './common-styles-desktop';
@import './_base-item-widget-desktop';

@include viewport.tablet {
  .basic-item-widget {
    @include item-widget();
    @include base-item-widget();

    .modifiers {
      margin-top: 30.5px;
    }

    .actions {
      @include item-actions();
    }

    .spinner-container {
      @include item-customisation-loading();
    }
  }
}
