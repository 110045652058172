@use '../themes/default' as default-theme;
@use '../constants' as constants;
@use '../viewport' as viewport;
@use '../typography' as typography;

@include viewport.mobile {
  .add-to-cart-widget {
    display: grid;
    grid-template-columns: 100%;

    .quantity {
      display: flex;
      justify-content: space-between;
      @include typography.body-regular;
      color: #{default-theme.$restoplus-dark};
      margin-bottom: 10px;
      padding: 14px 13px;
      border: 1px solid #{default-theme.$material-gray-300};
      border-radius: 8px;
      background: #{default-theme.$bg};

      .quantity-actions {
        display: flex;
        gap: 18px;

        .inc-icon,
        .dec-icon {
          background: #{default-theme.$material-gray-200};
          width: 24px;
          border-radius: 50%;
          text-align: center;
          color: #{default-theme.$gray-dark};
        }

        .number {
          grid-area: number;
        }
      }
    }

    .total-price {
      display: flex;
      justify-content: space-between;
      @include typography.body-regular;
      color: #{default-theme.$restoplus-dark};
      margin-bottom: 30px;
      padding: 14px 13px;
      border: 1px solid #{default-theme.$material-gray-300};
      border-radius: 8px;
      background: #{default-theme.$bg};
    }

    .add-to-cart-button {
      background: #{default-theme.$restoplus-teal} !important;
      border-radius: 8px;
      @include typography.button1;
      padding: 14px 0;
      color: #{default-theme.$bg};

      &.disabled {
        opacity: 0.5;
        color: #fff !important;
        pointer-events: none;
      }

      span {
        margin-left: 0.25em;
      }
    }
  }
}
