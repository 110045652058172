@use '../viewport'as viewport;
@use '../themes/default'as default-theme;
@use '../typography' as typography;

@include viewport.tablet {
  .store-closed-info-widget {
    height: 30px;
    background: #{default-theme.$red-light};
    padding: 10px 20px;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    @include typography.body-regular;
    text-align: left;
    color: #{default-theme.$message-error-fg};

    span {
      margin-right: 7px;
      font-weight: 700;
    }
  }
}
