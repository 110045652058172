@use "../viewport" as viewport;
@use "../themes/default" as default-theme;
@use "../typography" as typography;

@include viewport.tablet {
  .form-single-select-pill {
    .label {
      @include typography.h4;
      color: #{default-theme.$restoplus-dark};
      margin-bottom: 7px;
    }

    .options {
      &.large {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 8px;
        row-gap: 8px;
      }

      &.small {
        display: flex;
        flex-wrap: wrap;
      }

      .option {
        @include typography.body-regular;
        text-align: center;
        color: #{default-theme.$material-gray-600};
        background: white;

        border: 1.5px solid #{default-theme.$material-gray-300};
        box-sizing: border-box;
        border-radius: 50px;
        cursor: pointer;
        margin: 0px 8px 8px 0px;
        padding: 8.5px 15px;

        &.selected {
          background: #{default-theme.$restoplus-teal};
          color: white;
        }
      }
    }
  }
}
