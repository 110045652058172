@use "../viewport"as viewport;
@use "../themes/default" as default-theme;
@use "../constants" as constants;
@use "../typography" as typography;

@include viewport.tablet {
  .loyalty-card-page {
    padding-bottom: 90px;

    > .spinner {
      margin: 50px;
      text-align: center;
    }

    > .alert {
      margin: constants.$horizontal-gap;
    }

    .loyalty {
      margin: 42px constants.$horizontal-gap;
      display: flex;
      flex-direction: column;

      .loyalty-card-widget {
        align-self: center;
      }


      .claim-info {
        margin: 0 2px;
        margin-top: 58px;
        text-align: center;

        .title {
          @include typography.h3;
          color: #{default-theme.$restoplus-dark};
        }

        .subtitle {
          white-space: pre-wrap;
          @include typography.body-regular;
          color: #{default-theme.$material-gray-600};
          width: 390px;
          margin: 5px auto;
        }
      }
    }
  }
}
