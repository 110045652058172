@use '../viewport' as viewport;

.third-party-delivery {
  text-align: center;
  padding: 30px !important;

  .sub-title {
    color: #8d92a3;
    text-align: center;
  }

  @include viewport.tablet {
    padding: 40px !important;
    .sub-title {
      line-height: 20px;
    }
    .third-party-delivery-services-list > a {
      padding: 10px;
    }
  }

  .third-party-delivery-services-list {
    display: grid;
    place-items: start center;

    a {
      margin: 5px;
      font-weight: 600;
      padding: 8px;
      border-radius: 10px;
      cursor: pointer;
      width: 55%;
      text-decoration: none;
      border: 1px solid #0162ff;
      color: var(--link-fg);

      &:hover {
        background: #0162ff;
        color: white;
      }
    }
  }
}
