@use "../card"as card;
@use "../constants"as constants;
@use "../form"as form;
@use "../buttons"as buttons;

$padding-horizontal: 15px;

.delivery-settings-widget {
  .form-input {
    .delivery-suburb {
      @include card.container;
      @include card.stack;
      margin-bottom: 20px;

      .info {
        @include card.upper;
        @include form.inline;
      }

      .actions {
        @include card.lower;
        @include card.round-buttons;
        .action {
          @include card.round-button;
        }
      }
    }

    .delivery-suburbs {
      button {
        @include buttons.primary-transparent;
      }
    }
  }

  .delivery-suburbs-form-item > label {
    @include form.top-align-label();
  }
}
