@use "../typography"as typography;
@use "../constants"as constants;
@use "../viewport"as viewport;
@use "../utils"as utils;
@use "../buttons"as buttons;
@use "../themes/default" as default-theme;

@include viewport.tablet {
  .page {
    position: relative;
    min-height: calc(100vh - 59px - 250px); /* Viewport height minus header and footer height */
    background: #{default-theme.$material-gray-50};

    @media (max-width: constants.$max-body-width) {
      padding: 0 32px;
    }

    > .header {
      // margin-top: 56px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background: #{default-theme.$material-gray-50};
      padding-top: 102px;
      padding-bottom: 22px;
      width: 100%;
      z-index: 2;

      .wrapper {
        width: 100%;
        max-width: constants.$max-body-width;
        margin: 0 auto;

        .title {
          @include typography.h1;
          text-align: center !important;
          color: #27283e;
        }
      }

      .actions {
        @include buttons.compact;

        a:link {
          text-decoration: none;
        }
      }
    }
    > .body {
      max-width: constants.$max-body-width;
      margin: 0 auto;
      // padding-top: 88px;

      > .spinner {
        margin: 50px;
        text-align: center;
      }
    }
  }
}
