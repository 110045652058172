@use "../viewport";
@use "../constants";
@use "../colors";
@use "../themes/default" as default-theme;
@use "../typography";
@import './common-styles-desktop';

@include viewport.tablet {
  .table-booking-edit-booking-details-widget {
    .form-group {
      .section-name {
        @include typography.body-bold;
        color: #{default-theme.$restoplus-dark};
        margin-bottom: 24px;
      }
    }

    .form-buttons {
      margin-top: 14px;
    }
  }

  .table-booking-date-picker-modal {
    > .header {
      @include modal-header();

      .close-icon {
        width: 16px;
      }
    }

    .note {
      background-color: #{default-theme.$orange-light};
      @include typography.caption-regular;
      color: #{default-theme.$restoplus-dark};
      margin: 0 constants.$horizontal-gap-desktop;
      padding: 10px;

      .store-hours-link {
        color: #{default-theme.$orange-dark};
        cursor: pointer;
      }
    }

    > .date-picker-widget {
      margin: 17px 24px 0px 24px;
    }

    > .date-picker-widget {
      margin: 7px 86px 41px 86px;
    }
  }

  .table-booking-time-picker-modal {
    > .header {
      @include modal-header();

      .close-icon {
        width: 16px;
      }
    }

    .time-of-day-list {
      padding: 32px;
      padding-top: 0px;

      .time-of-day {
        display: flex;
        background: white;
        border: 1px solid #{default-theme.$material-gray-300};
        box-sizing: border-box;
        border-radius: 8px;
        align-items: center;
        margin-bottom: 8px;
        padding: 14.5px 12.5px;
        cursor: pointer;

        input[type='radio'] {
          filter: opacity(0.8);
          margin: 0px 14px 0px 0px;
        }

        .value {
          @include typography.body-regular;
          color: #{default-theme.$restoplus-dark};
        }

        &.unavailable {
          background-color: colors.$light-gray-100;
          border: 1px solid colors.$light-gray-100;

          .value {
            color: colors.$blue-gray-300;
          }
        }
      }
    }
  }
}
