@use "../viewport" as viewport;
@use "../constants" as constants;
@use "../themes/default" as default-theme;
@use "../typography" as typography;

@include viewport.tablet {
  .uber-delivery-info-view-widget {
    padding: 30px 31px 30px 31px;
    margin: 0 constants.$horizontal-gap;
    margin-bottom: 24px;
    background: white;
    border: 1px solid #{default-theme.$material-gray-300};
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    .tracking-map {
      overflow: hidden;

      .map-container {
        width: 100%;
        overflow: hidden;
        height: 310px;
        margin-bottom: 20px;

        > iframe {
          overflow: hidden;
          position: relative;
          width: 170%;
          height: 550px;
          top: -80px;
          left: -12px;
          transform: scale(0.8);
          transform-origin: 0 0;
        }
      }
    }

    .courier-info {
      display: grid;
      align-items: center;
      grid-template-columns: 80px 200px;
      grid-template-areas: 'courier-image courier-additional-info';
      gap: 15px;
      .courier-image {
        grid-area: courier-image;
        img {
          border-radius: 50%;
          width: 100%;
        }
      }
      .courier-additional-info {
        grid-area: courier-additional-info;
        .courier-name {
          font-weight: 700;
        }
        .courier-vehicle {
          color: #7d7e92;
        }

        .courier-phone-number {
          color: #7d7e92;
          font-weight: 700;
        }

        .call-courier {
          color: #{default-theme.$primary};
          text-decoration: none;
          font-weight: 400;
        }
      }
    }
  }
}
