@use '../viewport'as viewport;
@use '../themes/default'as default-theme;
@use '../colors';

@mixin center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@include viewport.tablet {
  .customer-review-page {
    padding: 0;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
    background-color: #{default-theme.$bg};
    font-family: Poppins, sans-serif;

    > .header {
      padding: 0; 
    }

    > .body {
      max-width: unset;

      > .spinner-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: calc(100vh);
      }

      .store-header {
        @include center();
        background-color: colors.$yellow-800;
        padding: 16px 0;
        position: sticky;
        top: 0;
        z-index: 100;
      
        .restaurant-logo {
          padding-right: 16px;

          img {
            width: 48px;
            height: 48px;
            border-radius: 8px;
          }
        }

        .name {
          color: colors.$white;
          font-size: 16px;
        }
      }

      .main-display {
        height: calc(100vh - 80px);

        &.sad-rating-feedback-form {
          height: 100vh;
        }
         
        .rating-selector { 
          @include center();
          flex-direction: column;
          padding-top: 282px;
          transition: opacity 0.5s ease-in, transform 0.5s ease-in;
          position: relative;
          z-index: 2;

          &.hidden {
            opacity: 0;
            transform: translateY(-100px);
            pointer-events: none;
          }
  
          &.show {
            opacity: 1;
            transform: translateY(0);
          }
  
          .message {
            font-weight: 600;
            font-size: 34px;
          }
  
          .selectors {
            margin-top: 40px;
  
            .happy-rating, .sad-rating {
              width: 112px;
              height: 112px;
              cursor: pointer;
            }
  
            .sad-rating {
              margin-right: 24px;
            }
          }
        }

        .happy-rating-feedback-message {
          @include center();
          flex-direction: column;
          margin-top: -459px;
          padding-top: 131px;
          position: relative;
          z-index: 2;
          transition: opacity 0.5s ease-in;

          &.hidden {
            opacity: 0;
            height: 0;
            overflow: hidden;
            pointer-events: none;

            .message {
              transform: scale(1.3);
            }

            .description {
              transform: translateY(-10px);
            }

            .happy-rating-animation, .google-review-button {
              transform: translateY(50px);
            }
          }

          &.show {  
            opacity: 1;
        
            .message {
              transform: scale(1);
            }

            .happy-rating-animation, .description, .google-review-button {
              transform: translateY(0);
            }
          }

          .happy-rating-animation {
            transition: transform 0.5s ease-in;
          }
  
          .message {
            font-size: 24px;
            font-weight: 600;
            padding: 32px 0 22px 0;
            transition: transform 0.5s ease-in;
          }
  
          .description {
            width: 260px;
            font-size: 14px;
            text-align: center;
            transition: transform 0.5s ease-in;
          }
  
          .google-review-button { 
            display: flex;
            background-color: colors.$blue-600;          
            padding: 14px 32px;
            margin-top: 32px;
            text-decoration: none;
            border-radius: 4px;
            transition: transform 0.5s ease-in;
  
            span {
              color: colors.$white;
              font-size: 16px;
              padding-right: 6px;
            }
  
            img {
              width: 60.76px;
              height: 20px;
            }
          }
        }

        .sad-rating-feedback-form {
          @include center();
          flex-direction: column;
          margin-top: -132px;
          padding: 28px 0 60px 0;
          position: relative;
          z-index: 2;
          transition: opacity 0.5s ease-in, transform 0.5s ease-in;

          &.hidden {
            opacity: 0;
            transform: translateY(100px);
            pointer-events: none;
          }
  
          &.show {
            opacity: 1;
            transform: translateY(0);
          }
  
          .title {
            color: colors.$blue-gray-900;
            font-size: 24px;
            font-weight: 600;
            padding: 32px 0 20px 0;
          }
  
          .sub-title {
            color: colors.$blue-gray-900;
            font-size: 14px;
            padding-bottom: 32px;
          }
  
          form {
            > .form-items {
              margin-left: calc(50% - 163px);
  
              .form-item {
                textarea, input {
                  font-family: Poppins, sans-serif;
                  width: 326px;
                  margin-top: 6px;
                }
                
                textarea {
                  height: 128px;
                }
  
                label {
                  color: colors.$blue-gray-500;
                  font-weight: 500;
                }
  
                .meta {
                  display: flex;
                  align-items: center;
                  
                  &::before {
                    content: url('../images/exclamation-circle-icon-filled.svg');
                    width: 12.8px;
                    height: 12.8px;
                    padding-bottom: 2px;
                  }
  
                  .error {
                    padding-left: 5px;
                    color: colors.$rose-500;
                  }
                }
              }
  
              .checkbox {
                color: colors.$blue-gray-900;
                
                input {
                  appearance: none;
                  -webkit-appearance: none;
                  width: 17px;
                  height: 17px;
                  background-color: colors.$material-gray-100;
                  border: 1px solid colors.$material-gray-300;
                  border-radius: 4px;
                  position: relative;
                  cursor: pointer;
                  margin-bottom: 15px;
  
                  &:checked {
                    background-color: colors.$teal-500;
                    border: 1px solid colors.$teal-500;
    
                    &::after {
                      content: "";
                      position: absolute;
                      left: 50%;
                      top: 50%;
                      width: 3px;
                      height: 7px;
                      border: solid white;
                      border-width: 0 2px 2px 0;
                      transform: translate(-50%, -50%) rotate(45deg);
                      transform-origin: center;
                    }
                  }
                }
  
                label {
                  white-space: unset;
                  width: 260px;
                  padding-left: 10px;
                  font-size: 14px;
                  line-height: 21px;
                }
              }
            }
          }
  
          .actions {
            margin-top: 32px;
  
            .submit-button {
              background-color: colors.$blue-600;
              color: colors.$white;
              text-transform: unset;
              font-family: Poppins, sans-serif;
              font-weight: 500;
              font-size: 16px;
              letter-spacing: 1px;
              padding: 14px 80px;
              border-radius: 4px;
            }
  
            .google-review-link {
              display: flex;
              justify-content: center;
              color: colors.$blue-gray-500;
              font-weight: 500;
              font-size: 16px;
              margin-top: 27px;
            }
          }
        }
  
        .sad-rating-feedback-message {
          @include center();
          flex-direction: column;
          margin-top: -885px;
          padding-top: 135px;
          text-align: center;
          position: relative;
          z-index: 2;
          transition: opacity 0.5s ease-in, transform 0.5s ease-in;
  
          &.hidden {
            opacity: 0;
            transform: translateY(-100px);
            pointer-events: none;
          }
  
          &.show {
            opacity: 1;
            transform: translateY(0);
          }

          .message {
            font-size: 24px;
            font-weight: 600;
            padding: 49px 0 22px 0;
          }
  
          .description {
            font-size: 14px;
          }
  
          .google-review-link {
            color: colors.$blue-gray-500;
            font-weight: 500;
            font-size: 16px;
            margin-top: 47px;
          }
        }
      }  

      .happy-rating-message-background, .sad-rating-feedback-form-background, .sad-rating-feedback-message-background {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100vmax;
        height: 100vmax;
        transform-origin: center;
      }

      .happy-rating-message-background {
        background: url('../images/happy-rating-message-background.svg') no-repeat center center/contain;
        transform: translate(-50%, -50%) scale(0) rotate(-50deg);
        transition: transform 0.7s ease-in-out;
        
        &.show {
          transform: translate(-50%, -50%) scale(2) rotate(0deg);
        }
      }

      .sad-rating-feedback-form-background {
        background: url('../images/sad-rating-form-background.svg') no-repeat center center/contain;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.5s ease-in-out;

        &.show {
          transform: translate(-50%, -50%) scale(3);
        }
      }

      .sad-rating-feedback-message-background {
        background: url('../images/sad-rating-message-background.svg') no-repeat center center/contain;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.5s ease-in-out;

        &.show {
          transform: translate(-50%, -50%) scale(3);
        }
      }
    }
  }
}

@media only screen and (max-height: 700px) {
  .customer-review-page {
    > .body {
      .main-display {
        &.sad-rating-feedback-form {
          height: 130vh;
        }
         
        .rating-selector {      
          padding-top: 230px; 
        }

        .sad-rating-feedback-form {
          margin-top: -107px;
        }
      }
    }
  }
}
