@use '../constants' as constants;
@use '../buttons' as buttons;
@use './item_customization_modal' as item_customization_modal;

.basic-item-widget {
  > .title {
    @include item_customization_modal.title;
  }

  .cancel-button {
    @include item_customization_modal.cancel-button;
  }

  .spinner-container {
    @include item_customization_modal.spinner-container;
  }
}
