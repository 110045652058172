@use "viewport"as viewport;

.label {
  margin: 0;
}

.time-list {
  display: grid;
  grid-template-columns: repeat(4, min-content);
  grid-gap: 9px !important;

  .option {
    text-align: center;
    padding: 4px;
    white-space: nowrap;
  }

  .selected {
    background-color: var(--message-success-bg);
    color: var(--message-success-text);
    border-radius: 4px;
  }
}

.duration-picker {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-gap: 15px;
  align-items: center;
}
