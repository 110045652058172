@use "../card"as card;
@use "../constants"as constants;
@use "../typography"as typography;

.store-card-view {
  @include card.container;
  display: flex;
  flex-direction: row;
  padding: 20px 20px;
  cursor: pointer;

  .name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .image {
    width: 60px;
    border-radius: constants.$border-radius;
    overflow: hidden;
    margin-right: 22px;
  }

  .restaurant-name {
    // font-style: normal;
    // font-size: 11px;
    // letter-spacing: 0.116667px;
    // color: #92929d;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: -1px;
  }

  .store-name {
    @include typography.h2;
    color: #4c4c77;
    margin-top: -1px;
  }
}
