@use '../viewport' as viewport;
@use '../themes/default' as default-theme;
@use '../constants' as constants;
@use "../typography" as typography;
@import './common-styles-mobile';

@include viewport.mobile {
  .third-party-delivery-services-modal {
    padding-bottom: 28px;

    .header {
      @include item-bottom-sheet-modal-header();
    }

    .section {
      padding: #{constants.$horizontal-gap};
      padding-top: 0px;

      .unavailability-message {
        @include typography.body-regular;
        color: #{default-theme.$restoplus-dark};
      }

      .get-order-message {
        @include typography.body-regular;
        color: #{default-theme.$restoplus-dark};
        margin-bottom: 15px;
      }
    }

    .actions > .back-button {
      cursor: pointer;
      margin: 10px auto;
      padding: 0;
      background: none;
      box-shadow: none;
      @include typography.caption-regular;
      color: #{default-theme.$material-gray-600};
    }
  }
}
