@use '../card' as card;
@use '../constants' as constants;

@mixin title {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.1px;
  color: var(--fg);
  margin-bottom: 20px;
}

@mixin cancel-button {
  width: 100%;
}

@mixin spinner-container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: max-content;
  align-items: center;
}
