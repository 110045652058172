@use "form"as form;
@use "card"as card;
@use "viewport"as viewport;
@use "typography" as typography;

.dropdown {
  .input-box {
    @include form.form-input();
    padding: form.$padding;
    @include typography.body-regular;
    cursor: pointer;
  }

  .options {
    @include card.container();
    @include card.content();
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 25px;
    display: grid;
    grid-gap: 18px;
    font-weight: 400;
    cursor: pointer;
  }

  @include viewport.mobile {
    .overlay {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.7);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 998;
    }

    .options {
    }
  }

  @include viewport.tablet {
    .options-wrapper {
      position: relative;
    }
    .options {
      position: absolute;
      top: 100%;
      margin-top: 10px;
      z-index: 999;
    }
  }

  .selected {
    .icon {
      color: var(--message-success-text);
    }
  }

  .option {
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
  }
}
