@use "../viewport" as viewport;
@use "../themes/default" as default-theme;
@use "../typography" as typography;

@include viewport.mobile {
  #toasts-container {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: grid;
    grid-gap: 15px;
    z-index: 1001;

    .wrapper {
      text-align: center;
    }
  }

  .toast {
    display: inline-grid;
    grid-template-columns: min-content max-content;
    align-items: center;
    padding: 15px 20px;
    border-radius: 8px;
    box-sizing: border-box;
    box-shadow: 0px 10px 16px rgba(49, 49, 59, 0.1);

    .icon {
      margin-top: -4px;
      margin-right: 10px;
    }

    .message {
      @include typography.body-regular;
      text-align: left;
      max-width: 220px;
    }

    button.close {
      margin-left: 10px;
      padding: 0;
      border: 0;
      background-color: transparent;
      box-shadow: none;

      opacity: 0.5;
      color: black;
      font-size: 1.4rem;
    }

    &.success {
      border: 1px solid #8bc1a4;
      background-color: var(--message-success-bg);
      color: var(--message-success-text);
    }

    &.info {
      border: 1px solid #d67300;
      // background-color: var(--message-info-bg);
      // color: var(--message-info-text);

      background-color: var(--message-warning-bg);
      color: var(--message-warning-text);
    }

    &.error {
      border: 1px solid #d9657a;
      background-color: var(--message-error-bg);
      color: var(--message-error-text);
    }

    &.warning {
      background-color: var(--message-warning-bg);
      color: var(--message-warning-text);
    }

    &.loading {
      border: 1px solid #d67300;
      background-color: #{default-theme.$orange-light};
      color: #{default-theme.$orange-dark};

      .spinner {
        width: 22px;
        height: 22px;
        margin-right: 8px;
        color: #{default-theme.$orange-dark};
        animation: spin 1s infinite linear;
      }
    }
  }
}
