@use "./constants"as constants;

@mixin container {
}

@mixin item {
  display: grid;
  grid-template-columns: min-content 1fr;
  padding: 10px 0;
  grid-gap: 5px;
  border-bottom: constants.$line-dashed;

  &.selected {
    color: var(--fg);
    font-weight: 700;
  }

  &:last-child {
    border-bottom: 0;
  }
}
