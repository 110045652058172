@use '../viewport'as viewport;
@use '../themes/default'as default-theme;
@use '../typography' as typography;

@include viewport.mobile {
  .category-list-widget {
    background-color: #{default-theme.$bg};
    gap: 10px;
    width: 100vw;
    overflow: visible;
    position: sticky;
    top: 58px;
    z-index: 1;

    #categories-container {
      display: flex;
      overflow: scroll hidden;
      width: 100%;
      box-shadow: inset 0px -1px 0px #{default-theme.$border-light};

      &::-webkit-scrollbar {
        display: none;
      }

      #categories-wrapper {
        display: grid;
        grid-auto-flow: column;

        .category {
          padding: 14px 11px 10px 11px;
          white-space: nowrap;
          -webkit-tap-highlight-color: transparent;
          cursor: pointer;
          word-wrap: break-word;

          &:first-child {
            margin-left: 7px;
          }

          &.selected {
            border-bottom: 2px solid #{default-theme.$restoplus-dark};
          }

          .name {
            @include typography.body-regular;
            text-align: left;
            color: #{default-theme.$material-gray-600};

            &.selected {
              font-weight: 700;
              color: #{default-theme.$restoplus-dark};
            }
          }
        }
      }
    }

    &.store-closed {
      top: 118px;
    }
  }
}
