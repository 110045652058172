@use '../viewport' as viewport;
@use '../themes/default' as default-theme;
@use "../typography" as typography;

@include viewport.mobile {
  .otp-for-mobile-number-request-widget {
    .error {
      display: none;
    }

    .supporting-text {
      @include typography.caption-regular;
      text-align: center;
      color: #{default-theme.$material-gray-600};
      a {
        text-decoration: none;
        color: #{default-theme.$restoplus-teal};
      }
    }
  }
}
