@use "../typography"as typography;
@use "../constants"as constants;
@use "../viewport"as viewport;
@use "../themes/default" as default-theme;

@include viewport.tablet {
  .footer-widget {
    background-color: #{default-theme.$restoplus-dark};
    height: 100%;

    .wrapper {
      max-width: constants.$max-body-width;
      height: calc(250px - 112px); /* footer height minus top and bottom padding */
      margin: 0 auto;
      display: grid;
      grid-template-columns: min-content 1fr max-content;
      padding: 56px 20px;

      .powered-by {
        .text {
          @include typography.caption-regular;
          color: #{default-theme.$bg};
          margin-bottom: 10px;
        }
      }

      .content {
        margin-left: 17%;
        margin-top: 16px;

        .text {
          @include typography.body-regular;
          color: #{default-theme.$bg};
          width: 55%;
          margin-bottom: 28px;
        }

        .cta {
          text-decoration: none;
          @include typography.button3;
          color: #{default-theme.$restoplus-teal};
          border-radius: 8px;
        }
      }

      .links {
        display: flex;
        gap: 16px;

        > a {
          text-decoration: none;
          @include typography.body-regular;
          color: #{default-theme.$secondary-fg};
        }
      }
    }
  }
}
