@use '../viewport' as viewport;
@use '../themes/default' as default-theme;
@use '../constants' as constants;
@use '../typography' as typography;

@mixin item-widget {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .item-body {
    .close-icon {
      position: fixed;
      background: #{default-theme.$bg};
      width: 20px;
      padding: 10px;
      border-radius: 100%;
      right: 19px;
      top: 19px;
      z-index: 1;
      box-shadow: 0px 2px 2px rgba(49, 48, 62, 0.08), 0px 6px 10px rgba(49, 48, 62, 0.1);

      &.no-image {
        background: #{default-theme.$material-gray-200};
        top: 52px;
      }
    }
  }

  .item-footer {
    padding-top: 18px;
  }

  /** no CSS anymore
  .item-image {
    // empty
  }
  */

  .item-name {
    padding: 0 #{constants.$horizontal-gap};
    padding-top: 25px;
    padding-right: 65px;
    @include typography.h2;
    background: white;
    color: #{default-theme.$restoplus-dark};

    &.no-image {
      margin-top: 35px;
    }
  }

  &.no-image-and-modifier {
    height: unset;

    .item-body {
      .close-icon {
        position: absolute;
        top: 17px;
      }

      .item-name {
        margin-top: 0;
      }
    }

    .add-to-cart-widget {
      padding: 32px #{constants.$horizontal-gap} !important;
    }
  }
}

@mixin item-details {
  padding: 10px #{constants.$horizontal-gap} 32px #{constants.$horizontal-gap};
  background: white;

  * {
    margin: 0;
  }

  .item-description {
    @include typography.body-regular;
    color: #{default-theme.$material-gray-600};
  }

  .item-price-and-sold-out {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    padding-top: 15px;
    margin-bottom: 16px;

    .item-price {
      @include typography.body-regular;
      color: #{default-theme.$restoplus-dark};
    }

    .item-sold-out,
    .item-unavailable {
      background: #{default-theme.$material-gray-300};
      padding: 2px 8px;

      @include typography.body-regular;
      color: #{default-theme.$restoplus-dark};

      border-radius: 4px;
      margin: 0 16px;
    }
  }
}

@mixin item-actions {
  .add-to-cart-widget {
    background: #{default-theme.$restoplus-light-green};
    padding: 32px #{constants.$horizontal-gap};
    padding-bottom: 122px;
  }
}

@mixin choose-pizza-header {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr max-content;

  .title {
    @include typography.h4;
    color: #{default-theme.$restoplus-dark};

    .requiredness {
      padding: 0px 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      @include typography.caption-regular;
      color: #{default-theme.$material-gray-600};
    }

    .selected::before {
      content: url('../images/green-check.svg');
      padding-right: 5px;
    }

    .required {
      color: #{default-theme.$green-dark};
    }

    .error {
      background-color: #{default-theme.$red-light};
      color: #{default-theme.$red-dark};
    }
  }

  > .form-item.switch {
    label {
      @include typography.body-regular;
      color: #{default-theme.$restoplus-dark};
    }
  }
}

@mixin choose-pizza-button {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr min-content min-content;
  gap: 15px;
  padding: 18px 16px;
  border: 1px solid #{default-theme.$material-gray-300};
  border-radius: 8px;
  align-items: center;

  .text {
    align-items: center;
    @include typography.body-regular;
    color: #{default-theme.$restoplus-dark};

    &.no-pizza {
      color: #{default-theme.$material-gray-500};
    }
  }

  .extra-charge {
    white-space: nowrap;
    @include typography.body-regular;
    color: #{default-theme.$material-gray-500};
  }
}

@mixin customize-pizza-button {
  margin-left: 2px;
  @include typography.button2;
  color: #{default-theme.$restoplus-teal};
  margin-bottom: 28px;

  &.customize {
    margin-top: 20px;
  }
}

@mixin summary {
  > pre {
    @include typography.caption-regular;
    color: #{default-theme.$material-gray-600};
    margin: 17px 2px;
    margin-bottom: 12px;
    letter-spacing: 0.3px;
    overflow-x: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
}

@mixin item-radio-button {
  input[type='radio'] {
    display: none;

    + label {
      position: relative;
      margin-left: 25px;
      @include typography.body-regular;
      color: #{default-theme.$restoplus-dark};

      &::before {
        content: '';
        position: absolute;
        top: 0px;
        left: -28px;
        border-radius: 50%;
        border: 1px solid #{default-theme.$material-gray-300};
        width: 15px;
        height: 15px;
      }
    }

    &:checked {
      + label::before {
        background: #{default-theme.$restoplus-teal};
        border: 1px solid #{default-theme.$restoplus-teal};
      }
    }
  }
}

@mixin item-checkbox {
  input[type='checkbox'] {
    display: none;

    + label {
      position: relative;
      margin-left: 25px;
      @include typography.body-regular;
      color: #{default-theme.$restoplus-dark};

      &::before {
        content: '';
        position: absolute;
        top: 0px;
        left: -28px;
        border-radius: 4px;
        border: 1px solid #{default-theme.$material-gray-300};
        width: 16px;
        height: 16px;
      }
    }

    &:checked {
      + label::before {
        background: #{default-theme.$restoplus-teal};
        border: 1px solid #{default-theme.$restoplus-teal};
        background-image: url('../images/icon-check-light.svg');
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
}

@mixin item-bottom-sheet-modal-header {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 17px 20px;
  border-bottom: 1px solid #{default-theme.$material-gray-300};
  position: sticky;
  top: 0;
  background: #{default-theme.$bg};
  z-index: 99;

  .title {
    @include typography.h3;
    color: #{default-theme.$restoplus-dark};
  }
}

@mixin half-and-half-pizzas {
  display: grid;
  grid-gap: 27px;
  margin-top: 10px;

  p {
    margin: 0;
    margin-bottom: 11px;
  }

  .header {
    padding: 16px #{constants.$horizontal-gap};
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @include typography.h4;
    margin: 0;
    background-color: #{default-theme.$material-gray-50};

    .title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 3px;

      .pizza-half {
        @include typography.h4;
        margin-right: 8px;
      }

      .requiredness {
        padding: 3px 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-style: normal;
        font-weight: normal;
        @include typography.caption-regular;
        color: #{default-theme.$material-gray-600};
      }

      .selected::before {
        content: url('../images/green-check.svg');
        padding-right: 5px;
      }

      .required {
        color: #{default-theme.$green-dark};
      }

      .error {
        background-color: #{default-theme.$red-light};
        color: #{default-theme.$red-dark};
      }
    }
  }

  .half-and-half-pizza-entry {
    padding: 16px #{constants.$horizontal-gap};
  }

  .choose-pizza-button {
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    padding: 14px 30px 14px 16px;
    @include typography.body-regular;
    border-radius: 8px;
    border: 1px solid #{default-theme.$material-gray-300};
    background: #{default-theme.$bg};
    color: #{default-theme.$material-gray-500};
    background-image: url('../images/right-arrow-head-icon.svg');
    background-repeat: no-repeat;
    background-position: right 14px bottom 19px;

    .label.with-input {
      color: #{default-theme.$restoplus-dark};
    }

    .extra-charge {
      display: flex;
      grid-gap: 5px;
    }
  }

  .summary {
    margin: 0;
    padding: 18px 0 0 2px;
    white-space: pre-wrap;
    @include typography.caption-regular;
    color: #{default-theme.$material-gray-600};
  }

  .customize-pizza-button {
    @include typography.button2;
    text-transform: none;
    background: #{default-theme.$bg};
    color: #{default-theme.$restoplus-teal};
    box-shadow: none;
    padding: 20px 3px;
    letter-spacing: unset;
  }

  .edit-pizza-button {
    @include typography.button2;
    text-transform: none;
    background: #{default-theme.$bg};
    color: #{default-theme.$restoplus-teal};
    box-shadow: none;
    padding: 14px 0 0 2px;
    letter-spacing: unset;
  }
}

@mixin pizza-list-widget {
  display: flex;
  flex-flow: column;
  height: 100%;
  @include item-radio-button();

  .item-header {
    @include item-bottom-sheet-modal-header();

    .close {
      margin: 5px;
      overflow: hidden;
      color: transparent;
      background-image: url('../images/x-icon.svg');
      background-repeat: no-repeat;
      background-size: cover;
      width: 14px;
      height: 14px;
    }
  }

  .item-body {
    height: 100%;
    overflow: scroll;
    margin-bottom: 26px;
  }

  .pizzas {
    display: flex;
    flex-direction: column;
    overflow: auto;
    grid-gap: 20px;
    padding: 16px constants.$horizontal-gap;

    .pizza {
      padding: 14px 0;
      border-radius: 8px;
      border: 1px solid #{default-theme.$material-gray-300};
      overflow: hidden;
      position: relative;

      .soldout-overlay {
        background: #ababab36;
        width: 100%;
        height: 100%;
        position: absolute;
        cursor: not-allowed;
        left: 0;
        top: 0;
      }

      .header {
        padding: 0 10px 0 15px;
        @include typography.body-regular;
        color: #{default-theme.$restoplus-dark};
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      .price {
        @include typography.body-regular;
        color: #{default-theme.$material-gray-600};
        white-space: nowrap;
      }

      .description {
        margin: 0;
        padding: 5px 15px 0 40px;
        @include typography.body-regular;
        color: #{default-theme.$material-gray-600};
      }
    }
  }
}

@mixin session-create-widget-title-and-image {
  .title {
    grid-area: title;
    display: flex;
    align-self: center;
    @include typography.h4;
    color: #{default-theme.$restoplus-dark};
    margin-bottom: 15px;
  }

  .image {
    grid-area: image;
    width: 38px;
    margin-bottom: 15px;
  }
}

@mixin item-customisation-loading {
  position: absolute;
  top: 42px;
  left: calc(50% - 280px / 2);
  background: #{default-theme.$orange-light};
  border: 1px solid #f8bd79;
  box-sizing: border-box;
  border-radius: 8px;
  height: 51px;
  gap: 10px;
  padding: 14px;

  display: flex;
  flex-direction: row;

  .message {
    @include typography.body-regular;
    text-align: center;
    color: #{default-theme.$orange-dark};
  }

  .spinner {
    animation: spin 2s linear infinite;
  }
}

@mixin info-tag {
  @include typography.caption-regular;
  padding: 3px 6px;
  margin-left: 8px;

  border-radius: 4px;
  color: #{default-theme.$restoplus-dark};
  background: #{default-theme.$material-gray-300};
}

@mixin session-view-widget {
  display: flex;
  height: 30px;
  min-width: 0;
  background: #{default-theme.$green-light};
  padding: 10px 20px;
  align-items: center;
  justify-content: space-between;
  color: #{default-theme.$green-dark};
  @include typography.body-regular;

  .session {
    display: flex;
    width: 80%;

    .collection-info {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .datetime,
  .to,
  .address,
  .table-name {
    display: inline;
    font-weight: 700;
    margin-left: 4px;
  }

  .to {
    font-weight: 400;
  }

  .action-label {
    justify-self: end;
    font-weight: 700;
  }
}

@mixin orange-strip {
  background: #{default-theme.$orange-light};
  padding: 15px constants.$horizontal-gap;
  color: #{default-theme.$orange-dark};
  @include typography.body-regular;
  text-align: left;
}
