@use "../card"as card;
@use "../switch"as switch;
@use "../constants"as constants;
@use "../form"as form;
@use "../buttons"as buttons;

$padding-horizontal: 15px;

.pizza-edit-widget {
  .pizza-sizes-form-item > label {
    @include form.top-align-label;
  }
  .sizes {
    display: grid;
    grid-row-gap: 20px;
  }
  .size {
    @include card.container;

    .switch {
      @include switch.inline();
    }

    .name {
      @include card.header;
    }

    .spec {
      @include form.inline;
      @include card.content;

      // > :nth-child(2) {
      //   display: flex;
      //   flex-direction: row;
      //   align-items: center;
      //   justify-content: space-between;

      //   input {
      //     max-width: 80px;
      //   }
      // }
    }
  }

  .remove-toppings-form-item {
    > label {
      @include form.top-align-label;
    }
    .list {
      @include card.container;
      @include card.content;
      display: grid;
      grid-gap: 20px;
      margin-bottom: 25px;

      .item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        input {
          margin-right: 20px;
        }
        .action {
          @include card.round-button;
        }
      }
    }
    .actions {
      margin-top: 4px;
      button {
        @include buttons.primary-transparent;
      }
    }
  }
}
