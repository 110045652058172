@use "../card"as card;
@use "../constants"as constants;
@use "../typography"as typography;
@use "../utils"as utils;

.backoffice {

  .category-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    grid-gap: 15px;
  }

  .category {
    @include card.container;
    @include card.stack;
    display: grid;
    grid-template-rows: 1fr min-content;
    background-color: #fafafb;

    .image {
      border-radius: constants.$border-radius;
      overflow: hidden;
      margin-bottom: 15px;
    }

    .name {
      @include typography.h2;
      @include utils.curve-padding;
    }

    .description {
      @include utils.curve-padding;
    }

    .body {
      @include card.upper;
      cursor: pointer;
      padding: 10px;
    }

    .actions {
      @include card.lower;
      @include card.round-buttons;

      .action {
        @include card.round-button;
      }
    }
  }
}