@use "../viewport" as viewport;
@use "../themes/default" as default-theme;
@use "../typography" as typography;

@include viewport.tablet {
  .badge {
    display: inline;
    border-radius: 4px;
    padding: 6.5px 15px;
    @include typography.body-regular;

    &.success {
      background: #{default-theme.$green-light};
      color: #{default-theme.$green-dark};
    }

    &.failure {
      background: #{default-theme.$red-light};
      color: #{default-theme.$red-dark};
    }

    &.primary,
    &.warning {
      background: #{default-theme.$orange-light};
      color: #{default-theme.$orange-dark};
    }
  }
}
