@use "../themes/default"as default-theme;

@mixin timeline_event {
  .timeline-event-container {
    width: 100%;
    &:first-child {
      width: 60px;
    }
    .timeline-event-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      .timeline-event-line {
        width: 100%;
        height: 2px;
      }
      .timeline-event-color {
        margin: 0px 5px;
        padding: 12px 13px;
        border-radius: 50%;
        img {
          display: block;
          width: 20px;
        }
      }
      .default-status-color {
        background-color: #c8c8d0;
      }
      .success {
        background-color: #{default-theme.$primary};
      }
      .delivery-failed {
        background-color: #fbbf24;
      }
      .rejected {
        background-color: #9f1239;
      }
    }
  }
}
