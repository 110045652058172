@use "../buttons"as buttons;
@use "../constants"as constants;
@use "../viewport"as viewport;
@use "../themes/default"as default-theme;

.website-order-success-page {
  #order-history-button {
    @include buttons.primary;
    margin-left: constants.$curve-padding;
  }
}
