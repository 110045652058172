@use "../themes/default" as default-theme;
@use "../typography" as typography;
@use "../viewport" as viewport;

@include viewport.mobile {
  .bottom-nav-bar {
    box-shadow: 0px -1px 3px rgba(104, 122, 155, 0.25);
    z-index: 1;
    background-color: #27283E;

    @supports (padding: max(0px)) {
      padding-bottom: max(10px, var(--safe-area-inset-bottom));
    }

    .item {
      display: grid;
      grid-template-rows: 1fr max-content;
      text-align: center;
      position: relative;
      padding-top: 15px;
      flex-grow: 1;
      flex-basis: 0;
      color: #fff;

      .icon-count-container {
        .icon {
          opacity: 0.45;

          &.selected {
            opacity: 1;
          }
        }

        .count {
          position: absolute;
          right: calc(50% - 23px);
          top: 5px;
          padding: 8px;
          background: #{default-theme.$button-danger-bg};
          color: #{default-theme.$bg};
          border-radius: 50%;

          > span {
            position: absolute;
            top: -2px;
            width: 100%;
            left: 0;
            text-align: center;
            font-size: 9px;
          }
        }
      }

      .label {
        color: #{default-theme.$bg};
        position: relative;
        text-align: center;
        @include typography.nav-link;
        opacity: 0.45;

        &.selected {
          opacity: 1;
        }
      }
    }
  }
}
