@use '../viewport' as viewport;
@use '../themes/default' as default-theme;
@use '../typography' as typography;

@include viewport.tablet {
  .delivery-address-edit-widget {
    padding: 0px 32px 32px 32px;
    display: flex;
    flex-direction: column;

    .section {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;

      .title {
        @include typography.h4;
        color: #{default-theme.$restoplus-dark};
      }
    }

    .section-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;

      .image {
        width: 39px;
        display: flex;
      }
    }

    .delivery-address {
      padding-bottom: 24px;
      border-bottom: 1px solid #{default-theme.$material-gray-300};
    }

    .unavailable-address {
      padding: 12px;
      background-color: #{default-theme.$message-error-bg};
      display: flex;
      gap: 10px;
      margin-bottom: 12px;

      .image {
        width: 24px;
        grid-area: auto;
        top: 0;
        right: 0;
      }

      .text {
        display: flex;
        align-items: center;
      }
    }

    .delivery-charge {
      background-color: #{default-theme.$bg};
      display: flex;
      gap: 10px;
      margin-bottom: 17px;
      color: #{default-theme.$light-gray-900};
    }

    .invalid {
      border-color: #e0e0e0 !important;
    }
  }
}
