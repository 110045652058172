@use '../card'as card;
@use '../constants'as constants;
@use '../typography'as typography;
@use '../viewport'as viewport;
@use '../utils'as utils;
@use '../themes/default'as default-theme;
@import './common-styles-mobile';

@include viewport.mobile {
  .store-search-page {
    .search-page {
      .search-box {
        margin: 18px 20px;
        padding: 10px 16px;
        border: 1px solid #{default-theme.$material-gray-400};
        background: #{default-theme.$bg};
        box-sizing: border-box;
        border-radius: 8px;
        line-height: 22px;

        display: flex;
        align-items: center;
        justify-content: center;

        .inputbox {
          width: 110%;
          @include typography.body-regular;
          letter-spacing: 0.1px;

          border: none;
          outline: none;
        }
      }

      .empty-results {
        margin-top: 15vh;
        text-align: center;

        .logo-search {
          margin-bottom: 10px;
          height: 60px;
          width: 60px;
        }

        .text {
          @include typography.body-regular;
          color: #{default-theme.$material-gray-600};
        }
      }

      .no-results {
        margin-top: 15vh;
        text-align: center;

        > .logo-no-results {
          margin-bottom: 10px;
        }

        > .text {
          @include typography.body-regular;
          color: #7d7d7d;

          span {
            margin-left: 0.25em;
            font-weight: bold;
          }
        }
      }

      .search-results {
        .search-results-message {
          text-align: center;
          @include typography.body-regular;
          color: #{default-theme.$material-gray-500};
        }

        .category-summary {
          padding: 25px 0;
          display: flex;
          flex-flow: column;
          grid-gap: 10px;

          .link-anchor {
            grid-area: link-anchor;
            width: 100%;
            position: relative;
            top: -170px;
          }

          .name {
            @include typography.h3;
            text-align: left;
            word-break: break-word;
            color: #{default-theme.$restoplus-dark};
          }

          .description {
            display: none;
          }
        }

        > .items {
          margin: 0 auto;
          width: 89.5%;
          grid-area: items;

          .header-mobile {
            @include typography.body-regular;
            text-align: center;
            align-items: center;
            color: #{default-theme.$material-gray-500};
          }

          .items-per-category:last-of-type {
            margin-bottom: 30px;
          }
        }

        .items-container {
          display: grid;
          gap: 20px;

          .item.no-image {
            height: auto;
            padding-top: 0;

            .body {
              position: relative;
            }
          }

          .item {
            overflow: hidden;
            position: relative;
            height: auto;
            background-color: #{default-theme.$bg};
            border-radius: 8px;
            border: 1px solid #e0e0e0;
            box-sizing: border-box;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

            height: 0;
            padding-top: calc(35px / 80px) * 100%;

            .anchor {
              position: absolute;
              top: -120px;
            }

            .body {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              display: flex;
              flex-flow: row;

              &.no-image {
                grid-template-columns: 1fr;

                .item-info > .description {
                  -webkit-line-clamp: unset !important;
                }
              }

              .image {
                flex: 1;
                height: auto;
                img {
                  object-fit: cover;
                }
              }

              .item-info {
                display: flex;
                flex: 1.55;
                flex-direction: column;
                padding: 15px;

                .name {
                  flex-shrink: 0;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  overflow: hidden;
                  @include typography.h4;
                  text-align: left;
                  align-self: start;
                  word-wrap: break-word;
                  margin-bottom: 8px;
                  color: #{default-theme.$restoplus-dark};
                }

                .description {
                  flex-shrink: 1;
                  overflow: hidden;
                  align-self: start;
                  color: #{default-theme.$material-gray-600};
                  margin-bottom: 17.6px;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;

                  &.line-clamp-1 {
                    -webkit-line-clamp: 1;
                  }

                  &.line-clamp-2 {
                    -webkit-line-clamp: 2;
                  }
                }

                .price-and-sold-out {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  margin-top: auto;

                  .price {
                    flex-shrink: 0;
                    margin-top: auto;
                    @include typography.body-regular;
                    text-align: left;
                    color: #{default-theme.$restoplus-dark};
                  }

                  .soldout,
                  .unavailable {
                    @include info-tag;
                  }
                }

                &.no-image {
                  display: unset;
                  flex: unset;
                }
              }
            }

            .action {
              grid-area: e;
              @include card.footer;
              padding: 12px 18px;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: start;
              align-items: start;

              img {
                margin-right: 10px;
                margin-top: 5px;
              }
              span {
                font-family: Fira Sans;
                @include typography.body-regular;
                color: #426ed9;
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
  }
}
