@use '../viewport'as viewport;
@use "../themes/default" as default-theme;
@use "../typography" as typography;

@include viewport.mobile {
  .coupons {
    display: grid;
    gap: 23px;
    margin: 10px 23px;
    margin-top: 20px;

    .coupon {
      position: relative;
      border: 0.01px solid #{default-theme.$bg}63;
      border-left: unset;
      min-width: 321px;
      box-shadow: 0 0 11px 0 #bdbdbd78;
      clip-path: inset(-10px -10px -10px 0);

      .zigzag {
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(
            45deg,
            rgb(255 255 255 / 0%) 45%,
            #{default-theme.$material-gray-450}11 45%,
            #{default-theme.$material-gray-450}11 55%,
            rgb(255 255 255) 55%
          ),
          linear-gradient(
            315deg,
            rgb(255 255 255) 45%,
            #{default-theme.$material-gray-450}11 45%,
            #{default-theme.$material-gray-450}11 55%,
            #f4f4f45e 55%
          );
        background-repeat: repeat-y;
        background-size: 23px 20px;
        width: 12px;
        height: 100%;
      }

      .body {
        display: grid;
        min-height: 112px;
        padding: 16px 13px 11px 21px;
        grid-template-rows: min-content 1fr min-content;
        grid-template-columns: 1fr min-content;
        grid-template-areas:
          'code         icon'
          'description  icon'
          'discount     availability';
        color: #{default-theme.$restoplus-dark};

        &.used {
          filter: grayscale(1);
          color: #{default-theme.$restoplus-dark}69;
        }

        .code {
          grid-area: code;
          overflow: hidden;
          -o-text-overflow: ellipsis;
          text-overflow: ellipsis;
          @include typography.coupon-code;
          white-space: nowrap;
          width: 100%;
          overflow: hidden;
          -o-text-overflow: ellipsis;
          text-overflow: ellipsis;
        }

        .icon {
          grid-area: icon;
          width: 45px;
          margin-bottom: 1px;
          justify-self: end;

          &.available {
            right: 0;
          }
        }

        .description {
          grid-area: description;
          @include typography.caption-regular;
          color: #{default-theme.$material-gray-600};
          display: block;
          margin-bottom: 5px;
        }

        .discount {
          grid-area: discount;
          @include typography.caption-regular;
          margin-top: auto;
          color: #{default-theme.$restoplus-dark};
        }

        .availability {
          grid-area: availability;
          text-transform: capitalize;
          @include typography.caption-regular;
          color: #{default-theme.$restoplus-teal};
          margin-top: auto;
          margin-left: 5px;
        }
      }
    }
  }
}
