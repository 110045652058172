@use "../viewport" as viewport;
@use "../constants" as constants;
@use "../themes/default" as default-theme;
@use "../typography" as typography;

@include viewport.mobile {
  .uber-delivery-info-view-widget {
    display: grid;
    grid-template-rows: 180px max-content;

    .tracking-map {
      position: relative;
      overflow: hidden;
      top: -35px;

      .map-container {
        width: 100%;
        overflow: hidden;
        height: 210px;

        > iframe {
          overflow: hidden;
          position: relative;
          width: 350%;
          height: 420px;
          right: 90%;
          top: -65%;
          transform: scale(0.8);
          transform-origin: 0 0;
        }
      }
    }

    .courier-info {
      padding: 20px 15px;
      margin: 0px constants.$horizontal-gap;
      margin-bottom: 24px;
      background: white;
      border: 1px solid #{default-theme.$material-gray-300};
      box-sizing: border-box;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      display: grid;
      align-items: center;
      grid-template-columns: 90px 200px;
      grid-template-areas: 'courier-image courier-additional-info';
      .courier-image {
        grid-area: courier-image;
        img {
          border-radius: 50%;
          width: 80%;
        }
      }
      .courier-additional-info {
        grid-area: courier-additional-info;
        .courier-name {
          font-weight: 700;
        }
        .courier-vehicle {
          color: #7d7e92;
        }

        .courier-phone-number {
          color: #7d7e92;
          font-weight: 700;
        }

        .call-courier {
          color: #{default-theme.$primary};
          text-decoration: none;
          font-weight: 400;
        }
      }
    }
  }
}
