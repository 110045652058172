@use "../constants"as constants;
@use "../typography"as typography;
@use "../card"as card;

.restaurant-browse-page .list,
.restaurant-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  grid-gap: 15px;

  .restaurant {
    @include card.container;

    display: flex;
    flex-direction: column;
    cursor: pointer;
    overflow: hidden;

    .image {
    }

    .name {
      @include typography.h4;
      padding: 10px 10px 0;
    }

    .timezone {
      padding: 0 10px 10px;
      font-size: 12px;
      color: #92929d;
    }

    .description,
    .country {
      display: none;
    }
  }
}
