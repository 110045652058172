@use '../viewport'as viewport;
@use '../themes/default'as default-theme;
@use '../typography' as typography;
@import './common-styles-mobile';
@import '_top-nav-bar-mobile';

@include viewport.mobile {
  .online-ordering .menu-page {
    .header {
      margin-top: 0;
      padding: 0;
      &.on-top {
        transition: visibility 0s linear 0.2s, opacity 0.2s linear;
        display: none;
        opacity: 0;
      }

      .wrapper {
        position: relative;

        .nav {
          @include top-nav-bar();
          @include banner-nav-bar();
          @include banner-nav-bar-title();
        }

        .banner-image {
          position: relative;
          width: 100vw;

          .desktop {
            display: none;
          }

          .restaurant-info {
            display: flex;
            flex-direction: column;
            z-index: 1;

            &.mobile-banner {
              display: none;
            }

            .restaurant-logo {
              position: absolute;
              width: 120px;
              bottom: 127px;
              left: 24px;
              background-color: #{default-theme.$bg};
              border-radius: 23px;

              // special case: for very small screens
              @media (max-width: 330px) {
                bottom: 90px;
              }

              > .image {
                border-radius: 23px;
                overflow: hidden;
              }
            }
            .store-title {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;

              padding: 20px;
              box-sizing: border-box;

              position: absolute;
              bottom: 0;
              color: var(--bg);
              width: 100%;

              background: linear-gradient(
                180deg,
                rgba(42, 42, 49, 0) 11.39%,
                rgba(42, 42, 49, 0.1) 24.94%,
                rgba(42, 42, 49, 0.8) 100%
              );
              background-blend-mode: multiply;
              .store-name {
                @include typography.h1;

                margin: 4px 0px;
              }
              .store-location {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;

                @include typography.caption-regular;
                text-align: left;

                .address {
                  margin: 0px 9px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                .icon {
                  width: 17px;
                }
              }
            }
          }
        }
      }
    }

    .body > .menu {
      background-color: #{default-theme.$material-gray-50};
      display: grid;
      grid-template-areas:
        'collection-type-selector'
        'store-closed-info'
        'categories'
        'items';

      .collection-type-selector-widget {
        grid-area: collection-type-selector;
      }
      .store-closed-info-widget {
        grid-area: store-closed-info;
      }
      .category-list-widget {
        grid-area: categories;
      }
      .item-list-widget {
        grid-area: items;
      }

      #top-of-site-pixel-anchor {
        position: absolute;
        width: 1px;
        height: 1px;
        top: 100px;
        left: 0;
      }
    }
  }
}
