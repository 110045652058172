@use "../viewport"as viewport;
@use "../themes/default"as default-theme;

.modal.loyalty-card-modal {
  max-width: 450px;

  > .close {
    display: none;
  }

  > .loyalty-card-modal-data {
    > .loyalty-card-modal-body {
      position: relative;
      margin-top: 30px;
      text-align: center;

      .header-icon {
        position: absolute;
        top: -80px;
        left: 0;
        right: 0;
        width: 100%;

        .bg {
          fill: var(--color-primary);
        }
      }

      .header-text {
        font-weight: 400;
        font-size: 20px;
      }

      .loyalty-card {
        width: unset;

        @include viewport.desktop {
          margin: 0 15px;
        }
      }

      .subtitle {
        font-size: 16px;
      }

      .close-link {
        font-size: 16px;
        margin-top: 30px;
        cursor: pointer;
      }
    }
  }
}
