@use '../viewport' as viewport;
@use '../themes/default' as default-theme;
@use '../constants' as constants;
@use '../typography' as typography;
@import './common-styles-mobile';

@include viewport.mobile {
  .suburbs-list-widget {
    @include session-create-widget-title-and-image();
    margin-bottom: 72px;
    padding: 0 #{constants.$horizontal-gap};
    display: grid;
    grid-template-areas:
      'title             image'
      'suburb-search     suburb-search'
      'suburb-list       suburb-list';
    grid-template-columns: 1fr max-content;
    background: #{default-theme.$bg};

    .title {
      @include typography.h4;
      color: #{default-theme.$restoplus-dark};
    }

    .suburb-search {
      grid-area: suburb-search;
      position: relative;

      .search-icon {
        position: absolute;
        z-index: 1;
        top: 15px;
        left: 15px;
        opacity: 0.55;
      }

      input {
        border-radius: 8px;
        border: 1px solid #{default-theme.$material-gray-300};
        padding: 15px 42px;
        margin-bottom: 8px;
        outline: none;
        width: -webkit-fill-available;
        font-family: Chivo;
        @include typography.body-regular;
        color: #{default-theme.$restoplus-dark};
      }
    }

    .suburbs {
      grid-area: suburb-list;
      display: grid;
      gap: 8px;

      .no-suburb-found {
        @include typography.body-regular;
        color: #{default-theme.$material-gray-600};
        text-align: center;
        margin-top: 30px;
      }

      .suburb {
        display: grid;
        grid-template-areas: 'name postcode . delivery-charge caret-right-icon';
        grid-template-columns: max-content min-content 1fr min-content min-content;
        padding: 14px 16px;
        border: 1px solid #{default-theme.$material-gray-300};
        border-radius: 8px;
        align-items: center;
        @include typography.body-regular;

        .name {
          grid-area: name;
        }

        .postcode {
          grid-area: postcode;
          color: #{default-theme.$material-gray-600};
          margin-left: 8px;
        }

        .delivery-charge {
          grid-area: delivery-charge;
          color: #{default-theme.$material-gray-600};
          white-space: nowrap;
          margin-right: 8px;
        }

        .caret-right.icon {
          grid-area: caret-right-icon;
        }
      }
    }
  }
}
