@use "../viewport" as viewport;
@use "../themes/default" as default-theme;
@use '../constants' as constants;
@use "../typography" as typography;
@import './common-styles-mobile';

@include viewport.mobile {
  .customer-login-flow-widget {
    position: relative;
    height: 100%;
    display: grid;
    grid-template-rows: min-content 1fr;

    .top-header {
      display: grid;
      grid-template-areas: 'back-button step-indicator close-button';
      justify-content: space-between;
      align-items: center;
      padding: 20px 20px 17px 20px;
      .back-button {
        grid-area: back-button;
      }
      .close-button {
        grid-area: close-button;
        img {
          height: 18px;
        }
      }
      .step-indicator {
        grid-area: step-indicator;
        margin: 0 auto;
        top: 10px;
        @include typography.caption-regular;
        text-align: left;
        color: #{default-theme.$material-gray-600};
      }
    }

    .body {
      padding: 0 #{constants.$horizontal-gap};
      height: 100%;

      .intro {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 40px;
        .title {
          margin-top: 6px;
          @include typography.h4;
          text-align: center;
          color: #{default-theme.$restoplus-dark};
        }
        .subtitle {
          margin-top: 5px;
          @include typography.body-regular;
          text-align: center;
          color: #{default-theme.$material-gray-600};
        }
      }
    }

    button {
      padding: 14px;
      width: 100%;
      margin-bottom: 16px;
      border-radius: 8px;
      @include typography.button1;
      span {
        color: #{default-theme.$bg};
      }
      &.disabled {
        background-color: #{default-theme.$restoplus-teal} !important;
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    .form-buttons {
      margin-top: 25px;
    }

    .grecaptcha-badge {
      visibility: hidden;
    }

    #recaptcha-wrapper {
      position: fixed !important;
      bottom: 0;
      left: 50%;
      height: 75vh;
      overflow: auto;
      transform: translateX(-50%);
    }
  }
}
