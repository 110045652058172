@use '../viewport' as viewport;
@use '../themes/default' as default-theme;
@import './common-styles-desktop';

@include viewport.tablet {
  .customer-display-name-update-widget {
    height: 100%;
    display: grid;
    grid-template-rows: min-content 1fr;
  }
}
