// DayPicker is the name of the component library
@use "form"as form;
@use "viewport" as viewport;
@use "typography" as typography;
@use "./themes/default" as default-theme;

/* DayPicker styles */

.DayPicker {
  display: inline-block;
  font-size: 1rem;

  align-items: center;
  justify-content: center;

  width: 100%;
}

.DayPicker-wrapper {
  position: relative;
  width: 100%;
  flex-direction: row;
  padding-bottom: 1em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0 auto;
}

.DayPicker-Months {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.DayPicker-Month {
  display: table;
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.DayPicker-NavBar {
  display: flex;
  justify-content: space-between;
  position: relative;
  top: 22px;
  width: 85%;
  margin: 0 auto;
}

.DayPicker-NavButton {
  // position: absolute;
  top: 1em;
  right: 1.5em;
  left: auto;

  display: inline-block;
  margin-top: 2px;
  width: 1.25em;
  height: 1.25em;
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  color: #8b9898;
  cursor: pointer;
}

.DayPicker-NavButton:hover {
  opacity: 0.8;
}

.DayPicker-NavButton--prev {
  background-image: url('../images/caret-right-icon.svg') !important;
  background-size: 11.9px !important;
  transform: rotate(180deg); // rotate the right caret to point it to left
}

.DayPicker-NavButton--next {
  background-image: url('../images/caret-right-icon.svg') !important;
  background-size: 11.9px !important;
}

.DayPicker-NavButton--interactionDisabled {
  display: none;
}

.DayPicker-Caption {
  display: table-caption;
  text-align: center;
  margin-bottom: 34px;

  > div {
    font-size: 17px;
    font-weight: 700;
  }
}

.DayPicker-Weekdays {
  display: table-header-group;
}

.DayPicker-WeekdaysRow {
  display: table-row;
}

.DayPicker-Weekday {
  display: table-cell;
  padding: 7px;
  color: #{default-theme.$restoplus-dark};
  text-align: center;
  @include typography.caption-regular;
}

.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

.DayPicker-Body {
  display: table-row-group;
}

.DayPicker-Week {
  display: table-row;

  ::before {
    content: '';
    height: 33px;
    display: table-row;
    /* Be warned that this pseudo content spans the whole table width in Firefox, but not in Chrome. Uncomment the background rule to see: */
    /* background: pink; */
  }
}

.DayPicker-Day {
  display: table-cell;
  padding: 7.5px 10px;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  @include typography.caption-regular;
  color: #{default-theme.$restoplus-dark};
  position: relative;
  z-index: 2;
}

.DayPicker-Day--highlighted {
  color: #{default-theme.$bg} !important;
  font-weight: bold;

  &::after {
    content: '';
    width: 40px;
    height: 40px;
    background-color: #{default-theme.$restoplus-teal};
    border-radius: 50% !important;
    position: absolute;
    z-index: -1;
    left: 5px;
    top: 30px;
  }

  &.DayPicker-Day--outside {
    &::after {
      display: none; // don't show the highlight if the date is outside of the current month
    }
  }
}

.DayPicker-Day--today {
  color: #{default-theme.$fg} !important;
  font-weight: 400 !important;
}

.DayPicker-Day--highlighted.DayPicker-Day--today {
  color: #{default-theme.$bg} !important;
  font-weight: 700 !important;
}

.DayPicker-Day.DayPicker-Day--disabled {
  pointer-events: none;
  text-decoration: line-through;
}

.DayPicker-WeekNumber {
  display: table-cell;
  padding: 0.5em;
  min-width: 1em;
  border-right: 1px solid #eaecec;
  color: #8b9898;
  vertical-align: middle;
  text-align: right;
  font-size: 0.75em;
  cursor: pointer;
}

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}

.DayPicker-Footer {
  padding-top: 0.5em;
}

.DayPicker-TodayButton {
  border: none;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  color: #4a90e2;
  font-size: 0.875em;
  cursor: pointer;
}

/* Default modifiers */

.DayPicker-Day--today {
  color: #d0021b;
  font-weight: 700;
}

.DayPicker-Day--outside {
  color: #8b9898;
  cursor: default;
}

.DayPicker-Day--disabled {
  color: #dce0e0 !important;
  cursor: default !important;
  /* background-color: #eff1f1; */
}

/* Example modifiers */

.DayPicker-Day--sunday {
  background-color: #f7f8f8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
  color: #dce0e0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;

  background-color: #4a90e2;
  color: #f0f8ff;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #51a0fa;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  // background-color: #f0f8ff;
}

/* DayPickerInput */

.DayPickerInput {
  display: inline-block;
}

.DayPickerInput-OverlayWrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 998;

  @include viewport.desktop {
    position: relative;
    background: unset;
  }
}

.DayPickerInput-Overlay {
  // position: absolute;
  border-radius: 10px;
  left: 0;
  z-index: 1;

  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.DayPickerInput {
  width: 100%;
  input {
    @include form.form-input();
    cursor: pointer;
  }
}

.DayPicker-Day--unavailable {
  color: #9c9c9c;

  &::after {
    content: '';
    width: 40px;
    height: 40px;
    background-color: #f9f9f9;
    border-radius: 50% !important;
    position: absolute;
    z-index: -1;
    left: 5px;
    top: 29px;
  }
}

@include viewport.mobile {
  .DayPicker-Day--unavailable {
    width: 25px;
    &::after {
      left: 5%;
    }
  }

  .DayPicker-Day--highlighted {
    width: 25px;
  
    &::after {
      left: 5%;      
    }
  }
}
