@use '../card'as card;
@use '../viewport'as viewport;
@use '../themes/default'as default-theme;
@use '../typography' as typography;
@import './common-styles-desktop';

@include viewport.tablet {
  .item-list-widget {
    // Display of all available menu items
    display: flex;
    flex-flow: column;
    grid-area: items;
    grid-gap: 33px;
    border-right: 1px solid #{default-theme.$material-gray-300};
    margin-top: 38px;
    margin-bottom: 31.5px;
    padding: 31.5px 32px 37vh 32px;

    .items-per-category {
      .category-summary {
        display: flex;
        flex-flow: column;

        .link-anchor {
          width: 100%;
          position: relative;
          top: -200px;
        }

        .name {
          @include typography.h2;
          word-break: break-word;
          color: #{default-theme.$restoplus-dark};
        }

        .description {
          @include typography.body-regular;
          grid-area: description;
          margin-top: 14px;
          margin-right: 75px;
          color: #{default-theme.$material-gray-600};
        }
      }

      .items-container {
        display: grid;
        gap: 16px;
        grid-template-columns: repeat(auto-fill, minmax(335px, 1fr));
        margin: 27.5px 0 20px 0;
      }
    }

    // Display of search results: No search result
    &.no-items-found {
      .no-results {
        padding-top: 153px;
        padding-bottom: 265px;
        margin: auto;
        @include typography.body-regular;
        text-align: center;
        color: #{default-theme.$material-gray-600};

        @include viewport.mobile {
          padding-top: 20px;
        }

        .text {
          margin-top: 7px;
        }

        span {
          font-weight: 700;
          line-height: 22.1px;
        }
      }
    }

    // Display of search results: With search results
    .search-results-message {
      @include typography.body-regular;
      color: #{default-theme.$restoplus-dark};

      .collection-type {
        font-weight: 700;
        letter-spacing: unset;
      }
    }
  }
}
