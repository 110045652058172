@use "../viewport"as viewport;
@use "../themes/default" as default-theme;
@use "../constants" as constants;
@import './common-styles-desktop';

@include viewport.tablet {
  .deal-pizza-list-widget {
    @include pizza-list-widget();
  }
}
