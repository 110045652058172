@use '../card' as card;
@use '../viewport'as viewport;
@use '../themes/default'as default-theme;
@use '../typography' as typography;

@include viewport.tablet {
  .collection-type-selector-widget {
    padding: 10px 20px;
    background-color: #{default-theme.$orange-light};
    display: flex;
    place-content: space-between;
    align-items: center;

    .collection-types {
      width: 27%;
      min-width: 300px;

      .collection-type-single {
        @include typography.body-regular;
        text-align: left;
        color: #{default-theme.$orange-dark};
        padding: 10px 0;

        span {
          cursor: pointer;
          @include typography.body-bold;
          text-align: left;

          &:last-of-type {
            margin-left: 1em;
          }
        }
      }

      .collection-type-selector {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 1fr;
        grid-auto-columns: 1fr;
        overflow: hidden;
        padding: 4px;
        gap: 5px;
        border-radius: 10px;
        background-color: rgba(214, 115, 0, 0.1);

        .start-order,
        .collection-type {
          position: relative;
          text-align: center;
          padding: 3px 0;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          background-color: transparent;
          border-radius: 10px;
          -webkit-tap-highlight-color: transparent;

          .collection-label {
            padding: 1px;
          }

          span {
            @include typography.body-regular;
            text-align: left;
            color: #{default-theme.$orange-dark};
          }

          &.selected {
            background-color: #{default-theme.$bg};

            .check {
              // position: absolute;
              margin-right: 10px;
              left: 33px;
              width: 16px;
            }
          }
        }

        .start-order {
          background-color: var(--button-success-bg);
          color: var(--button-success-fg);
        }
      }
    }

    .start-ordering-link {
      @include typography.body-bold;
      text-align: left;
      color: #{default-theme.$orange-dark};
      cursor: pointer;
    }
  }
}
