@use '../viewport' as viewport;
@use '../card' as card;
@use '../themes/default' as default-theme;
@use '../typography' as typography;
@import './common-styles-mobile';

@include viewport.mobile {
  .item-card-widget {
    overflow: hidden;
    background-color: #{default-theme.$bg};
    border-radius: 8px;
    border: 1px solid #e2e2e2;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 5%);
    box-sizing: border-box;
    display: grid;
    grid-template-areas:
      'image  image'
      'item-tag item-tag'
      'name name'
      'description  description'
      'price dietary';
    grid-template-rows: min-content min-content min-content 1fr min-content;
    grid-template-columns: min-content auto;
    grid-row-gap: 8px;
    overflow: hidden;
    padding-bottom: 16px;

    .anchor {
      position: absolute;
      top: -120px;
    }

    .soldout,
    .unavailable {
      @include info-tag;
      @include typography.caption-regular;
      grid-area: item-tag;
      text-align: center;
      align-items: center;
      width: fit-content;
      margin-left: 16px;
      margin-top: 12px;
    }

    .item-label {
      grid-area: item-tag;
      padding: 0 16px;
      @include typography.caption-regular;
      color: #{default-theme.$restoplus-dark};
      background-color: #ffebcf;
      padding: 3px 6px;
      border-radius: 4px;
      width: fit-content;
      margin: 12px 16px 0px 16px;
    }

    .name {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      @include typography.h4;
      text-align: left;
      align-self: start;
      word-wrap: break-word;
      color: #{default-theme.$restoplus-dark};
      grid-area: name;
      padding: 0 16px;
      margin-top: 8px;
    }

    .description {
      @include typography.body-regular;
      align-self: start;
      color: #{default-theme.$material-gray-600};
      overflow: hidden;
      height: min-content;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      grid-area: description;
      padding: 0 16px;
    }

    .price {
      align-self: center;
      font-style: normal;
      @include typography.body-regular;
      text-align: left;
      color: #{default-theme.$restoplus-dark};
      grid-area: price;
      padding-left: 16px;
    }

    .item-dietary {
      @include typography.caption-regular;
      color: #757575;
      justify-self: flex-end;
      align-self: center;
      grid-area: dietary;
      padding-right: 16px;
    }

    .image {
      grid-area: image;
    }

    .body.no-image {
      grid-template-columns: unset;
      height: unset;

      .item-info > .description > .content {
        -webkit-line-clamp: unset;
      }
    }

    &.disabled {
      cursor: auto;
    }
  }
}
