@use "../viewport" as viewport;
@use "../constants" as constants;
@use "../themes/default" as default-theme;
@use "../typography" as typography;
@use "../colors";

@include viewport.tablet {
  .order-view-page {
    > .header {
      padding: 0;
      .wrapper {
        display: none;
      }
    }

    .order-tracking-label {
      margin: 5px constants.$horizontal-gap;
      color: #27283e;
      line-height: 24px;
      font-size: 18px;
      font-weight: 600;
    }

    .body {
      max-width: constants.$max-body-width;
      padding-bottom: 92px;

      .order-placed-time {
        @include typography.h3;
        padding-top: 71px;
        margin: 0 auto;    
        text-align: center;
        color: colors.$light-gray-900;
      }

      .cancel-order-info {
        margin: 0 constants.$horizontal-gap;
        padding-top: 42px;
        text-align: center;
        color: colors.$material-gray-500;

        span {
          @include typography.body-bold;
        }

        .contact {
          @include typography.caption-regular;
          a {
            margin-left: 3px;
            color: #{default-theme.$restoplus-teal};
          }
        }
      }

      .buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        > .customer-review-link-button {
          border: 1px solid colors.$material-gray-300;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
          background-color: colors.$white;
          padding: 20px;
          margin-top: 44px;
  
          > .label {
            display: flex;
            align-items: center;
  
            img {
              width: 44px;
              height: 44px;
            }
  
            span {
              color: colors.$blue-gray-900;
              padding-left: 16px;
            }
          }
        }

        .back-to-menu-button {
          background-color: transparent;
          border: none;
          box-shadow: none;
          color: colors.$material-gray-500;
          margin-top: 16px;
          padding: 14px 50px;
        }
      }

      .order-view-header {
        margin-top: 87px;
        
        > .header {
          @include typography.h1;
          text-align: left;
          color: #{default-theme.$restoplus-dark};
          margin: 0 20px 23px 20px;
        }

        .order-view-crumbs {
          position: relative;
          display: flex;
          flex-direction: row;
          margin: 0px 20px -1px 20px;
          > span {
            align-self: center;
            @include typography.body-regular;
            text-align: left;
            color: #{default-theme.$restoplus-dark};
          }

          .caret-right {
            height: 14px;
            width: 14px;
            margin: 14px;
            background-position: center;
            background-image: url('../images/caret-right-icon.svg');
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }
}
