@use '../viewport';
@use '../themes/default' as default-theme;
@use '../colors';
@use '../typography';
@use '../buttons';
@import './common-styles-desktop';

@include viewport.tablet {
  .otp-verifier-widget {
    height: 100%;
    display: grid;

    .otp-input-wrapper {
      .otp-input {
        input {
          box-sizing: border-box;

          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: 31px;
          text-align: center;

          width: 100%;
          padding: 16px;

          border-radius: 6px;
          border: 1px solid default-theme.$material-gray-400;

          &::placeholder {
            @include typography.body-regular;
            color: default-theme.$material-gray-600;
            letter-spacing: normal;
          }
        }
      }
    }

    .error-banner {
      align-self: start;
      background: default-theme.$red-light;
      margin: 20px auto 0 auto;
      width: 335px;
      box-sizing: border-box;
      padding: 16px;
      display: flex;
      flex-flow: row;
      grid-gap: 10.95px;
      .red-warning-icon {
        display: block;
        height: min-content;
      }
      .error-message {
        @include typography.caption-regular;
        color: default-theme.$restoplus-dark;
      }
    }

    .resend-otp {
      margin: 32px 0;
      text-align: center;
      @include typography.body-regular;
      .resend {
        @include typography.button2;
        color: default-theme.$restoplus-teal;
        cursor: pointer;

        &.disabled {
          color: colors.$blue-gray-300;
          cursor: default;
        }
      }
    }

    .recaptcha-branding {
      width: 328px;
      margin: 0 auto;
      margin-top: auto;
      @include typography.caption-regular;
      text-align: center;
      color: default-theme.$material-gray-600;
      a {
        text-decoration: none;
        color: default-theme.$restoplus-teal;
      }
    }
  }
}
