@use '../card' as card;
@use '../viewport'as viewport;
@use '../typography' as typography;
@use "../constants" as constants;
@use "../themes/default" as default-theme;

@include viewport.tablet {
  .session-view-widget {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 0;
    padding: 14.5px 20px;
    cursor: pointer;
    height: 30px;
    background: #{default-theme.$green-light};
    color: #{default-theme.$green-dark};
    @include typography.body-regular;

    .session {
      display: flex;

      .collection-info {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .datetime,
  .to,
  .address,
  .table-name {
    display: inline;
    font-weight: 700;
    margin-left: 4px;
  }

  .to {
    font-weight: 400;
  }

  .action-label {
    display: inline;
    font-weight: 700;
    margin-left: 16px;
  }
}
