@use '../card'as card;
@use '../viewport'as viewport;
@use "../themes/default" as default-theme;
@use '../typography' as typography;

@include viewport.tablet {
  .cart-widget {
    padding-bottom: 100px;
    padding-top: 68px;
    padding-left: 10px;
    display: grid;
    grid-template-areas:
      'cart-header'
      'collection-info'
      'items'
      'totals'
      'checkout';

    .collection-info {
      grid-area: collection-info;

      .session-info {
        display: none;
      }

      .minimum-order-value-info {
        display: flex;
        align-items: flex-start;
        gap: 6px;
        margin-top: 25px;

        .text {
          @include typography.caption-regular;
          color: #{default-theme.$orange-dark};
        }
      }
    }

    .empty-cart-header,
    .cart-header {
      grid-area: cart-header;
      padding-left: 5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      &.empty-cart {
        padding-top: 0;
      }

      .item-count {
        @include typography.caption-regular;
        text-align: left;
        color: #{default-theme.$material-gray-600};
      }
      .cart-header-text {
        @include typography.h4;
        text-align: left;
        color: #{default-theme.$restoplus-dark};
      }
    }

    .cart {
      @include card.container;
    }

    .empty-cart {
      padding-top: 55px;
      text-align: center;

      > .cart-image {
        margin-bottom: 29px;
      }

      > .text {
        @include typography.body-regular;
        color: #{default-theme.$material-gray-500};
      }

      > .sub-text {
        @include typography.body-regular;
        color: #{default-theme.$material-gray-600};
        margin-top: 11px;
      }
    }

    .totals {
      grid-area: totals;

      .sub-total,
      .delivery-charges,
      .public-holiday-surcharge,
      .grand-total,
      .gst-amount {
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;
        @include typography.body-regular;
        text-align: left;
        color: #{default-theme.$restoplus-dark};
        padding-bottom: 15px;
        padding-top: 10px;
        border-bottom: 1px solid #e3ebec;
      }

      .grand-total {
        padding-top: 10px;
        letter-spacing: 0.25px;
        @include typography.body-bold;
        border: none;
      }

      .gst-amount {
        padding-bottom: 40px;
      }
    }

    .checkout {
      grid-area: checkout;
      display: grid;
      grid-auto-flow: column;
      align-items: center;

      .button {
        background: #0162ff;
        color: white;
        border-radius: 10px;
        text-align: center;
        @include typography.button1;
        text-align: center;

        padding: 8px 0;
        cursor: pointer;

        &.disabled {
          background: #f1f1f5;
          color: #bcbcc5;
          cursor: not-allowed;
        }
      }

      .quantity {
        @include typography.caption-bold;
        text-align: end;
      }
    }

    .select-all {
      display: grid;
      grid-auto-flow: column;
      padding: 10px 20px 10px 15px;
      justify-content: space-between;

      > .check,
      > .remove {
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        cursor: pointer;
      }

      .check > input {
        margin-right: 8px;
        cursor: pointer;
      }

      .check > .text {
        color: #426ed9;
        @include typography.caption-bold;
      }

      .remove > i {
        font-size: 20px;
      }

      .remove {
        color: #fc6565;
        @include typography.caption-bold;
      }

      .remove.disabled {
        color: #bcbcc5;
        cursor: not-allowed;
      }
    }

    .items {
      grid-area: items;
      display: grid;
      grid-gap: 15px;
      padding-top: 25px;
      padding-bottom: 15px;

      .item {
        padding: 15px 15px 19px 15px;
        background-color: #ffffff;
        border: 1px solid #e2e2e2;
        box-shadow: 0px 2px 4px rgb(0 0 0 / 5%);
        box-sizing: border-box;
        border-radius: 8px;
        display: grid;
        grid-template-columns: 1fr min-content;
        grid-template-areas:
          'name     .         remove-icon'
          'description  description   description'
          'quantity deal      price';
      }

      .deal {
        cursor: pointer;
        display: flex;
        align-items: center;
        grid-area: deal;
        @include typography.caption-regular;
        text-align: left;
        color: #{default-theme.$orange-dark};
        text-transform: uppercase;
        padding: 20px 5px 0 5px;

        img {
          margin-top: -2px;
          margin-right: 2px;
        }
      }

      .remove-icon {
        grid-area: remove-icon;
        justify-self: flex-end;
        cursor: pointer;
        margin: 4px;
      }

      .name {
        grid-area: name;
        @include typography.body-bold;
        text-align: left;
        color: #{default-theme.$restoplus-dark};
      }

      .quantity {
        padding-top: 15px;
        grid-area: quantity;
        display: grid;
        grid-template-columns: min-content min-content min-content;
        gap: 22px;
      }

      .quantity > .number {
        padding: 0 2px;
        display: grid;
        align-self: center;
        @include typography.body-regular;
        text-align: center;
        color: #{default-theme.$restoplus-dark};
      }

      .quantity > .icon {
        align-self: center;
        display: grid;
        border-radius: 5px;
        width: 23px;
        height: 23px;
        text-align: center;
        font-size: 20px;
        color: #426ed9;
        cursor: pointer;
      }

      .price {
        padding-top: 21px;
        grid-area: price;
        text-align: right;
        @include typography.body-regular;
        justify-self: flex-end;
        color: #{default-theme.$restoplus-brand-green};
      }

      .divider {
        grid-area: divider;
        height: 1px;
        background-color: #e2e2ea;
        margin-top: 20px;
      }

      .divider.hide {
        display: none;
      }

      .item-description-view-widget {
        grid-area: description;
        padding-top: 10px;
        @include typography.caption-regular;
        text-align: left;
        color: #{default-theme.$material-gray-600};

        &.hide {
          display: none;
        }
      }
    }
  }
}
