@use '../viewport' as viewport;
@use '../themes/default' as default-theme;
@import './common-styles-mobile';

@include viewport.mobile {
  .customer-display-name-update-widget {
    height: 100%;
    display: grid;
    grid-template-rows: min-content 1fr;

    form {
      height: 100%;
      display: grid;
      grid-template-rows: min-content 1fr;
      .form-buttons {
        margin-top: auto;
        margin-bottom: 30px;
      }
    }
  }
}
