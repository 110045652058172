@use "../card"as card;
@use "../form"as form;
@use "../constants"as constants;
@use "../typography"as typography;
@use "../buttons"as buttons;
@use "../utils"as utils;
@use "../selection_list"as selection_list;
@use "../viewport"as viewport;

.update-delivery-address {
  padding: 0 !important;

  .section {
    padding: 20px 30px 10px 30px;
    border-bottom: 1px solid #e2e2ea;

    .header {
      font-size: 18px;
    }

    .sub-header {
      font-size: 16px;
    }
  }

  .title {
    font-weight: 500;
    margin-bottom: 20px;
  }

  p {
    text-align: center;
    margin: 10px !important;
  }

  .delivery-address {
    padding-bottom: 40px;
  }

  .action-buttons {
    display: grid;
    grid-gap: 18px;
    grid-auto-flow: row;
    justify-items: center;
    margin: 30px;

    button {
      width: 70%;
      font-size: 14px;
    }

    .save {
      background: var(--button-success-bg);
      color: var(--button-success-fg);
    }

    .discard {
      background: none;
      color: #fc6565;
      border: #fc6565 1px solid;
    }

    .cancel {
      background: none;
      color: var(--button-secondary-fg);
    }
  }
}
