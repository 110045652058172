@use "../viewport" as viewport;
@use '../themes/default' as default-theme;
@use "../typography" as typography;

@include viewport.tablet {
  .upsell-items-widget {
    padding: 24px;

    .header {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        @include typography.h3;
        color: #{default-theme.$restoplus-dark};
      }

      .close-icon {
        width: 15px;
        cursor: pointer;
      }
    }

    .upsell-items {
      margin: 20px 0;
      overflow: hidden scroll;
      max-height: calc(100vh - 420px);
      display: grid;
      gap: 18px;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #{default-theme.$restoplus-dark}50;
        border-radius: 20px;
      }
    }

    .actions {
      button {
        width: 100%;
      }
    }
  }
}