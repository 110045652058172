@use "../viewport" as viewport;
@use "../constants" as constants;
@use "../themes/default" as default-theme;
@use "../typography" as typography;

@include viewport.mobile {
  .swal2-modal {
    border-radius: 16px !important;
    width: 327px !important;
    padding: 0px !important;
    margin: 0 !important;

    .swal2-header {
      position: relative;
      padding: 10px constants.$horizontal-gap;
    }

    .swal2-content {
      white-space: pre-wrap;
      padding: 0 constants.$horizontal-gap;

      #swal2-content {
        margin-bottom: 20px;

        > b {
          font-weight: 700;
        }
      }
    }

    .swal2-title {
      justify-content: center;
      margin-top: 15px;
      width: 100%;
      @include typography.h4;
      color: #{default-theme.$restoplus-dark};
    }

    .swal2-html-container {
      margin: 0 auto;
      position: relative;
      @include typography.body-regular;
      color: #{default-theme.$restoplus-dark};
      text-align: center;
    }

    .swal2-actions {
      display: flex;
      gap: 8px;
      flex-direction: row-reverse;
      justify-content: space-between !important;
      padding: 17px;
      margin: 0;
      background: #fcfcfc;
      border-radius: 0px 0px 16px 16px;
      box-shadow: inset 0px 1px 0px #e8ecf1;

      button {
        @include typography.button1;
        text-align: center !important;
        padding: 14px;
        text-transform: uppercase;
      }

      .confirm-button {
        flex: 1;
        background: #4cbba1 !important;
        border-radius: 8px !important;
      }

      .cancel-button {
        flex: 1;
        background: #fcfcfc !important;
        box-shadow: inset 0px 0px 1px 2px #e8ecf1 !important;
        border-radius: 8px !important;
        color: #{default-theme.$material-gray-600};
      }
    }
  }
}
